// set visibility by device/screen size
.desktop-view {
  display: none;

  @include mq('large') {
    display: block;
  }

  @include aem-editor-view {
    display: block;
  }
}

// visible on tablet size & larger
.tablet-desktop-view {
  display: none;

  @include mq('medium') {
    display: block;
  }

  @include aem-editor-view {
    display: block;
  }
}

// visible on tablet size & smaller
.tablet-mobile-view {
  display: block;

  @include mq('large') {
    display: none;
  }

  @include aem-editor-view {
    display: block;
  }
}

.mobile-view {
  display: block;

  @include mq('medium') {
    display: none;
  }

  @include aem-editor-view {
    display: block;
  }
}

// padding adjustments
.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

// centers the contents present in an element
// used for centering button elements or any other elements that has max-width
.u-mx-auto {
  margin-left: auto;
  margin-right: auto;

  // needed to increase specificity
  &.emu-button-v2.emu-button-v2.emu-button {
    display: block;
  }
}

// centered container
.u-container-default {
  padding-left: 15px;
  padding-right: 15px;
}

// utilities classes for text
[class*='u-text-color'],
[class*='u-title'] {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  li,
  p,
  a,
  span {
    color: inherit;
  }
}

.u-text-color--white {
  color: var(--emu-common-colors-white) !important;
}

.u-text-color--black {
  color: var(--emu-common-colors-black) !important;
}

.u-text-color--blue {
  color: var(--emu-common-colors-brand-blue-600) !important;
}

.u-text-color--violet {
  color: var(--emu-common-colors-brand-violet-500) !important;
}

.u-text-color--pink {
  color: var(--emu-common-colors-brand-pink-500) !important;
}

.u-text-color--grey {
  color: var(--emu-common-colors-dark-grey-400) !important;
}

.u-text-color--light-pink {
  color: var(--emu-common-colors-brand-pink-400) !important;
}

// utility classes for the background colors, button is added for text to get background color on hover
.u-bg-color--blue {
  background-color: var(--emu-common-colors-brand-blue-500);

  .button {
    .emu-button-v2.emu-button,
    .btn--violet.emu-button-v2.emu-button,
    .btn--grey.emu-button-v2.emu-button,
    .btn--light-pink.emu-button-v2.emu-button {
      &:hover {
        color: var(--emu-common-colors-brand-blue-500);
      }
    }
  }
}

.u-bg-color--dark-blue {
  background-color: var(--emu-common-colors-brand-blue-600);

  .button {
    .emu-button-v2.emu-button,
    .btn--violet.emu-button-v2.emu-button,
    .btn--grey.emu-button-v2.emu-button,
    .btn--light-pink.emu-button-v2.emu-button {
      &:hover {
        color: var(--emu-common-colors-brand-blue-600);
      }
    }
  }
}

.u-bg-color--light-blue {
  background-color: var(--emu-common-colors-brand-blue-50);

  .button {
    .emu-button-v2.emu-button,
    .btn--violet.emu-button-v2.emu-button,
    .btn--grey.emu-button-v2.emu-button,
    .btn--light-pink.emu-button-v2.emu-button {
      &:hover,
      &:focus {
        color: var(--emu-common-colors-brand-blue-50);
      }

      &:active {
        background-color: var(--emu-common-colors-transparent);
        color: var(--emu-common-colors-brand-blue-500);
      }
    }
  }
}

.u-bg-color--secondary-light-blue {
  background-color: var(--emu-common-colors-brand-blue-100);

  .button {
    .emu-button-v2.emu-button,
    .btn--violet.emu-button-v2.emu-button,
    .btn--grey.emu-button-v2.emu-button,
    .btn--light-pink.emu-button-v2.emu-button {
      &:hover,
      &:focus {
        color: var(--emu-common-colors-brand-blue-100);
      }

      &:active {
        background-color: var(--emu-common-colors-transparent);
        color: var(--emu-common-colors-brand-blue-100);
      }
    }
  }
}

.u-bg-color--violet {
  background-color: var(--emu-common-colors-brand-violet-400);

  .button {
    .emu-button-v2.emu-button,
    .btn--violet.emu-button-v2.emu-button,
    .btn--grey.emu-button-v2.emu-button,
    .btn--light-pink.emu-button-v2.emu-button {
      &:hover {
        color: var(--emu-common-colors-brand-violet-400);
      }
    }
  }
}

.u-bg-color-pink {
  background-color: var(--emu-common-colors-brand-pink-500);

  .button {
    .emu-button-v2.emu-button,
    .btn--violet.emu-button-v2.emu-button,
    .btn--grey.emu-button-v2.emu-button,
    .btn--light-pink.emu-button-v2.emu-button {
      &:hover,
      &:focus {
        color: var(--emu-common-colors-brand-pink-500);
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }

      &:active {
        background-color: var(--emu-common-colors-transparent);
        color: var(--emu-common-colors-brand-blue-500);
      }
    }
  }
}

.u-bg-color--violet-500 {
  background-color: var(--emu-common-colors-brand-violet-300);

  .button {
    .emu-button-v2.emu-button.emu-button-v2.emu-button {
      &:hover,
      &:focus,
      &:active {
        color: var(--emu-common-colors-brand-violet-300);
        background-color: var(--emu-common-colors-brand-violet-500);
        border-color: var(--emu-common-colors-brand-violet-500);
      }
    }
  }
}

.u-bg-color--light-pink {
  background-color: var(--emu-common-colors-brand-pink-300);
}

// hides br in mobile and shows above 768px
.u-hide-br-in-mobile {
  br {
    display: none;

    @include mq('medium') {
      display: block;
    }
  }
}

// hides br in mobile and shows above 992px
.u-show-br-in-lg-desktop {
  display: none;

  @include mq('medium-plus') {
    display: block;
  }
}

// hides br above 768px
.u-hide-br-in-desktop {
  br {
    @include mq('medium') {
      display: none;
    }
  }
}

// makes the emphasis to display block in mobile
.u-emphasis-block-in-mobile {
  .emphasis {
    display: block;

    @include mq('medium') {
      display: inline;
    }
  }
}

// makes the emphasis to display inline-block in mobile
.u-emphasis-inline-block-in-mobile {
  .emphasis {
    display: inline-block;

    @include mq('medium') {
      display: inline;
    }
  }
}

// makes the emphasis display block in desktop
.u-emphasis-block-in-desktop {
  .emphasis {
    @include mq('medium') {
      display: block;
    }
  }
}

// makes the emphasis display block in desktop
.u-emphasis-inline-block-in-desktop {
  .emphasis {
    @include mq('medium') {
      display: inline-block;
    }
  }
}

// makes the emphasis display block
.u-emphasis-block {
  .emphasis {
    display: block;
  }
}

// makes the emphasis display inline-block
.u-emphasis-inline-block {
  .emphasis {
    display: inline-block;
  }
}

// adds colors and font families to titles
// adds only blue color to the title
.u-title-primary--blue {
  color: var(--emu-common-colors-brand-blue-500);
  i,
  b {
    font-family: var(--emu-common-font-families-aleo);
    font-weight: 700;
    font-style: italic;
  }
}

// adds only violet color to the title
.u-title-primary--violet {
  color: var(--emu-common-colors-brand-violet-400);

  i,
  b {
    font-family: var(--emu-common-font-families-aleo);
    font-weight: var(--emu-common-font-weight-bold);
    font-style: italic;
  }
}

// adds only light pink color the title
.u-title-primary--light-pink {
  color: var(--emu-common-colors-brand-pink-400);

  i,
  b {
    font-family: var(--emu-common-font-families-aleo);
    font-weight: var(--emu-common-font-weight-bold);
    font-style: italic;
  }
}

// adds white and pink color to the title
.u-title-secondary--pink {
  color: var(--emu-common-colors-white);

  i,
  b {
    font-family: var(--emu-common-font-families-aleo);
    font-weight: var(--emu-common-font-weight-bold);
    font-style: italic;
    color: var(--emu-common-colors-brand-pink-500);
  }
}

// adds grey and blue color to the title
.u-title-tertiary--blue {
  color: var(--emu-common-colors-dark-grey-400);

  i,
  b {
    font-family: var(--emu-common-font-families-aleo);
    font-weight: var(--emu-common-font-weight-bold);
    font-style: italic;
    color: var(--emu-common-colors-brand-blue-500);
  }
}

//adds grey and light blue color to the title
.u-title-tertiary--light-blue {
  color: var(--emu-common-colors-dark-grey-400);

  i,
  b {
    font-family: var(--emu-common-font-families-aleo);
    font-weight: var(--emu-common-font-weight-bold);
    font-style: italic;
    color: var(--emu-common-colors-brand-blue-200);
  }
}

.u-container-max-width {
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @include mq('medium') {
    max-width: 100%;
  }

  @include mq('medium-plus') {
    max-width: 960px;
  }

  @include mq('x-large') {
    max-width: var(--emu-common-sizing-xxxl);
  }
}

// hides background image in the mobile devices
.u-hide-background-image-in-mobile {
  position: relative;
  background-size: 100% !important; //to override aem container bg styles

  @include mq('767px', 'max-width') {
    background-image: none !important;
  }
}

// hides overflow in the mobile devices
.mobile-hide-overflow {
  @include mq('large', 'max-width') {
    overflow: hidden;
  }
}

.u-container-max-width {
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @include mq('medium') {
    max-width: 100%;
  }

  @include mq('medium-plus') {
    max-width: 960px;
  }

  @include mq('x-large') {
    max-width: var(--emu-common-sizing-xxxl);
  }
}

.u-wave-container {
  img,
  svg {
    display: block;
    width: 100%;
  }
}

.u-hide {
  display: none !important;

  @include aem-editor-view {
    display: initial !important;
  }
}

.u-accordion--violet {
  &.aaaem-accordion {
    .aaaem-accordion {
      &__title {
        color: var(--emu-common-colors-brand-violet-500);
      }

      &__panel .js-badger-accordion-panel-inner .cmp-text {
        color: var(--emu-common-colors-brand-violet-400);
      }

      &__button.plus-minus-icon .aaaem-accordion__icon {
        background-image: url('./assets/images/accordion-open-icon-purple.svg');
      }

      &__header.-ba-is-active
        .aaaem-accordion__button.plus-minus-icon
        .aaaem-accordion__icon {
        background-image: url('./assets/images/accordion-close-icon-purple.svg');
      }
    }
  }
}

.u-underline-as-no-word-wrap {
  u {
    text-decoration: none;
    white-space: nowrap;
    font-family: inherit;
  }
}
