[data-component='modal'] .modal {
  &-wrapper {
    display: block;
    opacity: 0;
    transition: all 0.3s linear 0.3s;
    pointer-events: none;
    padding-right: var(--scrollbar-width, 0);

    @include aem-editor-view {
      opacity: 1 !important;
    }

    &.is-open {
      opacity: 1;
      transition-delay: var(--emu-common-other-time-delay-none);
      pointer-events: initial;

      @include mq('medium') {
        transform: translateY(0);
      }

      .modal-content {
        transition-delay: 0.3s;
        opacity: 1;

        @include mq('medium') {
          transform: translateY(0);
        }
      }
    }
  }

  &-content {
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease-out;
    opacity: 0;

    @include mq('medium') {
      top: var(--emu-common-sizing-none);
      transform: translateY(-50px);
    }

    @include aem-editor-view {
      opacity: 1 !important;
    }
  }

  &__header {
    background-color: var(--emu-semantic-colors-brand-grey-400);
    height: 82.2px;
    padding-top: var(--emu-common-spacing-medium);
    padding-bottom: var(--emu-common-spacing-medium);
    padding-left: var(--emu-common-spacing-medium);
    padding-right: var(--emu-common-spacing-medium);
    border: 1px solid var(--emu-semantic-colors-brand-grey-400);
    border-top-right-radius: 4.8px;
    border-top-left-radius: 4.8px;

    //breakpoint is added to match live
    @include mq('medium-plus') {
      display: none;
    }
  }

  &__body {
    border: var(--emu-semantic-border-width-thin) solid rgba(0, 0, 0, 0.2);
    padding-left: 48px;
    padding-top: 48px;
    padding-bottom: 48px;
    padding-right: 48px;

    //breakpoint is added to match live
    @include mq('medium-plus') {
      padding-top: 58.4px;
      padding-left: var(--emu-common-spacing-large);
      padding-right: var(--emu-common-spacing-large);
    }
  }

  &__title {
    margin-top: var(--emu-semantic-sizing-none);
    margin-bottom: var(--emu-common-spacing-medium);

    h2 {
      font-size: var(--emu-semantic-font-sizes-narrow-xl);
      line-height: 38px;
      letter-spacing: -0.3px;

      //breakpoint is added to match live
      @include mq('medium-plus') {
        font-size: 36px;
        line-height: 48px;
        margin-bottom: 24px;
      }

      sup {
        font-size: 13px;
        font-weight: var(--emu-common-font-weight-black);
        top: -4px;
        left: 1px;

        @include mq('medium-plus') {
          font-size: 15px;
          left: 3px;
          top: -4px;
        }
      }
    }
  }

  &__description {
    p {
      line-height: 30px;
      letter-spacing: 1.26px;

      @include mq('medium-plus') {
        font-size: 14px;
        letter-spacing: 0.98px;
        line-height: var(--emu-semantic-sizing-one-line-height-wide);
      }

      @include mq('large') {
        line-height: 15.4px;
        letter-spacing: 0.07em;
      }
    }

    sup {
      font-size: 7px;
      font-weight: var(--emu-common-font-weight-black);
      left: -1px;
      top: -2px;
    }

    a {
      color: var(--emu-semantic-colors-brand-primary-600);

      &:active {
        color: var(--emu-semantic-colors-brand-primary-600);
      }
    }
  }
}

.js-modal-opened {
  overflow: hidden;
  padding-right: var(--scrollbar-width);
}
