.faq {
  &__mobile-top-wave {
    margin-top: -0.8%; // adjusted value to match the live site for mobile
    margin-bottom: -8px;
  }

  &__title {
    padding-top: 45px;
    letter-spacing: -0.2px;

    &--pink {
      // increasing specificity
      &.faq__title {
        color: var(--emu-common-colors-brand-pink-400);
      }
    }

    @include mq('medium') {
      padding-top: 4%;
      color: var(--emu-common-colors-black);
      font-size: 32px;
      line-height: 48px;
      letter-spacing: -0.4px;
    }

    @include mq('large') {
      line-height: var(--emu-common-line-heights-wide-xxl);
      font-size: var(--emu-common-font-sizes-wide-xxl);
      letter-spacing: -0.8px;
    }

    h1,
    h2,
    h3,
    h4 {
      @include mq('medium') {
        font-size: inherit;
        line-height: inherit;
      }
    }

    .emphasis {
      display: block;

      @include mq('medium') {
        display: inline;
      }
    }
  }

  &__description {
    line-height: 25px;
    letter-spacing: 0.54px;

    @include mq('medium') {
      font-size: 17px;
    }

    @include mq('large') {
      font-size: var(--emu-common-font-sizes-wide-small);
      letter-spacing: normal;
    }

    p {
      width: 95%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: var(--emu-semantic-spacing-none);
      padding-top: 10px;

      @include mq('medium') {
        padding-left: 50px;
        padding-right: 50px;
        width: 100%;
      }

      @include mq('large') {
        width: 95%;
        padding-left: var(--emu-semantic-spacing-none);
        padding-right: var(--emu-semantic-spacing-none);
      }
    }
  }

  &__main {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 25px;
    margin-top: -6px;

    @include mq('medium') {
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
      padding-bottom: 50px;
      margin-top: var(--emu-common-spacing-none);
    }

    p {
      &:has(+ *) {
        margin-bottom: 10px;

        b:only-child {
          margin-bottom: -5px;
          display: block;
        }
      }
    }

    ul {
      padding-left: var(--emu-common-spacing-none);
      margin-left: 26.4px;
      margin-top: var(--emu-common-spacing-none);

      @include mq('medium') {
        margin-left: 14.4px;
      }
    }

    li {
      margin-bottom: var(--emu-common-spacing-none);
    }

    a {
      font-family: var(--emu-common-font-families-graphik);
      border-bottom: var(--emu-common-border-width-thin) solid currentColor;
      padding-bottom: 1px;

      u {
        text-decoration: none;
      }

      &,
      &:focus,
      &:hover,
      &:active {
        color: inherit;
        text-decoration: none;
      }
    }
  }
}
