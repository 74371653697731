.image-text {
  .cmp-image__image {
    width: 100%;
  }
  svg {
    @include mq('medium') {
      width: 100%;
    }
  }

  &__container {
    overflow: hidden;
  }

  &__flex-container {
    display: flex;
    flex-direction: column-reverse;

    @include mq('medium') {
      flex-direction: row;
      max-height: 700px; // as per the live
    }

    > .container:first-child {
      @include mq('medium') {
        max-width: 40%; // to match live
      }

      @include mq('medium-plus') {
        max-width: 45%; // as per live
      }
    }

    > .container:last-child {
      @include mq('medium') {
        max-width: 55%; // to match live
      }

      // breakpoint added to match with the live site
      @include mq('x-large') {
        max-width: 48%; // to match live
      }
    }
  }

  &__image-container {
    &-image {
      margin-top: 12px;
      margin-bottom: 15px;

      @include mq('medium') {
        margin-top: var(--emu-semantic-spacing-none);
        margin-bottom: var(--emu-semantic-spacing-none);
      }
    }
  }

  &__content {
    @include mq('medium') {
      display: flex;
      align-items: flex-end;
      justify-content: space-evenly;
      flex-direction: column;
      height: 100%;
    }

    @include mq('medium-plus') {
      margin-right: 35px;
    }
  }

  &__title {
    margin-top: 15%; // as per live
    margin-bottom: var(--emu-common-spacing-small);

    @include mq('medium') {
      text-align: right;
      padding-top: 35px;
      margin-bottom: var(--emu-common-spacing-small);
      margin-top: var(--emu-semantic-spacing-none);
    }

    @include mq('medium-plus') {
      padding-top: 38px;
    }

    h2 {
      @include mq('medium') {
        font-size: 32px;
        line-height: 35.2px;
        letter-spacing: 0.1px;
      }

      @include mq('large') {
        font-size: var(--emu-semantic-font-sizes-wide-xxl);
        line-height: 60px;
      }

      sup {
        font-size: 20px;
        left: -1px;

        @include mq('xxx-large') {
          font-weight: var(--emu-common-font-weight-black);
          top: -7px;
        }
      }

      i {
        letter-spacing: -0.4px;
        font-weight: var(--emu-common-font-weight-medium);

        @include mq('large') {
          letter-spacing: -0.9px;
        }

        @include mq('x-large') {
          letter-spacing: -0.19px;
        }
      }
    }
  }

  &__disclaimer {
    margin-bottom: 40px;
    max-width: 90%; // as per live

    @include mq('medium') {
      margin-top: 21px;
      margin-bottom: 15px;
      max-width: 100%;
      padding-top: var(--emu-semantic-spacing-none);
    }

    @include mq('medium-plus') {
      margin-top: 15px;
    }

    @include mq('large') {
      margin-top: 25px;
      margin-bottom: var(--emu-semantic-spacing-none);
    }

    @include mq('x-large') {
      position: relative;
      left: 35px;
    }

    &.cmp-text {
      font-size: var(--emu-semantic-font-sizes-narrow-small);
      line-height: var(--emu-semantic-line-heights-narrow-small);
      letter-spacing: 0.1px;

      @include mq('medium') {
        line-height: 14px;
        letter-spacing: 0.01px;
      }

      sup {
        text-indent: -7px;
        display: inline-block;
      }

      p {
        margin-bottom: var(--emu-semantic-spacing-none);
      }
    }
  }

  &__quote {
    margin-bottom: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
    max-width: 85%; // as per live

    @include mq('medium') {
      margin-bottom: var(--emu-common-spacing-medium);

      max-width: 100%;
    }

    @include mq('x-large') {
      max-width: 97%;
    }

    @include mq('xxx-large') {
      max-width: 100%;
    }

    &.cmp-text {
      letter-spacing: 0.54px;

      @include mq('medium') {
        letter-spacing: 0.96px;

        font-size: var(--emu-semantic-font-sizes-narrow-xl);
        line-height: 38px;
      }

      @include mq('large') {
        letter-spacing: 2.24px;
      }

      p {
        margin-bottom: var(--emu-semantic-spacing-none);
      }

      br {
        // breakpoint added to match with the live site
        @include mq('576px') {
          display: none;
        }

        &:last-child {
          @include mq('medium-plus') {
            display: block;
          }
        }
      }
    }
  }

  &__patient-name {
    margin-bottom: 5px;
    padding-top: var(--emu-semantic-spacing-none);

    @include mq('medium') {
      margin-bottom: var(--emu-common-spacing-medium);
    }

    @include mq('xxx-large') {
      margin-top: 3px;
    }

    &.cmp-text {
      letter-spacing: 5.4px;

      @include mq('medium') {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.42px;
      }

      @include mq('large') {
        font-size: var(--emu-semantic-font-sizes-wide-body);
        line-height: var(--emu-semantic-line-heights-wide-xs);
        letter-spacing: 1.26px;
      }

      p {
        margin-bottom: var(--emu-semantic-spacing-none);
      }
    }
  }

  &__btn {
    position: unset;
    margin-top: 42px;
    margin-bottom: 75px;
    width: 320px; // as per the live

    @include mq('medium') {
      margin-top: 10px;
      margin-bottom: 20px;
      width: 100%;
    }

    @include mq('large') {
      margin-top: 25px;
      margin-bottom: 25px;
    }

    &.emu-button-v2.emu-button__primary-outline.emu-button {
      padding-right: 25px;
      padding-left: 25px;
      width: 100%;

      @include mq('medium') {
        margin-left: var(--emu-semantic-spacing-none);
        margin-right: var(--emu-semantic-spacing-none);
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 15px;
        padding-right: 15px;
        width: auto;
      }

      @include mq('large') {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 30px;
        padding-right: 30px;
      }
    }

    span {
      width: 100%;
      letter-spacing: 4.2px;
      line-height: 16px;

      @include mq('medium') {
        letter-spacing: 3.6px;
        font-size: var(--emu-semantic-font-sizes-wide-xs);
      }

      @include mq('large') {
        font-size: 15px;
        letter-spacing: 4.5px;
        line-height: var(--emu-semantic-line-heights-wide-xs);
      }
    }
  }

  &--left-align {
    .image-text {
      &__flex-container {
        flex-direction: column;

        @include mq('medium') {
          flex-direction: row-reverse;
        }
      }
    }
  }
}
