.button {
  .emu-button {
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .btn--violet {
    // specificity is required to override the default styles
    &.emu-button-v2.emu-button.emu-button {
      border-color: var(--emu-common-colors-brand-violet-500);
      color: var(--emu-common-colors-brand-violet-500);

      &:hover,
      &:focus {
        background-color: var(
          --emu-common-colors-brand-violet-500
        ) !important; // needed to override any other styles from page, added with page id in hierarchy
        color: var(
          --emu-common-colors-white
        ) !important; // needed to override any other styles from page, added with page id in hierarchy
      }
    }
  }

  .btn--grey {
    // specificity is required to override the default styles
    &.emu-button-v2.emu-button.emu-button {
      border-color: var(--emu-common-colors-dark-grey-400);
      color: var(--emu-common-colors-dark-grey-400);

      &:hover,
      &:focus {
        background-color: var(--emu-common-colors-dark-grey-400);
        color: var(--emu-common-colors-brand-blue-50);
      }

      &:active {
        background-color: var(--emu-common-colors-transparent);
      }
    }
  }

  .btn--light-pink {
    // specificity is required to override the default styles
    &.emu-button-v2.emu-button.emu-button {
      border-color: var(--emu-common-colors-brand-pink-400);
      color: var(--emu-common-colors-brand-pink-400);

      &:hover,
      &:focus {
        background-color: var(--emu-common-colors-brand-pink-400);
        color: var(--emu-common-colors-brand-violet-400);
      }

      &:active {
        background-color: var(--emu-common-colors-transparent);
      }
    }
  }
}
