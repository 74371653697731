#natrelle-collection {
  .natrelle-inspira-implants {
    .image-text {
      &__container {
        padding-top: 6%;
        overflow: visible;

        @include mq('medium') {
          padding-bottom: 2%;
        }
      }

      &__flex-container {
        flex-direction: column;
        min-height: 630px; // as per live
        position: relative;

        @include mq('medium') {
          flex-direction: row;
          min-height: 470px; // as per the live
        }

        .container:first-child {
          @include mq('medium') {
            max-width: 73%; // as per live
          }
        }

        .container:last-child {
          @include mq('medium') {
            max-width: 27%; // as per live
            padding-left: 3%;
          }

          @include mq('large') {
            padding-left: 6%;
          }
        }
      }

      &__image-container {
        &-image {
          margin-top: var(--emu-semantic-spacing-none);
          margin-bottom: var(--emu-semantic-spacing-none);

          img {
            max-width: 300px; // as per live
            width: 100%;
            position: absolute;
            top: 79%;
            right: -15px;

            @include mq('medium') {
              max-width: 180px; // as per live
              top: 23px;
              right: unset;
            }

            @include mq('large') {
              min-width: 240px; // as per live
            }
          }
        }
      }

      &__content {
        padding-bottom: 110px;

        @include mq('medium') {
          margin-right: var(--emu-semantic-spacing-none);
          justify-content: normal;
          padding-bottom: var(--emu-semantic-spacing-none);
        }
      }

      &__title {
        margin-top: var(--emu-semantic-spacing-none);

        @include mq('medium') {
          padding-top: var(--emu-semantic-spacing-none);
        }

        h2 {
          @include mq('medium') {
            font-size: 38px;
            line-height: 48px;
          }

          @include mq('medium-plus') {
            font-size: 50px;
            letter-spacing: -0.1px;
          }

          @include mq('large') {
            line-height: 70.4px;
          }

          // breakpoint added to match with the live site
          @include mq('1201px') {
            font-size: 57px;
          }

          sup {
            top: -5.5px;
            left: -4.5px;
            font-size: 45%;
            font-weight: var(--emu-semantic-font-weight-black);

            @include mq('medium') {
              top: -5px;
            }
          }

          i {
            letter-spacing: -0.9px;

            @include mq('x-large') {
              letter-spacing: -1.1px;
            }
          }
        }
      }

      &__description {
        padding-top: 3%;

        @include mq('medium') {
          max-width: 92%; // as per live
        }

        @include mq('large') {
          max-width: 100%;
        }

        &.cmp-text {
          letter-spacing: 0.03em;

          @include mq('large') {
            letter-spacing: 0.01em;
          }
        }

        sup {
          top: 0;
          left: 0;
          font-size: 65%;
        }

        sup + sup {
          font-size: 45%;
          left: -1px;
          top: -1px;
        }
      }
    }
  }

  .five-fullness-sec {
    img {
      width: 100%;
    }

    &__mobile-top-wave {
      img {
        background: linear-gradient(
          180deg,
          var(--emu-common-colors-brand-blue-50) 0%,
          var(--emu-common-colors-brand-blue-50) 50%,
          var(--emu-common-colors-brand-blue-50) 7%,
          var(--emu-common-colors-brand-blue-50) 2.25%,
          var(--emu-common-colors-brand-blue-50) 5%,
          #99dde9 99%,
          #99dde9 100%
        ); // color variable not present for #99dde9
      }
    }

    &__desktop-top-wave {
      background-color: var(--emu-common-colors-brand-blue-100);
      img {
        max-width: 1440px;
        background-color: var(--emu-common-colors-brand-blue-50);
      }
    }

    &__wrapper {
      padding-top: 3%;
      background-color: var(--emu-common-colors-brand-blue-100);

      @include mq('medium') {
        margin-top: var(--emu-semantic-spacing-none);
      }
    }

    .u-container-max-width {
      @include mq('medium-plus') {
        max-width: 720px; // as per live
      }

      @include mq('large') {
        max-width: var(--emu-common-sizing-xxxl); // as per live
      }
    }

    .content-box {
      &__title {
        margin-bottom: var(--emu-common-spacing-small);

        h2 {
          font-weight: var(--emu-common-font-weight-regular);
          letter-spacing: -0.4px;

          @include mq('medium') {
            font-size: var(--emu-semantic-font-sizes-narrow-xxl);
            line-height: 48px;
            letter-spacing: -0.5px;
          }

          @include mq('large') {
            font-size: var(--emu-common-font-sizes-wide-xxl);
            line-height: var(--emu-common-line-heights-wide-xxl);
            letter-spacing: -0.7px;
          }

          @include mq('xxx-large') {
            letter-spacing: -0.4px;
          }
        }
      }

      &__description {
        padding-top: 1%;
        max-width: 100%;
        margin-bottom: var(--emu-common-spacing-medium);

        @include mq('medium') {
          font-size: var(--emu-common-font-sizes-wide-body);
          line-height: var(--emu-common-line-heights-wide-body);
          max-width: 92%;
        }

        @include mq('large') {
          max-width: 100%;
        }

        @include mq('x-large') {
          text-indent: unset;
        }

        .emphasis {
          display: inline-block;
        }

        p {
          margin-bottom: var(--emu-common-spacing-small);
          letter-spacing: 0.54px;

          @include mq('large') {
            letter-spacing: 0.18px;
          }

          sup {
            font-size: 9px;
            top: -2px;
            left: 0;
          }
        }
      }
    }

    &__sub-heading {
      h3 {
        font-size: var(--emu-semantic-font-sizes-narrow-medium);
        line-height: 32px;
        letter-spacing: 0.2em;
        font-weight: var(--emu-common-font-weight-bold);
        font-family: var(--emu-common-font-families-graphik);

        @include mq('medium') {
          letter-spacing: normal;
          line-height: 28.8px;
        }

        @include mq('large') {
          max-width: 75%; // to match live
        }

        sup {
          font-size: 45%;
          top: -0.2em;
          letter-spacing: normal;
        }
      }
    }

    &__projections {
      padding-top: 10px;

      @include mq('medium') {
        padding-top: 35px;
      }

      &-title {
        margin-bottom: 24px;

        @include mq('medium') {
          margin-bottom: var(--emu-common-spacing-small);
        }

        h3 {
          font-family: var(--emu-common-font-families-graphik);
          font-weight: var(--emu-common-font-weight-bold);

          @include mq('medium') {
            line-height: 24px;
          }

          @include mq('large') {
            max-width: 100%;
          }
        }
      }

      &-description {
        &.content-box__description {
          padding-top: var(--emu-semantic-spacing-none);
          margin-bottom: var(--emu-semantic-spacing-none);
          max-width: 100%;

          @include mq('medium') {
            max-width: 92%; // as per live
          }

          @include mq('large') {
            max-width: 100%;
            letter-spacing: 0.18px;
          }
        }
      }
    }

    &__five-type {
      &-title {
        padding-top: 4%;
        padding-bottom: 4%;
        margin-bottom: var(--emu-common-spacing-small);

        &.five-fullness-sec__sub-heading {
          h3 {
            // letter-spacing: 4.5px;
          }
        }
      }

      &-image {
        overflow: hidden;

        @include mq('medium') {
          padding-top: 40px;
          max-width: 100%;
        }

        @include mq('large') {
          max-width: var(--emu-common-sizing-xxxl);
        }

        .cmp-image {
          margin-left: -15px;
          margin-right: -17px;

          @include mq('medium') {
            margin-left: var(--emu-semantic-spacing-none);
            margin-right: var(--emu-semantic-spacing-none);
          }
        }

        &.u-container-max-width {
          @include mq('large', 'max-width') {
            max-width: 100%;
          }
        }
      }
    }

    &__shell-block {
      margin-top: -5%;

      @include mq('medium') {
        display: flex;
        margin-top: var(--emu-semantic-spacing-none);
      }

      @include mq('large') {
        margin-top: -5%;
      }

      .container:first-child {
        @include mq('medium') {
          max-width: 40%; // as per live
        }
      }

      .container:last-child {
        @include mq('medium') {
          max-width: 60%; // as per live
        }
      }

      &-title {
        padding-top: 7%;
        margin-bottom: var(--emu-common-spacing-small);

        @include mq('medium') {
          padding-top: 14%;
        }

        &.five-fullness-sec__sub-heading {
          h3 {
            @include mq('medium-plus') {
              letter-spacing: normal;
            }
          }
        }
      }

      &-description {
        &.content-box__description {
          padding-top: 5%;
          margin-bottom: var(--emu-semantic-spacing-none);

          @include mq('medium') {
            max-width: 92%; // as per live
            padding-top: var(--emu-semantic-spacing-none);
            margin-bottom: var(--emu-common-spacing-medium);
          }

          @include mq('large') {
            max-width: 100%;
            letter-spacing: 0.2px;
          }

          p {
            margin-bottom: var(--emu-semantic-spacing-none);

            sup {
              font-size: 45%;
            }

            sup + sup + sup {
              font-size: 65%; // as per live
              top: 0.1em;
            }
          }
        }
      }
    }
  }

  .round-breast-implants {
    &__above-vector-img {
      > .cmp-image__image {
        width: 100%;
        background: linear-gradient(
          180deg,
          #99dde9 0%,
          #99dde9 50%,
          #99dde9 7%,
          #99dde9 2.25%,
          #99dde9 5%,
          var(--emu-common-colors-brand-blue-50) 99%,
          var(--emu-common-colors-brand-blue-50) 100%
        ); // css variable not available for #99dde9
      }
    }

    .patients-first {
      padding-top: 4%;
      padding-bottom: 6%;

      &__container {
        padding-top: 15px;
        padding-bottom: 15px;

        @include mq('medium') {
          padding-top: var(--emu-semantic-sizing-none);
          padding-bottom: var(--emu-semantic-sizing-none);
        }
      }

      &__title {
        text-align: unset;
        padding-top: 3%;
        padding-bottom: 2%;
        margin-bottom: var(--emu-common-spacing-small);
        max-width: 100%;

        @include mq('medium') {
          padding-top: 3%;
        }

        > h2 {
          @include mq('medium') {
            font-size: 25.6px;
            line-height: var(--emu-common-line-heights-wide-xxl);
          }

          @include mq('large') {
            font-size: var(--emu-common-font-sizes-wide-xxl);
            line-height: 60px;
          }

          @include mq('x-large') {
            line-height: var(--emu-common-line-heights-wide-xxl);
          }

          > i {
            letter-spacing: -0.7px;

            @include mq('medium') {
              letter-spacing: -0.5px;
            }

            @include mq('medium-plus') {
              letter-spacing: -1px;
            }
          }

          sup {
            font-size: 47%;
            top: 0;
            left: -1px;
            font-weight: var(--emu-semantic-font-weight-black);
            font-style: normal;

            @include mq('medium-plus') {
              top: -7px;
              left: -3px;
            }

            @include mq('large') {
              top: -4px;
            }
          }
        }
      }

      &__content {
        max-width: 100%;
        padding-top: 4%;
        padding-bottom: 4%;
        margin-bottom: var(--emu-common-spacing-medium);

        @include mq('medium') {
          width: 65%; //as per live
          padding-top: var(--emu-semantic-spacing-none);
          padding-bottom: var(--emu-semantic-spacing-none);
        }

        &.text > p {
          letter-spacing: 0.55px;

          @include mq('medium') {
            font-size: var(--emu-semantic-font-sizes-wide-body);
            line-height: var(--emu-semantic-line-heights-wide-body);
          }

          @include mq('large') {
            letter-spacing: 0.2px;
          }

          > br {
            display: none;

            @include mq('medium-plus') {
              display: block;
            }
          }
        }
      }

      &__image {
        @include mq('medium') {
          max-width: 255px; //as per live
          right: 0px;
          top: 0px;
        }

        @include mq('large') {
          max-width: 360px; //as per live
        }

        &-mobile {
          width: 100%;
          margin-top: var(--emu-semantic-spacing-none);
          margin-bottom: var(--emu-semantic-spacing-none);

          > .cmp-image__image {
            width: 345px; //as per live
            margin-left: -15px;
          }
        }
      }
    }
  }

  .generation-true-form {
    margin-top: -10px;

    @include mq('medium') {
      margin-top: var(--emu-common-spacing-none);
    }

    &__vector-img {
      > .cmp-image__image {
        background: linear-gradient(
          45deg,
          var(--emu-common-colors-brand-blue-50),
          var(--emu-common-colors-brand-blue-50)
        );
        width: 100%;
      }

      &-mobile {
        > .cmp-image__image {
          width: 100%;
          min-height: 40px; //as per live
          background: linear-gradient(
            180deg,
            var(--emu-common-colors-brand-blue-50) 0%,
            var(--emu-common-colors-brand-blue-50) 10%,
            var(--emu-common-colors-brand-blue-500) 2%,
            var(--emu-common-colors-brand-blue-500) 2.25%,
            var(--emu-common-colors-brand-blue-500) 7%,
            var(--emu-common-colors-brand-blue-500) 10%,
            var(--emu-common-colors-brand-blue-500) 100%
          );

          @include mq('small') {
            min-height: 45px;
          }
        }
      }
    }

    &__container {
      padding-bottom: 33px;

      @include mq('medium') {
        padding-bottom: var(--emu-semantic-spacing-none);
      }
    }

    &__wrapper {
      padding-top: 15%;
      padding-bottom: 8%;
      margin-top: -7px;

      @include mq('medium') {
        padding-top: 2%;
        padding-bottom: 5%;
      }

      @include mq('large') {
        margin-top: -7px;
      }
    }

    .content-box {
      &__title {
        margin-bottom: var(--emu-common-spacing-small);

        > h2 {
          font-weight: var(--emu-semantic-font-weight-medium);
          letter-spacing: -0.3px;

          @include mq('medium') {
            font-size: var(--emu-common-font-sizes-narrow-xxl);
            line-height: 48px;
          }

          @include mq('large') {
            font-size: var(--emu-common-font-sizes-wide-xxl);
            line-height: var(--emu-common-line-heights-wide-xxl);
          }

          > b {
            letter-spacing: -0.39px;

            @include mq('large') {
              letter-spacing: -1.5px;
            }
          }
        }
      }

      &__description {
        padding-top: 0.9%;
        max-width: 100%;
        margin-bottom: var(--emu-common-spacing-medium);

        @include mq('medium') {
          max-width: 92%; //as per live
          font-size: var(--emu-common-font-sizes-narrow-body);
          line-height: var(--emu-semantic-line-heights-narrow-body);
        }

        @include mq('large') {
          padding-top: 0.9%;
          max-width: 100%;
        }

        @include mq('x-large') {
          text-indent: unset;
        }

        > p {
          @include mq('large') {
            letter-spacing: 0.18px;
          }

          u {
            font-size: var(--emu-common-font-sizes-wide-small);
            font-family: var(--emu-common-font-families-graphik);
            line-height: 0;
          }
        }

        &--list {
          padding-top: 1%;

          @include mq('medium') {
            max-width: 100%;
          }

          > p {
            padding-top: 1%;

            @include mq('large') {
              padding-top: 0.9%;
            }
          }

          > ul {
            margin-top: var(--emu-semantic-spacing-none);
            margin-bottom: var(--emu-semantic-spacing-none);
            padding-left: 7%;
            letter-spacing: 0px;

            @include mq('medium') {
              padding-left: 3%;
            }

            > li {
              margin-bottom: var(--emu-semantic-spacing-none);
              letter-spacing: normal;
              font-family: var(--emu-common-font-families-graphik);
            }
          }
        }
      }
    }

    &__silicon-block {
      @include mq('small') {
        padding-left: 15px;
        padding-right: 15px;
      }

      @include mq('medium-plus') {
        padding-left: 4%;
        padding-right: 4%;
      }
    }

    &__fifth-truform {
      padding-top: 3%;

      .content-box {
        &__title {
          margin-bottom: var(--emu-semantic-spacing-none);

          > h2 {
            font-size: 33.6px;
            font-weight: var(--emu-semantic-font-weight-regular);
            padding-bottom: 4%;
            margin-bottom: var(--emu-semantic-spacing-none);

            @include mq('medium') {
              font-size: var(--emu-common-font-sizes-narrow-xxl);
              line-height: 48px;
              padding-bottom: var(--emu-semantic-spacing-none);
              letter-spacing: -0.29px;
            }

            @include mq('large') {
              font-size: var(--emu-common-font-sizes-wide-xxl);
              line-height: var(--emu-common-line-heights-wide-xxl);
              letter-spacing: -0.5px;
            }

            @include mq('xxx-large') {
              font-size: var(--emu-common-font-sizes-wide-xxl);
              letter-spacing: -0.39px;
            }

            > sup {
              top: 3px;
              left: -3px;
              display: inline;

              @include mq('large') {
                left: -6px;
              }

              & + sup {
                font-size: 43%;
                top: -3px;
                left: 0px;
                font-weight: var(--emu-semantic-font-weight-black);

                @include mq('large') {
                  top: -7px;
                }
              }
            }
          }
        }

        &__description {
          @include mq('medium') {
            padding-top: 0.9%;
          }

          > p sup {
            font-size: 50%;
            top: -2px;
            left: -2px;
          }
        }
      }
    }

    &__carousel-container {
      padding-top: 10px;

      @include mq('medium') {
        padding-top: var(--emu-semantic-spacing-none);
      }

      .carousel {
        padding-top: var(--emu-semantic-spacing-none);
        padding-left: var(--emu-semantic-spacing-none);
        padding-right: var(--emu-semantic-spacing-none);
        background-color: var(--emu-common-colors-transparent);

        margin-bottom: -3px;

        &.panelcontainer {
          display: block;
          max-width: 100%;
        }
      }
    }

    &__carousel {
      @include mq('medium') {
        padding-bottom: var(--emu-semantic-spacing-none);
      }

      &.emu-carousel .tns-nav {
        position: absolute;
        bottom: -28px;
        padding-left: var(--emu-semantic-spacing-none);

        button {
          height: 15px; // as per live
          width: 15px; // as per live
          margin-left: 5px;
          margin-right: 5px;
        }
      }

      &.emu-carousel {
        border-color: var(--emu-common-colors-brand-blue-500);
        background-color: var(--emu-common-colors-brand-blue-500);
        padding-bottom: var(--emu-semantic-spacing-none);

        .emu-carousel__item {
          border-color: var(--emu-common-colors-brand-blue-500);
          background-color: var(--emu-common-colors-brand-blue-500);
        }
      }

      & .tns-controls {
        display: none;
      }

      .emu-carousel__content {
        min-height: 490px; //as per live

        @include mq('medium') {
          display: flex;
          background-color: var(--emu-common-colors-brand-blue-500);
          margin-left: -15px;
          margin-right: -15px;
          min-height: auto;
        }

        .emu-carousel__item {
          @include mq('medium') {
            max-width: 33.66%; // to match live
            width: 100%;
          }
        }
      }

      &-item {
        @include mq('medium') {
          min-height: auto;
          padding-top: var(--emu-semantic-spacing-none);
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }

    &__image-wrap {
      max-width: 100%;
      position: relative;

      img {
        width: 70%; // as per live
        margin-left: auto;
        margin-right: auto;

        @include mq('medium') {
          width: 100%;
          max-width: 200px; //as per live
        }

        @include mq('x-large') {
          width: 85%; // to match live
        }
      }

      .cmp-text {
        padding-top: 14%;
        padding-bottom: 5%;
        cursor: default;
        font-family: var(--emu-common-font-families-graphik);

        @include mq('medium') {
          font-size: 15px;
          padding-top: 18%;
          line-height: 21px;
          padding-bottom: var(--emu-semantic-spacing-none);
        }

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-body);
          padding-top: 18%;
        }

        > p {
          margin-bottom: var(--emu-semantic-spacing-none);

          sup {
            font-size: 54%;
          }
        }
      }
    }

    &__bg-img {
      .cmp-image__image {
        width: 100%;
        background: linear-gradient(
          180deg,
          var(--emu-common-colors-brand-blue-500) 0%,
          var(--emu-common-colors-brand-blue-500) 10%,
          var(--emu-common-colors-white) 15%,
          var(--emu-common-colors-white) 100%
        );

        @include mq('medium') {
          background: none;
        }
      }
    }
  }

  .aesthetics-section {
    .patients-first {
      padding-top: 35px;
      padding-bottom: 10%;
      margin-top: -0.2%;

      @include mq('medium') {
        padding-top: 5%;
        padding-bottom: 5%;
        margin-top: var(--emu-semantic-spacing-none);
      }

      &__title {
        margin-bottom: 39px;
        max-width: 100%;

        @include mq('medium') {
          margin-bottom: 24px;
        }

        > h2 {
          @include mq('medium') {
            font-size: var(--emu-semantic-font-sizes-narrow-xxl);
            line-height: 48px;
          }

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-xxl);
            line-height: var(--emu-semantic-line-heights-wide-xxl);
          }

          @include mq('xxx-large') {
            letter-spacing: -0.1px;
          }

          > i {
            display: block;

            @include mq('medium') {
              display: inline-block;
              letter-spacing: -1px;
            }
            @include mq('large') {
              letter-spacing: -1.5px;
            }
          }
        }
      }

      &__image {
        @include mq('medium') {
          top: 5%;
          right: -1px;
        }

        @include mq('medium-plus') {
          top: 6%;
        }

        @include mq('large') {
          top: 5%;
        }

        &-mobile {
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: 15px;
          width: 80%; //as per live
        }
      }

      &__content {
        max-width: 88%; //to match live
        padding-top: 9%;
        margin-bottom: 5%;
        padding-bottom: 5%;
        margin-bottom: var(--emu-common-spacing-none);

        @include mq('medium') {
          max-width: 93%; //to match live
          padding-top: var(--emu-common-spacing-none);
          padding-bottom: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-large);
        }

        @include mq('medium-plus') {
          max-width: 92%; //as per live
        }

        @include mq('large') {
          max-width: 88%; //as per live
        }

        &.text > p {
          letter-spacing: 0.6px;

          @include mq('medium') {
            font-size: var(--emu-common-font-sizes-wide-body);
            line-height: var(--emu-common-line-heights-narrow-body);
          }

          @include mq('large') {
            letter-spacing: 1.26px;
          }
        }
      }

      //to increase the specificity
      &__btn.emu-button.emu-button-v2.emu-button__primary-outline {
        margin-top: 15px;
        margin-bottom: 15px;
        width: 280px; //as per live

        @include mq('medium') {
          width: 100%;
          max-width: fit-content;
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-none);
        }
      }
    }
  }

  .faq {
    &__top-mobile-wave {
      margin-right: -2px;

      .cmp-image__image {
        background: linear-gradient(
          180deg,
          #daf2f6 0%,
          #daf2f6 35%,
          #003359 35.1%,
          #003359 2.25%,
          #003359 7%,
          #003359 10%,
          #003359 100%
        );
      }
    }

    &__wave {
      height: 85px;
      margin-top: -2px;
      overflow: hidden;

      @include mq('medium') {
        margin-top: -14px;
        height: 80px;
      }

      @include mq('large') {
        margin-top: var(--emu-common-spacing-none);
        height: auto;
      }

      img {
        @include mq('medium') {
          background: linear-gradient(
            45deg,
            var(--emu-common-colors-brand-blue-50),
            var(--emu-common-colors-brand-blue-50)
          );
        }

        @include mq('x-large') {
          max-width: 1442px; // needed to match live site
        }
      }
    }

    &__title {
      line-height: 42px;
      padding-bottom: 2.5%;
      padding-top: var(--emu-common-spacing-none);
      letter-spacing: -0.6px;

      @include mq('medium') {
        color: var(--emu-common-colors-white);
        font-size: 25.28px;
        line-height: 34px;
        padding-top: 4.5%;
        letter-spacing: -0.1px;
      }

      @include mq('large') {
        font-size: 35.2px;
        line-height: 58.5px;
      }

      @include mq('x-large') {
        font-size: 43.2px;
        line-height: 57px;
      }

      &-container {
        padding-left: 15px;
        padding-right: 15px;
        margin-top: -5px;
        padding-bottom: var(--emu-common-spacing-xs);

        @include mq('large') {
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }

        > .text {
          @include mq('medium-plus') {
            max-width: 720px;
            margin-left: auto;
            margin-right: auto;
            padding-left: 35px;
            padding-right: 35px;
          }

          @include mq('large') {
            max-width: 1170px; // as per live site
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
          }
        }
      }

      br {
        display: none;

        @include mq('large') {
          display: block;
        }
      }
    }

    &__main {
      padding-bottom: 22px;

      @include mq('medium') {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 50px;
        padding-top: 5px;
      }

      @include mq('large') {
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
      }

      .accordion {
        @include mq('medium-plus') {
          max-width: 720px;
          margin-left: auto;
          margin-right: auto;
          padding-left: 35px;
          padding-right: 35px;
        }

        @include mq('large') {
          max-width: 1170px;
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }
      }

      .aaaem-accordion .aaaem-accordion__panel {
        margin-top: -15px;

        @include mq('medium') {
          margin-bottom: 35px;
        }

        &.-ba-is-active {
          margin-bottom: 20px;
          margin-top: var(--emu-common-spacing-none);

          @include mq('medium') {
            margin-top: -5px;
          }
        }
      }
    }
  }

  #fas .nca-fas {
    &__above-vector-img {
      background-color: var(--emu-common-colors-brand-blue-500);
      margin-bottom: -6px;

      @include mq('medium') {
        display: none;
      }
    }

    &__bg-img {
      @include mq('large') {
        margin-top: var(--emu-semantic-spacing-none);
      }
    }

    &__inner-container .content-box {
      &__title {
        h2 {
          line-height: 48px;

          @include mq('large') {
            line-height: var(--emu-common-line-heights-wide-xxl);
          }
        }
      }

      &__description {
        letter-spacing: 1.26px;
        line-height: 29px;

        @include mq('medium') {
          font-size: var(--emu-common-font-sizes-narrow-body);
          line-height: var(--emu-common-line-heights-wide-body);
          letter-spacing: 0.54px;
        }

        @include mq('large') {
          letter-spacing: 0.18px;
          line-height: 30px;
        }

        &.emphasis {
          br {
            @include mq('medium') {
              display: block;
            }
          }
        }
      }
    }
  }
}

[lang='fr_ca'] {
  #natrelle-collection {
    .natrelle-inspira-implants .image-text {
      &__title h2 sup {
        display: inline;
        font-family: var(--emu-common-font-families-aleo);
        font-weight: var(--emu-common-font-weight-medium);
        font-size: 55%;
        top: 0.4px;
        left: 0;
      }

      &__image-container-image img {
        @include mq('767px', 'max-width') {
          max-width: 250px;
          top: 88%;
        }
      }
    }
  }
}
