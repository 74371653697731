
/**
 * ** 🚨 DO NOT EDIT THIS FILE DIRECTLY!
 * If you want to make edits make them in each appropriate token JSON file.
 */

$emu-common-spacing-xs: 4px !default;
$emu-common-spacing-xxs: 2px !default;
$emu-common-spacing-small: 8px !default;
$emu-common-spacing-medium: 16px !default;
$emu-common-spacing-large: 32px !default;
$emu-common-spacing-xl: 64px !default;
$emu-common-spacing-none: 0px !default;
$emu-common-spacing-unset: unset !default;
$emu-common-colors-white: #FFFFFF !default;
$emu-common-colors-black: #000000 !default;
$emu-common-colors-red-50: #FFF5F5 !default;
$emu-common-colors-red-100: #FFDCDB !default;
$emu-common-colors-red-200: #FFABA8 !default;
$emu-common-colors-red-300: #FF7F7A !default;
$emu-common-colors-red-400: #FF4E47 !default;
$emu-common-colors-red-500: #FF1D15 !default;
$emu-common-colors-red-600: #DB0700 !default;
$emu-common-colors-red-700: #A30500 !default;
$emu-common-colors-red-800: #700400 !default;
$emu-common-colors-red-900: #380200 !default;
$emu-common-colors-red-950: #190100 !default;
$emu-common-colors-grey-100: #F4F5F5 !default;
$emu-common-colors-grey-200: #D4D7D8 !default;
$emu-common-colors-grey-300: #B4B8BB !default;
$emu-common-colors-grey-400: #949A9E !default;
$emu-common-colors-grey-500: #7E868B !default;
$emu-common-colors-grey-600: #61676B !default;
$emu-common-colors-grey-700: #44484B !default;
$emu-common-colors-grey-800: #303436 !default;
$emu-common-colors-grey-900: #1D1F20 !default;
$emu-common-colors-transparent: rgba(0,0,0,0) !default;
$emu-common-colors-teal-50: #E0FBFF !default;
$emu-common-colors-teal-100: #C2F7FF !default;
$emu-common-colors-teal-200: #85EFFF !default;
$emu-common-colors-teal-300: #42E6FF !default;
$emu-common-colors-teal-400: #05DEFF !default;
$emu-common-colors-teal-500: #00ABC7 !default;
$emu-common-colors-teal-600: #00899E !default;
$emu-common-colors-teal-700: #006675 !default;
$emu-common-colors-teal-800: #004752 !default;
$emu-common-colors-teal-900: #002329 !default;
$emu-common-colors-teal-950: #001214 !default;
$emu-common-colors-beige-50: #FEFCFB !default;
$emu-common-colors-beige-100: #FDF9F7 !default;
$emu-common-colors-beige-200: #F9EFEB !default;
$emu-common-colors-beige-300: #F7E8E3 !default;
$emu-common-colors-beige-400: #F5E2DB !default;
$emu-common-colors-beige-500: #F2D9D0 !default;
$emu-common-colors-beige-600: #DFA18B !default;
$emu-common-colors-beige-700: #CB6743 !default;
$emu-common-colors-beige-800: #8C4227 !default;
$emu-common-colors-beige-900: #482214 !default;
$emu-common-colors-beige-950: #24110A !default;
$emu-common-colors-light-brown-50: #FAF6F5 !default;
$emu-common-colors-light-brown-100: #F5ECEA !default;
$emu-common-colors-light-brown-200: #EAD7D2 !default;
$emu-common-colors-light-brown-300: #E0C4BD !default;
$emu-common-colors-light-brown-400: #D5AEA5 !default;
$emu-common-colors-light-brown-500: #CA9A8E !default;
$emu-common-colors-light-brown-600: #B4705F !default;
$emu-common-colors-light-brown-700: #8E5243 !default;
$emu-common-colors-light-brown-800: #5E362C !default;
$emu-common-colors-light-brown-900: #311C17 !default;
$emu-common-colors-light-brown-950: #180E0B !default;
$emu-common-colors-medium-brown-50: #F6F0EF !default;
$emu-common-colors-medium-brown-100: #EBDDDB !default;
$emu-common-colors-medium-brown-200: #D6BCB8 !default;
$emu-common-colors-medium-brown-300: #C29A94 !default;
$emu-common-colors-medium-brown-400: #AD7971 !default;
$emu-common-colors-medium-brown-500: #925C54 !default;
$emu-common-colors-medium-brown-600: #754A43 !default;
$emu-common-colors-medium-brown-700: #573732 !default;
$emu-common-colors-medium-brown-800: #3A2522 !default;
$emu-common-colors-medium-brown-900: #1D1211 !default;
$emu-common-colors-medium-brown-950: #100A09 !default;
$emu-common-colors-dark-brown-50: #F1E4E5 !default;
$emu-common-colors-dark-brown-100: #E3CACB !default;
$emu-common-colors-dark-brown-200: #C8989A !default;
$emu-common-colors-dark-brown-300: #AC6365 !default;
$emu-common-colors-dark-brown-400: #7E4345 !default;
$emu-common-colors-dark-brown-500: #492728 !default;
$emu-common-colors-dark-brown-600: #3C2021 !default;
$emu-common-colors-dark-brown-700: #2B1718 !default;
$emu-common-colors-dark-brown-800: #1E1010 !default;
$emu-common-colors-dark-brown-900: #0D0707 !default;
$emu-common-colors-dark-brown-950: #070404 !default;
$emu-common-colors-light-grey-50: #FFFFFF !default;
$emu-common-colors-light-grey-100: #FDFCFC !default;
$emu-common-colors-light-grey-200: #FDFCFC !default;
$emu-common-colors-light-grey-300: #FCF9F8 !default;
$emu-common-colors-light-grey-400: #FCF9F8 !default;
$emu-common-colors-light-grey-500: #FAF6F5 !default;
$emu-common-colors-light-grey-600: #DABCB4 !default;
$emu-common-colors-light-grey-700: #B87F70 !default;
$emu-common-colors-light-grey-800: #855042 !default;
$emu-common-colors-light-grey-900: #221411 !default;
$emu-common-colors-medium-grey-50: #FFFFFF !default;
$emu-common-colors-medium-grey-100: #F8F7F7 !default;
$emu-common-colors-medium-grey-200: #E6E5E5 !default;
$emu-common-colors-medium-grey-300: #D8D5D5 !default;
$emu-common-colors-medium-grey-400: #C9C5C5 !default;
$emu-common-colors-medium-grey-500: #B9B4B4 !default;
$emu-common-colors-medium-grey-600: #958E8E !default;
$emu-common-colors-medium-grey-700: #716A6A !default;
$emu-common-colors-medium-grey-800: #4C4747 !default;
$emu-common-colors-medium-grey-900: #252222 !default;
$emu-common-colors-medium-grey-950: #121111 !default;
$emu-common-colors-dark-grey-50: #F7F7F7 !default;
$emu-common-colors-dark-grey-100: #E8E8E8 !default;
$emu-common-colors-dark-grey-200: #C9C9CA !default;
$emu-common-colors-dark-grey-300: #727272 !default;
$emu-common-colors-dark-grey-400: #656569 !default;
$emu-common-colors-dark-grey-500: #1E1E1E !default;
$emu-common-colors-dark-grey-600: #58595A !default;
$emu-common-colors-dark-grey-700: #424243 !default;
$emu-common-colors-dark-grey-800: #2D2E2E !default;
$emu-common-colors-dark-grey-900: #171717 !default;
$emu-common-colors-dark-grey-950: #0A0A0A !default;
$emu-common-colors-blush-50: #FFFFFF !default;
$emu-common-colors-blush-100: #FDFCFC !default;
$emu-common-colors-blush-200: #F4EBEF !default;
$emu-common-colors-blush-300: #EDDEE4 !default;
$emu-common-colors-blush-400: #E6D1D9 !default;
$emu-common-colors-blush-500: #DDC1CC !default;
$emu-common-colors-blush-600: #C08CA1 !default;
$emu-common-colors-blush-700: #A15876 !default;
$emu-common-colors-blush-800: #693A4D !default;
$emu-common-colors-blush-900: #351D26 !default;
$emu-common-colors-blush-950: #1A0E13 !default;
$emu-common-colors-abbvie-blue-50: #E3EBFC !default;
$emu-common-colors-abbvie-blue-100: #BED1F9 !default;
$emu-common-colors-abbvie-blue-200: #6A97F1 !default;
$emu-common-colors-abbvie-blue-300: #1B60E9 !default;
$emu-common-colors-abbvie-blue-400: #0F3D9A !default;
$emu-common-colors-abbvie-blue-500: #071D49 !default;
$emu-common-colors-abbvie-blue-600: #06183C !default;
$emu-common-colors-abbvie-blue-700: #04112A !default;
$emu-common-colors-abbvie-blue-800: #030B1C !default;
$emu-common-colors-abbvie-blue-900: #01060E !default;
$emu-common-colors-abbvie-blue-950: #010409 !default;
$emu-common-colors-brand-blue-50: #DAF2F6 !default;
$emu-common-colors-brand-blue-100: #99dde8 !default;
$emu-common-colors-brand-blue-200: #00ABC7 !default;
$emu-common-colors-brand-blue-300: #4E748B !default;
$emu-common-colors-brand-blue-400: #003349 !default;
$emu-common-colors-brand-blue-500: #003359 !default;
$emu-common-colors-brand-blue-600: #112B4E !default;
$emu-common-colors-brand-violet-300: #ded4e0 !default;
$emu-common-colors-brand-violet-400: #492250 !default;
$emu-common-colors-brand-violet-500: #5B2A64 !default;
$emu-common-colors-brand-pink-300: #f4d6cc !default;
$emu-common-colors-brand-pink-400: #ECBAAA !default;
$emu-common-colors-brand-pink-500: #F5A6E4 !default;
$emu-common-font-families-sans: Arial, sans-serif !default;
$emu-common-font-families-serif: Times New Roman, serif !default;
$emu-common-font-families-mono: Courier, monospace !default;
$emu-common-font-families-roboto: Roboto, system-ui !default;
$emu-common-font-families-aleo: Aleo, system-ui !default;
$emu-common-font-families-graphik: Graphik,NeueHaasUnicaW1G-Regular,Helvetica Neue,Helvetica,Arial,sans-serif !default;
$emu-common-font-families-superscript-regular: Superscript_Regular !default;
$emu-common-font-families-superscript-bold: Superscript_Bold !default;
$emu-common-font-weight-medium: 500 !default;
$emu-common-font-weight-bold: 700 !default;
$emu-common-font-weight-light: 300 !default;
$emu-common-font-weight-black: 900 !default;
$emu-common-font-weight-regular: 400 !default;
$emu-common-font-sizes-narrow-body: 18px !default;
$emu-common-font-sizes-narrow-xs: 10px !default;
$emu-common-font-sizes-narrow-small: 12px !default;
$emu-common-font-sizes-narrow-medium: 20px !default;
$emu-common-font-sizes-narrow-large: 22px !default;
$emu-common-font-sizes-narrow-xl: 32px !default;
$emu-common-font-sizes-narrow-xxl: 35.13px !default;
$emu-common-font-sizes-narrow-xxxl: 35.13px !default;
$emu-common-font-sizes-wide-body: 18px !default;
$emu-common-font-sizes-wide-xs: 12px !default;
$emu-common-font-sizes-wide-small: 16px !default;
$emu-common-font-sizes-wide-medium: 20px !default;
$emu-common-font-sizes-wide-large: 22px !default;
$emu-common-font-sizes-wide-xl: 40px !default;
$emu-common-font-sizes-wide-xxl: 48px !default;
$emu-common-font-sizes-wide-xxxl: 52px !default;
$emu-common-line-heights-narrow-body: 28px !default;
$emu-common-line-heights-narrow-xs: 14px !default;
$emu-common-line-heights-narrow-small: 18px !default;
$emu-common-line-heights-narrow-medium: 26px !default;
$emu-common-line-heights-narrow-large: 35px !default;
$emu-common-line-heights-narrow-xl: 35.2px !default;
$emu-common-line-heights-narrow-xxl: 42px !default;
$emu-common-line-heights-narrow-xxxl: 42px !default;
$emu-common-line-heights-wide-body: 28px !default;
$emu-common-line-heights-wide-xs: 18px !default;
$emu-common-line-heights-wide-small: 14.5px !default;
$emu-common-line-heights-wide-medium: 26px !default;
$emu-common-line-heights-wide-large: 35px !default;
$emu-common-line-heights-wide-xl: 44px !default;
$emu-common-line-heights-wide-xxl: 56px !default;
$emu-common-line-heights-wide-xxxl: 62px !default;
$emu-common-border-radius-xxs: 2px !default;
$emu-common-border-radius-xs: 4px !default;
$emu-common-border-radius-small: 8px !default;
$emu-common-border-radius-medium: 16px !default;
$emu-common-border-radius-large: 55.79px !default;
$emu-common-border-radius-none: 0px !default;
$emu-common-border-width-thin: 1px !default;
$emu-common-border-width-small: 1.8px !default;
$emu-common-border-width-medium: 2px !default;
$emu-common-border-width-thick: 4px !default;
$emu-common-border-width-none: 0px !default;
$emu-common-outline-width-thin: thin !default;
$emu-common-outline-width-medium: medium !default;
$emu-common-outline-width-thick: thick !default;
$emu-common-outline-width-unset: unset !default;
$emu-common-sizing-none: 0px !default;
$emu-common-sizing-xxs: 1px !default;
$emu-common-sizing-xs: 8px !default;
$emu-common-sizing-small: 16px !default;
$emu-common-sizing-medium: 32px !default;
$emu-common-sizing-large: 64px !default;
$emu-common-sizing-xl: 120px !default;
$emu-common-sizing-xxl: 256px !default;
$emu-common-sizing-xxxl: 1140px !default;
$emu-common-sizing-container: 1520px !default;
$emu-common-other-time-transition-short: 200ms !default;
$emu-common-other-time-transition-base: 400ms !default;
$emu-common-other-time-transition-long: 600ms !default;
$emu-common-other-time-transition-xl: 1000ms !default;
$emu-common-other-time-duration-instant: 400ms !default;
$emu-common-other-time-duration-short: 2000ms !default;
$emu-common-other-time-duration-base: 4000ms !default;
$emu-common-other-time-duration-long: 6000ms !default;
$emu-common-other-time-delay-none: 0ms !default;
$emu-common-other-time-delay-short: 50ms !default;
$emu-common-other-time-delay-base: 100ms !default;
$emu-common-other-time-delay-long: 200ms !default;
$emu-common-other-z-index-cookie-banner: 700 !default;
$emu-common-other-z-index-modal: 600 !default;
$emu-common-other-z-index-header: 500 !default;
$emu-common-other-z-index-isi: 400 !default;
$emu-common-other-z-index-overlay: 300 !default;
$emu-common-other-z-index-layer: 100 !default;
$emu-common-other-z-index-base: 0 !default;
$emu-common-other-z-index-behind: -1 !default;
$emu-common-box-shadow-soft-light: 0 0 6 0 #44484B !default;

$common: (
  'common': (
    'spacing': (
      'xs': $emu-common-spacing-xs,
      'xxs': $emu-common-spacing-xxs,
      'small': $emu-common-spacing-small,
      'medium': $emu-common-spacing-medium,
      'large': $emu-common-spacing-large,
      'xl': $emu-common-spacing-xl,
      'none': $emu-common-spacing-none,
      'unset': $emu-common-spacing-unset
    ),
    'colors': (
      'white': $emu-common-colors-white,
      'black': $emu-common-colors-black,
      'red': (
        '50': $emu-common-colors-red-50,
        '100': $emu-common-colors-red-100,
        '200': $emu-common-colors-red-200,
        '300': $emu-common-colors-red-300,
        '400': $emu-common-colors-red-400,
        '500': $emu-common-colors-red-500,
        '600': $emu-common-colors-red-600,
        '700': $emu-common-colors-red-700,
        '800': $emu-common-colors-red-800,
        '900': $emu-common-colors-red-900,
        '950': $emu-common-colors-red-950
      ),
      'grey': (
        '100': $emu-common-colors-grey-100,
        '200': $emu-common-colors-grey-200,
        '300': $emu-common-colors-grey-300,
        '400': $emu-common-colors-grey-400,
        '500': $emu-common-colors-grey-500,
        '600': $emu-common-colors-grey-600,
        '700': $emu-common-colors-grey-700,
        '800': $emu-common-colors-grey-800,
        '900': $emu-common-colors-grey-900
      ),
      'transparent': $emu-common-colors-transparent,
      'teal': (
        '50': $emu-common-colors-teal-50,
        '100': $emu-common-colors-teal-100,
        '200': $emu-common-colors-teal-200,
        '300': $emu-common-colors-teal-300,
        '400': $emu-common-colors-teal-400,
        '500': $emu-common-colors-teal-500,
        '600': $emu-common-colors-teal-600,
        '700': $emu-common-colors-teal-700,
        '800': $emu-common-colors-teal-800,
        '900': $emu-common-colors-teal-900,
        '950': $emu-common-colors-teal-950
      ),
      'beige': (
        '50': $emu-common-colors-beige-50,
        '100': $emu-common-colors-beige-100,
        '200': $emu-common-colors-beige-200,
        '300': $emu-common-colors-beige-300,
        '400': $emu-common-colors-beige-400,
        '500': $emu-common-colors-beige-500,
        '600': $emu-common-colors-beige-600,
        '700': $emu-common-colors-beige-700,
        '800': $emu-common-colors-beige-800,
        '900': $emu-common-colors-beige-900,
        '950': $emu-common-colors-beige-950
      ),
      'lightBrown': (
        '50': $emu-common-colors-light-brown-50,
        '100': $emu-common-colors-light-brown-100,
        '200': $emu-common-colors-light-brown-200,
        '300': $emu-common-colors-light-brown-300,
        '400': $emu-common-colors-light-brown-400,
        '500': $emu-common-colors-light-brown-500,
        '600': $emu-common-colors-light-brown-600,
        '700': $emu-common-colors-light-brown-700,
        '800': $emu-common-colors-light-brown-800,
        '900': $emu-common-colors-light-brown-900,
        '950': $emu-common-colors-light-brown-950
      ),
      'mediumBrown': (
        '50': $emu-common-colors-medium-brown-50,
        '100': $emu-common-colors-medium-brown-100,
        '200': $emu-common-colors-medium-brown-200,
        '300': $emu-common-colors-medium-brown-300,
        '400': $emu-common-colors-medium-brown-400,
        '500': $emu-common-colors-medium-brown-500,
        '600': $emu-common-colors-medium-brown-600,
        '700': $emu-common-colors-medium-brown-700,
        '800': $emu-common-colors-medium-brown-800,
        '900': $emu-common-colors-medium-brown-900,
        '950': $emu-common-colors-medium-brown-950
      ),
      'darkBrown': (
        '50': $emu-common-colors-dark-brown-50,
        '100': $emu-common-colors-dark-brown-100,
        '200': $emu-common-colors-dark-brown-200,
        '300': $emu-common-colors-dark-brown-300,
        '400': $emu-common-colors-dark-brown-400,
        '500': $emu-common-colors-dark-brown-500,
        '600': $emu-common-colors-dark-brown-600,
        '700': $emu-common-colors-dark-brown-700,
        '800': $emu-common-colors-dark-brown-800,
        '900': $emu-common-colors-dark-brown-900,
        '950': $emu-common-colors-dark-brown-950
      ),
      'lightGrey': (
        '50': $emu-common-colors-light-grey-50,
        '100': $emu-common-colors-light-grey-100,
        '200': $emu-common-colors-light-grey-200,
        '300': $emu-common-colors-light-grey-300,
        '400': $emu-common-colors-light-grey-400,
        '500': $emu-common-colors-light-grey-500,
        '600': $emu-common-colors-light-grey-600,
        '700': $emu-common-colors-light-grey-700,
        '800': $emu-common-colors-light-grey-800,
        '900': $emu-common-colors-light-grey-900
      ),
      'mediumGrey': (
        '50': $emu-common-colors-medium-grey-50,
        '100': $emu-common-colors-medium-grey-100,
        '200': $emu-common-colors-medium-grey-200,
        '300': $emu-common-colors-medium-grey-300,
        '400': $emu-common-colors-medium-grey-400,
        '500': $emu-common-colors-medium-grey-500,
        '600': $emu-common-colors-medium-grey-600,
        '700': $emu-common-colors-medium-grey-700,
        '800': $emu-common-colors-medium-grey-800,
        '900': $emu-common-colors-medium-grey-900,
        '950': $emu-common-colors-medium-grey-950
      ),
      'darkGrey': (
        '50': $emu-common-colors-dark-grey-50,
        '100': $emu-common-colors-dark-grey-100,
        '200': $emu-common-colors-dark-grey-200,
        '300': $emu-common-colors-dark-grey-300,
        '400': $emu-common-colors-dark-grey-400,
        '500': $emu-common-colors-dark-grey-500,
        '600': $emu-common-colors-dark-grey-600,
        '700': $emu-common-colors-dark-grey-700,
        '800': $emu-common-colors-dark-grey-800,
        '900': $emu-common-colors-dark-grey-900,
        '950': $emu-common-colors-dark-grey-950
      ),
      'blush': (
        '50': $emu-common-colors-blush-50,
        '100': $emu-common-colors-blush-100,
        '200': $emu-common-colors-blush-200,
        '300': $emu-common-colors-blush-300,
        '400': $emu-common-colors-blush-400,
        '500': $emu-common-colors-blush-500,
        '600': $emu-common-colors-blush-600,
        '700': $emu-common-colors-blush-700,
        '800': $emu-common-colors-blush-800,
        '900': $emu-common-colors-blush-900,
        '950': $emu-common-colors-blush-950
      ),
      'abbvieBlue': (
        '50': $emu-common-colors-abbvie-blue-50,
        '100': $emu-common-colors-abbvie-blue-100,
        '200': $emu-common-colors-abbvie-blue-200,
        '300': $emu-common-colors-abbvie-blue-300,
        '400': $emu-common-colors-abbvie-blue-400,
        '500': $emu-common-colors-abbvie-blue-500,
        '600': $emu-common-colors-abbvie-blue-600,
        '700': $emu-common-colors-abbvie-blue-700,
        '800': $emu-common-colors-abbvie-blue-800,
        '900': $emu-common-colors-abbvie-blue-900,
        '950': $emu-common-colors-abbvie-blue-950
      ),
      'brandBlue': (
        '50': $emu-common-colors-brand-blue-50,
        '100': $emu-common-colors-brand-blue-100,
        '200': $emu-common-colors-brand-blue-200,
        '300': $emu-common-colors-brand-blue-300,
        '400': $emu-common-colors-brand-blue-400,
        '500': $emu-common-colors-brand-blue-500,
        '600': $emu-common-colors-brand-blue-600
      ),
      'brandViolet': (
        '300': $emu-common-colors-brand-violet-300,
        '400': $emu-common-colors-brand-violet-400,
        '500': $emu-common-colors-brand-violet-500
      ),
      'brandPink': (
        '300': $emu-common-colors-brand-pink-300,
        '400': $emu-common-colors-brand-pink-400,
        '500': $emu-common-colors-brand-pink-500
      )
    ),
    'fontFamilies': (
      'sans': $emu-common-font-families-sans,
      'serif': $emu-common-font-families-serif,
      'mono': $emu-common-font-families-mono,
      'roboto': $emu-common-font-families-roboto,
      'aleo': $emu-common-font-families-aleo,
      'graphik': $emu-common-font-families-graphik,
      'superscriptRegular': $emu-common-font-families-superscript-regular,
      'superscriptBold': $emu-common-font-families-superscript-bold
    ),
    'fontWeight': (
      'medium': $emu-common-font-weight-medium,
      'bold': $emu-common-font-weight-bold,
      'light': $emu-common-font-weight-light,
      'black': $emu-common-font-weight-black,
      'regular': $emu-common-font-weight-regular
    ),
    'fontSizes': (
      'narrow': (
        'body': $emu-common-font-sizes-narrow-body,
        'xs': $emu-common-font-sizes-narrow-xs,
        'small': $emu-common-font-sizes-narrow-small,
        'medium': $emu-common-font-sizes-narrow-medium,
        'large': $emu-common-font-sizes-narrow-large,
        'xl': $emu-common-font-sizes-narrow-xl,
        'xxl': $emu-common-font-sizes-narrow-xxl,
        'xxxl': $emu-common-font-sizes-narrow-xxxl
      ),
      'wide': (
        'body': $emu-common-font-sizes-wide-body,
        'xs': $emu-common-font-sizes-wide-xs,
        'small': $emu-common-font-sizes-wide-small,
        'medium': $emu-common-font-sizes-wide-medium,
        'large': $emu-common-font-sizes-wide-large,
        'xl': $emu-common-font-sizes-wide-xl,
        'xxl': $emu-common-font-sizes-wide-xxl,
        'xxxl': $emu-common-font-sizes-wide-xxxl
      )
    ),
    'lineHeights': (
      'narrow': (
        'body': $emu-common-line-heights-narrow-body,
        'xs': $emu-common-line-heights-narrow-xs,
        'small': $emu-common-line-heights-narrow-small,
        'medium': $emu-common-line-heights-narrow-medium,
        'large': $emu-common-line-heights-narrow-large,
        'xl': $emu-common-line-heights-narrow-xl,
        'xxl': $emu-common-line-heights-narrow-xxl,
        'xxxl': $emu-common-line-heights-narrow-xxxl
      ),
      'wide': (
        'body': $emu-common-line-heights-wide-body,
        'xs': $emu-common-line-heights-wide-xs,
        'small': $emu-common-line-heights-wide-small,
        'medium': $emu-common-line-heights-wide-medium,
        'large': $emu-common-line-heights-wide-large,
        'xl': $emu-common-line-heights-wide-xl,
        'xxl': $emu-common-line-heights-wide-xxl,
        'xxxl': $emu-common-line-heights-wide-xxxl
      )
    ),
    'borderRadius': (
      'xxs': $emu-common-border-radius-xxs,
      'xs': $emu-common-border-radius-xs,
      'small': $emu-common-border-radius-small,
      'medium': $emu-common-border-radius-medium,
      'large': $emu-common-border-radius-large,
      'none': $emu-common-border-radius-none
    ),
    'borderWidth': (
      'thin': $emu-common-border-width-thin,
      'small': $emu-common-border-width-small,
      'medium': $emu-common-border-width-medium,
      'thick': $emu-common-border-width-thick,
      'none': $emu-common-border-width-none
    ),
    'outlineWidth': (
      'thin': $emu-common-outline-width-thin,
      'medium': $emu-common-outline-width-medium,
      'thick': $emu-common-outline-width-thick,
      'unset': $emu-common-outline-width-unset
    ),
    'sizing': (
      'none': $emu-common-sizing-none,
      'xxs': $emu-common-sizing-xxs,
      'xs': $emu-common-sizing-xs,
      'small': $emu-common-sizing-small,
      'medium': $emu-common-sizing-medium,
      'large': $emu-common-sizing-large,
      'xl': $emu-common-sizing-xl,
      'xxl': $emu-common-sizing-xxl,
      'xxxl': $emu-common-sizing-xxxl,
      'container': $emu-common-sizing-container
    ),
    'other': (
      'time': (
        'transition': (
          'short': $emu-common-other-time-transition-short,
          'base': $emu-common-other-time-transition-base,
          'long': $emu-common-other-time-transition-long,
          'xl': $emu-common-other-time-transition-xl
        ),
        'duration': (
          'instant': $emu-common-other-time-duration-instant,
          'short': $emu-common-other-time-duration-short,
          'base': $emu-common-other-time-duration-base,
          'long': $emu-common-other-time-duration-long
        ),
        'delay': (
          'none': $emu-common-other-time-delay-none,
          'short': $emu-common-other-time-delay-short,
          'base': $emu-common-other-time-delay-base,
          'long': $emu-common-other-time-delay-long
        )
      ),
      'zIndex': (
        'cookieBanner': $emu-common-other-z-index-cookie-banner,
        'modal': $emu-common-other-z-index-modal,
        'header': $emu-common-other-z-index-header,
        'isi': $emu-common-other-z-index-isi,
        'overlay': $emu-common-other-z-index-overlay,
        'layer': $emu-common-other-z-index-layer,
        'base': $emu-common-other-z-index-base,
        'behind': $emu-common-other-z-index-behind
      )
    ),
    'boxShadow': (
      'soft': (
        'light': $emu-common-box-shadow-soft-light
      )
    )
  )
);
