.error {
  &__content {
    &-wrapper {
      padding-top: 88px;
      padding-bottom: 88px;

      @include mq('medium') {
        padding-top: 164px;
        padding-bottom: 164px;
      }

      @include mq('large') {
        padding-top: 88px;
        padding-bottom: 88px;
      }

      &-title {
        &.cmp-text {
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-small);
        }

        h1 {
          font-size: 96px;
          line-height: 96px;
          letter-spacing: 4.8px;
          font-family: var(--emu-common-font-families-roboto-bold);
          font-weight: var(--emu-common-font-weight-bold);

          @include mq('medium') {
            font-size: 160px;
            line-height: 160px;
            letter-spacing: 8px;
          }
        }
      }

      &-pretitle {
        &.cmp-text {
          margin-top: var(--emu-common-spacing-large);
          margin-bottom: var(--emu-common-spacing-none);

          h2 {
            font-size: 18.4px;
            line-height: 32px;
            letter-spacing: 0.184px;
            font-family: var(--emu-common-font-families-roboto-bold);
            font-weight: var(--emu-common-font-weight-medium);
          }
        }
      }

      &-description {
        &.cmp-text {
          font-size: 13.6px;
          letter-spacing: 0.68px;

          @include mq('medium') {
            letter-spacing: 0.408px;
            line-height: 20px;
          }

          @include mq('large') {
            letter-spacing: 0;
            line-height: 14px;
          }

          @include mq('x-large') {
            line-height: 15px;
          }
        }
      }
    }
  }
}
