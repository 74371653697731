#warranty {
  .warranty {
    &__title {
      font-size: var(--emu-common-font-sizes-narrow-xxl);
      line-height: var(--emu-common-line-heights-narrow-xxl);
      letter-spacing: -0.4px;
      font-weight: var(--emu-common-font-weight-bold);

      @include mq('medium') {
        font-size: 44.8px;
        line-height: 1.15;
      }

      h1,
      h2,
      h3 {
        font-size: inherit;
        line-height: inherit;
        font-weight: inherit;
      }

      sup {
        font-style: normal;
        font-size: 40%;
        font-weight: var(--emu-common-font-weight-bold);
        top: -4px;
        left: -1px;
      }

      .emphasis {
        sup {
          font-size: 75%;
          top: 6px;
          left: -1px;
        }
      }

      i {
        @include mq('medium') {
          display: block;
        }

        @include mq('medium-plus') {
          display: inline;
        }
      }

      &-container {
        margin-top: 48px;

        @include mq('medium') {
          margin-top: 80px;
        }
      }
    }

    &__tabs {
      &-container {
        @include mq('medium') {
          margin-top: 45px;
          margin-bottom: 65px;
        }
      }

      &-title {
        padding-top: 25px;

        @include mq('medium') {
          padding-top: var(--emu-common-spacing-none);
          padding-bottom: 4%;
          font-family: var(--emu-common-font-families-graphik);
        }

        @include mq('medium-plus') {
          text-align: center;
        }

        b {
          @include mq('medium') {
            font-size: 30px;
          }
        }

        p {
          margin-bottom: var(--emu-common-spacing-large);
          letter-spacing: 0.05em;

          @include mq('medium') {
            font-size: 28px;
            line-height: 44.3px;
            letter-spacing: normal;
            margin-bottom: var(--emu-common-spacing-none);
          }

          @include mq('medium-plus') {
            font-size: 30px;
            display: inline;
            line-height: 48px;
          }

          @include mq('x-large') {
            display: block;
          }

          &:last-child {
            margin-bottom: 12px;
            letter-spacing: 4px;

            @include mq('medium') {
              margin-bottom: var(--emu-common-spacing-none);
              letter-spacing: normal;
            }
          }

          sup {
            letter-spacing: normal;
            font-size: 45%;
          }
        }
      }

      &-top-wave {
        margin-bottom: -7px;
      }

      &-bottom-wave {
        margin-top: -7px;
      }

      &-item {
        margin-top: 40px;
        display: flex;
        align-items: center;

        @include mq('medium') {
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: 5%;
          margin-left: -15px;
          margin-right: -15px;
        }

        &--first {
          margin-top: 22px;

          @include mq('medium') {
            margin-top: var(--emu-common-spacing-none);
          }
        }

        > .image {
          flex: 33.333%;
          padding-left: 15px;
          padding-right: 15px;

          @include mq('medium') {
            flex: 16.666667%;
          }

          @include mq('large') {
            padding-left: var(--emu-common-spacing-none);
          }
        }

        > .text {
          flex: 66.667%;
          padding-left: 15px;

          @include mq('medium') {
            flex: 83.333%;
          }
        }

        &-text {
          letter-spacing: 1.5px;
          font-family: var(--emu-common-font-families-graphik);

          @include mq('medium') {
            font-size: 18px;
            line-height: 35px;
            letter-spacing: 0.13em;
          }

          p {
            margin-bottom: var(--emu-common-spacing-none);
          }

          sup {
            font-size: 10px;
            top: -1px; // to match with live site
            font-family: var(--emu-common-font-families-superscript-regular);

            @include mq('medium') {
              font-size: 7px;
              top: -2px;
            }
          }

          b {
            @include mq('medium') {
              font-size: 16px;
            }
          }
        }
      }

      &-main {
        .aaaem-tabs {
          &__tabpanel {
            overflow: visible;
          }

          &__tab {
            width: 320px; // as per live site

            @include mq('medium') {
              width: 45%; // as per live site
            }

            @include mq('x-large') {
              width: 535px; // as per live site
            }
          }
        }

        &-container {
          padding-bottom: 35px;

          @include mq('medium') {
            padding-bottom: var(--emu-common-spacing-none);
          }

          &--saline {
            // only mobile need the styles. Desktop does not need any overrides
            @include mq('767px', 'max-width') {
              padding-bottom: var(--emu-common-spacing-none);
            }

            > .container {
              // only mobile need the styles. Desktop does not need any overrides
              @include mq('767px', 'max-width') {
                margin-left: -15px;
                margin-right: -15px;
              }
            }

            .text {
              // only mobile need the styles. Desktop does not need any overrides
              @include mq('767px', 'max-width') {
                color: var(--emu-common-colors-white);
              }
            }

            .warranty__tabs {
              &-main-container-inner {
                // only mobile need the styles. Desktop does not need any overrides
                @include mq('767px', 'max-width') {
                  padding-top: 35px;
                  padding-right: 15px;
                  padding-bottom: 40px;
                  padding-left: 15px;
                  background-color: var(--emu-common-colors-brand-blue-500);
                }
              }

              &-title {
                // only mobile need the styles. Desktop does not need any overrides
                @include mq('767px', 'max-width') {
                  padding-top: var(--emu-common-spacing-none);
                }

                p {
                  // only mobile need the styles. Desktop does not need any overrides
                  @include mq('767px', 'max-width') {
                    font-size: var(--emu-common-font-sizes-narrow-large);
                    margin-bottom: 5px;
                    letter-spacing: 1.1px;
                  }

                  b {
                    // only mobile need the styles. Desktop does not need any overrides
                    @include mq('767px', 'max-width') {
                      font-size: var(--emu-common-font-sizes-narrow-body);
                    }
                  }

                  &:last-child {
                    // only mobile need the styles. Desktop does not need any overrides
                    @include mq('767px', 'max-width') {
                      font-size: var(--emu-common-font-sizes-narrow-body);
                      margin-bottom: 12px;
                      letter-spacing: 4px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &__confidence {
      padding-top: 60px;
      padding-bottom: 40px;

      @include mq('medium') {
        padding-top: var(--emu-common-spacing-none);
        padding-bottom: 85px;
      }

      > .container {
        @include mq('medium') {
          margin-left: 75px;
          margin-right: 75px;
        }
      }

      &-intro {
        sup {
          font-size: 45%;
          top: -2px;
        }

        &-title {
          font-size: 24px;
          letter-spacing: 3px;
          line-height: 28px;
          font-family: var(--emu-common-font-families-roboto);
          margin-bottom: var(--emu-common-spacing-small);

          @include mq('medium') {
            font-size: 30px;
            line-height: 30px;
            letter-spacing: normal;
          }

          h1,
          h2,
          h3,
          h4 {
            font: inherit;
          }
        }

        &-text {
          padding-top: 10px;
          margin-bottom: var(--emu-common-spacing-medium);
          letter-spacing: 2px;

          @include mq('medium') {
            letter-spacing: 0.03em;
          }

          @include mq('large') {
            letter-spacing: normal;
          }

          sup {
            @include mq('large') {
              margin-right: var(--emu-common-spacing-xs);
            }
          }

          p {
            margin: var(--emu-common-spacing-none);
          }
        }
      }

      &-img-text {
        padding-top: 20px;
        padding-bottom: 20px;
        margin-left: -15px;
        margin-right: -15px;
        display: flex;
        align-items: flex-start;

        @include mq('medium') {
          padding-top: var(--emu-common-spacing-none);
          padding-bottom: 55px;
        }

        > .image {
          flex: 33.333%;
          padding-left: 15px;
          padding-right: 15px;

          @include mq('medium') {
            flex: 16.667%;
          }
        }

        > .container {
          flex: 66.667%;
          padding-left: 15px;
          padding-right: 15px;

          @include mq('medium') {
            flex: 83.333%;
          }
        }

        .warranty__confidence {
          &-img {
            padding-top: 16%;
          }

          &-text {
            h5 {
              font-family: var(--emu-common-font-families-roboto);
              padding-top: 20px;
              font-size: var(--emu-common-font-sizes-narrow-medium);
              line-height: var(--emu-common-line-heights-narrow-body);
              letter-spacing: 3px;

              @include mq('medium') {
                padding-top: var(--emu-common-spacing-medium);
                line-height: 30px;
                letter-spacing: 0.13em;
                font-size: 14px;
              }

              @include mq('medium-plus') {
                padding-top: 48px;
                line-height: 35px;
              }

              @include mq('large') {
                font-size: var(--emu-common-font-sizes-wide-small);
              }

              br {
                display: none;

                @include mq('x-large') {
                  display: block;
                }
              }

              b {
                font-family: var(--emu-common-font-families-graphik);

                @include mq('medium') {
                  font-size: var(--emu-common-font-sizes-wide-small);
                  line-height: 1.1;
                }
              }
            }

            p {
              margin-bottom: var(--emu-common-spacing-none);
              padding-top: var(--emu-common-spacing-medium);
              letter-spacing: 2px;

              @include mq('medium') {
                letter-spacing: 1px;
                font-size: var(--emu-common-font-sizes-wide-small);
              }

              @include mq('medium-plus') {
                letter-spacing: 0.07em;
              }
            }

            a {
              font-size: 16px;
              font-family: var(--emu-common-font-families-graphik);

              &,
              &:hover,
              &:active,
              &:focus {
                color: inherit;
                text-decoration: none;

                @include mq('medium') {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }

      &-cta {
        margin-top: 35px;
        margin-bottom: 35px;
        padding-top: 15px;
        padding-right: 25px;
        padding-bottom: 15px;
        padding-left: 25px;
        font-size: 14px;
        letter-spacing: 0.3em;
        line-height: 16px;
        max-width: 100%;
        width: fit-content;

        @include mq('medium') {
          margin: var(--emu-common-spacing-none);
          padding-top: 10px;
          padding-right: 15px;
          padding-bottom: 10px;
          padding-left: 15px;
          font-size: var(--emu-common-font-sizes-wide-xs);
          line-height: 14px;
        }

        @include mq('large') {
          padding-top: 15px;
          padding-right: 30px;
          padding-bottom: 15px;
          padding-left: 30px;
          font-size: 14px;
          line-height: 16px;
        }

        span {
          text-align: center;
        }
      }
    }

    &__terms {
      &-top-wave,
      &-bottom-wave {
        overflow: hidden;

        img {
          margin-top: -7px;
          margin-bottom: -7px;

          @include mq('medium') {
            margin-top: var(--emu-common-spacing-none);
          }

          @include mq('medium-plus') {
            margin-bottom: var(--emu-common-spacing-none);
          }
        }
      }

      &-main {
        padding-top: 40px;
        padding-bottom: 40px;
      }

      &-wrapper {
        @include mq('medium') {
          margin-left: 75px;
          margin-right: 75px;
        }
      }

      &-intro {
        &-title {
          font-size: 20.8px;
          line-height: var(--emu-common-line-heights-narrow-body);
          letter-spacing: 2px;
          font-family: var(--emu-common-font-families-roboto);
          font-weight: var(
            --emu-common-font-weight-regular
          ); // live has 500. But 400 is matching well
          margin-bottom: 15px;

          @include mq('medium') {
            font-size: 30px;
            line-height: 30px;
            letter-spacing: normal;
          }

          h1,
          h2,
          h3,
          h4 {
            font: inherit;
          }
        }

        &-text {
          letter-spacing: 0.05em;
          margin-bottom: var(--emu-common-spacing-medium);

          @include mq('medium') {
            letter-spacing: 0.03em;
          }

          @include mq('medium-plus') {
            letter-spacing: normal;
          }

          p {
            margin-bottom: var(--emu-common-spacing-none);
          }

          sup {
            font-size: 45%;
          }
        }
      }

      &-text {
        padding-top: 28px;

        p,
        li {
          margin-bottom: var(--emu-common-spacing-none);
        }

        ul {
          padding-top: 20px;
          padding-left: 25px;
          margin-top: var(--emu-common-spacing-none);
          margin-left: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-medium);

          @include mq('medium') {
            padding-top: 5px;
          }
        }

        li {
          font-family: var(--emu-common-font-families-graphik);
        }

        sup {
          font-size: 45%;
        }

        b {
          @include mq('medium') {
            font-size: var(--emu-common-font-sizes-wide-small);
            line-height: 1.1;
          }
        }

        &--no-list {
          letter-spacing: 0.05em;
          margin-bottom: var(--emu-common-spacing-medium);

          @include mq('medium') {
            letter-spacing: 0.03em;
          }

          @include mq('medium-plus') {
            letter-spacing: normal;
          }

          &:not(.warranty__terms-text--no-list-special) {
            padding-top: 20px;
          }
        }
      }

      &-diff-wrapper {
        // needed only for mobile.
        @include mq('767px', 'max-width') {
          display: flex;
          flex-direction: column-reverse;
        }

        @include mq('medium') {
          padding-bottom: 14px;
        }

        > .text {
          &:first-child {
            .warranty__terms-text {
              // needed only for mobile.
              @include mq('767px', 'max-width') {
                padding-top: 20px;
              }

              @include mq('medium') {
                p {
                  font-size: var(--emu-common-font-sizes-wide-small);
                  line-height: 1.1;
                }
              }
            }
          }

          &:last-child {
            .warranty__terms-text {
              // needed only for mobile.
              @include mq('767px', 'max-width') {
                padding-top: 10px;
              }
            }
          }
        }
      }
    }

    &__spl-cons {
      padding-top: 35px;
      padding-bottom: 60px;

      @include mq('medium') {
        padding-top: 25px;
        padding-bottom: 52px;
      }

      > .container {
        @include mq('medium') {
          margin-left: 75px;
          margin-right: 75px;
        }
      }

      &-wrapper {
        padding-top: 25px;
      }

      &-title {
        font-size: 22.4px;
        font-weight: var(
          --emu-common-font-weight-regular
        ); // 500 in live, but 400 matches more closer
        font-family: var(--emu-common-font-families-graphik);
        letter-spacing: 4px;
        margin-bottom: var(--emu-common-spacing-small);

        @include mq('medium') {
          font-size: 30px;
          line-height: 30px;
          letter-spacing: normal;
        }

        h1,
        h2,
        h3,
        h4 {
          font: inherit;
        }
      }

      &-text {
        padding-top: 10px;
        margin-bottom: var(--emu-common-spacing-medium);
        font-size: 16px;
        letter-spacing: 0.05em;

        @include mq('medium') {
          padding-top: var(--emu-common-spacing-none);
          font-size: 14px;
          letter-spacing: 0.03em;
        }

        @include mq('large') {
          font-size: var(--emu-common-font-sizes-wide-small);
          letter-spacing: normal;
        }

        sup {
          font-size: 45%;
        }

        p {
          margin-bottom: var(--emu-common-spacing-none);
        }
      }

      &-disclaimer {
        padding-top: 45px;

        @include mq('medium') {
          padding-top: 25px;
        }

        &-text {
          font-size: 11px;
          line-height: 14px;
          letter-spacing: 0.05em;

          @include mq('medium') {
            font-size: var(--emu-common-font-sizes-wide-xs);
            letter-spacing: 0.03em;
          }

          @include mq('large') {
            letter-spacing: normal;
          }

          p {
            margin-bottom: var(--emu-common-spacing-none);
          }

          sup {
            margin-top: 6px;
            top: -2px;
            left: -1px;
          }

          .emphasis sup {
            margin-top: var(--emu-common-spacing-none);
            top: 0;
            left: 0;
          }
        }
      }
    }
  }

  // to change active home link color from pink to white
  .header {
    .emu-navigation__menu--level-0 {
      li {
        &.emu-navigation__item--active {
          > a,
          > span a {
            color: var(--emu-common-colors-white);
          }
        }
      }
    }
  }
}

[lang='fr_ca'] {
  #warranty {
    .warranty__title {
      font-size: 30px;
      line-height: var(--emu-common-line-heights-narrow-large);

      @include mq('medium') {
        font-size: 44.8px;
        line-height: 51.5px;
      }

      sup {
        font-size: 50%;
        font-weight: var(--emu-common-font-weight-medium);
        top: -1px;
      }
    }
  }
}
