.video-modal[data-component='modal'] {
  .modal-content {
    width: calc(
      100% - var(--emu-common-spacing-medium)
    ); // 8px margin added on both sides
    margin: var(--emu-common-spacing-small);
    display: flex;
    flex-direction: column;
    padding: var(--emu-common-spacing-none);
    border-radius: 4.8px;
    overflow: hidden;
    background-color: var(--emu-common-colors-white);
    border: var(--emu-common-border-width-thin) solid rgba(0, 0, 0, 0.2);
    background-clip: padding-box;

    // breakpoint needed to match the live site
    @include mq('576px') {
      margin-left: var(--emu-common-spacing-none);
      margin-right: var(--emu-common-spacing-none);
      margin-top: 28px;
      margin-bottom: 28px;
      width: 100%;
    }

    @include mq('medium') {
      max-width: 700px;
      margin-left: auto;
      margin-right: auto;
    }

    @include mq('large') {
      max-width: 900px;
    }
  }

  .close {
    font-size: 24px;
    line-height: 1.1;
    color: var(--emu-common-colors-brand-blue-500);
    opacity: 1;
    align-self: flex-end;
    padding: var(--emu-common-spacing-none);
    position: static;
    display: inline-flex;
    font-weight: var(--emu-common-font-weight-bold);
    padding-left: var(--emu-common-spacing-small);
    padding-right: 6px;

    @include mq('medium') {
      font-size: 25px;
      max-height: 26px;
    }
  }

  .embed {
    .aaaem-embed__oembed {
      padding: var(--emu-common-spacing-none);
    }

    iframe {
      width: 600px;
      height: 210px;
      max-width: 100%;
      position: static;
      display: block;
      margin-bottom: 4px;

      @include mq('medium') {
        width: 750px;
        height: 400px;
      }

      @include mq('large') {
        width: 1000px;
        height: 500px;
      }
    }
  }
}
