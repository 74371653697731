.fancy-img-with-text {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  position: relative;

  @include mq('medium') {
    flex-direction: row;
    max-height: 600px;
  }

  @include aem-editor-view {
    max-height: none;
  }

  > .container:first-child {
    @include mq('medium') {
      max-width: 45%;
    }
  }

  > .container:last-child {
    @include mq('medium') {
      max-width: 55%;
    }
  }

  &--reverse {
    @include mq('medium') {
      flex-direction: row-reverse;
    }

    > .container:first-child {
      @include mq('medium') {
        padding-left: 30px;
        max-width: 55%;
      }
    }

    > .container:last-child {
      @include mq('medium') {
        max-width: 45%;
      }
    }
  }

  &__img-container {
    img {
      min-width: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
      width: 100%;
      display: block;

      @include mq('medium') {
        position: relative;
        padding: var(--emu-common-spacing-none);
        z-index: var(--emu-common-other-z-index-layer);
        min-width: 300px;
        top: -10px;
      }

      @include mq('x-large') {
        top: 0;
      }
    }
  }

  &__img-disclaimer {
    font-size: 10px;
    letter-spacing: 0.07em;
    color: var(--emu-common-colors-black);
    max-width: 95%;
    margin-bottom: var(--emu-common-spacing-medium);

    p {
      margin-bottom: var(--emu-common-spacing-none);
    }
  }

  .title-text {
    &__title {
      padding-top: var(--emu-common-spacing-medium);
      letter-spacing: -0.2px;

      @include mq('medium') {
        padding-top: 52.8px; // as per live site its 3.3rem, and calculates to 52.8
      }
    }

    &__txt {
      padding-bottom: var(--emu-common-spacing-none);
    }
  }
}
