body {
  font-family: var(--emu-semantic-font-families-body);
  font-weight: var(--emu-semantic-font-weight-regular);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--emu-semantic-font-families-heading);
  font-weight: var(--emu-semantic-font-weight-bolder);
  color: inherit;
  margin: var(--emu-common-spacing-none);
}

:root {
  --emu-common-spacing-xs: 4px;
  --emu-common-spacing-xxs: 2px;
  --emu-common-spacing-small: 8px;
  --emu-common-spacing-medium: 16px;
  --emu-common-spacing-large: 32px;
  --emu-common-spacing-xl: 64px;
  --emu-common-spacing-none: 0px;
  --emu-common-spacing-unset: unset;
  --emu-common-colors-white: #fff;
  --emu-common-colors-black: #000;
  --emu-common-colors-red-50: #fff5f5;
  --emu-common-colors-red-100: #ffdcdb;
  --emu-common-colors-red-200: #ffaba8;
  --emu-common-colors-red-300: #ff7f7a;
  --emu-common-colors-red-400: #ff4e47;
  --emu-common-colors-red-500: #ff1d15;
  --emu-common-colors-red-600: #db0700;
  --emu-common-colors-red-700: #a30500;
  --emu-common-colors-red-800: #700400;
  --emu-common-colors-red-900: #380200;
  --emu-common-colors-red-950: #190100;
  --emu-common-colors-grey-100: #f4f5f5;
  --emu-common-colors-grey-200: #d4d7d8;
  --emu-common-colors-grey-300: #b4b8bb;
  --emu-common-colors-grey-400: #949a9e;
  --emu-common-colors-grey-500: #7e868b;
  --emu-common-colors-grey-600: #61676b;
  --emu-common-colors-grey-700: #44484b;
  --emu-common-colors-grey-800: #303436;
  --emu-common-colors-grey-900: #1d1f20;
  --emu-common-colors-transparent: #0000;
  --emu-common-colors-teal-50: #e0fbff;
  --emu-common-colors-teal-100: #c2f7ff;
  --emu-common-colors-teal-200: #85efff;
  --emu-common-colors-teal-300: #42e6ff;
  --emu-common-colors-teal-400: #05deff;
  --emu-common-colors-teal-500: #00abc7;
  --emu-common-colors-teal-600: #00899e;
  --emu-common-colors-teal-700: #006675;
  --emu-common-colors-teal-800: #004752;
  --emu-common-colors-teal-900: #002329;
  --emu-common-colors-teal-950: #001214;
  --emu-common-colors-beige-50: #fefcfb;
  --emu-common-colors-beige-100: #fdf9f7;
  --emu-common-colors-beige-200: #f9efeb;
  --emu-common-colors-beige-300: #f7e8e3;
  --emu-common-colors-beige-400: #f5e2db;
  --emu-common-colors-beige-500: #f2d9d0;
  --emu-common-colors-beige-600: #dfa18b;
  --emu-common-colors-beige-700: #cb6743;
  --emu-common-colors-beige-800: #8c4227;
  --emu-common-colors-beige-900: #482214;
  --emu-common-colors-beige-950: #24110a;
  --emu-common-colors-light-brown-50: #faf6f5;
  --emu-common-colors-light-brown-100: #f5ecea;
  --emu-common-colors-light-brown-200: #ead7d2;
  --emu-common-colors-light-brown-300: #e0c4bd;
  --emu-common-colors-light-brown-400: #d5aea5;
  --emu-common-colors-light-brown-500: #ca9a8e;
  --emu-common-colors-light-brown-600: #b4705f;
  --emu-common-colors-light-brown-700: #8e5243;
  --emu-common-colors-light-brown-800: #5e362c;
  --emu-common-colors-light-brown-900: #311c17;
  --emu-common-colors-light-brown-950: #180e0b;
  --emu-common-colors-medium-brown-50: #f6f0ef;
  --emu-common-colors-medium-brown-100: #ebdddb;
  --emu-common-colors-medium-brown-200: #d6bcb8;
  --emu-common-colors-medium-brown-300: #c29a94;
  --emu-common-colors-medium-brown-400: #ad7971;
  --emu-common-colors-medium-brown-500: #925c54;
  --emu-common-colors-medium-brown-600: #754a43;
  --emu-common-colors-medium-brown-700: #573732;
  --emu-common-colors-medium-brown-800: #3a2522;
  --emu-common-colors-medium-brown-900: #1d1211;
  --emu-common-colors-medium-brown-950: #100a09;
  --emu-common-colors-dark-brown-50: #f1e4e5;
  --emu-common-colors-dark-brown-100: #e3cacb;
  --emu-common-colors-dark-brown-200: #c8989a;
  --emu-common-colors-dark-brown-300: #ac6365;
  --emu-common-colors-dark-brown-400: #7e4345;
  --emu-common-colors-dark-brown-500: #492728;
  --emu-common-colors-dark-brown-600: #3c2021;
  --emu-common-colors-dark-brown-700: #2b1718;
  --emu-common-colors-dark-brown-800: #1e1010;
  --emu-common-colors-dark-brown-900: #0d0707;
  --emu-common-colors-dark-brown-950: #070404;
  --emu-common-colors-light-grey-50: #fff;
  --emu-common-colors-light-grey-100: #fdfcfc;
  --emu-common-colors-light-grey-200: #fdfcfc;
  --emu-common-colors-light-grey-300: #fcf9f8;
  --emu-common-colors-light-grey-400: #fcf9f8;
  --emu-common-colors-light-grey-500: #faf6f5;
  --emu-common-colors-light-grey-600: #dabcb4;
  --emu-common-colors-light-grey-700: #b87f70;
  --emu-common-colors-light-grey-800: #855042;
  --emu-common-colors-light-grey-900: #221411;
  --emu-common-colors-medium-grey-50: #fff;
  --emu-common-colors-medium-grey-100: #f8f7f7;
  --emu-common-colors-medium-grey-200: #e6e5e5;
  --emu-common-colors-medium-grey-300: #d8d5d5;
  --emu-common-colors-medium-grey-400: #c9c5c5;
  --emu-common-colors-medium-grey-500: #b9b4b4;
  --emu-common-colors-medium-grey-600: #958e8e;
  --emu-common-colors-medium-grey-700: #716a6a;
  --emu-common-colors-medium-grey-800: #4c4747;
  --emu-common-colors-medium-grey-900: #252222;
  --emu-common-colors-medium-grey-950: #121111;
  --emu-common-colors-dark-grey-50: #f7f7f7;
  --emu-common-colors-dark-grey-100: #e8e8e8;
  --emu-common-colors-dark-grey-200: #c9c9ca;
  --emu-common-colors-dark-grey-300: #727272;
  --emu-common-colors-dark-grey-400: #656569;
  --emu-common-colors-dark-grey-500: #1e1e1e;
  --emu-common-colors-dark-grey-600: #58595a;
  --emu-common-colors-dark-grey-700: #424243;
  --emu-common-colors-dark-grey-800: #2d2e2e;
  --emu-common-colors-dark-grey-900: #171717;
  --emu-common-colors-dark-grey-950: #0a0a0a;
  --emu-common-colors-blush-50: #fff;
  --emu-common-colors-blush-100: #fdfcfc;
  --emu-common-colors-blush-200: #f4ebef;
  --emu-common-colors-blush-300: #eddee4;
  --emu-common-colors-blush-400: #e6d1d9;
  --emu-common-colors-blush-500: #ddc1cc;
  --emu-common-colors-blush-600: #c08ca1;
  --emu-common-colors-blush-700: #a15876;
  --emu-common-colors-blush-800: #693a4d;
  --emu-common-colors-blush-900: #351d26;
  --emu-common-colors-blush-950: #1a0e13;
  --emu-common-colors-abbvie-blue-50: #e3ebfc;
  --emu-common-colors-abbvie-blue-100: #bed1f9;
  --emu-common-colors-abbvie-blue-200: #6a97f1;
  --emu-common-colors-abbvie-blue-300: #1b60e9;
  --emu-common-colors-abbvie-blue-400: #0f3d9a;
  --emu-common-colors-abbvie-blue-500: #071d49;
  --emu-common-colors-abbvie-blue-600: #06183c;
  --emu-common-colors-abbvie-blue-700: #04112a;
  --emu-common-colors-abbvie-blue-800: #030b1c;
  --emu-common-colors-abbvie-blue-900: #01060e;
  --emu-common-colors-abbvie-blue-950: #010409;
  --emu-common-colors-brand-blue-50: #daf2f6;
  --emu-common-colors-brand-blue-100: #99dde8;
  --emu-common-colors-brand-blue-200: #00abc7;
  --emu-common-colors-brand-blue-300: #4e748b;
  --emu-common-colors-brand-blue-400: #003349;
  --emu-common-colors-brand-blue-500: #003359;
  --emu-common-colors-brand-blue-600: #112b4e;
  --emu-common-colors-brand-violet-300: #ded4e0;
  --emu-common-colors-brand-violet-400: #492250;
  --emu-common-colors-brand-violet-500: #5b2a64;
  --emu-common-colors-brand-pink-300: #f4d6cc;
  --emu-common-colors-brand-pink-400: #ecbaaa;
  --emu-common-colors-brand-pink-500: #f5a6e4;
  --emu-common-font-families-sans: Arial, sans-serif;
  --emu-common-font-families-serif: Times New Roman, serif;
  --emu-common-font-families-mono: Courier, monospace;
  --emu-common-font-families-roboto: Roboto, system-ui;
  --emu-common-font-families-aleo: Aleo, system-ui;
  --emu-common-font-families-graphik: Graphik, NeueHaasUnicaW1G-Regular, Helvetica Neue, Helvetica, Arial, sans-serif;
  --emu-common-font-families-superscript-regular: Superscript_Regular;
  --emu-common-font-families-superscript-bold: Superscript_Bold;
  --emu-common-font-weight-medium: 500;
  --emu-common-font-weight-bold: 700;
  --emu-common-font-weight-light: 300;
  --emu-common-font-weight-black: 900;
  --emu-common-font-weight-regular: 400;
  --emu-common-font-sizes-narrow-body: 18px;
  --emu-common-font-sizes-narrow-xs: 10px;
  --emu-common-font-sizes-narrow-small: 12px;
  --emu-common-font-sizes-narrow-medium: 20px;
  --emu-common-font-sizes-narrow-large: 22px;
  --emu-common-font-sizes-narrow-xl: 32px;
  --emu-common-font-sizes-narrow-xxl: 35.13px;
  --emu-common-font-sizes-narrow-xxxl: 35.13px;
  --emu-common-font-sizes-wide-body: 18px;
  --emu-common-font-sizes-wide-xs: 12px;
  --emu-common-font-sizes-wide-small: 16px;
  --emu-common-font-sizes-wide-medium: 20px;
  --emu-common-font-sizes-wide-large: 22px;
  --emu-common-font-sizes-wide-xl: 40px;
  --emu-common-font-sizes-wide-xxl: 48px;
  --emu-common-font-sizes-wide-xxxl: 52px;
  --emu-common-line-heights-narrow-body: 28px;
  --emu-common-line-heights-narrow-xs: 14px;
  --emu-common-line-heights-narrow-small: 18px;
  --emu-common-line-heights-narrow-medium: 26px;
  --emu-common-line-heights-narrow-large: 35px;
  --emu-common-line-heights-narrow-xl: 35.2px;
  --emu-common-line-heights-narrow-xxl: 42px;
  --emu-common-line-heights-narrow-xxxl: 42px;
  --emu-common-line-heights-wide-body: 28px;
  --emu-common-line-heights-wide-xs: 18px;
  --emu-common-line-heights-wide-small: 14.5px;
  --emu-common-line-heights-wide-medium: 26px;
  --emu-common-line-heights-wide-large: 35px;
  --emu-common-line-heights-wide-xl: 44px;
  --emu-common-line-heights-wide-xxl: 56px;
  --emu-common-line-heights-wide-xxxl: 62px;
  --emu-common-border-radius-xxs: 2px;
  --emu-common-border-radius-xs: 4px;
  --emu-common-border-radius-small: 8px;
  --emu-common-border-radius-medium: 16px;
  --emu-common-border-radius-large: 55.79px;
  --emu-common-border-radius-none: 0px;
  --emu-common-border-width-thin: 1px;
  --emu-common-border-width-small: 1.8px;
  --emu-common-border-width-medium: 2px;
  --emu-common-border-width-thick: 4px;
  --emu-common-border-width-none: 0px;
  --emu-common-outline-width-thin: thin;
  --emu-common-outline-width-medium: medium;
  --emu-common-outline-width-thick: thick;
  --emu-common-outline-width-unset: unset;
  --emu-common-sizing-none: 0px;
  --emu-common-sizing-xxs: 1px;
  --emu-common-sizing-xs: 8px;
  --emu-common-sizing-small: 16px;
  --emu-common-sizing-medium: 32px;
  --emu-common-sizing-large: 64px;
  --emu-common-sizing-xl: 120px;
  --emu-common-sizing-xxl: 256px;
  --emu-common-sizing-xxxl: 1140px;
  --emu-common-sizing-container: 1520px;
  --emu-common-other-time-transition-short: .2s;
  --emu-common-other-time-transition-base: .4s;
  --emu-common-other-time-transition-long: .6s;
  --emu-common-other-time-transition-xl: 1s;
  --emu-common-other-time-duration-instant: .4s;
  --emu-common-other-time-duration-short: 2s;
  --emu-common-other-time-duration-base: 4s;
  --emu-common-other-time-duration-long: 6s;
  --emu-common-other-time-delay-none: 0s;
  --emu-common-other-time-delay-short: 50ms;
  --emu-common-other-time-delay-base: .1s;
  --emu-common-other-time-delay-long: .2s;
  --emu-common-other-z-index-cookie-banner: 700;
  --emu-common-other-z-index-modal: 600;
  --emu-common-other-z-index-header: 500;
  --emu-common-other-z-index-isi: 400;
  --emu-common-other-z-index-overlay: 300;
  --emu-common-other-z-index-layer: 100;
  --emu-common-other-z-index-base: 0;
  --emu-common-other-z-index-behind: -1;
  --emu-common-box-shadow-soft-light: 0 0 6 0 var(--emu-common-colors-grey-700);
  --emu-semantic-colors-none: var(--emu-common-colors-transparent);
  --emu-semantic-colors-surface-light: var(--emu-common-colors-light-brown-100);
  --emu-semantic-colors-surface-dark: var(--emu-common-colors-dark-brown-500);
  --emu-semantic-colors-surface-modal-backdrop: #00000080;
  --emu-semantic-colors-actions-primary-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-semantic-colors-actions-primary-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-semantic-colors-actions-primary-default-light: var(--emu-semantic-colors-brand-primary-500);
  --emu-semantic-colors-actions-primary-default-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-actions-primary-hover-light: var(--emu-semantic-colors-brand-primary-500);
  --emu-semantic-colors-actions-primary-hover-dark: var(--emu-common-colors-beige-300);
  --emu-semantic-colors-actions-primary-active-light: var(--emu-semantic-colors-brand-primary-500);
  --emu-semantic-colors-actions-primary-active-dark: var(--emu-common-colors-beige-300);
  --emu-semantic-colors-actions-primary-focus-light: var(--emu-semantic-colors-brand-primary-500);
  --emu-semantic-colors-actions-primary-focus-dark: var(--emu-common-colors-beige-300);
  --emu-semantic-colors-actions-primary-disabled-light: var(--emu-common-colors-medium-grey-500);
  --emu-semantic-colors-actions-primary-disabled-dark: var(--emu-common-colors-medium-grey-300);
  --emu-semantic-colors-actions-primary-error-light: var(--emu-semantic-colors-error-500);
  --emu-semantic-colors-actions-primary-error-dark: var(--emu-semantic-colors-error-300);
  --emu-semantic-colors-actions-on-primary-light: var(--emu-common-colors-white);
  --emu-semantic-colors-actions-on-primary-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-actions-secondary-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-semantic-colors-actions-secondary-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-semantic-colors-actions-secondary-default-light: var(--emu-semantic-colors-brand-secondary-500);
  --emu-semantic-colors-actions-secondary-default-dark: var(--emu-common-colors-blush-500);
  --emu-semantic-colors-actions-secondary-hover-light: var(--emu-semantic-colors-brand-secondary-500);
  --emu-semantic-colors-actions-secondary-hover-dark: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-actions-secondary-active-light: var(--emu-semantic-colors-brand-secondary-500);
  --emu-semantic-colors-actions-secondary-active-dark: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-actions-secondary-focus-light: var(--emu-semantic-colors-brand-secondary-500);
  --emu-semantic-colors-actions-secondary-focus-dark: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-actions-secondary-disabled-light: var(--emu-common-colors-medium-grey-300);
  --emu-semantic-colors-actions-secondary-disabled-dark: var(--emu-common-colors-medium-grey-200);
  --emu-semantic-colors-actions-on-secondary-light: var(--emu-common-colors-white);
  --emu-semantic-colors-actions-on-secondary-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-text-light: var(--emu-common-colors-brand-blue-500);
  --emu-semantic-colors-text-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-foreground-light: var(--emu-common-colors-medium-brown-300);
  --emu-semantic-colors-foreground-dark: var(--emu-common-colors-beige-500);
  --emu-semantic-colors-primary-0: var(--emu-common-colors-white);
  --emu-semantic-colors-primary-50: var(--emu-common-colors-medium-brown-50);
  --emu-semantic-colors-primary-100: var(--emu-common-colors-medium-brown-100);
  --emu-semantic-colors-primary-200: var(--emu-common-colors-medium-brown-200);
  --emu-semantic-colors-primary-300: var(--emu-common-colors-medium-brown-300);
  --emu-semantic-colors-primary-400: var(--emu-common-colors-medium-brown-400);
  --emu-semantic-colors-primary-500: var(--emu-common-colors-medium-brown-500);
  --emu-semantic-colors-primary-600: var(--emu-common-colors-medium-brown-600);
  --emu-semantic-colors-primary-700: var(--emu-common-colors-medium-brown-700);
  --emu-semantic-colors-primary-800: var(--emu-common-colors-medium-brown-800);
  --emu-semantic-colors-primary-900: var(--emu-common-colors-medium-brown-900);
  --emu-semantic-colors-primary-950: var(--emu-common-colors-medium-brown-950);
  --emu-semantic-colors-primary-light: var(--emu-semantic-colors-primary-500);
  --emu-semantic-colors-primary-dark: var(--emu-semantic-colors-primary-200);
  --emu-semantic-colors-secondary-50: var(--emu-common-colors-blush-50);
  --emu-semantic-colors-secondary-100: var(--emu-common-colors-blush-100);
  --emu-semantic-colors-secondary-200: var(--emu-common-colors-blush-200);
  --emu-semantic-colors-secondary-300: var(--emu-common-colors-blush-300);
  --emu-semantic-colors-secondary-400: var(--emu-common-colors-blush-400);
  --emu-semantic-colors-secondary-500: var(--emu-common-colors-blush-500);
  --emu-semantic-colors-secondary-600: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-secondary-700: var(--emu-common-colors-blush-700);
  --emu-semantic-colors-secondary-800: var(--emu-common-colors-blush-800);
  --emu-semantic-colors-secondary-900: var(--emu-common-colors-blush-900);
  --emu-semantic-colors-secondary-950: var(--emu-common-colors-blush-950);
  --emu-semantic-colors-secondary-light: var(--emu-semantic-colors-secondary-500);
  --emu-semantic-colors-secondary-dark: var(--emu-semantic-colors-secondary-200);
  --emu-semantic-colors-tertiary-50: var(--emu-common-colors-abbvie-blue-50);
  --emu-semantic-colors-tertiary-100: var(--emu-common-colors-abbvie-blue-100);
  --emu-semantic-colors-tertiary-200: var(--emu-common-colors-abbvie-blue-200);
  --emu-semantic-colors-tertiary-300: var(--emu-common-colors-abbvie-blue-300);
  --emu-semantic-colors-tertiary-400: var(--emu-common-colors-abbvie-blue-400);
  --emu-semantic-colors-tertiary-500: var(--emu-common-colors-abbvie-blue-500);
  --emu-semantic-colors-tertiary-600: var(--emu-common-colors-abbvie-blue-600);
  --emu-semantic-colors-tertiary-700: var(--emu-common-colors-abbvie-blue-700);
  --emu-semantic-colors-tertiary-800: var(--emu-common-colors-abbvie-blue-800);
  --emu-semantic-colors-tertiary-900: var(--emu-common-colors-abbvie-blue-900);
  --emu-semantic-colors-tertiary-950: var(--emu-common-colors-abbvie-blue-950);
  --emu-semantic-colors-tertiary-light: var(--emu-semantic-colors-tertiary-500);
  --emu-semantic-colors-tertiary-dark: var(--emu-semantic-colors-tertiary-200);
  --emu-semantic-colors-brand-primary-50: var(--emu-common-colors-brand-blue-50);
  --emu-semantic-colors-brand-primary-100: var(--emu-common-colors-brand-blue-100);
  --emu-semantic-colors-brand-primary-200: var(--emu-common-colors-brand-blue-200);
  --emu-semantic-colors-brand-primary-300: var(--emu-common-colors-brand-blue-300);
  --emu-semantic-colors-brand-primary-400: var(--emu-common-colors-brand-blue-400);
  --emu-semantic-colors-brand-primary-500: var(--emu-common-colors-brand-blue-500);
  --emu-semantic-colors-brand-primary-600: var(--emu-common-colors-brand-blue-600);
  --emu-semantic-colors-brand-secondary-300: var(--emu-common-colors-brand-pink-300);
  --emu-semantic-colors-brand-secondary-400: var(--emu-common-colors-brand-pink-400);
  --emu-semantic-colors-brand-secondary-500: var(--emu-common-colors-brand-pink-500);
  --emu-semantic-colors-brand-tertiary-300: var(--emu-common-colors-brand-violet-300);
  --emu-semantic-colors-brand-tertiary-400: var(--emu-common-colors-brand-violet-400);
  --emu-semantic-colors-brand-tertiary-500: var(--emu-common-colors-brand-violet-500);
  --emu-semantic-colors-brand-grey-400: var(--emu-common-colors-dark-grey-400);
  --emu-semantic-colors-brand-grey-500: var(--emu-common-colors-dark-grey-500);
  --emu-semantic-colors-error-50: var(--emu-common-colors-red-50);
  --emu-semantic-colors-error-100: var(--emu-common-colors-red-100);
  --emu-semantic-colors-error-200: var(--emu-common-colors-red-200);
  --emu-semantic-colors-error-300: var(--emu-common-colors-red-300);
  --emu-semantic-colors-error-400: var(--emu-common-colors-red-400);
  --emu-semantic-colors-error-500: var(--emu-common-colors-red-500);
  --emu-semantic-colors-error-600: var(--emu-common-colors-red-600);
  --emu-semantic-colors-error-700: var(--emu-common-colors-red-700);
  --emu-semantic-colors-error-800: var(--emu-common-colors-red-800);
  --emu-semantic-colors-error-900: var(--emu-common-colors-red-900);
  --emu-semantic-colors-error-950: var(--emu-common-colors-red-950);
  --emu-semantic-colors-error-light: var(--emu-semantic-colors-error-500);
  --emu-semantic-colors-error-dark: var(--emu-semantic-colors-error-200);
  --emu-semantic-colors-background-light: var(--emu-common-colors-light-brown-50);
  --emu-semantic-colors-background-dark: var(--emu-common-colors-beige-500);
  --emu-semantic-font-weight-medium: var(--emu-common-font-weight-medium);
  --emu-semantic-font-weight-bold: var(--emu-common-font-weight-bold);
  --emu-semantic-font-weight-light: var(--emu-common-font-weight-light);
  --emu-semantic-font-weight-black: var(--emu-common-font-weight-black);
  --emu-semantic-font-weight-regular: var(--emu-common-font-weight-regular);
  --emu-semantic-font-families-body: var(--emu-common-font-families-roboto);
  --emu-semantic-font-families-heading: var(--emu-common-font-families-aleo);
  --emu-semantic-font-families-mono: var(--emu-common-font-families-mono);
  --emu-semantic-spacing-none: var(--emu-common-spacing-none);
  --emu-semantic-spacing-one-line-height-wide: var(--emu-semantic-line-heights-wide-small);
  --emu-semantic-spacing-one-line-height-narrow: var(--emu-semantic-line-heights-narrow-small);
  --emu-semantic-spacing-two-line-height-wide: calc(var(--emu-semantic-spacing-one-line-height-wide) * 2);
  --emu-semantic-spacing-two-line-height-narrow: calc(var(--emu-semantic-spacing-one-line-height-narrow) * 2);
  --emu-semantic-spacing-three-line-height-wide: calc(var(--emu-semantic-spacing-one-line-height-wide) * 3);
  --emu-semantic-spacing-three-line-height-narrow: calc(var(--emu-semantic-spacing-one-line-height-narrow) * 3);
  --emu-semantic-spacing-rem-horizontal: var(--emu-common-spacing-small);
  --emu-semantic-spacing-horizontal-narrow: 8px;
  --emu-semantic-spacing-horizontal-wide: 16px;
  --emu-semantic-spacing-horizontal-onerem-narrow: 1rem;
  --emu-semantic-spacing-horizontal-onerem-wide: 1rem;
  --emu-semantic-spacing-horizontal-tworem-narrow: calc(var(--emu-semantic-spacing-horizontal-onerem-narrow) * 2);
  --emu-semantic-spacing-horizontal-tworem-wide: calc(var(--emu-semantic-spacing-horizontal-onerem-wide) * 2);
  --emu-semantic-spacing-rem-vertical: var(--emu-common-spacing-small);
  --emu-semantic-spacing-vertical-narrow: 8px;
  --emu-semantic-spacing-vertical-wide: 16px;
  --emu-semantic-spacing-half-line-height-wide: calc(var(--emu-semantic-line-heights-wide-small) * .5);
  --emu-semantic-spacing-half-line-height-narrow: calc(var(--emu-semantic-line-heights-narrow-small) * .5);
  --emu-semantic-spacing-quarter-line-height-wide: calc(var(--emu-semantic-line-heights-wide-small) * .25);
  --emu-semantic-spacing-quarter-line-height-narrow: calc(var(--emu-semantic-line-heights-narrow-small) * .25);
  --emu-semantic-font-sizes-narrow-body: var(--emu-common-font-sizes-narrow-body);
  --emu-semantic-font-sizes-narrow-xs: var(--emu-common-font-sizes-narrow-xs);
  --emu-semantic-font-sizes-narrow-small: var(--emu-common-font-sizes-narrow-small);
  --emu-semantic-font-sizes-narrow-medium: var(--emu-common-font-sizes-narrow-medium);
  --emu-semantic-font-sizes-narrow-large: var(--emu-common-font-sizes-narrow-large);
  --emu-semantic-font-sizes-narrow-xl: var(--emu-common-font-sizes-narrow-xl);
  --emu-semantic-font-sizes-narrow-xxl: var(--emu-common-font-sizes-narrow-xxl);
  --emu-semantic-font-sizes-narrow-xxxl: var(--emu-common-font-sizes-narrow-xxxl);
  --emu-semantic-font-sizes-wide-body: var(--emu-common-font-sizes-wide-body);
  --emu-semantic-font-sizes-wide-xs: var(--emu-common-font-sizes-wide-xs);
  --emu-semantic-font-sizes-wide-small: var(--emu-common-font-sizes-wide-small);
  --emu-semantic-font-sizes-wide-medium: var(--emu-common-font-sizes-wide-medium);
  --emu-semantic-font-sizes-wide-large: var(--emu-common-font-sizes-wide-large);
  --emu-semantic-font-sizes-wide-xl: var(--emu-common-font-sizes-wide-xl);
  --emu-semantic-font-sizes-wide-xxl: var(--emu-common-font-sizes-wide-xxl);
  --emu-semantic-font-sizes-wide-xxxl: var(--emu-common-font-sizes-wide-xxxl);
  --emu-semantic-line-heights-narrow-body: var(--emu-common-line-heights-narrow-body);
  --emu-semantic-line-heights-narrow-xs: var(--emu-common-line-heights-narrow-xs);
  --emu-semantic-line-heights-narrow-small: var(--emu-common-line-heights-narrow-small);
  --emu-semantic-line-heights-narrow-medium: var(--emu-common-line-heights-narrow-medium);
  --emu-semantic-line-heights-narrow-large: var(--emu-common-line-heights-narrow-large);
  --emu-semantic-line-heights-narrow-xl: var(--emu-common-line-heights-narrow-xl);
  --emu-semantic-line-heights-narrow-xxl: var(--emu-common-line-heights-narrow-xxl);
  --emu-semantic-line-heights-narrow-xxxl: var(--emu-common-line-heights-narrow-xxxl);
  --emu-semantic-line-heights-wide-body: var(--emu-common-line-heights-wide-body);
  --emu-semantic-line-heights-wide-xs: var(--emu-common-line-heights-wide-xs);
  --emu-semantic-line-heights-wide-small: var(--emu-common-line-heights-wide-small);
  --emu-semantic-line-heights-wide-medium: var(--emu-common-line-heights-wide-medium);
  --emu-semantic-line-heights-wide-large: var(--emu-common-line-heights-wide-large);
  --emu-semantic-line-heights-wide-xl: var(--emu-common-line-heights-wide-xl);
  --emu-semantic-line-heights-wide-xxl: var(--emu-common-line-heights-wide-xxl);
  --emu-semantic-line-heights-wide-xxxl: var(--emu-common-line-heights-wide-xxxl);
  --emu-semantic-border-radius-xs: var(--emu-common-border-radius-xs);
  --emu-semantic-border-radius-small: var(--emu-common-border-radius-small);
  --emu-semantic-border-radius-medium: var(--emu-common-border-radius-medium);
  --emu-semantic-border-radius-large: var(--emu-common-border-radius-large);
  --emu-semantic-border-radius-none: var(--emu-common-border-radius-none);
  --emu-semantic-border-width-thin: var(--emu-common-border-width-thin);
  --emu-semantic-border-width-medium: var(--emu-common-border-width-medium);
  --emu-semantic-border-width-thick: var(--emu-common-border-width-thick);
  --emu-semantic-border-width-none: 0px;
  --emu-semantic-outline-width-thin: var(--emu-common-outline-width-thin);
  --emu-semantic-outline-width-medium: var(--emu-common-outline-width-medium);
  --emu-semantic-outline-width-thick: var(--emu-common-outline-width-thick);
  --emu-semantic-outline-width-unset: unset;
  --emu-semantic-sizing-none: var(--emu-common-sizing-none);
  --emu-semantic-sizing-xxs: var(--emu-common-sizing-xxs);
  --emu-semantic-sizing-xs: var(--emu-common-sizing-xs);
  --emu-semantic-sizing-small: var(--emu-common-sizing-small);
  --emu-semantic-sizing-medium: var(--emu-common-sizing-medium);
  --emu-semantic-sizing-large: var(--emu-common-sizing-large);
  --emu-semantic-sizing-xl: var(--emu-common-sizing-xl);
  --emu-semantic-sizing-xxl: var(--emu-common-sizing-xxl);
  --emu-semantic-sizing-breakpoints-x-small: 320px;
  --emu-semantic-sizing-breakpoints-small: 576px;
  --emu-semantic-sizing-breakpoints-medium: 768px;
  --emu-semantic-sizing-breakpoints-medium-plus: 992px;
  --emu-semantic-sizing-breakpoints-large: 1025px;
  --emu-semantic-sizing-breakpoints-x-large: 1200px;
  --emu-semantic-sizing-breakpoints-xx-large: 1300px;
  --emu-semantic-sizing-breakpoints-xxx-large: 1440px;
  --emu-semantic-sizing-breakpoints-jumbo: 1500px;
  --emu-semantic-sizing-breakpoints-mega: 1600px;
  --emu-semantic-sizing-one-line-height-narrow: 20px;
  --emu-semantic-sizing-one-line-height-wide: 20px;
  --emu-semantic-sizing-two-line-height-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * 2);
  --emu-semantic-sizing-two-line-height-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * 2);
  --emu-semantic-sizing-three-line-height-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * 3);
  --emu-semantic-sizing-three-line-height-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * 3);
  --emu-semantic-sizing-max-width-text-narrow: 40em;
  --emu-semantic-sizing-max-width-text-wide: 55em;
  --emu-semantic-typography-narrow-headings-xxxl: var(--emu-semantic-font-weight-medium) var(--emu-semantic-font-sizes-narrow-xxxl) / var(--emu-semantic-line-heights-narrow-xxxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-xxl: var(--emu-semantic-font-weight-medium) var(--emu-semantic-font-sizes-narrow-xxl) / var(--emu-semantic-line-heights-narrow-xxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-xl: var(--emu-semantic-font-weight-medium) var(--emu-semantic-font-sizes-narrow-xl) / var(--emu-semantic-line-heights-narrow-xl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-large: var(--emu-semantic-font-weight-medium) var(--emu-semantic-font-sizes-narrow-large) / var(--emu-semantic-line-heights-narrow-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-medium: var(--emu-semantic-font-weight-medium) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-body-regular: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-body) / var(--emu-semantic-line-heights-narrow-body) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-xxxl: var(--emu-semantic-font-weight-medium) var(--emu-semantic-font-sizes-wide-xxxl) / var(--emu-semantic-line-heights-wide-xxxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-xxl: var(--emu-semantic-font-weight-medium) var(--emu-semantic-font-sizes-wide-xxl) / var(--emu-semantic-line-heights-wide-xxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-xl: var(--emu-semantic-font-weight-medium) var(--emu-semantic-font-sizes-wide-xl) / var(--emu-semantic-line-heights-wide-xl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-large: var(--emu-semantic-font-weight-medium) var(--emu-semantic-font-sizes-wide-large) / var(--emu-semantic-line-heights-wide-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-medium: var(--emu-semantic-font-weight-medium) var(--emu-semantic-font-sizes-wide-medium) / var(--emu-semantic-line-heights-wide-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-body-regular: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-body) / var(--emu-semantic-line-heights-wide-body) var(--emu-semantic-font-families-body);
  --emu-component-cards-card-margin-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-button-text-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-size-height: 30px;
  --emu-component-cards-card-header-button-icon-size-width: 30px;
  --emu-component-cards-card-header-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-cards-card-header-button-icon-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-cards-card-header-button-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-body-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-body-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-teaser-padding-top-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-top-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-right-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-right-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-bottom-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-bottom-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-left-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-left-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-narrow: 40px 15px 0px 15px;
  --emu-component-cards-teaser-padding-wide: 70px 0px 0px 0px;
  --emu-component-cards-teaser-text-color-light: var(--emu-common-colors-white);
  --emu-component-cards-teaser-text-color-dark: var(--emu-common-colors-brand-blue-500);
  --emu-component-cards-teaser-title-max-width: 66.6667%;
  --emu-component-cards-teaser-title-margin-top-narrow: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-title-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-title-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-title-margin-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-title-margin-narrow: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-title-margin-wide: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-description-margin-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-description-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-bottom-wide: var(--emu-common-spacing-small);
  --emu-component-cards-teaser-description-padding-narrow: var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-description-padding-wide: var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-subtext-padding-narrow: var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-subtext-padding-wide: var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-actions-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-cards-teaser-actions-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-cards-teaser-actions-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-actions-padding-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-disclaimer-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-cards-teaser-disclaimer-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-cards-teaser-old-padding-top-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-top-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-right-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-right-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-bottom-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-bottom-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-left-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-left-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-text-color-light: var(--emu-common-colors-white);
  --emu-component-cards-teaser-old-text-color-dark: var(--emu-common-colors-black);
  --emu-component-cards-teaser-old-title-max-width: 66.6667%;
  --emu-component-cards-teaser-old-title-margin-top-narrow: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-old-title-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-old-title-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-old-title-margin-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-old-description-margin-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-old-description-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-old-description-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-old-description-margin-bottom-wide: var(--emu-common-spacing-small);
  --emu-component-cards-content-fragment-card-margin-narrow: var(--emu-semantic-spacing-horizontal-onerem-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-horizontal-onerem-narrow) var(--emu-semantic-spacing-none);
  --emu-component-cards-content-fragment-card-margin-wide: var(--emu-semantic-spacing-horizontal-onerem-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-horizontal-onerem-wide) var(--emu-semantic-spacing-none);
  --emu-component-cards-content-fragment-card-text-typography-name-narrow: var(--emu-semantic-typography-narrow-headings-xl);
  --emu-component-cards-content-fragment-card-text-typography-name-wide: var(--emu-semantic-typography-wide-headings-xl);
  --emu-component-cards-content-fragment-card-text-typography-title-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-cards-content-fragment-card-text-typography-title-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-cards-content-fragment-card-text-typography-bio-narrow: var(--emu-component-ingredients-text-typography-narrow);
  --emu-component-cards-content-fragment-card-text-typography-bio-wide: var(--emu-component-ingredients-text-typography-wide);
  --emu-component-cards-content-fragment-card-text-margin-narrow: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none);
  --emu-component-cards-content-fragment-card-text-margin-wide: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none);
  --emu-component-cards-content-fragment-card-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-cards-content-fragment-card-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-cards-content-fragment-card-padding-narrow: var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-horizontal-onerem-narrow) var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-horizontal-onerem-narrow);
  --emu-component-cards-content-fragment-card-padding-wide: var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-horizontal-onerem-wide) var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-horizontal-onerem-wide);
  --emu-component-cards-content-fragment-card-color-background-dark: var(--emu-semantic-colors-primary-950);
  --emu-component-cards-content-fragment-card-color-background-light: var(--emu-semantic-colors-primary-0);
  --emu-component-cards-content-fragment-card-border-radius-narrow: var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small);
  --emu-component-cards-content-fragment-card-border-radius-wide: var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small);
  --emu-component-cards-content-fragment-card-author-image-sizing-height-narrow: var(--emu-semantic-sizing-xl);
  --emu-component-cards-content-fragment-card-author-image-sizing-height-wide: var(--emu-semantic-sizing-xxl);
  --emu-component-cards-content-fragment-card-author-image-sizing-width-narrow: var(--emu-semantic-sizing-xl);
  --emu-component-cards-content-fragment-card-author-image-sizing-width-wide: var(--emu-semantic-sizing-xxl);
  --emu-component-cards-content-fragment-card-author-image-border-radius-narrow: var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium);
  --emu-component-cards-content-fragment-card-author-image-border-radius-wide: var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium);
  --emu-component-lists-accordion-color-background-light: var(--emu-component-lists-accordion-item-body-color-background-light);
  --emu-component-lists-accordion-color-background-dark: var(--emu-component-lists-accordion-item-body-color-background-dark);
  --emu-component-lists-accordion-color-text-light: var(--emu-component-lists-accordion-item-header-text-color-light);
  --emu-component-lists-accordion-color-text-dark: var(--emu-component-lists-accordion-item-header-text-color-dark);
  --emu-component-lists-accordion-color-border-light: var(--emu-component-lists-accordion-item-header-color-border-bottom-open-light);
  --emu-component-lists-accordion-color-border-dark: var(--emu-component-lists-accordion-item-header-color-border-bottom-open-dark);
  --emu-component-lists-accordion-margin-narrow: var(--emu-common-spacing-none) var(--emu-common-spacing-none) var(--emu-common-spacing-none) var(--emu-common-spacing-none);
  --emu-component-lists-accordion-margin-wide: var(--emu-common-spacing-none) var(--emu-common-spacing-none) var(--emu-common-spacing-none) var(--emu-common-spacing-none);
  --emu-component-lists-accordion-margin-top-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-top-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-right-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-right-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-bottom-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-bottom-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-left-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-left-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-color-border-bottom-closed-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-color-border-bottom-closed-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-color-border-bottom-open-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-color-border-bottom-open-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-color-background-light: var(--emu-common-colors-white);
  --emu-component-lists-accordion-item-body-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-color-border-open-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-color-border-open-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-body-padding-narrow: 0px 12px 8px 13px;
  --emu-component-lists-accordion-item-body-padding-wide: 0px 33px 8px 33px;
  --emu-component-lists-accordion-item-body-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-border-width-bottom-open: var(--emu-semantic-border-width-none);
  --emu-component-lists-accordion-item-header-text-typography-narrow: var(--emu-semantic-typography-narrow-body-regular);
  --emu-component-lists-accordion-item-header-text-typography-wide: var(--emu-semantic-typography-wide-body-regular);
  --emu-component-lists-accordion-item-header-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-accordion-item-header-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-accordion-item-header-padding-narrow: 13px 14px 13px 13px;
  --emu-component-lists-accordion-item-header-padding-wide: 18px 33px 23px 33px;
  --emu-component-lists-accordion-item-header-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-icon-margin-narrow: var(--emu-common-spacing-none) var(--emu-common-spacing-none) var(--emu-common-spacing-none) var(--emu-common-spacing-none);
  --emu-component-lists-accordion-item-header-icon-margin-wide: var(--emu-common-spacing-none) var(--emu-common-spacing-none) var(--emu-common-spacing-none) var(--emu-common-spacing-none);
  --emu-component-lists-accordion-item-header-icon-spacing: var(--emu-common-spacing-small);
  --emu-component-lists-accordion-item-header-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-lists-accordion-item-header-icon-border-radius: var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-transform-open: rotate(0);
  --emu-component-lists-accordion-item-header-icon-transform-closed: rotate(180deg);
  --emu-component-lists-accordion-item-header-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-light: var(--emu-common-colors-black);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-dark: var(--emu-common-colors-black);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-light: var(--emu-common-colors-black);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-dark: var(--emu-common-colors-black);
  --emu-component-lists-accordion-item-header-icon-size-height-narrow: 25px;
  --emu-component-lists-accordion-item-header-icon-size-height-wide: 30px;
  --emu-component-lists-accordion-item-header-icon-size-width-narrow: 25px;
  --emu-component-lists-accordion-item-header-icon-size-width-wide: 30px;
  --emu-component-lists-accordion-item-header-color-background-light: var(--emu-common-colors-white);
  --emu-component-lists-accordion-item-header-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-header-color-border-bottom-open-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-header-color-border-bottom-open-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-header-color-border-bottom-closed-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-header-color-border-bottom-closed-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-border-width-bottom-open: var(--emu-semantic-border-width-none);
  --emu-component-lists-accordion-border-radius-narrow: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-wide: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-width-narrow: var(--emu-common-border-width-none);
  --emu-component-lists-accordion-border-width-wide: var(--emu-common-border-width-none);
  --emu-component-lists-navigation-banner-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-banner-color-background-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-default-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-color-background-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-hover-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-banner-item-color-background-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-border-default-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-banner-item-color-border-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-border-hover-light: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-border-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-text-color-default-light: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-banner-item-text-color-default-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-text-color-hover-light: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-banner-item-text-color-hover-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-border-width: var(--emu-common-border-width-none);
  --emu-component-lists-navigation-banner-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-dropdown-color-background-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-color-background-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-border-width: var(--emu-common-border-width-thin);
  --emu-component-lists-navigation-dropdown-item-color-background-default-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-item-color-background-default-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-light: var(--emu-common-colors-grey-100);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-dark: var(--emu-common-colors-grey-100);
  --emu-component-lists-navigation-dropdown-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-z-index: var(--emu-common-other-z-index-header);
  --emu-component-lists-navigation-text-padding-top-narrow: 33.6px;
  --emu-component-lists-navigation-text-padding-top-wide: var(--emu-common-spacing-small);
  --emu-component-lists-navigation-text-padding-right-narrow: 20.8px;
  --emu-component-lists-navigation-text-padding-right-wide: 20.8px;
  --emu-component-lists-navigation-text-padding-bottom-narrow: 33.6px;
  --emu-component-lists-navigation-text-padding-bottom-wide: var(--emu-common-spacing-small);
  --emu-component-lists-navigation-text-padding-left-narrow: 20.8px;
  --emu-component-lists-navigation-text-padding-left-wide: 20.8px;
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-default-light: var(--emu-component-ingredients-select-input-option-text-color-filled-default-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-default-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-default-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-hover-light: var(--emu-component-ingredients-select-input-option-text-color-filled-hover-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-hover-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-hover-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-active-light: var(--emu-component-ingredients-select-input-option-text-color-filled-active-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-active-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-active-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-focus-light: var(--emu-component-ingredients-select-input-option-text-color-filled-focus-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-focus-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-focus-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-default-light: var(--emu-component-ingredients-select-input-option-text-color-outline-default-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-default-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-default-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-hover-light: var(--emu-component-ingredients-select-input-option-text-color-outline-hover-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-hover-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-hover-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-active-light: var(--emu-component-ingredients-select-input-option-text-color-outline-active-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-active-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-active-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-focus-light: var(--emu-component-ingredients-select-input-option-text-color-outline-focus-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-focus-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-focus-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-filled-light: var(--emu-component-ingredients-select-input-option-color-background-default-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-default-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-outline-light: var(--emu-component-ingredients-select-input-option-color-background-default-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-default-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-filled-light: var(--emu-component-ingredients-select-input-option-color-background-hover-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-hover-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-outline-light: var(--emu-component-ingredients-select-input-option-color-background-hover-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-hover-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-filled-light: var(--emu-component-ingredients-select-input-option-color-background-active-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-active-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-outline-light: var(--emu-component-ingredients-select-input-option-color-background-active-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-active-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-filled-light: var(--emu-component-ingredients-select-input-option-color-background-focus-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-focus-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-outline-light: var(--emu-component-ingredients-select-input-option-color-background-focus-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-focus-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-default-filled-light: var(--emu-component-ingredients-select-input-color-border-default-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-default-filled-dark: var(--emu-component-ingredients-select-input-color-border-default-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-default-outline-light: var(--emu-component-ingredients-select-input-color-border-default-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-default-outline-dark: var(--emu-component-ingredients-select-input-color-border-default-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-filled-light: var(--emu-component-ingredients-select-input-color-border-hover-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-filled-dark: var(--emu-component-ingredients-select-input-color-border-hover-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-outline-light: var(--emu-component-ingredients-select-input-color-border-hover-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-outline-dark: var(--emu-component-ingredients-select-input-color-border-hover-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-active-filled-light: var(--emu-component-ingredients-select-input-color-border-active-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-active-filled-dark: var(--emu-component-ingredients-select-input-color-border-active-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-active-outline-light: var(--emu-component-ingredients-select-input-color-border-active-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-active-outline-dark: var(--emu-component-ingredients-select-input-color-border-active-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-filled-light: var(--emu-component-ingredients-select-input-color-border-focus-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-filled-dark: var(--emu-component-ingredients-select-input-color-border-focus-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-outline-light: var(--emu-component-ingredients-select-input-color-border-focus-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-outline-dark: var(--emu-component-ingredients-select-input-color-border-focus-outline-dark);
  --emu-component-lists-language-navigation-dropdown-border-radius-narrow: var(--emu-component-ingredients-select-input-border-radius-narrow);
  --emu-component-lists-language-navigation-dropdown-border-radius-wide: var(--emu-component-ingredients-select-input-border-radius-wide);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-default-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-default-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-default-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-default-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-hover-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-hover-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-hover-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-hover-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-active-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-active-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-active-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-active-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-default-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-default-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-default-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-default-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-hover-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-hover-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-hover-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-hover-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-active-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-active-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-active-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-active-dark);
  --emu-component-lists-language-navigation-dropdown-padding-narrow: var(--emu-component-ingredients-select-input-padding-narrow);
  --emu-component-lists-language-navigation-dropdown-padding-wide: var(--emu-component-ingredients-select-input-padding-wide);
  --emu-component-lists-language-navigation-dropdown-gap-narrow: var(--emu-component-ingredients-select-input-gap-narrow);
  --emu-component-lists-language-navigation-dropdown-gap-wide: var(--emu-component-ingredients-select-input-gap-wide);
  --emu-component-lists-language-navigation-dropdown-border-width-filled-narrow: var(--emu-component-ingredients-select-input-border-width-filled-narrow);
  --emu-component-lists-language-navigation-dropdown-border-width-filled-wide: var(--emu-component-ingredients-select-input-border-width-filled-wide);
  --emu-component-lists-language-navigation-dropdown-border-width-outline-narrow: var(--emu-component-ingredients-select-input-border-width-outline-narrow);
  --emu-component-lists-language-navigation-dropdown-border-width-outline-wide: var(--emu-component-ingredients-select-input-border-width-outline-wide);
  --emu-component-lists-language-navigation-pipes-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-lists-language-navigation-pipes-gap-wide: var(--emu-common-spacing-small);
  --emu-component-lists-language-navigation-pipes-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-lists-language-navigation-pipes-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-lists-language-navigation-pipes-color-border-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-language-navigation-pipes-color-border-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-language-navigation-pipes-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-language-navigation-pipes-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-language-navigation-pipes-item-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-language-navigation-pipes-item-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-language-navigation-pipes-item-text-color-active-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-language-navigation-pipes-item-text-color-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-feeds-instagram-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-feeds-instagram-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-feeds-instagram-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-post-gap-narrow: 40px;
  --emu-component-feeds-instagram-post-gap-wide: 40px;
  --emu-component-ingredients-icon-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-icon-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-icon-size-height-narrow: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-height-wide: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-width-narrow: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-width-wide: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-separator-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-separator-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-separator-size-height: var(--emu-semantic-sizing-xxs);
  --emu-component-ingredients-title-h1-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxxl);
  --emu-component-ingredients-title-h1-typography-wide: var(--emu-semantic-typography-wide-headings-xxxl);
  --emu-component-ingredients-title-h2-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-ingredients-title-h2-typography-wide: var(--emu-semantic-typography-wide-headings-xxl);
  --emu-component-ingredients-title-h3-typography-narrow: var(--emu-semantic-typography-narrow-headings-xl);
  --emu-component-ingredients-title-h3-typography-wide: var(--emu-semantic-typography-wide-headings-xl);
  --emu-component-ingredients-title-h4-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-ingredients-title-h4-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-ingredients-title-h5-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-ingredients-title-h5-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-ingredients-title-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-title-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-text-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-text-text-typography-narrow: var(--emu-component-ingredients-text-typography-narrow);
  --emu-component-ingredients-text-text-typography-wide: var(--emu-component-ingredients-text-typography-wide);
  --emu-component-ingredients-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-typography-narrow: var(--emu-semantic-typography-narrow-body-regular);
  --emu-component-ingredients-text-typography-wide: var(--emu-semantic-typography-wide-body-regular);
  --emu-component-ingredients-loader-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-loader-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-image-map-buttons-size-height: 27px;
  --emu-component-ingredients-image-map-buttons-size-width: 27px;
  --emu-component-ingredients-image-map-buttons-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-image-map-buttons-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-image-map-buttons-color-icon-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-ingredients-image-map-buttons-color-icon-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-ingredients-image-map-buttons-border-radius-top-left: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-top-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-left: 50%;
  --emu-component-ingredients-image-map-buttons-shadow: 0 0 2 5 #0009;
  --emu-component-ingredients-google-map-color-background-light: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-color-background-dark: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-margin-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-image-caption-padding-narrow: calc(var(--emu-semantic-spacing-vertical-narrow) * .5);
  --emu-component-ingredients-label-margin-narrow: var(--emu-semantic-spacing-half-line-height-narrow) 0px;
  --emu-component-ingredients-label-margin-wide: var(--emu-semantic-spacing-half-line-height-wide) 0px;
  --emu-component-ingredients-label-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-label-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-label-text-color-default-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-label-text-color-default-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-label-text-color-checked-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-label-text-color-checked-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-label-text-color-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-label-text-color-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-label-text-color-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-label-text-color-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-legend-margin-narrow: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-legend-margin-wide: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-legend-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-legend-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-field-message-margin-narrow: var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-field-message-margin-wide: var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-field-message-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-field-message-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-error-message-margin-narrow: var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-error-message-margin-wide: var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-error-message-text-color-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-error-message-text-color-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-option-text-color-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-option-text-color-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-option-text-color-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-option-text-color-outline-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-option-color-background-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-default-outline-light: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-default-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-hover-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-hover-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-active-filled-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-ingredients-select-input-option-color-background-active-filled-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-ingredients-select-input-option-color-background-active-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-active-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-focus-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-focus-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-disabled-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-disabled-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-disabled-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-disabled-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-error-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-error-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-option-color-background-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-color-border-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-default-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-default-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-hover-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-hover-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-active-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-active-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-active-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-active-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-focus-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-focus-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-disabled-filled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-color-border-disabled-filled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-color-border-disabled-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-disabled-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-error-filled-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-color-border-error-filled-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-color-border-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-color-border-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-select-input-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-select-input-icon-color-fill-filled-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-hover-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-hover-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-hover-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-hover-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-select-input-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-select-input-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-select-input-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-select-input-border-width-filled-narrow: var(--emu-common-border-width-none);
  --emu-component-ingredients-select-input-border-width-filled-wide: var(--emu-common-border-width-none);
  --emu-component-ingredients-select-input-border-width-outline-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-select-input-border-width-outline-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-select-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-select-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-select-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-select-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-select-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-select-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-select-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-select-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-select-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-select-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-select-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-select-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-select-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-select-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-select-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-select-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-select-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-select-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-text-input-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-input-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-input-text-color-empty-light: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-text-input-text-color-empty-dark: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-text-input-text-color-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-input-text-color-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-input-text-color-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-text-input-text-color-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-text-input-text-color-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-text-input-text-color-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-text-input-text-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-text-input-text-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-text-input-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-text-input-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-text-input-color-border-empty-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-text-input-color-border-empty-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-text-input-color-border-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-text-input-color-border-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-text-input-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-text-input-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-text-input-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-text-input-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-text-input-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-empty-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-empty-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-focus-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-disabled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-disabled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-error-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-error-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-text-input-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-text-input-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-text-input-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-text-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-text-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-text-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-text-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-text-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-text-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-text-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-text-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-text-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-text-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-text-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-text-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-text-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-text-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-text-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-text-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-text-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-text-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-radio-input-control-size-all-narrow: var(--emu-semantic-sizing-one-line-height-narrow);
  --emu-component-ingredients-radio-input-control-size-all-wide: var(--emu-semantic-sizing-one-line-height-wide);
  --emu-component-ingredients-radio-input-control-border-radius-narrow: var(--emu-semantic-border-radius-medium);
  --emu-component-ingredients-radio-input-control-border-radius-wide: var(--emu-semantic-border-radius-medium);
  --emu-component-ingredients-radio-input-control-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-radio-input-control-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-radio-input-control-color-background-focus-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-focus-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-checked-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-checked-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-disabled-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-disabled-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-error-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-error-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-active-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-active-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-hover-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-hover-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-border-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-ingredients-radio-input-control-color-border-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-ingredients-radio-input-control-color-border-focus-light: var(--emu-semantic-colors-actions-primary-focus-light);
  --emu-component-ingredients-radio-input-control-color-border-focus-dark: var(--emu-semantic-colors-actions-primary-focus-dark);
  --emu-component-ingredients-radio-input-control-color-border-checked-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-ingredients-radio-input-control-color-border-checked-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-ingredients-radio-input-control-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-radio-input-control-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-radio-input-control-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-radio-input-control-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-radio-input-control-color-border-active-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-ingredients-radio-input-control-color-border-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-ingredients-radio-input-control-color-border-hover-light: var(--emu-semantic-colors-actions-primary-focus-light);
  --emu-component-ingredients-radio-input-control-color-border-hover-dark: var(--emu-semantic-colors-actions-primary-focus-dark);
  --emu-component-ingredients-radio-input-control-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-ingredients-radio-input-control-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-component-ingredients-radio-input-icon-color-fill-checked-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-ingredients-radio-input-icon-color-fill-checked-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-ingredients-radio-input-icon-color-fill-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-radio-input-icon-color-fill-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-radio-input-icon-color-fill-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-radio-input-icon-color-fill-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-radio-input-icon-size-all-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow)  - 4px);
  --emu-component-ingredients-radio-input-icon-size-all-wide: calc(var(--emu-semantic-sizing-one-line-height-wide)  - 4px);
  --emu-component-ingredients-radio-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-radio-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-radio-input-label-gap-narrow: var(--emu-component-ingredients-label-gap-narrow);
  --emu-component-ingredients-radio-input-label-gap-wide: var(--emu-component-ingredients-label-gap-wide);
  --emu-component-ingredients-radio-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-radio-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-radio-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-radio-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-radio-input-label-text-color-checked-light: var(--emu-component-ingredients-label-text-color-checked-light);
  --emu-component-ingredients-radio-input-label-text-color-checked-dark: var(--emu-component-ingredients-label-text-color-checked-dark);
  --emu-component-ingredients-radio-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-radio-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-radio-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-radio-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-radio-input-legend-margin-narrow: var(--emu-component-ingredients-legend-margin-narrow);
  --emu-component-ingredients-radio-input-legend-margin-wide: var(--emu-component-ingredients-legend-margin-wide);
  --emu-component-ingredients-radio-input-legend-text-color-light: var(--emu-component-ingredients-legend-text-color-light);
  --emu-component-ingredients-radio-input-legend-text-color-dark: var(--emu-component-ingredients-legend-text-color-dark);
  --emu-component-ingredients-radio-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-radio-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-radio-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-radio-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-radio-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-radio-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-radio-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-radio-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-checkbox-input-control-size-all-narrow: var(--emu-semantic-sizing-one-line-height-narrow);
  --emu-component-ingredients-checkbox-input-control-size-all-wide: var(--emu-semantic-sizing-one-line-height-wide);
  --emu-component-ingredients-checkbox-input-control-border-radius-narrow: var(--emu-common-border-radius-xxs);
  --emu-component-ingredients-checkbox-input-control-border-radius-wide: var(--emu-common-border-radius-xxs);
  --emu-component-ingredients-checkbox-input-control-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-checkbox-input-control-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-focus-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-focus-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-checked-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-checked-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-disabled-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-disabled-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-error-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-error-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-hover-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-hover-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-active-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-active-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-checkbox-input-control-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-focus-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-focus-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-checked-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-checked-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-checkbox-input-control-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-checkbox-input-control-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-active-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-active-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-hover-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-hover-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-checkbox-input-control-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-checkbox-input-icon-color-fill-checked-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-checkbox-input-icon-color-fill-checked-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-checkbox-input-icon-color-fill-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-checkbox-input-icon-color-fill-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-checkbox-input-icon-color-fill-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-checkbox-input-icon-color-fill-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-checkbox-input-icon-size-all-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow)  - 4px);
  --emu-component-ingredients-checkbox-input-icon-size-all-wide: calc(var(--emu-semantic-sizing-one-line-height-wide)  - 4px);
  --emu-component-ingredients-checkbox-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-checkbox-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-checkbox-input-label-gap-narrow: var(--emu-component-ingredients-label-gap-narrow);
  --emu-component-ingredients-checkbox-input-label-gap-wide: var(--emu-component-ingredients-label-gap-wide);
  --emu-component-ingredients-checkbox-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-checkbox-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-checkbox-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-checked-light: var(--emu-component-ingredients-label-text-color-checked-light);
  --emu-component-ingredients-checkbox-input-label-text-color-checked-dark: var(--emu-component-ingredients-label-text-color-checked-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-checkbox-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-checkbox-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-checkbox-input-legend-margin-narrow: var(--emu-component-ingredients-legend-margin-narrow);
  --emu-component-ingredients-checkbox-input-legend-margin-wide: var(--emu-component-ingredients-legend-margin-wide);
  --emu-component-ingredients-checkbox-input-legend-text-color-light: var(--emu-component-ingredients-legend-text-color-light);
  --emu-component-ingredients-checkbox-input-legend-text-color-dark: var(--emu-component-ingredients-legend-text-color-dark);
  --emu-component-ingredients-checkbox-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-checkbox-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-checkbox-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-checkbox-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-checkbox-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-checkbox-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-checkbox-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-checkbox-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-textarea-input-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-textarea-input-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-textarea-input-text-color-empty-light: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-textarea-input-text-color-empty-dark: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-textarea-input-text-color-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-textarea-input-text-color-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-textarea-input-text-color-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-textarea-input-text-color-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-textarea-input-text-color-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-textarea-input-text-color-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-textarea-input-text-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-textarea-input-text-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-textarea-input-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-textarea-input-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-textarea-input-color-border-empty-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-textarea-input-color-border-empty-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-textarea-input-color-border-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-textarea-input-color-border-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-textarea-input-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-textarea-input-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-textarea-input-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-textarea-input-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-textarea-input-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-color-background-empty-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-empty-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-color-background-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-focus-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-color-background-disabled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-disabled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-color-background-error-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-error-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-textarea-input-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-textarea-input-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-textarea-input-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-textarea-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-textarea-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-textarea-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-textarea-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-textarea-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-textarea-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-textarea-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-textarea-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-textarea-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-textarea-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-textarea-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-textarea-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-textarea-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-textarea-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-textarea-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-textarea-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-textarea-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-textarea-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-actions-button-text-typography-narrow: var(--emu-semantic-font-weight-regular) 14px / 16px var(--emu-semantic-font-families-body);
  --emu-component-actions-button-text-typography-wide: var(--emu-semantic-font-weight-regular) 14px / 16px var(--emu-semantic-font-families-body);
  --emu-component-actions-button-text-color-link-light: var(--emu-component-actions-button-text-color-link-default-light);
  --emu-component-actions-button-text-color-link-dark: var(--emu-component-actions-button-text-color-link-default-dark);
  --emu-component-actions-button-text-color-link-hover-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-actions-button-text-color-link-hover-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-button-text-color-link-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-button-text-color-link-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-button-text-color-link-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-actions-button-text-color-link-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-button-text-color-link-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-actions-button-text-color-link-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-button-text-color-primary-filled-light: var(--emu-component-actions-button-text-color-primary-filled-default-light);
  --emu-component-actions-button-text-color-primary-filled-dark: var(--emu-component-actions-button-text-color-primary-filled-default-dark);
  --emu-component-actions-button-text-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-default-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-default-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-disabled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-light: var(--emu-component-actions-button-text-color-primary-outline-default-light);
  --emu-component-actions-button-text-color-primary-outline-dark: var(--emu-component-actions-button-text-color-primary-outline-default-dark);
  --emu-component-actions-button-text-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-default-light: var(--emu-semantic-colors-actions-primary-light);
  --emu-component-actions-button-text-color-primary-outline-default-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-text-color-primary-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-text-color-secondary-filled-light: var(--emu-component-actions-button-text-color-secondary-filled-default-light);
  --emu-component-actions-button-text-color-secondary-filled-dark: var(--emu-component-actions-button-text-color-secondary-filled-default-dark);
  --emu-component-actions-button-text-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-default-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-filled-default-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-light: var(--emu-component-actions-button-text-color-secondary-outline-default-light);
  --emu-component-actions-button-text-color-secondary-outline-dark: var(--emu-component-actions-button-text-color-secondary-outline-default-dark);
  --emu-component-actions-button-text-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-default-light: var(--emu-semantic-colors-actions-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-text-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-border-width-primary-filled: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-primary-outline: var(--emu-common-border-width-small);
  --emu-component-actions-button-border-width-secondary-filled: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-outline: var(--emu-common-border-width-small);
  --emu-component-actions-button-outline-width-primary-filled: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-outline-width-primary-outline: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-outline-width-secondary-filled: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-outline-width-secondary-outline: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-color-border-primary-filled-light: var(--emu-component-actions-button-color-border-primary-filled-default-light);
  --emu-component-actions-button-color-border-primary-filled-dark: var(--emu-component-actions-button-color-border-primary-filled-default-dark);
  --emu-component-actions-button-color-border-primary-filled-focus-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-filled-focus-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-hover-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-filled-hover-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-active-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-filled-active-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-filled-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-color-border-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-color-border-primary-outline-light: var(--emu-component-actions-button-color-border-primary-outline-default-light);
  --emu-component-actions-button-color-border-primary-outline-dark: var(--emu-component-actions-button-color-border-primary-filled-default-dark);
  --emu-component-actions-button-color-border-primary-outline-focus-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-outline-focus-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-hover-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-outline-hover-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-active-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-outline-active-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-outline-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-color-border-primary-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-color-border-secondary-filled-light: var(--emu-component-actions-button-color-border-secondary-filled-default-light);
  --emu-component-actions-button-color-border-secondary-filled-dark: var(--emu-component-actions-button-color-border-secondary-filled-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-focus-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-hover-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-active-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-filled-active-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-default-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-filled-default-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-color-border-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-color-border-secondary-outline-light: var(--emu-component-actions-button-color-border-secondary-outline-default-light);
  --emu-component-actions-button-color-border-secondary-outline-dark: var(--emu-component-actions-button-color-border-secondary-outline-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-focus-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-hover-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-active-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-active-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-default-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-color-border-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-color-background-primary-filled-light: var(--emu-component-actions-button-color-background-primary-filled-default-light);
  --emu-component-actions-button-color-background-primary-filled-dark: var(--emu-component-actions-button-color-background-primary-filled-default-dark);
  --emu-component-actions-button-color-background-primary-filled-focus-light: var(--emu-semantic-colors-actions-primary-focus-light);
  --emu-component-actions-button-color-background-primary-filled-focus-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-button-color-background-primary-filled-hover-light: var(--emu-semantic-colors-actions-primary-hover-light);
  --emu-component-actions-button-color-background-primary-filled-hover-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-button-color-background-primary-filled-active-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-actions-button-color-background-primary-filled-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-button-color-background-primary-filled-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-background-primary-filled-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-color-background-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-color-background-primary-outline-light: var(--emu-component-actions-button-color-background-primary-outline-default-light);
  --emu-component-actions-button-color-background-primary-outline-dark: var(--emu-component-actions-button-color-background-primary-outline-default-dark);
  --emu-component-actions-button-color-background-primary-outline-focus-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-background-primary-outline-focus-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-outline-hover-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-background-primary-outline-hover-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-outline-active-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-background-primary-outline-active-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-outline-default-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-default-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-disabled-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-disabled-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-filled-light: var(--emu-component-actions-button-color-background-secondary-filled-default-light);
  --emu-component-actions-button-color-background-secondary-filled-dark: var(--emu-component-actions-button-color-background-secondary-filled-disabled-dark);
  --emu-component-actions-button-color-background-secondary-filled-focus-light: var(--emu-semantic-colors-actions-secondary-focus-light);
  --emu-component-actions-button-color-background-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-secondary-focus-dark);
  --emu-component-actions-button-color-background-secondary-filled-hover-light: var(--emu-semantic-colors-actions-secondary-hover-light);
  --emu-component-actions-button-color-background-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-secondary-hover-dark);
  --emu-component-actions-button-color-background-secondary-filled-active-light: var(--emu-semantic-colors-actions-secondary-active-light);
  --emu-component-actions-button-color-background-secondary-filled-active-dark: var(--emu-semantic-colors-actions-secondary-active-dark);
  --emu-component-actions-button-color-background-secondary-filled-default-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-background-secondary-filled-default-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-color-background-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-color-background-secondary-outline-light: var(--emu-component-actions-button-color-background-secondary-outline-default-light);
  --emu-component-actions-button-color-background-secondary-outline-dark: var(--emu-component-actions-button-color-background-secondary-outline-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-focus-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-background-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-hover-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-background-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-active-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-background-secondary-outline-active-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-default-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-default-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-disabled-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-disabled-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-outline-color-primary-filled-light: var(--emu-component-actions-button-color-outline-color-primary-filled-default-light);
  --emu-component-actions-button-color-outline-color-primary-filled-dark: var(--emu-component-actions-button-color-outline-color-primary-filled-default-dark);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-color-outline-color-primary-filled-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-filled-default-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-color-outline-color-primary-filled-default-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-color-outline-color-primary-filled-disabled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-color-outline-color-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-color-outline-color-primary-outline-light: var(--emu-component-actions-button-color-outline-color-primary-outline-default-light);
  --emu-component-actions-button-color-outline-color-primary-outline-dark: var(--emu-component-actions-button-color-outline-color-primary-outline-default-dark);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-outline-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-outline-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-light: var(--emu-component-actions-button-color-outline-color-secondary-filled-default-light);
  --emu-component-actions-button-color-outline-color-secondary-filled-dark: var(--emu-component-actions-button-color-outline-color-secondary-filled-default-dark);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-light: var(--emu-component-actions-button-color-outline-color-secondary-outline-default-light);
  --emu-component-actions-button-color-outline-color-secondary-outline-dark: var(--emu-component-actions-button-color-outline-color-secondary-outline-default-dark);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-group-horizontal-gap: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-left: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-right: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-top: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-horizontal-padding-bottom: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-vertical-gap: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-left: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-right: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-top: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-vertical-padding-bottom: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-actions-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-actions-button-icon-transform-open: rotate(0);
  --emu-component-actions-button-icon-transform-closed: rotate(180deg);
  --emu-component-actions-button-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-actions-button-icon-color-primary-filled-light: var(--emu-component-actions-button-icon-color-primary-filled-default-light);
  --emu-component-actions-button-icon-color-primary-filled-dark: var(--emu-component-actions-button-icon-color-primary-filled-default-dark);
  --emu-component-actions-button-icon-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-default-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-default-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-disabled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-light: var(--emu-component-actions-button-icon-color-primary-outline-default-light);
  --emu-component-actions-button-icon-color-primary-outline-dark: var(--emu-component-actions-button-icon-color-primary-outline-default-dark);
  --emu-component-actions-button-icon-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-default-light: var(--emu-semantic-colors-actions-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-default-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-icon-color-primary-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-icon-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-default-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-default-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-light: var(--emu-component-actions-button-icon-color-secondary-filled-default-light);
  --emu-component-actions-button-icon-color-secondary-filled-dark: var(--emu-component-actions-button-icon-color-secondary-filled-default-dark);
  --emu-component-actions-button-icon-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-default-light: var(--emu-semantic-colors-actions-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-icon-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-icon-color-secondary-outline-light: var(--emu-component-actions-button-icon-color-secondary-outline-default-light);
  --emu-component-actions-button-icon-color-secondary-outline-dark: var(--emu-component-actions-button-icon-color-secondary-outline-default-dark);
  --emu-component-actions-button-icon-size-height-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * .75);
  --emu-component-actions-button-icon-size-height-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * .75);
  --emu-component-actions-button-icon-size-width-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * .75);
  --emu-component-actions-button-icon-size-width-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * .75);
  --emu-component-actions-button-icon-border-radius: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-padding-top-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-padding-top-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-component-actions-button-padding-right-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-padding-right-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-actions-button-padding-bottom-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-padding-bottom-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-component-actions-button-padding-left-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-padding-left-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-actions-button-padding-narrow: 15px 10px 15px 10px;
  --emu-component-actions-button-padding-wide: 15px 30px 15px 30px;
  --emu-component-actions-button-border-radius-primary-filled: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-outline: var(--emu-semantic-border-radius-large) var(--emu-semantic-border-radius-large) var(--emu-semantic-border-radius-large) var(--emu-semantic-border-radius-large);
  --emu-component-actions-button-border-radius-secondary-filled: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline: var(--emu-semantic-border-radius-large) var(--emu-semantic-border-radius-large) var(--emu-semantic-border-radius-large) var(--emu-semantic-border-radius-large);
  --emu-component-actions-back-to-top-z-index: var(--emu-common-other-z-index-modal);
  --emu-component-actions-back-to-top-icon-size-height: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-icon-size-width: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-position-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-left-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-left-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-right-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-right-wide: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-top-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-top-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-margin-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-left-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-left-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-right-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-right-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-add-to-calendar-button-text-color-filled-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-button-color-background-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-default-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-button-color-background-default-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-button-color-background-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-hover-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-hover-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-active-filled-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-actions-add-to-calendar-button-color-background-active-filled-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-add-to-calendar-button-color-background-active-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-active-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-focus-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-focus-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-error-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-error-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-button-color-background-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-button-border-width-filled-narrow: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-button-border-width-filled-wide: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-button-border-width-outline-narrow: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-button-border-width-outline-wide: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-button-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-add-to-calendar-button-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-filled-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-filled-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-width: 165px;
  --emu-component-actions-add-to-calendar-dropdown-border-width-filled-narrow: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-dropdown-border-width-filled-wide: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-dropdown-border-width-outline-narrow: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-dropdown-border-width-outline-wide: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-dropdown-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-actions-add-to-calendar-dropdown-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-actions-add-to-calendar-dropdown-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-add-to-calendar-dropdown-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-actions-add-to-calendar-dropdown-icon-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-actions-add-to-calendar-dropdown-icon-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-add-to-calendar-dropdown-icon-size-height-narrow: var(--emu-semantic-sizing-small);
  --emu-component-actions-add-to-calendar-dropdown-icon-size-height-wide: 24px;
  --emu-component-actions-add-to-calendar-dropdown-icon-size-width-narrow: var(--emu-semantic-sizing-small);
  --emu-component-actions-add-to-calendar-dropdown-icon-size-width-wide: 24px;
  --emu-component-actions-pagination-icon-narrow: var(--emu-semantic-font-sizes-narrow-small);
  --emu-component-actions-pagination-icon-wide: var(--emu-semantic-font-sizes-wide-small);
  --emu-component-actions-pagination-text-color-hover-light: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-hover-dark: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-default-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-actions-pagination-text-color-default-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-actions-pagination-text-color-active-light: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-active-dark: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-focus-light: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-focus-dark: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-typography-narrow: var(--emu-component-ingredients-text-typography-narrow);
  --emu-component-actions-pagination-text-typography-wide: var(--emu-component-ingredients-text-typography-wide);
  --emu-component-actions-pagination-gap-narrow: var(--emu-semantic-spacing-horizontal-onerem-narrow);
  --emu-component-actions-pagination-gap-wide: var(--emu-semantic-spacing-horizontal-onerem-wide);
  --emu-component-actions-pagination-margin-narrow: var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-pagination-margin-wide: var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-scroll-reveal-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-containers-scroll-reveal-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-containers-scroll-reveal-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-padding-top-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-top-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-static-section-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-containers-scroll-reveal-cards-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-cards-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-containers-scroll-reveal-cards-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-containers-scroll-reveal-cards-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-comparison-slider-divider-color-border-light: var(--emu-component-containers-comparison-slider-divider-color-light);
  --emu-component-containers-comparison-slider-divider-color-border-dark: var(--emu-component-containers-comparison-slider-divider-color-dark);
  --emu-component-containers-comparison-slider-divider-color-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-comparison-slider-divider-color-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-comparison-slider-divider-border-width-narrow: var(--emu-component-containers-comparison-slider-divider-size-width-narrow);
  --emu-component-containers-comparison-slider-divider-border-width-wide: var(--emu-component-containers-comparison-slider-divider-size-width-wide);
  --emu-component-containers-comparison-slider-divider-size-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-divider-size-width-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-handle-size-width-narrow: var(--emu-semantic-sizing-medium);
  --emu-component-containers-comparison-slider-handle-size-width-wide: var(--emu-semantic-sizing-large);
  --emu-component-containers-comparison-slider-handle-color-background-light: var(--emu-component-containers-comparison-slider-custom-handle-color-background-light);
  --emu-component-containers-comparison-slider-handle-color-background-dark: var(--emu-component-containers-comparison-slider-custom-handle-color-background-dark);
  --emu-component-containers-comparison-slider-handle-padding-narrow: var(--emu-component-containers-comparison-slider-custom-handle-padding-narrow);
  --emu-component-containers-comparison-slider-handle-padding-wide: var(--emu-component-containers-comparison-slider-custom-handle-padding-wide);
  --emu-component-containers-comparison-slider-handle-icon-size-width-narrow: var(--emu-component-containers-comparison-slider-handle-size-width-narrow);
  --emu-component-containers-comparison-slider-handle-icon-size-width-wide: var(--emu-component-containers-comparison-slider-handle-size-width-wide);
  --emu-component-containers-comparison-slider-handle-icon-color-fill-light: var(--emu-component-containers-comparison-slider-divider-color-border-light);
  --emu-component-containers-comparison-slider-handle-icon-color-fill-dark: var(--emu-component-containers-comparison-slider-divider-color-border-dark);
  --emu-component-containers-comparison-slider-handle-icon-thickness-narrow: var(--emu-component-containers-comparison-slider-custom-handle-arrow-thickness-narrow);
  --emu-component-containers-comparison-slider-handle-icon-thickness-wide: var(--emu-component-containers-comparison-slider-custom-handle-arrow-thickness-wide);
  --emu-component-containers-comparison-slider-handle-text-typography-narrow: var(--emu-component-containers-comparison-slider-custom-handle-text-typography-narrow);
  --emu-component-containers-comparison-slider-handle-text-typography-wide: var(--emu-component-containers-comparison-slider-custom-handle-text-typography-narrow);
  --emu-component-containers-comparison-slider-handle-text-color-light: var(--emu-component-containers-comparison-slider-custom-handle-text-color-light);
  --emu-component-containers-comparison-slider-handle-text-color-dark: var(--emu-component-containers-comparison-slider-custom-handle-text-color-dark);
  --emu-component-containers-comparison-slider-custom-handle-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-narrow: var(--emu-component-containers-comparison-slider-custom-handle-padding-top-narrow) var(--emu-component-containers-comparison-slider-custom-handle-padding-right-narrow) var(--emu-component-containers-comparison-slider-custom-handle-padding-bottom-narrow) var(--emu-component-containers-comparison-slider-custom-handle-padding-left-narrow);
  --emu-component-containers-comparison-slider-custom-handle-padding-wide: var(--emu-component-containers-comparison-slider-custom-handle-padding-top-wide) var(--emu-component-containers-comparison-slider-custom-handle-padding-right-wide) var(--emu-component-containers-comparison-slider-custom-handle-padding-bottom-wide) var(--emu-component-containers-comparison-slider-custom-handle-padding-left-wide);
  --emu-component-containers-comparison-slider-custom-handle-arrow-size-width-narrow: var(--emu-common-sizing-small);
  --emu-component-containers-comparison-slider-custom-handle-arrow-size-width-wide: var(--emu-common-sizing-small);
  --emu-component-containers-comparison-slider-custom-handle-arrow-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-comparison-slider-custom-handle-arrow-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-comparison-slider-custom-handle-arrow-thickness-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-custom-handle-arrow-thickness-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-custom-handle-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-comparison-slider-custom-handle-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-comparison-slider-custom-handle-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-comparison-slider-custom-handle-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-comparison-slider-custom-handle-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-line-height-narrow: var(--emu-semantic-line-heights-narrow-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-line-height-wide: var(--emu-semantic-line-heights-wide-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-typography-narrow: var(--emu-semantic-font-weight-regular) var(--emu-component-containers-comparison-slider-custom-handle-text-font-size-narrow) / var(--emu-component-containers-comparison-slider-custom-handle-text-line-height-narrow) var(--emu-semantic-font-families-body);
  --emu-component-containers-comparison-slider-custom-handle-text-typography-wide: var(--emu-semantic-font-weight-regular) var(--emu-component-containers-comparison-slider-custom-handle-text-font-size-wide) / var(--emu-component-containers-comparison-slider-custom-handle-text-line-height-wide) var(--emu-semantic-font-families-body);
  --emu-component-containers-tabs-gap-horizontal-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-tabs-gap-horizontal-wide: var(--emu-common-spacing-none);
  --emu-component-containers-tabs-gap-vertical-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-tabs-gap-vertical-wide: var(--emu-common-spacing-none);
  --emu-component-containers-tabs-navigation-item-padding-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-navigation-item-padding-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-navigation-item-padding-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-navigation-item-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-navigation-item-padding-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-wide: var(--emu-common-spacing-none);
  --emu-component-containers-tabs-navigation-item-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-border-width-top-default: var(--emu-semantic-border-width-medium);
  --emu-component-containers-tabs-navigation-item-border-width-top-active: var(--emu-semantic-border-width-medium);
  --emu-component-containers-tabs-navigation-item-border-width-right-default: var(--emu-semantic-border-width-medium);
  --emu-component-containers-tabs-navigation-item-border-width-right-active: var(--emu-semantic-border-width-medium);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-left-default: var(--emu-semantic-border-width-medium);
  --emu-component-containers-tabs-navigation-item-border-width-left-active: var(--emu-semantic-border-width-medium);
  --emu-component-containers-tabs-navigation-item-color-border-default-light: #00000080;
  --emu-component-containers-tabs-navigation-item-color-border-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-border-active-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-containers-tabs-navigation-item-color-border-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-default-light: var(--emu-common-colors-white);
  --emu-component-containers-tabs-navigation-item-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-active-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-containers-tabs-navigation-item-color-background-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-tabs-navigation-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-navigation-item-text-color-active-light: var(--emu-common-colors-white);
  --emu-component-containers-tabs-navigation-item-text-color-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-content-padding-top-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-tabs-content-padding-top-wide: var(--emu-common-spacing-none);
  --emu-component-containers-tabs-content-padding-right-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-tabs-content-padding-right-wide: var(--emu-common-spacing-none);
  --emu-component-containers-tabs-content-padding-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-tabs-content-padding-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-containers-tabs-content-padding-left-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-tabs-content-padding-left-wide: var(--emu-common-spacing-none);
  --emu-component-containers-tabs-content-gap-horizontal-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-tabs-content-gap-horizontal-wide: var(--emu-common-spacing-none);
  --emu-component-containers-tabs-content-gap-vertical-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-tabs-content-gap-vertical-wide: var(--emu-common-spacing-none);
  --emu-component-containers-tabs-content-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-containers-tabs-content-color-background-dark: var(--emu-component-containers-container-color-background-dark);
  --emu-component-containers-isi-header-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-header-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-header-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-component-containers-isi-body-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-body-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-body-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-component-containers-isi-open-content-size: 30vh;
  --emu-component-containers-isi-z-index: var(--emu-common-other-z-index-isi);
  --emu-component-containers-container-max-width: 100%px;
  --emu-component-containers-container-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-narrow: var(--emu-component-containers-container-padding-top-narrow) var(--emu-component-containers-container-padding-right-narrow) var(--emu-component-containers-container-padding-bottom-narrow) var(--emu-component-containers-container-padding-left-narrow);
  --emu-component-containers-container-padding-wide: var(--emu-component-containers-container-padding-top-wide) var(--emu-component-containers-container-padding-right-wide) var(--emu-component-containers-container-padding-bottom-wide) var(--emu-component-containers-container-padding-left-wide);
  --emu-component-containers-container-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-container-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-padding-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-color-border-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-color-border-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-indicators-margin-top-narrow: var(--emu-component-containers-carousel-indicators-gap-horizontal-narrow);
  --emu-component-containers-carousel-indicators-margin-top-wide: var(--emu-component-containers-carousel-indicators-gap-horizontal-wide);
  --emu-component-containers-carousel-indicators-margin-bottom-narrow: var(--emu-component-containers-carousel-indicators-gap-horizontal-narrow);
  --emu-component-containers-carousel-indicators-margin-bottom-wide: var(--emu-component-containers-carousel-indicators-gap-horizontal-wide);
  --emu-component-containers-carousel-indicators-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-indicators-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-indicators-dots-color-background-default-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-indicators-dots-color-background-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-containers-carousel-indicators-dots-color-background-active-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-dots-color-background-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-containers-carousel-indicators-dots-color-border-default-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-dots-color-border-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-containers-carousel-indicators-dots-color-border-active-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-dots-color-border-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-containers-carousel-indicators-dots-border-width-all: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-indicators-dots-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-indicators-dots-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-indicators-dots-size-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-dots-size-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-dots-gap-narrow: 5px;
  --emu-component-containers-carousel-indicators-dots-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-indicators-dots-padding-narrow: var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-indicators-dots-padding-wide: var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-indicators-image-color-border-default-light: var(--emu-component-containers-carousel-indicators-thumbnail-color-border-default-light);
  --emu-component-containers-carousel-indicators-image-color-border-default-dark: var(--emu-component-containers-carousel-indicators-thumbnail-color-border-default-dark);
  --emu-component-containers-carousel-indicators-image-color-border-active-light: var(--emu-component-containers-carousel-indicators-thumbnail-color-border-active-light);
  --emu-component-containers-carousel-indicators-image-color-border-active-dark: var(--emu-component-containers-carousel-indicators-thumbnail-color-border-active-dark);
  --emu-component-containers-carousel-indicators-image-border-width-all: var(--emu-component-containers-carousel-indicators-thumbnail-border-width-narrow);
  --emu-component-containers-carousel-indicators-image-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-image-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-thumbnail-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-indicators-thumbnail-padding-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-indicators-thumbnail-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-indicators-thumbnail-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-indicators-thumbnail-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-carousel-indicators-thumbnail-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-indicators-thumbnail-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-indicators-thumbnail-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-indicators-thumbnail-color-border-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-indicators-thumbnail-color-border-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-indicators-thumbnail-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-indicators-thumbnail-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-indicators-text-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-light: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-dark: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-carousel-indicators-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-carousel-controls-padding-narrow: var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-controls-padding-wide: var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-controls-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-controls-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-color-border-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-controls-color-border-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-border-width-narrow: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-controls-border-width-wide: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-controls-border-width-all: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-controls-z-index: var(--emu-common-other-z-index-layer);
  --emu-component-containers-carousel-controls-icon-color-fill-light: var(--emu-component-ingredients-icon-color-fill-light);
  --emu-component-containers-carousel-controls-icon-color-fill-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-containers-carousel-controls-icon-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-controls-icon-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-frame-container-padding-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-frame-container-padding-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-frame-container-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-frame-container-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-frame-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-frame-padding-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-frame-color-background-default-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-frame-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-frame-color-background-active-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-frame-color-background-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-frame-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-frame-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-frame-color-border-active-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-frame-color-border-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-frame-border-width-default-narrow: var(--emu-semantic-border-width-none);
  --emu-component-containers-carousel-frame-border-width-default-wide: var(--emu-semantic-border-width-none);
  --emu-component-containers-carousel-frame-border-width-active-narrow: var(--emu-semantic-border-width-none);
  --emu-component-containers-carousel-frame-border-width-active-wide: var(--emu-semantic-border-width-none);
  --emu-component-containers-embed-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-containers-embed-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-embed-player-vimeo-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-vimeo-global-text-typography-line-height: 1.7;
  --emu-component-containers-embed-player-vimeo-color-background-light: #000;
  --emu-component-containers-embed-player-vimeo-color-background-dark: #000;
  --emu-component-containers-embed-player-vimeo-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-vimeo-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-vimeo-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-menu-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-vimeo-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-vimeo-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-vimeo-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-vimeo-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-vimeo-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-vimeo-controls-global-padding: 7px;
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-tooltip-padding: 5px;
  --emu-component-containers-embed-player-vimeo-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-light: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-dark: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-one: 0 1 1 0 #d71a1226;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-two: 0 0 0 1 #d71a1233;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-vimeo-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-light: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-dark: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-light: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-dark: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-light: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-dark: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-captions-color-background-light: #000c;
  --emu-component-containers-embed-player-vimeo-captions-color-background-dark: #000c;
  --emu-component-containers-embed-player-vimeo-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-youtube-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-youtube-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-youtube-global-text-typography-line-height: 1.7;
  --emu-component-containers-embed-player-youtube-color-background-light: #000;
  --emu-component-containers-embed-player-youtube-color-background-dark: #000;
  --emu-component-containers-embed-player-youtube-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-youtube-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-youtube-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-youtube-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-youtube-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-youtube-menu-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-youtube-menu-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-youtube-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-youtube-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-youtube-menu-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-youtube-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-youtube-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-youtube-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-youtube-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-youtube-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-youtube-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-youtube-controls-global-padding: 7px;
  --emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-youtube-tooltip-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-youtube-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-youtube-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-youtube-tooltip-padding: 5px;
  --emu-component-containers-embed-player-youtube-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-youtube-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-loading-light: #23282f99;
  --emu-component-containers-embed-player-youtube-scrubber-color-background-loading-dark: #23282f99;
  --emu-component-containers-embed-player-youtube-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-one: 0 1 1 0 #d71a1226;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-two: 0 0 0 1 #d71a1233;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-youtube-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-light: #c1c8d199;
  --emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-dark: #c1c8d199;
  --emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-light: #d71a121a;
  --emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-dark: #d71a121a;
  --emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-light: #ffffff80;
  --emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-dark: #ffffff80;
  --emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-youtube-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-youtube-captions-color-background-light: #000c;
  --emu-component-containers-embed-player-youtube-captions-color-background-dark: #000c;
  --emu-component-containers-embed-player-youtube-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-gallery-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-separator-content-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-sizing-width-narrow: 100%;
  --emu-component-containers-gallery-item-sizing-width-wide: calc(33.33% - 8px);
  --emu-component-containers-gallery-item-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-item-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-stage-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-gap-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-preview-container-gap-wide: var(--emu-common-sizing-xs);
  --emu-component-layers-modal-z-index: var(--emu-common-other-z-index-modal);
  --emu-component-layers-modal-scrim-color-background: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-layers-modal-body-sizing-width: 80%;
  --emu-component-layers-modal-body-sizing-max-width: 800px;
  --emu-component-layers-modal-body-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-color-background-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-background-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-color-border-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-border-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-border-width-all: var(--emu-semantic-border-width-thin);
  --emu-component-layers-modal-actions-close-padding-top: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-right: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-bottom: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-left: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-text-typography: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-layers-modal-actions-close-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-actions-close-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-actions-prompt-button-group-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-gap-wide: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-margin-top: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-layers-cookie-consent-banner-z-index: var(--emu-common-other-z-index-cookie-banner);
  --emu-component-layers-cookie-consent-banner-max-width: var(--emu-component-containers-container-max-width);
  --emu-component-layers-cookie-consent-banner-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-color-background-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-color-background-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-shadow: 4px 4px 20px 4px #0003;
  --emu-component-layers-cookie-consent-banner-padding-top-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-padding-top-wide: 24px;
  --emu-component-layers-cookie-consent-banner-padding-right-narrow: 32px;
  --emu-component-layers-cookie-consent-banner-padding-right-wide: 32px;
  --emu-component-layers-cookie-consent-banner-padding-bottom-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-padding-bottom-wide: 24px;
  --emu-component-layers-cookie-consent-banner-padding-left-narrow: 32px;
  --emu-component-layers-cookie-consent-banner-padding-left-wide: 32px;
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-narrow: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-wide: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-active-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-active-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-padding-top-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-top-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-right-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-right-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-left-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-left-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-top-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-top-wide: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-right-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-right-wide: 0px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-wide: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-left-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-left-wide: 56px;
  --emu-component-layers-cookie-consent-banner-settings-button-border-width: 1px;
  --emu-component-layers-cookie-consent-banner-settings-button-outline-width: 1px;
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-active-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-active-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-light: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-dark: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-active-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-active-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-light: var(--emu-common-colors-grey-300);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-dark: var(--emu-common-colors-grey-300);
  --emu-figma-component-cards-teaser-text-color-light: var(--emu-common-colors-white);
  --emu-figma-component-cards-teaser-text-color-dark: var(--emu-common-colors-black);
  --emu-figma-component-cards-teaser-title-max-width: 66.6667%;
  --emu-figma-component-cards-teaser-title-margin-narrow: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-none);
  --emu-figma-component-cards-teaser-title-margin-wide: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-none);
  --emu-figma-component-cards-teaser-description-margin-top-narrow: var(--emu-common-spacing-none);
  --emu-figma-component-cards-teaser-description-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-figma-component-cards-teaser-description-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-figma-component-cards-teaser-description-margin-bottom-wide: var(--emu-common-spacing-small);
  --emu-figma-component-cards-teaser-padding-narrow: var(--emu-semantic-spacing-two-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-cards-teaser-padding-wide: var(--emu-semantic-spacing-two-line-height-wide) var(--emu-common-sizing-medium);
  --emu-figma-component-cards-teaser-actions-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-cards-teaser-actions-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-cards-teaser-actions-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-cards-teaser-actions-padding-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-cards-teaser-disclaimer-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-cards-teaser-disclaimer-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-lists-navigation-banner-color-background-light: var(--emu-common-colors-black);
  --emu-figma-component-lists-navigation-banner-color-background-dark: var(--emu-common-colors-white);
  --emu-figma-component-lists-navigation-banner-item-color-background-default-light: var(--emu-common-colors-black);
  --emu-figma-component-lists-navigation-banner-item-color-background-default-dark: var(--emu-common-colors-white);
  --emu-figma-component-lists-navigation-banner-item-color-background-hover-light: var(--emu-common-colors-grey-800);
  --emu-figma-component-lists-navigation-banner-item-color-background-hover-dark: var(--emu-common-colors-grey-800);
  --emu-figma-component-lists-navigation-banner-item-color-border-default-light: var(--emu-common-colors-black);
  --emu-figma-component-lists-navigation-banner-item-color-border-default-dark: var(--emu-common-colors-white);
  --emu-figma-component-lists-navigation-banner-item-color-border-hover-light: var(--emu-common-colors-grey-800);
  --emu-figma-component-lists-navigation-banner-item-color-border-hover-dark: var(--emu-common-colors-grey-800);
  --emu-figma-component-lists-navigation-banner-item-text-color-default-light: var(--emu-semantic-colors-text-dark);
  --emu-figma-component-lists-navigation-banner-item-text-color-default-dark: var(--emu-semantic-colors-text-light);
  --emu-figma-component-lists-navigation-banner-item-text-color-hover-light: var(--emu-semantic-colors-text-dark);
  --emu-figma-component-lists-navigation-banner-item-text-color-hover-dark: var(--emu-semantic-colors-text-light);
  --emu-figma-component-lists-navigation-banner-item-border-width: var(--emu-common-border-width-thin);
  --emu-figma-component-lists-navigation-banner-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-lists-navigation-banner-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-lists-navigation-banner-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-lists-navigation-banner-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-lists-navigation-banner-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-lists-navigation-banner-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-lists-navigation-banner-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-lists-navigation-banner-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-lists-navigation-dropdown-color-background-light: var(--emu-common-colors-white);
  --emu-figma-component-lists-navigation-dropdown-color-background-dark: var(--emu-common-colors-black);
  --emu-figma-component-lists-navigation-dropdown-color-border-light: var(--emu-common-colors-black);
  --emu-figma-component-lists-navigation-dropdown-color-border-dark: var(--emu-common-colors-white);
  --emu-figma-component-lists-navigation-dropdown-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-figma-component-lists-navigation-dropdown-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-figma-component-lists-navigation-dropdown-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-figma-component-lists-navigation-dropdown-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-figma-component-lists-navigation-dropdown-border-width: var(--emu-common-border-width-thin);
  --emu-figma-component-lists-navigation-dropdown-item-color-background-default-light: var(--emu-common-colors-white);
  --emu-figma-component-lists-navigation-dropdown-item-color-background-default-dark: var(--emu-common-colors-black);
  --emu-figma-component-lists-navigation-dropdown-item-color-background-hover-light: var(--emu-common-colors-grey-100);
  --emu-figma-component-lists-navigation-dropdown-item-color-background-hover-dark: var(--emu-common-colors-grey-100);
  --emu-figma-component-lists-navigation-dropdown-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-figma-component-lists-navigation-dropdown-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-figma-component-lists-navigation-dropdown-item-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-figma-component-lists-navigation-dropdown-item-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-figma-component-lists-navigation-z-index: var(--emu-common-other-z-index-header);
  --emu-figma-component-lists-navigation-text-padding-top-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-lists-navigation-text-padding-top-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-lists-navigation-text-padding-right-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-lists-navigation-text-padding-right-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-lists-navigation-text-padding-bottom-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-lists-navigation-text-padding-bottom-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-lists-navigation-text-padding-left-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-lists-navigation-text-padding-left-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-feeds-instagram-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-feeds-instagram-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-feeds-instagram-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-feeds-instagram-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-feeds-instagram-padding-bottom-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-feeds-instagram-padding-bottom-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-feeds-instagram-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-feeds-instagram-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-feeds-instagram-post-gap-narrow: 40px;
  --emu-figma-component-feeds-instagram-post-gap-wide: 40px;
  --emu-figma-component-ingredients-google-map-color-background-light: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-figma-component-ingredients-google-map-color-background-dark: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-figma-component-ingredients-google-map-color-border-light: var(--emu-semantic-colors-none);
  --emu-figma-component-ingredients-google-map-margin-top-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-ingredients-google-map-margin-top-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-ingredients-google-map-margin-right-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-ingredients-google-map-margin-right-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-ingredients-google-map-margin-bottom-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-ingredients-google-map-margin-bottom-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-ingredients-google-map-margin-left-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-ingredients-google-map-margin-left-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-ingredients-google-map-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-figma-component-ingredients-google-map-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-figma-component-ingredients-google-map-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-figma-component-ingredients-google-map-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-figma-component-ingredients-image-color-background-light: var(--emu-semantic-colors-background-light);
  --emu-figma-component-ingredients-image-color-background-dark: var(--emu-semantic-colors-background-dark);
  --emu-figma-component-actions-back-to-top-z-index: var(--emu-common-other-z-index-modal);
  --emu-figma-component-actions-back-to-top-icon-size-height: var(--emu-common-sizing-medium);
  --emu-figma-component-actions-back-to-top-icon-size-width: var(--emu-common-sizing-medium);
  --emu-figma-component-actions-back-to-top-position-bottom-narrow: var(--emu-common-spacing-none);
  --emu-figma-component-actions-back-to-top-position-bottom-wide: var(--emu-common-spacing-none);
  --emu-figma-component-actions-back-to-top-position-left-narrow: var(--emu-common-spacing-unset);
  --emu-figma-component-actions-back-to-top-position-left-wide: var(--emu-common-spacing-unset);
  --emu-figma-component-actions-back-to-top-position-right-narrow: var(--emu-common-spacing-none);
  --emu-figma-component-actions-back-to-top-position-right-wide: var(--emu-common-spacing-none);
  --emu-figma-component-actions-back-to-top-position-top-narrow: var(--emu-common-spacing-unset);
  --emu-figma-component-actions-back-to-top-position-top-wide: var(--emu-common-spacing-unset);
  --emu-figma-component-actions-back-to-top-margin-bottom-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-actions-back-to-top-margin-bottom-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-actions-back-to-top-margin-left-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-actions-back-to-top-margin-left-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-actions-back-to-top-margin-right-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-actions-back-to-top-margin-right-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-actions-back-to-top-margin-top-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-actions-back-to-top-margin-top-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-actions-back-to-top-border-radius-top-left-narrow: var(--emu-semantic-border-radius-none);
  --emu-figma-component-actions-back-to-top-border-radius-top-left-wide: var(--emu-semantic-border-radius-none);
  --emu-figma-component-actions-back-to-top-border-radius-top-right-narrow: var(--emu-semantic-border-radius-none);
  --emu-figma-component-actions-back-to-top-border-radius-top-right-wide: var(--emu-semantic-border-radius-none);
  --emu-figma-component-actions-back-to-top-border-radius-bottom-left-narrow: var(--emu-semantic-border-radius-none);
  --emu-figma-component-actions-back-to-top-border-radius-bottom-left-wide: var(--emu-semantic-border-radius-none);
  --emu-figma-component-actions-back-to-top-border-radius-bottom-right-narrow: var(--emu-semantic-border-radius-none);
  --emu-figma-component-actions-back-to-top-border-radius-bottom-right-wide: var(--emu-semantic-border-radius-none);
  --emu-figma-component-actions-back-to-top-padding-bottom-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-actions-back-to-top-padding-bottom-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-actions-back-to-top-padding-left-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-actions-back-to-top-padding-left-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-actions-back-to-top-padding-top-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-actions-back-to-top-padding-top-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-actions-back-to-top-padding-right-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-actions-back-to-top-padding-right-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-actions-back-to-top-border-width-all-narrow: var(--emu-semantic-border-width-thin);
  --emu-figma-component-actions-back-to-top-border-width-all-wide: var(--emu-semantic-border-width-thin);
  --emu-figma-component-actions-back-to-top-color-border-default-light: var(--emu-common-colors-white);
  --emu-figma-component-actions-back-to-top-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-figma-component-actions-back-to-top-color-background-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-figma-component-actions-back-to-top-color-background-default-dark: var(--emu-common-colors-grey-200);
  --emu-figma-component-actions-back-to-top-color-icon-default-light: var(--emu-common-colors-white);
  --emu-figma-component-actions-back-to-top-color-icon-default-dark: var(--emu-common-colors-black);
  --emu-figma-component-containers-scroll-reveal-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-figma-component-containers-scroll-reveal-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-figma-component-containers-scroll-reveal-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-scroll-reveal-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-scroll-reveal-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-scroll-reveal-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-scroll-reveal-padding-top-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-containers-scroll-reveal-padding-top-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-containers-scroll-reveal-padding-bottom-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-containers-scroll-reveal-padding-bottom-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-containers-scroll-reveal-progress-bar-color-progress-indicator-light: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-scroll-reveal-progress-bar-color-progress-indicator-dark: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-scroll-reveal-progress-bar-color-bar-light: var(--emu-common-colors-grey-300);
  --emu-figma-component-containers-scroll-reveal-progress-bar-color-bar-dark: var(--emu-common-colors-grey-300);
  --emu-figma-component-containers-scroll-reveal-static-section-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-static-section-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-static-section-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-static-section-margin-right-wide: var(--emu-common-sizing-medium);
  --emu-figma-component-containers-scroll-reveal-static-section-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-static-section-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-static-section-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-static-section-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-cards-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-cards-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-cards-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-cards-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-cards-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-cards-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-cards-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-cards-padding-left-wide: var(--emu-common-sizing-none);
  --emu-figma-component-containers-scroll-reveal-cards-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-figma-component-containers-scroll-reveal-cards-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-figma-component-containers-scroll-reveal-cards-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-scroll-reveal-cards-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-scroll-reveal-cards-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-figma-component-containers-scroll-reveal-cards-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-figma-component-containers-scroll-reveal-cards-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-scroll-reveal-cards-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-tabs-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-containers-tabs-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-containers-tabs-gap-vertical-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-containers-tabs-gap-vertical-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-containers-tabs-navigation-item-padding-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-figma-component-containers-tabs-navigation-item-padding-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-figma-component-containers-tabs-navigation-item-padding-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-figma-component-containers-tabs-navigation-item-padding-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-figma-component-containers-tabs-navigation-item-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-figma-component-containers-tabs-navigation-item-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-figma-component-containers-tabs-navigation-item-padding-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-figma-component-containers-tabs-navigation-item-padding-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-figma-component-containers-tabs-navigation-item-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-containers-tabs-navigation-item-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-containers-tabs-navigation-item-gap-vertical-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-containers-tabs-navigation-item-gap-vertical-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-containers-tabs-navigation-item-border-width-top-default: var(--emu-semantic-border-width-none);
  --emu-figma-component-containers-tabs-navigation-item-border-width-top-active: var(--emu-semantic-border-width-none);
  --emu-figma-component-containers-tabs-navigation-item-border-width-right-default: var(--emu-semantic-border-width-none);
  --emu-figma-component-containers-tabs-navigation-item-border-width-right-active: var(--emu-semantic-border-width-none);
  --emu-figma-component-containers-tabs-navigation-item-border-width-bottom-default: var(--emu-semantic-border-width-none);
  --emu-figma-component-containers-tabs-navigation-item-border-width-bottom-active: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-tabs-navigation-item-border-width-left-default: var(--emu-semantic-border-width-none);
  --emu-figma-component-containers-tabs-navigation-item-border-width-left-active: var(--emu-semantic-border-width-none);
  --emu-figma-component-containers-tabs-navigation-item-color-border-default-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-tabs-navigation-item-color-border-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-tabs-navigation-item-color-border-active-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-tabs-navigation-item-color-border-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-tabs-navigation-item-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-tabs-navigation-item-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-tabs-navigation-item-color-background-active-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-tabs-navigation-item-color-background-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-tabs-navigation-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-figma-component-containers-tabs-navigation-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-figma-component-containers-tabs-navigation-item-text-color-active-light: var(--emu-semantic-colors-text-light);
  --emu-figma-component-containers-tabs-navigation-item-text-color-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-figma-component-containers-tabs-content-padding-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-figma-component-containers-tabs-content-padding-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-figma-component-containers-tabs-content-padding-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-figma-component-containers-tabs-content-padding-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-figma-component-containers-tabs-content-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-figma-component-containers-tabs-content-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-figma-component-containers-tabs-content-padding-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-figma-component-containers-tabs-content-padding-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-figma-component-containers-tabs-content-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-figma-component-containers-tabs-content-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-figma-component-containers-tabs-content-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-figma-component-containers-tabs-content-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-figma-component-containers-tabs-content-color-background-light: var(--emu-component-containers-container-color-background-light);
  --emu-figma-component-containers-tabs-content-color-background-dark: var(--emu-component-containers-container-color-background-dark);
  --emu-figma-component-containers-isi-header-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-header-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-header-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-header-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-header-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-header-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-header-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-header-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-header-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-isi-header-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-isi-header-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-isi-header-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-isi-header-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-isi-body-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-body-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-body-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-body-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-body-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-body-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-body-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-body-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-body-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-isi-body-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-isi-body-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-isi-body-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-isi-body-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-isi-open-content-size: 30vh;
  --emu-figma-component-containers-isi-z-index: var(--emu-common-other-z-index-isi);
  --emu-figma-component-layers-modal-z-index: var(--emu-common-other-z-index-modal);
  --emu-figma-component-layers-modal-scrim-color-background: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-figma-component-layers-modal-body-sizing-width: 80%;
  --emu-figma-component-layers-modal-body-sizing-max-width: 800px;
  --emu-figma-component-layers-modal-body-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-figma-component-layers-modal-body-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-figma-component-layers-modal-body-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-figma-component-layers-modal-body-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-figma-component-layers-modal-body-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-figma-component-layers-modal-body-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-figma-component-layers-modal-body-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-figma-component-layers-modal-body-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-figma-component-layers-modal-body-color-background-light: var(--emu-common-colors-black);
  --emu-figma-component-layers-modal-body-color-background-dark: var(--emu-common-colors-white);
  --emu-figma-component-layers-modal-body-color-border-light: var(--emu-common-colors-black);
  --emu-figma-component-layers-modal-body-color-border-dark: var(--emu-common-colors-white);
  --emu-figma-component-layers-modal-body-border-width-all: var(--emu-semantic-border-width-thin);
  --emu-figma-component-layers-modal-actions-close-padding-top: var(--emu-common-spacing-medium);
  --emu-figma-component-layers-modal-actions-close-padding-right: var(--emu-common-spacing-medium);
  --emu-figma-component-layers-modal-actions-close-padding-bottom: var(--emu-common-spacing-medium);
  --emu-figma-component-layers-modal-actions-close-padding-left: var(--emu-common-spacing-medium);
  --emu-figma-component-layers-modal-actions-close-text-typography: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-figma-component-layers-modal-actions-close-text-color-light: var(--emu-common-colors-black);
  --emu-figma-component-layers-modal-actions-close-text-color-dark: var(--emu-common-colors-white);
  --emu-figma-component-layers-modal-actions-prompt-button-group-gap-narrow: var(--emu-common-spacing-small);
  --emu-figma-component-layers-modal-actions-prompt-button-group-gap-wide: var(--emu-common-spacing-small);
  --emu-figma-component-layers-modal-actions-prompt-button-group-margin-top: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-layers-modal-close-size-height-narrow: var(--emu-semantic-sizing-one-line-height-narrow);
  --emu-figma-component-layers-modal-close-size-height-wide: var(--emu-semantic-sizing-one-line-height-wide);
  --emu-figma-component-layers-modal-close-size-width-narrow: var(--emu-semantic-sizing-one-line-height-narrow);
  --emu-figma-component-layers-modal-close-size-width-wide: var(--emu-semantic-sizing-one-line-height-wide);
  --emu-figma-component-layers-cookie-consent-banner-z-index: var(--emu-common-other-z-index-cookie-banner);
  --emu-figma-component-layers-cookie-consent-banner-max-width: var(--emu-component-containers-container-max-width);
  --emu-figma-component-layers-cookie-consent-banner-text-color-light: var(--emu-common-colors-black);
  --emu-figma-component-layers-cookie-consent-banner-text-color-dark: var(--emu-common-colors-white);
  --emu-figma-component-layers-cookie-consent-banner-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-layers-cookie-consent-banner-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-layers-cookie-consent-banner-shadow: 4px 4px 20px 4px #0003;
  --emu-figma-component-layers-cookie-consent-banner-padding-top-narrow: 24px;
  --emu-figma-component-layers-cookie-consent-banner-padding-top-wide: 24px;
  --emu-figma-component-layers-cookie-consent-banner-padding-right-narrow: 32px;
  --emu-figma-component-layers-cookie-consent-banner-padding-right-wide: 32px;
  --emu-figma-component-layers-cookie-consent-banner-padding-bottom-narrow: 24px;
  --emu-figma-component-layers-cookie-consent-banner-padding-bottom-wide: 24px;
  --emu-figma-component-layers-cookie-consent-banner-padding-left-narrow: 32px;
  --emu-figma-component-layers-cookie-consent-banner-padding-left-wide: 32px;
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-typography-narrow: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-heading);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-typography-wide: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-heading);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-typography-large: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-large) / var(--emu-semantic-line-heights-wide-large) var(--emu-semantic-font-families-heading);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-color-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-color-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-color-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-padding-top-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-padding-top-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-padding-right-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-padding-right-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-padding-bottom-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-padding-bottom-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-padding-left-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-padding-left-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-margin-top-narrow: 24px;
  --emu-figma-component-layers-cookie-consent-banner-settings-button-margin-top-wide: auto;
  --emu-figma-component-layers-cookie-consent-banner-settings-button-margin-right-narrow: auto;
  --emu-figma-component-layers-cookie-consent-banner-settings-button-margin-right-wide: 0px;
  --emu-figma-component-layers-cookie-consent-banner-settings-button-margin-bottom-narrow: auto;
  --emu-figma-component-layers-cookie-consent-banner-settings-button-margin-bottom-wide: auto;
  --emu-figma-component-layers-cookie-consent-banner-settings-button-margin-left-narrow: auto;
  --emu-figma-component-layers-cookie-consent-banner-settings-button-margin-left-wide: 56px;
  --emu-figma-component-layers-cookie-consent-banner-settings-button-border-width: var(--emu-common-border-width-thin);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-outline-width: var(--emu-semantic-outline-width-thin);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-border-radius-top-left: var(--emu-semantic-border-radius-none);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-border-radius-top-right: var(--emu-semantic-border-radius-none);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-border-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-border-dark: var(--emu-common-colors-black);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-border-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-border-focus-dark: var(--emu-common-colors-black);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-border-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-border-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-border-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-border-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-background-light: var(--emu-common-colors-transparent);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-background-dark: var(--emu-common-colors-transparent);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-background-focus-light: var(--emu-common-colors-transparent);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-background-focus-dark: var(--emu-common-colors-transparent);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-background-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-background-hover-dark: var(--emu-common-colors-black);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-background-active-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-background-active-dark: var(--emu-common-colors-black);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-outline-color-light: var(--emu-semantic-colors-none);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-outline-color-dark: var(--emu-semantic-colors-none);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-light: var(--emu-semantic-colors-none);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-dark: var(--emu-semantic-colors-none);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-light: var(--emu-semantic-colors-none);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-dark: var(--emu-semantic-colors-none);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-light: var(--emu-common-colors-grey-300);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-dark: var(--emu-common-colors-grey-300);
}

html {
  -webkit-text-size-adjust: 100%;
}

html, body {
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
  font-size: var(--emu-semantic-font-sizes-narrow-body);
  font-family: var(--emu-semantic-font-families-body);
  line-height: var(--emu-common-line-heights-narrow-body);
}

html .xf-content-height, body .xf-content-height {
  margin: inherit;
}

html .experiencefragment, body .experiencefragment {
  padding: var(--emu-common-spacing-none);
}

body {
  -webkit-overflow-scrolling: touch;
}

* {
  outline: var(--emu-semantic-colors-surface-dark);
}

.cmp-image__image, image {
  width: auto;
  max-width: 100%;
}

button, input, optgroup, select, textarea {
  font: inherit;
}

*, :before, :after {
  box-sizing: border-box;
}

sup {
  vertical-align: super;
  font-size: 75%;
  line-height: 0;
  font-family: var(--emu-common-font-families-roboto);
  letter-spacing: normal;
  margin-right: 1px;
  display: inline-block;
  position: relative;
  top: 0;
}

p, li {
  margin-bottom: var(--emu-common-spacing-medium);
  margin-top: var(--emu-common-spacing-none);
}

b {
  font-weight: var(--emu-common-font-weight-bold);
}

i, b, .emphasis, span, a {
  font-family: inherit;
}

.desktop-view {
  display: none;
}

@media (min-width: 1025px) {
  .desktop-view {
    display: block;
  }
}

.cq-Editable-dom--container .desktop-view {
  display: block;
}

.tablet-desktop-view {
  display: none;
}

@media (min-width: 768px) {
  .tablet-desktop-view {
    display: block;
  }
}

.cq-Editable-dom--container .tablet-desktop-view, .tablet-mobile-view {
  display: block;
}

@media (min-width: 1025px) {
  .tablet-mobile-view {
    display: none;
  }
}

.cq-Editable-dom--container .tablet-mobile-view, .mobile-view {
  display: block;
}

@media (min-width: 768px) {
  .mobile-view {
    display: none;
  }
}

.cq-Editable-dom--container .mobile-view {
  display: block;
}

.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

.u-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.u-mx-auto.emu-button-v2.emu-button-v2.emu-button {
  display: block;
}

.u-container-default {
  padding-left: 15px;
  padding-right: 15px;
}

[class*="u-text-color"] h1, [class*="u-text-color"] h2, [class*="u-text-color"] h3, [class*="u-text-color"] h4, [class*="u-text-color"] h5, [class*="u-text-color"] h6, [class*="u-text-color"] li, [class*="u-text-color"] p, [class*="u-text-color"] a, [class*="u-text-color"] span, [class*="u-title"] h1, [class*="u-title"] h2, [class*="u-title"] h3, [class*="u-title"] h4, [class*="u-title"] h5, [class*="u-title"] h6, [class*="u-title"] li, [class*="u-title"] p, [class*="u-title"] a, [class*="u-title"] span {
  color: inherit;
}

.u-text-color--white {
  color: var(--emu-common-colors-white) !important;
}

.u-text-color--black {
  color: var(--emu-common-colors-black) !important;
}

.u-text-color--blue {
  color: var(--emu-common-colors-brand-blue-600) !important;
}

.u-text-color--violet {
  color: var(--emu-common-colors-brand-violet-500) !important;
}

.u-text-color--pink {
  color: var(--emu-common-colors-brand-pink-500) !important;
}

.u-text-color--grey {
  color: var(--emu-common-colors-dark-grey-400) !important;
}

.u-text-color--light-pink {
  color: var(--emu-common-colors-brand-pink-400) !important;
}

.u-bg-color--blue {
  background-color: var(--emu-common-colors-brand-blue-500);
}

.u-bg-color--blue .button .emu-button-v2.emu-button:hover, .u-bg-color--blue .button .btn--violet.emu-button-v2.emu-button:hover, .u-bg-color--blue .button .btn--grey.emu-button-v2.emu-button:hover, .u-bg-color--blue .button .btn--light-pink.emu-button-v2.emu-button:hover {
  color: var(--emu-common-colors-brand-blue-500);
}

.u-bg-color--dark-blue {
  background-color: var(--emu-common-colors-brand-blue-600);
}

.u-bg-color--dark-blue .button .emu-button-v2.emu-button:hover, .u-bg-color--dark-blue .button .btn--violet.emu-button-v2.emu-button:hover, .u-bg-color--dark-blue .button .btn--grey.emu-button-v2.emu-button:hover, .u-bg-color--dark-blue .button .btn--light-pink.emu-button-v2.emu-button:hover {
  color: var(--emu-common-colors-brand-blue-600);
}

.u-bg-color--light-blue {
  background-color: var(--emu-common-colors-brand-blue-50);
}

.u-bg-color--light-blue .button .emu-button-v2.emu-button:hover, .u-bg-color--light-blue .button .emu-button-v2.emu-button:focus, .u-bg-color--light-blue .button .btn--violet.emu-button-v2.emu-button:hover, .u-bg-color--light-blue .button .btn--violet.emu-button-v2.emu-button:focus, .u-bg-color--light-blue .button .btn--grey.emu-button-v2.emu-button:hover, .u-bg-color--light-blue .button .btn--grey.emu-button-v2.emu-button:focus, .u-bg-color--light-blue .button .btn--light-pink.emu-button-v2.emu-button:hover, .u-bg-color--light-blue .button .btn--light-pink.emu-button-v2.emu-button:focus {
  color: var(--emu-common-colors-brand-blue-50);
}

.u-bg-color--light-blue .button .emu-button-v2.emu-button:active, .u-bg-color--light-blue .button .btn--violet.emu-button-v2.emu-button:active, .u-bg-color--light-blue .button .btn--grey.emu-button-v2.emu-button:active, .u-bg-color--light-blue .button .btn--light-pink.emu-button-v2.emu-button:active {
  background-color: var(--emu-common-colors-transparent);
  color: var(--emu-common-colors-brand-blue-500);
}

.u-bg-color--secondary-light-blue {
  background-color: var(--emu-common-colors-brand-blue-100);
}

.u-bg-color--secondary-light-blue .button .emu-button-v2.emu-button:hover, .u-bg-color--secondary-light-blue .button .emu-button-v2.emu-button:focus, .u-bg-color--secondary-light-blue .button .btn--violet.emu-button-v2.emu-button:hover, .u-bg-color--secondary-light-blue .button .btn--violet.emu-button-v2.emu-button:focus, .u-bg-color--secondary-light-blue .button .btn--grey.emu-button-v2.emu-button:hover, .u-bg-color--secondary-light-blue .button .btn--grey.emu-button-v2.emu-button:focus, .u-bg-color--secondary-light-blue .button .btn--light-pink.emu-button-v2.emu-button:hover, .u-bg-color--secondary-light-blue .button .btn--light-pink.emu-button-v2.emu-button:focus {
  color: var(--emu-common-colors-brand-blue-100);
}

.u-bg-color--secondary-light-blue .button .emu-button-v2.emu-button:active, .u-bg-color--secondary-light-blue .button .btn--violet.emu-button-v2.emu-button:active, .u-bg-color--secondary-light-blue .button .btn--grey.emu-button-v2.emu-button:active, .u-bg-color--secondary-light-blue .button .btn--light-pink.emu-button-v2.emu-button:active {
  background-color: var(--emu-common-colors-transparent);
  color: var(--emu-common-colors-brand-blue-100);
}

.u-bg-color--violet {
  background-color: var(--emu-common-colors-brand-violet-400);
}

.u-bg-color--violet .button .emu-button-v2.emu-button:hover, .u-bg-color--violet .button .btn--violet.emu-button-v2.emu-button:hover, .u-bg-color--violet .button .btn--grey.emu-button-v2.emu-button:hover, .u-bg-color--violet .button .btn--light-pink.emu-button-v2.emu-button:hover {
  color: var(--emu-common-colors-brand-violet-400);
}

.u-bg-color-pink {
  background-color: var(--emu-common-colors-brand-pink-500);
}

.u-bg-color-pink .button .emu-button-v2.emu-button:hover, .u-bg-color-pink .button .emu-button-v2.emu-button:focus, .u-bg-color-pink .button .btn--violet.emu-button-v2.emu-button:hover, .u-bg-color-pink .button .btn--violet.emu-button-v2.emu-button:focus, .u-bg-color-pink .button .btn--grey.emu-button-v2.emu-button:hover, .u-bg-color-pink .button .btn--grey.emu-button-v2.emu-button:focus, .u-bg-color-pink .button .btn--light-pink.emu-button-v2.emu-button:hover, .u-bg-color-pink .button .btn--light-pink.emu-button-v2.emu-button:focus {
  color: var(--emu-common-colors-brand-pink-500);
}

.u-bg-color-pink .button .emu-button-v2.emu-button:focus, .u-bg-color-pink .button .btn--violet.emu-button-v2.emu-button:focus, .u-bg-color-pink .button .btn--grey.emu-button-v2.emu-button:focus, .u-bg-color-pink .button .btn--light-pink.emu-button-v2.emu-button:focus {
  box-shadow: 0 0 0 .2rem #007bff40;
}

.u-bg-color-pink .button .emu-button-v2.emu-button:active, .u-bg-color-pink .button .btn--violet.emu-button-v2.emu-button:active, .u-bg-color-pink .button .btn--grey.emu-button-v2.emu-button:active, .u-bg-color-pink .button .btn--light-pink.emu-button-v2.emu-button:active {
  background-color: var(--emu-common-colors-transparent);
  color: var(--emu-common-colors-brand-blue-500);
}

.u-bg-color--violet-500 {
  background-color: var(--emu-common-colors-brand-violet-300);
}

.u-bg-color--violet-500 .button .emu-button-v2.emu-button.emu-button-v2.emu-button:hover, .u-bg-color--violet-500 .button .emu-button-v2.emu-button.emu-button-v2.emu-button:focus, .u-bg-color--violet-500 .button .emu-button-v2.emu-button.emu-button-v2.emu-button:active {
  color: var(--emu-common-colors-brand-violet-300);
  background-color: var(--emu-common-colors-brand-violet-500);
  border-color: var(--emu-common-colors-brand-violet-500);
}

.u-bg-color--light-pink {
  background-color: var(--emu-common-colors-brand-pink-300);
}

.u-hide-br-in-mobile br {
  display: none;
}

@media (min-width: 768px) {
  .u-hide-br-in-mobile br {
    display: block;
  }
}

.u-show-br-in-lg-desktop {
  display: none;
}

@media (min-width: 992px) {
  .u-show-br-in-lg-desktop {
    display: block;
  }
}

@media (min-width: 768px) {
  .u-hide-br-in-desktop br {
    display: none;
  }
}

.u-emphasis-block-in-mobile .emphasis {
  display: block;
}

@media (min-width: 768px) {
  .u-emphasis-block-in-mobile .emphasis {
    display: inline;
  }
}

.u-emphasis-inline-block-in-mobile .emphasis {
  display: inline-block;
}

@media (min-width: 768px) {
  .u-emphasis-inline-block-in-mobile .emphasis {
    display: inline;
  }

  .u-emphasis-block-in-desktop .emphasis {
    display: block;
  }

  .u-emphasis-inline-block-in-desktop .emphasis {
    display: inline-block;
  }
}

.u-emphasis-block .emphasis {
  display: block;
}

.u-emphasis-inline-block .emphasis {
  display: inline-block;
}

.u-title-primary--blue {
  color: var(--emu-common-colors-brand-blue-500);
}

.u-title-primary--blue i, .u-title-primary--blue b {
  font-family: var(--emu-common-font-families-aleo);
  font-style: italic;
  font-weight: 700;
}

.u-title-primary--violet {
  color: var(--emu-common-colors-brand-violet-400);
}

.u-title-primary--violet i, .u-title-primary--violet b {
  font-family: var(--emu-common-font-families-aleo);
  font-weight: var(--emu-common-font-weight-bold);
  font-style: italic;
}

.u-title-primary--light-pink {
  color: var(--emu-common-colors-brand-pink-400);
}

.u-title-primary--light-pink i, .u-title-primary--light-pink b {
  font-family: var(--emu-common-font-families-aleo);
  font-weight: var(--emu-common-font-weight-bold);
  font-style: italic;
}

.u-title-secondary--pink {
  color: var(--emu-common-colors-white);
}

.u-title-secondary--pink i, .u-title-secondary--pink b {
  font-family: var(--emu-common-font-families-aleo);
  font-weight: var(--emu-common-font-weight-bold);
  color: var(--emu-common-colors-brand-pink-500);
  font-style: italic;
}

.u-title-tertiary--blue {
  color: var(--emu-common-colors-dark-grey-400);
}

.u-title-tertiary--blue i, .u-title-tertiary--blue b {
  font-family: var(--emu-common-font-families-aleo);
  font-weight: var(--emu-common-font-weight-bold);
  color: var(--emu-common-colors-brand-blue-500);
  font-style: italic;
}

.u-title-tertiary--light-blue {
  color: var(--emu-common-colors-dark-grey-400);
}

.u-title-tertiary--light-blue i, .u-title-tertiary--light-blue b {
  font-family: var(--emu-common-font-families-aleo);
  font-weight: var(--emu-common-font-weight-bold);
  color: var(--emu-common-colors-brand-blue-200);
  font-style: italic;
}

@media (min-width: 768px) {
  .u-container-max-width {
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .u-container-max-width {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .u-container-max-width {
    max-width: var(--emu-common-sizing-xxxl);
  }
}

.u-hide-background-image-in-mobile {
  position: relative;
  background-size: 100% !important;
}

@media (max-width: 767px) {
  .u-hide-background-image-in-mobile {
    background-image: none !important;
  }
}

@media (max-width: 1025px) {
  .mobile-hide-overflow {
    overflow: hidden;
  }
}

.u-container-max-width {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .u-container-max-width {
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .u-container-max-width {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .u-container-max-width {
    max-width: var(--emu-common-sizing-xxxl);
  }
}

.u-wave-container img, .u-wave-container svg {
  width: 100%;
  display: block;
}

.u-hide {
  display: none !important;
}

.cq-Editable-dom--container .u-hide {
  display: initial !important;
}

.u-accordion--violet.aaaem-accordion .aaaem-accordion__title {
  color: var(--emu-common-colors-brand-violet-500);
}

.u-accordion--violet.aaaem-accordion .aaaem-accordion__panel .js-badger-accordion-panel-inner .cmp-text {
  color: var(--emu-common-colors-brand-violet-400);
}

.u-accordion--violet.aaaem-accordion .aaaem-accordion__button.plus-minus-icon .aaaem-accordion__icon {
  background-image: url("resources/images/accordion-open-icon-purple.svg");
}

.u-accordion--violet.aaaem-accordion .aaaem-accordion__header.-ba-is-active .aaaem-accordion__button.plus-minus-icon .aaaem-accordion__icon {
  background-image: url("resources/images/accordion-close-icon-purple.svg");
}

.u-underline-as-no-word-wrap u {
  white-space: nowrap;
  font-family: inherit;
  text-decoration: none;
}

#breast-augmentation .img-with-text__top-wave {
  z-index: var(--emu-common-other-z-index-layer);
  margin-top: -25px;
  position: relative;
}

@media (min-width: 768px) {
  #breast-augmentation .img-with-text__top-wave {
    margin-top: -100px;
  }
}

@media (min-width: 1280px) {
  #breast-augmentation .img-with-text__top-wave {
    margin-bottom: -6px;
  }
}

@media (min-width: 1600px) {
  #breast-augmentation .img-with-text__top-wave {
    margin-top: -150px;
  }
}

#breast-augmentation .img-with-text__content-container {
  background-color: var(--emu-common-colors-brand-blue-600);
  min-height: 720px;
  margin-top: -5px;
  padding-top: 45px;
  position: relative;
}

@media (min-width: 768px) {
  #breast-augmentation .img-with-text__content-container {
    background-color: var(--emu-common-colors-brand-blue-500);
    min-height: initial;
    margin-top: -8px;
    padding-top: 3%;
    padding-bottom: 7%;
  }
}

@media (min-width: 1025px) {
  #breast-augmentation .img-with-text__content-container {
    margin-top: var(--emu-common-spacing-none);
  }
}

@media (min-width: 768px) {
  #breast-augmentation .img-with-text__content-container-inner {
    display: flex;
  }

  #breast-augmentation .img-with-text__content-container-inner > .container:first-child {
    flex: 66.667%;
  }

  #breast-augmentation .img-with-text__content-container-inner > .container:last-child {
    flex: 33.333%;
  }
}

#breast-augmentation .img-with-text__text-container {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 1025px) {
  #breast-augmentation .img-with-text__text-container {
    padding-right: var(--emu-common-spacing-none);
  }
}

#breast-augmentation .img-with-text__title {
  margin-bottom: var(--emu-common-spacing-small);
  letter-spacing: -.4px;
  max-width: 89%;
}

@media (min-width: 768px) {
  #breast-augmentation .img-with-text__title {
    font-size: 32px;
    line-height: var(--emu-common-font-sizes-wide-xxl);
    letter-spacing: -.2px;
    max-width: 100%;
  }
}

@media (min-width: 1025px) {
  #breast-augmentation .img-with-text__title {
    font-size: var(--emu-common-font-sizes-wide-xxl);
    line-height: 57px;
  }
}

@media (min-width: 1200px) {
  #breast-augmentation .img-with-text__title {
    letter-spacing: -.4px;
  }
}

#breast-augmentation .img-with-text__title h1, #breast-augmentation .img-with-text__title h2, #breast-augmentation .img-with-text__title h3 {
  letter-spacing: inherit;
}

@media (min-width: 768px) {
  #breast-augmentation .img-with-text__title h1, #breast-augmentation .img-with-text__title h2, #breast-augmentation .img-with-text__title h3 {
    font-size: inherit;
    line-height: inherit;
  }
}

#breast-augmentation .img-with-text__title .emphasis {
  max-width: 89%;
  display: block;
}

@media (min-width: 768px) {
  #breast-augmentation .img-with-text__title .emphasis {
    display: inline;
  }
}

#breast-augmentation .img-with-text__title sup {
  font-size: 45%;
  font-style: normal;
  font-family: var(--emu-common-font-families-roboto);
  margin-right: 1px;
  display: inline-block;
  top: -2px;
}

@media (min-width: 1200px) {
  #breast-augmentation .img-with-text__title sup {
    top: -3px;
    left: -2px;
  }
}

#breast-augmentation .img-with-text__text {
  margin-bottom: var(--emu-common-spacing-medium);
  letter-spacing: .03em;
  max-width: 97%;
  padding-top: 6%;
  padding-bottom: 6%;
}

@media (min-width: 768px) {
  #breast-augmentation .img-with-text__text {
    max-width: 100%;
    padding-top: 2%;
    padding-bottom: 3%;
  }
}

@media (min-width: 1025px) {
  #breast-augmentation .img-with-text__text {
    letter-spacing: normal;
  }
}

#breast-augmentation .img-with-text__text p {
  margin-bottom: var(--emu-common-spacing-none);
}

#breast-augmentation .img-with-text__text sup {
  margin-right: 1px;
  font-size: 45%;
  display: inline-block;
  top: -1px;
  left: -1px;
}

#breast-augmentation .img-with-text__text br {
  display: none;
}

@media (min-width: 768px) {
  #breast-augmentation .img-with-text__text br {
    display: block;
  }
}

#breast-augmentation .img-with-text__cta {
  background-color: var(--emu-common-colors-brand-blue-500);
  letter-spacing: .3em;
  border-width: var(--emu-common-border-width-medium);
  padding: 12px 11px 12px 12px;
  font-size: 13px;
  line-height: 17px;
  transition: all .2s linear;
}

@media (min-width: 768px) {
  #breast-augmentation .img-with-text__cta {
    padding: 10px 14px 10px 15px;
    font-size: 15px;
    line-height: 17px;
  }
}

@media (min-width: 1025px) {
  #breast-augmentation .img-with-text__cta {
    padding: 15px 29px 15px 30px;
  }
}

#breast-augmentation .img-with-text__cta:hover, #breast-augmentation .img-with-text__cta:focus {
  background-color: var(--emu-common-colors-brand-pink-500);
  color: var(--emu-common-colors-brand-blue-500);
}

#breast-augmentation .img-with-text__cta sup {
  font-size: 45%;
  top: -1px;
}

#breast-augmentation .img-with-text__img-container {
  width: 100%;
  max-width: 90%;
  margin-left: auto;
  padding-top: 30px;
  padding-bottom: 10px;
  overflow: hidden;
}

@media (min-width: 768px) {
  #breast-augmentation .img-with-text__img-container {
    padding-top: 12px;
    padding-bottom: var(--emu-common-spacing-none);
    width: 100%;
    max-width: 420px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 15px;
  }
}

@media (min-width: 1024px) {
  #breast-augmentation .img-with-text__img-container {
    max-width: 360px;
  }
}

@media (min-width: 1200px) {
  #breast-augmentation .img-with-text__img-container {
    max-width: 420px;
  }
}

@media (min-width: 1300px) {
  #breast-augmentation .img-with-text__img-container {
    position: static;
  }
}

#breast-augmentation .img-with-text__img-container img {
  width: 100%;
  position: relative;
  right: -60px;
}

@media (min-width: 768px) {
  #breast-augmentation .img-with-text__img-container img {
    width: 60%;
    position: absolute;
    right: 0;
  }
}

@media (min-width: 1025px) {
  #breast-augmentation .img-with-text__img-container img {
    width: 100%;
  }
}

@media (min-width: 1300px) {
  #breast-augmentation .img-with-text__img-container img {
    position: static;
  }
}

#breast-augmentation .aug-desc > .container {
  padding: 55px 15px 40px;
}

@media (min-width: 768px) {
  #breast-augmentation .aug-desc > .container {
    padding-top: 48px;
    padding-bottom: var(--emu-common-spacing-none);
  }
}

@media (min-width: 992px) {
  #breast-augmentation .aug-desc__inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 768px) {
  #breast-augmentation .aug-desc .title-text.aug-desc__intro .title-text__txt {
    padding-bottom: var(--emu-common-spacing-none);
  }
}

#breast-augmentation .aug-desc .title-text__cta {
  color: var(--emu-common-colors-dark-grey-400);
  border-color: var(--emu-common-colors-dark-grey-500);
}

#breast-augmentation .aug-desc .title-text__cta:hover, #breast-augmentation .aug-desc .title-text__cta:focus {
  color: var(--emu-common-colors-white);
  background-color: var(--emu-common-colors-dark-grey-400);
  border-color: var(--emu-common-colors-dark-grey-400);
}

#breast-augmentation .aug-invest {
  margin-top: -3px;
}

@media (min-width: 768px) {
  #breast-augmentation .aug-invest {
    margin-top: 15%;
  }
}

@media (min-width: 1025px) {
  #breast-augmentation .aug-invest {
    margin-top: 10%;
  }
}

@media (min-width: 1280px) {
  #breast-augmentation .aug-invest {
    margin-top: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1025px) {
  #breast-augmentation .aug-invest__desktop-top-wave {
    margin-top: -44px;
  }
}

@media (min-width: 1280px) {
  #breast-augmentation .aug-invest__desktop-top-wave {
    margin-bottom: -6px;
  }
}

#breast-augmentation .aug-invest__container {
  padding-bottom: 15%;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  #breast-augmentation .aug-invest__container {
    padding-bottom: 12%;
  }
}

@media (min-width: 992px) {
  #breast-augmentation .aug-invest__container {
    padding-left: 4%;
    padding-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1025px) {
  #breast-augmentation .aug-invest__container {
    padding-left: var(--emu-common-spacing-none);
    padding-bottom: 5%;
  }
}

#breast-augmentation .aug-invest__container-inner {
  padding-top: 40px;
  padding-bottom: 55px;
}

@media (min-width: 768px) {
  #breast-augmentation .aug-invest__container-inner {
    padding-top: 6%;
    padding-bottom: var(--emu-common-spacing-none);
  }
}

@media (min-width: 992px) {
  #breast-augmentation .aug-invest__container-inner {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#breast-augmentation .aug-invest .title-text__title {
  letter-spacing: -.4px;
}

#breast-augmentation .aug-invest .title-text__txt {
  max-width: 93%;
  padding-top: 5%;
}

@media (min-width: 768px) {
  #breast-augmentation .aug-invest .title-text__txt {
    max-width: 100%;
    padding-top: 1%;
  }
}

#breast-augmentation .aug-invest .title-text__cta {
  margin-top: 31px;
  margin-bottom: var(--emu-common-spacing-none);
  width: 95%;
}

#breast-augmentation .aug-invest .title-text__cta:focus {
  color: var(--emu-common-colors-brand-blue-500);
  box-shadow: 0 0 0 .2rem #007bff40;
}

#breast-augmentation .aug-invest .title-text__cta:active {
  color: var(--emu-common-colors-brand-blue-500);
}

@media (min-width: 768px) {
  #breast-augmentation .aug-invest .title-text__cta {
    margin-top: var(--emu-common-spacing-none);
    width: auto;
  }

  #breast-augmentation .aug-invest__text--top {
    margin-bottom: -2px;
  }
}

@media (min-width: 992px) {
  #breast-augmentation .aug-invest__text--top {
    margin-bottom: -1px;
  }
}

#breast-augmentation .aug-invest__text--top .title-text__title {
  max-width: 85%;
}

@media (min-width: 768px) {
  #breast-augmentation .aug-invest__text--top .title-text__title {
    max-width: 100%;
  }
}

#breast-augmentation .aug-invest__text--top .title-text__txt {
  padding-bottom: var(--emu-common-spacing-none);
}

#breast-augmentation .aug-invest__text--bottom {
  padding-top: 5%;
}

#breast-augmentation .aug-invest__text--bottom .title-text__txt {
  padding-bottom: 2.5%;
}

#breast-augmentation .aug-presurgery {
  background-color: var(--emu-common-colors-white);
  z-index: var(--emu-common-other-z-index-layer);
  background-position: 0 0;
  position: relative;
}

@media (max-width: 767px) {
  #breast-augmentation .aug-presurgery {
    background-image: none !important;
  }
}

@media (min-width: 768px) {
  #breast-augmentation .aug-presurgery {
    margin-top: -65px;
    margin-bottom: -1px;
  }
}

@media (max-width: 1024px) {
  #breast-augmentation .aug-presurgery {
    background-image: none !important;
  }
}

@media (min-width: 1025px) {
  #breast-augmentation .aug-presurgery {
    margin-top: var(--emu-common-spacing-none);
  }
}

#breast-augmentation .aug-presurgery__mobile-top-wave {
  margin-top: -30px;
  margin-bottom: -7px;
}

#breast-augmentation .aug-presurgery__mobile-bottom-wave {
  z-index: var(--emu-common-other-z-index-base);
  margin-top: -6px;
  margin-bottom: -7px;
  position: relative;
}

@media (min-width: 1280px) {
  #breast-augmentation .aug-presurgery__desktop-top-wave, #breast-augmentation .aug-presurgery__desktop-bottom-wave {
    margin-top: -6px;
    margin-bottom: -6px;
  }
}

#breast-augmentation .aug-presurgery__container {
  padding: 45px 15px;
}

@media (min-width: 992px) {
  #breast-augmentation .aug-presurgery__container {
    padding-left: var(--emu-semantic-spacing-none);
    padding-right: var(--emu-semantic-spacing-none);
  }
}

@media (min-width: 768px) {
  #breast-augmentation .aug-presurgery__container {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: 4%;
  }
}

@media (min-width: 1025px) {
  #breast-augmentation .aug-presurgery__container {
    padding-bottom: var(--emu-common-spacing-none);
  }
}

#breast-augmentation .aug-presurgery .title-text__title {
  letter-spacing: -.5px;
}

#breast-augmentation .aug-presurgery .title-text__txt {
  padding-top: 3%;
  padding-bottom: var(--emu-common-spacing-none);
  max-width: 100%;
}

@media (min-width: 768px) {
  #breast-augmentation .aug-presurgery .title-text__txt {
    padding-top: 1%;
  }
}

#breast-augmentation .aug-presurgery__desc--bottom {
  padding-top: 5%;
}

@media (min-width: 768px) {
  #breast-augmentation .aug-presurgery__desc--bottom {
    padding-top: 3%;
  }
}

#breast-augmentation .aug-presurgery__desc--bottom .title-text__txt {
  padding-bottom: 5%;
}

@media (min-width: 768px) {
  #breast-augmentation .aug-presurgery__desc--bottom .title-text__txt {
    padding-bottom: 3%;
  }
}

#breast-augmentation .aug-presurgery__img-container {
  padding-top: 5%;
  padding-bottom: 5%;
}

@media (min-width: 768px) {
  #breast-augmentation .aug-presurgery__img-container {
    padding-top: var(--emu-common-spacing-none);
    padding-right: 15px;
    padding-bottom: var(--emu-common-spacing-none);
    padding-left: 15px;
  }
}

#breast-augmentation .aug-presurgery__img-container img {
  width: 100%;
  position: relative;
  left: -8px;
}

@media (min-width: 768px) {
  #breast-augmentation .aug-presurgery__img-container img {
    left: -30px;
  }
}

@media (min-width: 1025px) {
  #breast-augmentation .aug-presurgery__img-container img {
    left: -65px;
  }
}

@media (min-width: 1300px) {
  #breast-augmentation .aug-presurgery__img-container img {
    position: unset;
  }
}

#breast-augmentation .aug-implant-options__container {
  padding-top: 65px;
  padding-bottom: 65px;
}

@media (min-width: 576px) {
  #breast-augmentation .aug-implant-options__container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 768px) {
  #breast-augmentation .aug-implant-options__container {
    padding-top: 5%;
    padding-bottom: var(--emu-common-spacing-none);
  }
}

@media (min-width: 992px) {
  #breast-augmentation .aug-implant-options__container {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#breast-augmentation .aug-implant-options__container-inner {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  #breast-augmentation .aug-implant-options__container-inner {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#breast-augmentation .aug-implant-options .title-text__title {
  letter-spacing: -.5px;
}

#breast-augmentation .aug-implant-options__text--top .title-text__txt {
  max-width: 100%;
  padding-top: 6%;
  padding-bottom: 10%;
}

@media (min-width: 768px) {
  #breast-augmentation .aug-implant-options__text--top .title-text__txt {
    padding-top: 1%;
    padding-bottom: 7%;
  }
}

#breast-augmentation .aug-implant-options__text--bottom .title-text__title {
  padding-top: 25%;
}

@media (min-width: 768px) {
  #breast-augmentation .aug-implant-options__text--bottom .title-text__title {
    padding-top: 5%;
  }
}

#breast-augmentation .aug-implant-options__text--bottom .title-text__txt {
  padding-top: 6%;
  padding-bottom: var(--emu-common-spacing-none);
  max-width: 99%;
}

@media (min-width: 768px) {
  #breast-augmentation .aug-implant-options__text--bottom .title-text__txt {
    margin-bottom: var(--emu-common-spacing-none);
    padding-top: 1%;
    padding-bottom: var(--emu-common-spacing-medium);
    max-width: 100%;
  }
}

#breast-augmentation .aug-implant-options .carousel {
  margin-bottom: var(--emu-common-spacing-none);
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
}

#breast-augmentation .aug-implant-options__carousel {
  border-width: var(--emu-common-border-width-none);
}

#breast-augmentation .aug-implant-options__carousel-container {
  padding-left: 45px;
  padding-right: 45px;
}

#breast-augmentation .aug-implant-options__carousel-item {
  min-height: 390px;
  padding-top: 15px;
}

@media (min-width: 768px) {
  #breast-augmentation .aug-implant-options__carousel-item {
    min-height: var(--emu-common-sizing-none);
    padding-top: var(--emu-common-spacing-none);
  }
}

#breast-augmentation .aug-implant-options__carousel img {
  width: 230px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

@media (min-width: 768px) {
  #breast-augmentation .aug-implant-options__carousel img {
    width: auto;
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  #breast-augmentation .aug-implant-options__carousel img {
    margin: var(--emu-common-spacing-none);
  }
}

#breast-augmentation .aug-implant-options__carousel .cmp-text {
  padding-top: 8%;
  font-size: 22px;
  line-height: 1.1;
}

@media (min-width: 768px) {
  #breast-augmentation .aug-implant-options__carousel .cmp-text {
    padding-right: 40px;
  }

  #breast-augmentation .aug-implant-options__carousel .cmp-text p {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

#breast-augmentation .aug-implant-options__carousel .tns-nav {
  gap: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
  width: 100%;
  position: absolute;
  top: auto;
  bottom: -25px;
}

#breast-augmentation .aug-implant-options__carousel .tns-nav > button {
  margin-right: var(--emu-component-containers-carousel-indicators-dots-gap-narrow);
  margin-bottom: 5px;
}

@media (min-width: 768px) {
  #breast-augmentation .aug-implant-options__carousel .emu-carousel__content {
    display: flex;
  }

  #breast-augmentation .aug-implant-options__carousel .emu-carousel__item {
    width: 33.33%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

#breast-augmentation .aug-implant-options__bottom-wave {
  background-color: var(--emu-common-colors-white);
  z-index: var(--emu-common-other-z-index-behind);
  margin-top: -8px;
  position: relative;
}

@media (min-width: 1280px) {
  #breast-augmentation .aug-journey__wave-top-desktop {
    margin-top: -6px;
    margin-bottom: -3px;
  }
}

#breast-augmentation .aug-journey__wave-mobile-bottom {
  margin-bottom: -6px;
}

#breast-augmentation .aug-journey__container {
  padding-top: 45px;
  padding-bottom: 45px;
}

@media (min-width: 768px) {
  #breast-augmentation .aug-journey__container {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
  }
}

#breast-augmentation .aug-journey__container-inner {
  padding-left: 15px;
  padding-right: 15px;
}

#breast-augmentation .aug-journey .title-text__title {
  padding-top: var(--emu-common-spacing-none);
}

@media (min-width: 576px) {
  #breast-augmentation .aug-journey .title-text__title {
    padding-top: 5%;
  }
}

@media (min-width: 992px) {
  #breast-augmentation .aug-journey .title-text__title {
    padding-top: 19%;
  }
}

@media (min-width: 1025px) {
  #breast-augmentation .aug-journey .title-text__title {
    font-size: var(--emu-common-font-sizes-wide-xl);
  }

  #breast-augmentation .aug-journey .title-text__title .emphasis {
    font-size: var(--emu-common-font-sizes-wide-xxl);
    line-height: var(--emu-common-line-heights-wide-xxl);
    padding-bottom: var(--emu-common-spacing-xs);
  }
}

#breast-augmentation .aug-journey .title-text__txt {
  letter-spacing: .03em;
  max-width: 100%;
  padding-top: 3%;
}

@media (min-width: 768px) {
  #breast-augmentation .aug-journey .title-text__txt {
    letter-spacing: .07em;
    width: 80%;
    font-size: 28px;
  }
}

@media (min-width: 1025px) {
  #breast-augmentation .aug-journey .title-text__txt {
    line-height: 1.1;
  }
}

@media (min-width: 768px) {
  #breast-augmentation .aug-journey .fancy-img-with-text {
    min-height: 530px;
  }
}

@media (min-width: 1200px) {
  #breast-augmentation .aug-journey .fancy-img-with-text {
    min-height: 720px;
  }
}

#breast-augmentation .aug-journey .fancy-img-with-text__img-disclaimer {
  letter-spacing: .03em;
  padding-top: 15px;
  font-size: 12px;
}

@media (min-width: 1025px) {
  #breast-augmentation .aug-journey .fancy-img-with-text__img-disclaimer {
    letter-spacing: normal;
    line-height: 1.1;
  }
}

#breast-augmentation .aug-journey .fancy-img-with-text__img img {
  padding: var(--emu-common-spacing-none);
  margin-top: 35px;
  margin-bottom: 5px;
  top: 0;
}

@media (min-width: 768px) {
  #breast-augmentation .aug-journey .fancy-img-with-text__img img {
    margin: var(--emu-common-spacing-none);
  }
}

#breast-augmentation .aug-3d__top-wave {
  padding-top: 18%;
}

@media (min-width: 768px) {
  #breast-augmentation .aug-3d__top-wave {
    padding-top: 4%;
  }
}

#breast-augmentation .aug-3d__container {
  margin-top: -30px;
  padding-top: 25px;
  padding-bottom: 25px;
}

@media (min-width: 768px) {
  #breast-augmentation .aug-3d__container {
    padding-bottom: 8%;
    padding-top: var(--emu-common-spacing-none);
    margin-top: -62px;
  }
}

@media (min-width: 1300px) {
  #breast-augmentation .aug-3d__container {
    margin-top: -5%;
  }
}

#breast-augmentation .aug-3d__container-inner {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  #breast-augmentation .aug-3d__container-inner {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#breast-augmentation .aug-3d .fancy-img-with-text__img img {
  padding: var(--emu-common-spacing-none);
  border-radius: 5%;
  width: 100%;
  position: static;
  overflow: hidden;
}

@media (min-width: 768px) {
  #breast-augmentation .aug-3d .fancy-img-with-text > .container {
    min-width: 50%;
    max-width: none;
    padding-left: 15px;
    padding-right: 15px;
  }

  #breast-augmentation .aug-3d .fancy-img-with-text .button {
    line-height: 14px;
  }
}

@media (min-width: 1025px) {
  #breast-augmentation .aug-3d .fancy-img-with-text .button {
    line-height: 16px;
  }
}

#breast-augmentation .aug-3d .title-text__title {
  text-align: center;
  letter-spacing: -.4px;
  padding-top: 8%;
  padding-bottom: 5%;
}

@media (min-width: 768px) {
  #breast-augmentation .aug-3d .title-text__title {
    text-align: left;
    letter-spacing: -.3px;
  }
}

#breast-augmentation .aug-3d .title-text__title i {
  font-style: normal;
}

#breast-augmentation .aug-3d .title-text__title sup {
  text-shadow: 1px 1px;
  font-size: 45%;
  top: -3px;
  left: -2px;
}

@media (min-width: 1200px) {
  #breast-augmentation .aug-3d .title-text__title .emphasis {
    display: block;
  }
}

#breast-augmentation .aug-3d .title-text__title .emphasis br:last-of-type {
  display: none;
}

@media (min-width: 768px) {
  #breast-augmentation .aug-3d .title-text__title .emphasis br:last-of-type {
    display: block;
  }
}

#breast-augmentation .aug-3d .title-text__cta {
  width: 85%;
  margin: 55px auto 35px;
}

@media (min-width: 768px) {
  #breast-augmentation .aug-3d .title-text__cta {
    margin: var(--emu-common-spacing-none);
    line-height: inherit;
    width: -moz-fit-content;
    width: fit-content;
    display: inline;
  }

  #breast-augmentation .aug-3d .title-text__cta.mobile-view {
    display: none;
  }
}

@media (max-width: 767px) {
  #breast-augmentation .aug-3d .title-text__cta.tablet-desktop-view {
    display: none;
  }
}

@media (min-width: 1025px) {
  #breast-augmentation .hero__img img {
    object-fit: unset;
  }
}

.cq-Editable-dom--container .aug-3d .title-text__cta {
  display: block !important;
}

[lang="fr_ca"] #breast-augmentation .aug-desc .title-text.aug-desc__intro .title-text__txt {
  max-width: 94%;
}

@media (min-width: 768px) {
  [lang="fr_ca"] #breast-augmentation .aug-desc .title-text.aug-desc__intro .title-text__txt {
    max-width: 95%;
  }
}

[lang="fr_ca"] #breast-augmentation .fancy-img-with-text__img-disclaimer {
  line-height: var(--emu-common-line-heights-narrow-small);
}

@media (min-width: 768px) {
  [lang="fr_ca"] #breast-augmentation .fancy-img-with-text .title-text__txt {
    max-width: 100%;
  }

  [lang="fr_ca"] #breast-augmentation .fancy-img-with-text .title-text__disclaimer {
    max-width: 100%;
    padding-top: 24px;
    line-height: 20px;
  }
}

@media (min-width: 1025px) {
  [lang="fr_ca"] #breast-augmentation .fancy-img-with-text .title-text__disclaimer {
    line-height: var(--emu-common-line-heights-wide-body);
    padding-top: 48px;
  }
}

[lang="fr_ca"] #breast-augmentation .hero__content-inner {
  max-width: 95%;
}

@media (min-width: 1025px) {
  [lang="fr_ca"] #breast-augmentation .hero__content-inner {
    width: 55%;
  }
}

[lang="fr_ca"] #breast-augmentation .hero__title {
  letter-spacing: -.5px;
}

@media (min-width: 768px) {
  [lang="fr_ca"] #breast-augmentation .hero__title h1 {
    letter-spacing: -.7px;
    font-size: 45px;
    line-height: 40px;
  }
}

@media (min-width: 1025px) {
  [lang="fr_ca"] #breast-augmentation .hero__title h1 {
    font-size: var(--emu-common-font-sizes-wide-xxxl);
    line-height: var(--emu-common-line-heights-wide-xxxl);
  }

  [lang="fr_ca"] #breast-augmentation .hero__title h1 i {
    letter-spacing: .2px;
  }
}

@media (min-width: 768px) {
  [lang="fr_ca"] #breast-augmentation .hero__para.cmp-text {
    max-width: 97%;
    font-size: 21px;
    line-height: 20px;
  }
}

@media (min-width: 1025px) {
  [lang="fr_ca"] #breast-augmentation .hero__para.cmp-text {
    font-size: var(--emu-common-font-sizes-wide-large);
    line-height: 30px;
  }
}

@media (min-width: 768px) {
  [lang="fr_ca"] #breast-augmentation .hero__person-label.hero__para.cmp-text {
    padding-top: 10px;
    line-height: 20px;
  }
}

@media (min-width: 1025px) {
  [lang="fr_ca"] #breast-augmentation .hero__person-label.hero__para.cmp-text {
    padding-top: 20px;
    line-height: 30px;
  }
}

[lang="fr_ca"] #breast-augmentation .hero__disclaimer.hero__para.cmp-text {
  font-size: 11px;
}

@media (min-width: 768px) {
  [lang="fr_ca"] #breast-augmentation .hero__disclaimer.hero__para.cmp-text {
    line-height: 20px;
  }
}

@media (min-width: 1025px) {
  [lang="fr_ca"] #breast-augmentation .hero__disclaimer.hero__para.cmp-text {
    line-height: 14.5px;
  }
}

[lang="fr_ca"] #breast-augmentation .img-with-text__content-container {
  min-height: 680px;
}

@media (min-width: 768px) {
  [lang="fr_ca"] #breast-augmentation .img-with-text__content-container {
    min-height: initial;
  }
}

[lang="fr_ca"] #breast-augmentation .img-with-text__title {
  margin-bottom: var(--emu-semantic-spacing-none);
  max-width: 100%;
}

@media (min-width: 768px) {
  [lang="fr_ca"] #breast-augmentation .img-with-text__title {
    margin-bottom: var(--emu-common-spacing-small);
  }
}

[lang="fr_ca"] #breast-augmentation .img-with-text__title h2 i {
  letter-spacing: -.6px;
}

[lang="fr_ca"] #breast-augmentation .img-with-text__title h2 i sup {
  font-size: 40%;
  display: inline;
}

[lang="fr_ca"] #breast-augmentation .img-with-text__text {
  max-width: 100%;
}

[lang="fr_ca"] #breast-augmentation .img-with-text__text sup {
  font-size: 55%;
  top: 2px;
}

@media (min-width: 1025px) {
  [lang="fr_ca"] #breast-augmentation .img-with-text__text-container {
    padding-right: 15px;
  }
}

@media (min-width: 1200px) {
  [lang="fr_ca"] #breast-augmentation .img-with-text__img-container {
    max-width: 360px;
  }
}

@media (min-width: 1201px) {
  [lang="fr_ca"] #breast-augmentation .img-with-text__img-container {
    max-width: 420px;
    position: absolute;
    right: 15px;
  }
}

@media (min-width: 1440px) {
  [lang="fr_ca"] #breast-augmentation .img-with-text__img-container {
    position: unset;
  }
}

[lang="fr_ca"] #breast-augmentation .img-with-text__cta {
  text-align: center;
}

[lang="fr_ca"] #breast-augmentation .img-with-text__cta sup {
  font-size: 55%;
  display: inline;
}

@media (min-width: 1025px) {
  [lang="fr_ca"] #breast-augmentation .before-after__intro .title-text__title {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  [lang="fr_ca"] #breast-augmentation .before-after__intro .title-text__txt {
    max-width: 75%;
  }

  [lang="fr_ca"] #breast-augmentation .before-after__disclaimer {
    margin-bottom: var(--emu-common-sizing-small);
  }
}

@media (min-width: 1025px) {
  [lang="fr_ca"] #breast-augmentation .before-after__disclaimer {
    margin-bottom: var(--emu-common-sizing-xs);
  }
}

@media (min-width: 1440px) {
  [lang="fr_ca"] #breast-augmentation .aug-journey__wave-bottom-desktop {
    margin-top: -2px;
    margin-bottom: -6px;
  }
}

@media (min-width: 1025px) {
  [lang="fr_ca"] #breast-augmentation .aug-journey .title-text__title h2 i {
    font-size: var(--emu-common-font-sizes-wide-xxl);
  }
}

@media (min-width: 768px) {
  [lang="fr_ca"] #breast-augmentation .aug-journey .fancy-img-with-text {
    max-height: 100%;
  }

  [lang="fr_ca"] #breast-augmentation .aug-journey .fancy-img-with-text__img-disclaimer {
    line-height: var(--emu-common-line-heights-narrow-body);
    max-width: 100%;
  }
}

@media (min-width: 1025px) {
  [lang="fr_ca"] #breast-augmentation .aug-journey .fancy-img-with-text__img-disclaimer {
    line-height: 13.2px;
  }

  [lang="fr_ca"] #breast-augmentation .aug-3d .fancy-img-with-text {
    max-height: -moz-fit-content;
    max-height: fit-content;
  }
}

[lang="fr_ca"] #breast-augmentation .aug-3d .title-text__title {
  letter-spacing: 0;
}

@media (min-width: 1025px) {
  [lang="fr_ca"] #breast-augmentation .aug-3d .title-text__title {
    letter-spacing: -.5px;
  }
}

[lang="fr_ca"] #breast-augmentation .aug-3d .title-text__title h2 u {
  text-decoration: none;
}

@media (min-width: 768px) {
  [lang="fr_ca"] #breast-augmentation .aug-3d .title-text__title h2 u {
    letter-spacing: -.5px;
  }
}

[lang="fr_ca"] #breast-augmentation .aug-3d .title-text__title h2 sup {
  font-family: var(--emu-common-font-families-graphik);
  text-shadow: none;
  font-size: 55%;
  top: 2px;
  left: -.5px;
}

#breast-reconstruction .img-with-text {
  position: relative;
  overflow: hidden;
}

#breast-reconstruction .img-with-text__top-wave {
  background-color: var(--emu-common-colors-white);
}

@media (min-width: 768px) {
  #breast-reconstruction .img-with-text__top-wave {
    background-color: var(--emu-common-colors-transparent);
  }
}

#breast-reconstruction .img-with-text__img-container {
  position: relative;
  right: -9px;
}

@media (min-width: 768px) {
  #breast-reconstruction .img-with-text__img-container {
    position: static;
  }
}

#breast-reconstruction .img-with-text__img-container img {
  width: 100%;
  min-width: 440px;
}

@media (min-width: 768px) {
  #breast-reconstruction .img-with-text__img-container img {
    min-width: 320px;
    position: absolute;
    bottom: 0;
    right: -27px;
  }
}

@media (min-width: 1025px) {
  #breast-reconstruction .img-with-text__img-container img {
    min-width: var(--emu-common-sizing-none);
    right: 11px;
  }
}

@media (min-width: 1200px) {
  #breast-reconstruction .img-with-text__img-container img {
    width: 100%;
    bottom: -45px;
  }
}

@media (min-width: 1268px) {
  #breast-reconstruction .img-with-text__img-container img {
    min-width: 440px;
  }
}

#breast-reconstruction .img-with-text__content-container {
  background-color: var(--emu-common-colors-brand-violet-300);
  margin-top: -2px;
  padding-top: 35px;
  padding-bottom: 25px;
  position: relative;
}

@media (min-width: 768px) {
  #breast-reconstruction .img-with-text__content-container {
    margin-left: -15px;
    margin-right: -15px;
    margin-top: var(--emu-common-spacing-none);
    padding-bottom: 35px;
  }
}

@media (min-width: 1200px) {
  #breast-reconstruction .img-with-text__content-container {
    padding-bottom: 60px;
  }
}

#breast-reconstruction .img-with-text__content-container-inner {
  flex-direction: column-reverse;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
}

@media (min-width: 768px) {
  #breast-reconstruction .img-with-text__content-container-inner {
    flex-direction: row;
  }
}

@media (min-width: 992px) {
  #breast-reconstruction .img-with-text__content-container-inner {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 768px) {
  #breast-reconstruction .img-with-text__content-container-inner > .container {
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
  }

  #breast-reconstruction .img-with-text__content-container-inner > .container:first-child {
    flex: 0 0 33.333%;
  }
}

#breast-reconstruction .img-with-text__content-container-inner > .container:last-child {
  max-width: 680px;
}

@media (min-width: 768px) {
  #breast-reconstruction .img-with-text__content-container-inner > .container:last-child {
    flex: 0 0 66.667%;
  }
}

#breast-reconstruction .img-with-text__title {
  font-size: var(--emu-common-font-sizes-narrow-xxl);
  line-height: var(--emu-common-line-heights-narrow-xxl);
  margin-bottom: var(--emu-common-spacing-medium);
  letter-spacing: -.4px;
}

@media (min-width: 768px) {
  #breast-reconstruction .img-with-text__title {
    font-size: 32px;
    line-height: 1.1;
  }
}

@media (min-width: 1025px) {
  #breast-reconstruction .img-with-text__title {
    font-size: var(--emu-common-font-sizes-wide-xxl);
    line-height: var(--emu-common-line-heights-wide-xxl);
  }
}

#breast-reconstruction .img-with-text__title h1, #breast-reconstruction .img-with-text__title h2, #breast-reconstruction .img-with-text__title h3 {
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

#breast-reconstruction .img-with-text__text {
  margin-bottom: var(--emu-common-spacing-medium);
  letter-spacing: .05em;
  max-width: 95%;
}

@media (min-width: 768px) {
  #breast-reconstruction .img-with-text__text {
    letter-spacing: .07em;
    max-width: 100%;
  }
}

#breast-reconstruction .img-with-text__text p {
  margin-bottom: var(--emu-common-spacing-none);
}

#breast-reconstruction .img-with-text__text sup {
  margin-right: 3px;
  font-size: 45%;
}

#breast-reconstruction .img-with-text__cta.emu-button-v2.emu-button-v2.emu-button-v2 {
  font-size: var(--emu-common-font-sizes-narrow-small);
  letter-spacing: .3em;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 5px;
  margin-bottom: 25px;
  padding: 15px 30px;
}

@media (min-width: 768px) {
  #breast-reconstruction .img-with-text__cta.emu-button-v2.emu-button-v2.emu-button-v2 {
    margin-top: 15px;
    margin-bottom: 35px;
    padding: 10px 15px;
    line-height: 14px;
  }
}

@media (min-width: 1025px) {
  #breast-reconstruction .img-with-text__cta.emu-button-v2.emu-button-v2.emu-button-v2 {
    padding: 15px 30px;
    font-size: 14px;
    line-height: 16px;
  }
}

#breast-reconstruction .img-with-text__cta.emu-button-v2.emu-button-v2.emu-button-v2 sup {
  font-size: 50%;
}

@media (min-width: 1025px) {
  #breast-reconstruction .img-with-text__cta.emu-button-v2.emu-button-v2.emu-button-v2 sup {
    margin-right: 4.5px;
  }
}

#breast-reconstruction .recon-desc {
  padding-top: 35px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-desc {
    padding-top: 50px;
  }
}

#breast-reconstruction .recon-desc .title-text__txt {
  padding-top: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-none);
  letter-spacing: .05em;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-desc .title-text__txt {
    font-size: var(--emu-common-font-sizes-wide-body);
    line-height: var(--emu-common-line-heights-wide-body);
    letter-spacing: .05em;
    max-width: 100%;
  }
}

#breast-reconstruction .recon-desc .title-text__txt a {
  font-family: var(--emu-common-font-families-graphik);
  font-size: 16px;
  line-height: 1.1;
}

#breast-reconstruction .recon-desc .title-text__txt sup {
  font-size: 45%;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-desc .title-text__title {
    letter-spacing: -.6px;
    font-size: 32px;
    line-height: 1.1;
  }
}

@media (min-width: 1025px) {
  #breast-reconstruction .recon-desc .title-text__title {
    font-size: var(--emu-common-font-sizes-wide-xxl);
    line-height: var(--emu-common-line-heights-wide-xxl);
  }
}

#breast-reconstruction .recon-desc .fancy-img-with-text {
  flex-direction: column;
  padding-top: 15px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-desc .fancy-img-with-text {
    padding-top: var(--emu-common-spacing-none);
    flex-direction: row;
    max-height: none;
  }

  #breast-reconstruction .recon-desc .fancy-img-with-text > .container {
    max-width: 50%;
  }

  #breast-reconstruction .recon-desc .fancy-img-with-text > .container:first-child {
    margin-top: 55px;
  }
}

#breast-reconstruction .recon-desc .fancy-img-with-text .title-text__title {
  font-size: var(--emu-common-font-sizes-narrow-xl);
  padding-top: var(--emu-common-spacing-none);
  max-width: 95%;
  margin-top: 5px;
  line-height: 1.15;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-desc .fancy-img-with-text .title-text__title {
    margin-top: var(--emu-common-spacing-none);
    font-size: 42px;
    line-height: 1.1;
  }
}

@media (min-width: 1025px) {
  #breast-reconstruction .recon-desc .fancy-img-with-text .title-text__title {
    line-height: var(--emu-common-line-heights-wide-xxl);
  }
}

#breast-reconstruction .recon-desc .fancy-img-with-text .title-text__title h1, #breast-reconstruction .recon-desc .fancy-img-with-text .title-text__title h2, #breast-reconstruction .recon-desc .fancy-img-with-text .title-text__title h3 {
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

#breast-reconstruction .recon-desc .fancy-img-with-text .title-text__cta {
  width: -moz-fit-content;
  width: fit-content;
  margin: 25px auto;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 12px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-desc .fancy-img-with-text .title-text__cta {
    margin-top: 25px;
    margin-bottom: 25px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1025px) {
  #breast-reconstruction .recon-desc .fancy-img-with-text .title-text__cta {
    padding-left: 30px;
    padding-right: 30px;
    font-size: 14px;
  }
}

#breast-reconstruction .recon-desc .fancy-img-with-text .title-text__cta:hover, #breast-reconstruction .recon-desc .fancy-img-with-text .title-text__cta:focus, #breast-reconstruction .recon-desc .fancy-img-with-text .title-text__cta:active {
  color: var(--emu-common-colors-white);
}

#breast-reconstruction .recon-desc .fancy-img-with-text__img-container img {
  padding-top: 5px;
  padding-bottom: 45px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-desc .fancy-img-with-text__img-container img {
    padding: var(--emu-common-spacing-none);
    top: auto;
  }
}

@media (min-width: 1200px) {
  #breast-reconstruction .recon-desc .fancy-img-with-text__img-container img {
    position: relative;
    bottom: -20px;
  }
}

#breast-reconstruction .recon-coverage__top-wave {
  margin-top: -20px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-coverage__top-wave {
    margin-top: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1200px) {
  #breast-reconstruction .recon-coverage__top-wave {
    margin-top: -90px;
  }
}

#breast-reconstruction .recon-coverage__container {
  margin-top: -2px;
  padding-top: 35px;
  padding-bottom: 35px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-coverage__container {
    margin-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1200px) {
  #breast-reconstruction .recon-coverage__container {
    padding-top: 65px;
  }
}

#breast-reconstruction .recon-coverage .title-text {
  padding-right: 15px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-coverage .title-text {
    padding-left: 15px;
  }
}

@media (min-width: 1200px) {
  #breast-reconstruction .recon-coverage .title-text {
    padding-left: var(--emu-common-spacing-none);
  }
}

#breast-reconstruction .recon-coverage .title-text__title {
  letter-spacing: 0;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-coverage .title-text__title {
    letter-spacing: -.2px;
    line-height: 1.1;
  }
}

@media (min-width: 1025px) {
  #breast-reconstruction .recon-coverage .title-text__title {
    letter-spacing: -.7px;
    line-height: 56px;
  }
}

#breast-reconstruction .recon-coverage .title-text__txt {
  padding: var(--emu-common-spacing-none);
  letter-spacing: .05em;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-coverage .title-text__txt {
    font-size: var(--emu-common-font-sizes-wide-body);
    max-width: 100%;
  }
}

#breast-reconstruction .recon-coverage__initial {
  margin-top: 35px;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-coverage__initial {
    margin-top: 55px;
    margin-bottom: 55px;
  }
}

#breast-reconstruction .recon-coverage__initial .title-text__title {
  max-width: 80%;
}

#breast-reconstruction .recon-coverage__timing {
  margin-top: 35px;
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-coverage__timing {
    margin-top: 75px;
  }
}

#breast-reconstruction .recon-coverage__timing .title-text__title {
  text-align: left;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-coverage__timing .title-text__title {
    text-align: center;
  }
}

#breast-reconstruction .recon-coverage__timing .title-text__txt {
  max-width: 100%;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-coverage__timing .title-text__txt {
    text-align: center;
    max-width: 69%;
    margin-left: auto;
    margin-right: auto;
  }
}

#breast-reconstruction .recon-coverage__time-diff-container {
  margin-top: 35px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-coverage__time-diff-container {
    margin-top: 55px;
  }
}

@media (min-width: 1200px) {
  #breast-reconstruction .recon-coverage__time-diff-container {
    max-width: 1040px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-coverage__time-diff-container-inner {
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
  }

  #breast-reconstruction .recon-coverage__time-diff-container-inner > .container {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

#breast-reconstruction .recon-coverage__time-diff-item {
  flex-direction: row-reverse;
  margin-top: 10px;
  display: flex;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-coverage__time-diff-item {
    margin-top: var(--emu-common-spacing-none);
    flex-direction: row;
  }
}

#breast-reconstruction .recon-coverage__time-diff-item > .image {
  flex: 0 0 20%;
  max-width: 20%;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-coverage__time-diff-item > .image {
    margin-right: var(--emu-common-spacing-small);
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media (min-width: 1025px) {
  #breast-reconstruction .recon-coverage__time-diff-item > .image {
    margin-right: var(--emu-common-spacing-none);
  }
}

#breast-reconstruction .recon-coverage__time-diff-item > .text {
  flex: 0 0 80%;
  max-width: 80%;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-coverage__time-diff-item > .text {
    flex: 0 0 75%;
    max-width: 75%;
  }
}

#breast-reconstruction .recon-coverage__time-diff-item h3 {
  font-family: var(--emu-common-font-families-roboto);
  font-size: var(--emu-common-font-sizes-narrow-large);
  line-height: var(--emu-common-line-heights-narrow-xl);
  letter-spacing: .13em;
  font-weight: var(--emu-common-font-weight-bold);
}

#breast-reconstruction .recon-coverage__time-diff-item p {
  letter-spacing: .05em;
  width: 360px;
  padding-top: 12px;
  padding-right: 30px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-coverage__time-diff-item p {
    letter-spacing: .07em;
    padding: var(--emu-semantic-border-radius-none);
    width: 100%;
  }
}

#breast-reconstruction .recon-coverage__bottom-wave {
  background: linear-gradient(180deg, var(--emu-common-colors-brand-pink-300) 0%, var(--emu-common-colors-brand-pink-300) 5%, var(--emu-common-colors-white) 30%, var(--emu-common-colors-white) 100%);
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-coverage__bottom-wave {
    background: var(--emu-common-colors-brand-pink-300);
  }
}

#breast-reconstruction .recon-options {
  margin-top: 35px;
  margin-bottom: 35px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-options {
    margin-top: 55px;
    margin-bottom: 55px;
  }
}

@media (min-width: 992px) {
  #breast-reconstruction .recon-options__container-inner {
    max-width: 100%;
  }
}

@media (min-width: 1025px) {
  #breast-reconstruction .recon-options__container-inner {
    max-width: 1040px;
  }
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-options__top-sec {
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
  }

  #breast-reconstruction .recon-options__top-sec > .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  #breast-reconstruction .recon-options__top-sec > .container:first-child {
    flex: 0 0 41.667%;
  }

  #breast-reconstruction .recon-options__top-sec > .container:last-child {
    flex: 0 0 58.333%;
  }
}

#breast-reconstruction .recon-options .title-text__title {
  font-size: var(--emu-common-font-sizes-narrow-xl);
  margin-bottom: var(--emu-common-spacing-medium);
  letter-spacing: -.5px;
  max-width: 65%;
  line-height: 1.15;
}

@media (min-width: 1025px) {
  #breast-reconstruction .recon-options .title-text__title {
    font-size: 41px;
    line-height: 48px;
  }
}

#breast-reconstruction .recon-options .title-text__title b {
  font-size: var(--emu-common-font-sizes-narrow-xxl);
  line-height: var(--emu-common-line-heights-narrow-xxl);
}

@media (min-width: 1025px) {
  #breast-reconstruction .recon-options .title-text__title b {
    font-size: var(--emu-common-font-sizes-wide-xxl);
    line-height: var(--emu-common-line-heights-wide-xxl);
  }
}

#breast-reconstruction .recon-options .title-text__title i {
  font-size: var(--emu-common-font-sizes-narrow-xl);
  line-height: 36.8px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-options .title-text__title i {
    font-size: 41px;
    line-height: 48px;
  }

  #breast-reconstruction .recon-options .title-text__title {
    max-width: 100%;
    font-size: 41px;
    line-height: 48px;
  }
}

#breast-reconstruction .recon-options .title-text__title h1, #breast-reconstruction .recon-options .title-text__title h2, #breast-reconstruction .recon-options .title-text__title h3, #breast-reconstruction .recon-options .title-text__title h4 {
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

#breast-reconstruction .recon-options .title-text__txt {
  letter-spacing: .05em;
  padding: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-options .title-text__txt {
    letter-spacing: .07em;
    font-size: var(--emu-common-font-sizes-wide-body);
    line-height: var(--emu-common-line-heights-wide-body);
    max-width: 85%;
  }
}

#breast-reconstruction .recon-options__imgs-container {
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

#breast-reconstruction .recon-options__imgs-container > .image {
  width: 50%;
  padding-left: 15px;
  padding-right: 15px;
}

#breast-reconstruction .recon-options__bottom-sec {
  margin-top: 25px;
  margin-bottom: 51px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-options__bottom-sec {
    margin-top: 55px;
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
  }

  #breast-reconstruction .recon-options__bottom-sec > .container {
    flex: 0 0 33.333%;
    max-width: 33.333%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

#breast-reconstruction .recon-options__card-content h3 {
  font-family: var(--emu-common-font-families-roboto);
  letter-spacing: .13em;
  font-size: var(--emu-common-font-sizes-narrow-medium);
  line-height: var(--emu-common-line-heights-narrow-medium);
  font-weight: var(--emu-common-font-weight-bold);
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-options__card-content h3 {
    margin-bottom: var(--emu-common-spacing-small);
  }
}

#breast-reconstruction .recon-options__card-content h3 .emphasis {
  color: var(--emu-common-colors-brand-blue-300);
  font-size: 16px;
  line-height: 1.1;
}

#breast-reconstruction .recon-options__card-content p {
  letter-spacing: .05em;
  max-width: 95%;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-options__card-content p {
    letter-spacing: .07em;
  }
}

#breast-reconstruction .recon-with-implants__container {
  padding-top: 55px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-with-implants__container {
    padding-top: 85px;
  }
}

@media (min-width: 992px) {
  #breast-reconstruction .recon-with-implants__container-inner {
    padding: var(--emu-common-spacing-none);
    max-width: none;
  }
}

#breast-reconstruction .recon-with-implants__intro {
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-with-implants__intro {
    margin-bottom: 55px;
  }
}

#breast-reconstruction .recon-with-implants__intro .title-text__title {
  letter-spacing: -.2px;
  max-width: 75%;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-with-implants__intro .title-text__title {
    text-align: center;
    letter-spacing: -.4px;
    max-width: none;
    line-height: 1.1;
  }
}

@media (min-width: 1025px) {
  #breast-reconstruction .recon-with-implants__intro .title-text__title {
    letter-spacing: -.8px;
    line-height: 56px;
  }
}

#breast-reconstruction .recon-with-implants__intro .title-text__txt {
  letter-spacing: .05em;
  padding-top: 20px;
  padding-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-with-implants__intro .title-text__txt {
    letter-spacing: .07em;
    text-align: center;
    font-size: var(--emu-common-font-sizes-wide-body);
    max-width: none;
    line-height: 20px;
  }
}

@media (min-width: 1025px) {
  #breast-reconstruction .recon-with-implants__intro .title-text__txt {
    line-height: 1.1;
  }
}

#breast-reconstruction .recon-with-implants__content {
  padding-bottom: 15px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-with-implants__content {
    padding-bottom: 35px;
  }
}

#breast-reconstruction .recon-with-implants__content-container {
  padding-bottom: 10px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-with-implants__content-container {
    padding-bottom: var(--emu-common-spacing-none);
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    display: flex;
  }
}

@media (min-width: 992px) {
  #breast-reconstruction .recon-with-implants__content-container {
    max-width: 1040px;
  }
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-with-implants__content-container > .text {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

#breast-reconstruction .recon-with-implants__content h3 {
  font-family: var(--emu-common-font-families-roboto);
  font-weight: var(--emu-common-font-weight-bold);
  font-size: var(--emu-common-font-sizes-narrow-medium);
  letter-spacing: .13em;
  margin-bottom: var(--emu-common-spacing-small);
  line-height: 26px;
}

#breast-reconstruction .recon-with-implants__content p {
  font-size: var(--emu-common-font-sizes-narrow-body);
  line-height: var(--emu-common-line-heights-narrow-body);
  letter-spacing: .07em;
}

#breast-reconstruction .recon-with-implants__carousel {
  min-height: 350px;
}

#breast-reconstruction .recon-with-implants__carousel-container {
  padding-top: 15px;
  padding-bottom: 35px;
}

#breast-reconstruction .recon-with-implants__carousel img {
  background: linear-gradient(#f4fbfd 0%, #d8f2f6 100%);
  width: 100%;
  max-width: 90%;
  min-height: 245px;
  margin-left: auto;
  margin-right: auto;
}

#breast-reconstruction .recon-with-implants__carousel-text {
  font-size: var(--emu-common-font-sizes-narrow-body);
  letter-spacing: .03em;
  line-height: 25px;
  font-family: var(--emu-common-font-families-roboto);
  font-weight: var(--emu-common-font-weight-bold);
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
}

#breast-reconstruction .recon-with-implants__carousel-text h1, #breast-reconstruction .recon-with-implants__carousel-text h2, #breast-reconstruction .recon-with-implants__carousel-text h3, #breast-reconstruction .recon-with-implants__carousel-text h4, #breast-reconstruction .recon-with-implants__carousel-text h5 {
  font: inherit;
}

#breast-reconstruction .recon-with-implants__carousel .tns-nav {
  padding-top: 6px;
  padding-bottom: var(--emu-common-spacing-xs);
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
  gap: var(--emu-common-spacing-none);
  width: 100%;
  bottom: -35px;
  left: 0;
  transform: none;
}

#breast-reconstruction .recon-with-implants__carousel .tns-nav > button {
  border-color: var(--emu-common-colors-brand-violet-500);
  margin-left: 5px;
  margin-right: 10px;
}

#breast-reconstruction .recon-with-implants__carousel .tns-nav > button.tns-nav-active {
  background-color: var(--emu-common-colors-brand-violet-500);
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-with-implants__img-txt-container {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    background: linear-gradient(#f4fbfd 0%, #d8f2f6 100%);
  }
}

@media (min-width: 992px) {
  #breast-reconstruction .recon-with-implants__img-txt-container {
    max-width: none;
  }
}

@media (min-width: 1025px) {
  #breast-reconstruction .recon-with-implants__img-txt-container {
    position: relative;
  }
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-with-implants__img-container-inner {
    display: flex;
  }
}

@media (min-width: 992px) {
  #breast-reconstruction .recon-with-implants__img-container-inner {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  #breast-reconstruction .recon-with-implants__img-container-inner {
    max-width: 1140px;
  }
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-with-implants__img-container-inner > .image {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    padding-left: 15px;
    padding-right: 15px;
  }

  #breast-reconstruction .recon-with-implants__img-container img {
    max-height: 200px;
  }
}

@media (min-width: 1025px) {
  #breast-reconstruction .recon-with-implants__img-container img {
    max-height: 330px;
  }
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-with-implants__txt-container {
    background-color: var(--emu-common-colors-brand-pink-300);
    margin-top: -45px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media (min-width: 1200px) {
  #breast-reconstruction .recon-with-implants__txt-container {
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: -75px;
  }
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-with-implants__txt-container-inner {
    display: flex;
  }
}

@media (min-width: 992px) {
  #breast-reconstruction .recon-with-implants__txt-container-inner {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  #breast-reconstruction .recon-with-implants__txt-container-inner {
    max-width: 1140px;
  }
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-with-implants__txt-container-inner > .text {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    padding-left: 15px;
    padding-right: 15px;
  }

  #breast-reconstruction .recon-with-implants__img-caption {
    font-weight: var(--emu-common-font-weight-bold);
    font-size: var(--emu-common-font-sizes-narrow-body);
    letter-spacing: .13em;
    margin-left: 40px;
    line-height: 25px;
  }

  #breast-reconstruction .recon-with-implants__img-caption p {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

#breast-reconstruction .recon-tissue-expanders {
  padding-top: 35px;
  padding-bottom: 15px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-tissue-expanders {
    padding-top: 85px;
    padding-bottom: 50px;
  }
}

#breast-reconstruction .recon-tissue-expanders .title-text__title {
  font-size: var(--emu-common-font-sizes-narrow-xl);
  letter-spacing: -.4px;
  max-width: 80%;
  line-height: 1.15;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-tissue-expanders .title-text__title {
    font-size: var(--emu-common-font-sizes-wide-xxl);
    letter-spacing: -.8px;
    max-width: 100%;
    line-height: 1.1;
  }
}

@media (min-width: 1025px) {
  #breast-reconstruction .recon-tissue-expanders .title-text__title {
    line-height: var(--emu-common-line-heights-wide-xxl);
  }
}

#breast-reconstruction .recon-tissue-expanders .title-text__title sup {
  font-size: 45%;
}

#breast-reconstruction .recon-tissue-expanders .title-text__title h1, #breast-reconstruction .recon-tissue-expanders .title-text__title h2, #breast-reconstruction .recon-tissue-expanders .title-text__title h3, #breast-reconstruction .recon-tissue-expanders .title-text__title h4 {
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

#breast-reconstruction .recon-tissue-expanders .title-text__txt {
  padding: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-tissue-expanders .title-text__txt {
    font-size: var(--emu-common-font-sizes-wide-body);
  }
}

@media (min-width: 576px) {
  #breast-reconstruction .recon-place-options__container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  #breast-reconstruction .recon-place-options__container {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#breast-reconstruction .recon-place-options__container-inner {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-place-options__container-inner {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1200px) {
  #breast-reconstruction .recon-place-options__container-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}

#breast-reconstruction .recon-place-options .title-text__title {
  letter-spacing: -.5px;
}

#breast-reconstruction .recon-place-options__text--top {
  margin-left: -15px;
  margin-right: -15px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-place-options__text--top {
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
  }
}

#breast-reconstruction .recon-place-options__text--top .title-text__title {
  max-width: 85%;
  margin-left: 15px;
  margin-right: 15px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-place-options__text--top .title-text__title {
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
    line-height: 1.1;
  }
}

@media (min-width: 1025px) {
  #breast-reconstruction .recon-place-options__text--top .title-text__title {
    line-height: 56px;
  }
}

#breast-reconstruction .recon-place-options__text--top .title-text__txt {
  max-width: 85%;
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-place-options__text--top .title-text__txt {
    font-size: var(--emu-common-font-sizes-wide-body);
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
    max-width: 95%;
  }
}

#breast-reconstruction .recon-place-options__text--bottom {
  margin-top: 55px;
  margin-bottom: 35px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-place-options__text--bottom {
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-common-spacing-none);
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1200px) {
  #breast-reconstruction .recon-place-options__text--bottom {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#breast-reconstruction .recon-place-options__text--bottom .title-text__title {
  max-width: 95%;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-place-options__text--bottom .title-text__title {
    line-height: 1.1;
  }
}

@media (min-width: 1025px) {
  #breast-reconstruction .recon-place-options__text--bottom .title-text__title {
    line-height: 56px;
  }
}

#breast-reconstruction .recon-place-options__text--bottom .title-text__txt {
  padding-top: 12px;
  padding-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-place-options__text--bottom .title-text__txt {
    padding-top: var(--emu-common-spacing-none);
    font-size: var(--emu-common-font-sizes-wide-body);
  }
}

#breast-reconstruction .recon-place-options .carousel {
  margin-bottom: var(--emu-common-spacing-none);
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
}

#breast-reconstruction .recon-place-options__carousel {
  border-width: var(--emu-common-border-width-none);
}

#breast-reconstruction .recon-place-options__carousel-container {
  margin-top: 36px;
  margin-bottom: 45px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-place-options__carousel-container {
    margin-top: 61px;
    margin-bottom: 60px;
  }
}

@media (min-width: 1200px) {
  #breast-reconstruction .recon-place-options__carousel-container {
    margin-left: -15px;
    margin-right: -15px;
  }
}

#breast-reconstruction .recon-place-options__carousel-item {
  min-height: 350px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-place-options__carousel-item {
    min-height: var(--emu-common-sizing-none);
  }
}

#breast-reconstruction .recon-place-options__carousel img {
  width: auto;
  max-width: 100%;
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

#breast-reconstruction .recon-place-options__carousel .cmp-text {
  font-size: var(--emu-common-font-sizes-narrow-body);
  margin-bottom: var(--emu-common-spacing-none);
  line-height: 22px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-place-options__carousel .cmp-text p {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

#breast-reconstruction .recon-place-options__carousel .tns-nav {
  gap: var(--emu-common-spacing-none);
  padding-top: var(--emu-common-spacing-none);
  width: 100%;
  padding-bottom: 5px;
  position: absolute;
  top: auto;
  bottom: -25px;
}

#breast-reconstruction .recon-place-options__carousel .tns-nav > button {
  border-color: var(--emu-common-colors-brand-violet-500);
  margin-left: 5px;
  margin-right: 10px;
}

#breast-reconstruction .recon-place-options__carousel .tns-nav > button.tns-nav-active {
  border-color: var(--emu-common-colors-brand-violet-500);
  background-color: var(--emu-common-colors-brand-violet-500);
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-place-options__carousel .emu-carousel__content {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

@media (min-width: 1200px) {
  #breast-reconstruction .recon-place-options__carousel .emu-carousel__content {
    padding-left: 15px;
  }
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-place-options__carousel .emu-carousel__item {
    width: 33.33%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

#breast-reconstruction .recon-place-options__bottom-wave {
  background: linear-gradient(180deg, var(--emu-common-colors-brand-pink-300) 0%, var(--emu-common-colors-brand-pink-300) 5%, var(--emu-common-colors-white) 30%, var(--emu-common-colors-white) 100%);
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-place-options__bottom-wave {
    background: none;
  }
}

#breast-reconstruction .recon-journey__container {
  padding-top: 35px;
  padding-bottom: 35px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-journey__container {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
  }
}

#breast-reconstruction .recon-journey__container-inner {
  padding-left: 15px;
  padding-right: 15px;
}

#breast-reconstruction .recon-journey .title-text__title {
  padding-top: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-journey .title-text__title {
    margin-top: 45px;
    line-height: 1.1;
  }
}

@media (min-width: 1025px) {
  #breast-reconstruction .recon-journey .title-text__title {
    font-size: var(--emu-common-font-sizes-wide-xxl);
    line-height: var(--emu-common-line-heights-wide-xxl);
  }
}

#breast-reconstruction .recon-journey .title-text__txt {
  padding-top: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-journey .title-text__txt {
    width: 80%;
    margin-top: 33px;
    font-size: 28px;
    line-height: 1.1;
  }
}

#breast-reconstruction .recon-journey .title-text__person-label {
  font-size: var(--emu-common-font-sizes-narrow-body);
  line-height: var(--emu-common-line-heights-narrow-body);
  letter-spacing: .07em;
  font-family: var(--emu-common-font-families-graphik);
  margin-top: 25px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-journey .title-text__person-label {
    max-width: 80%;
    margin-top: 41px;
    font-size: 28px;
    line-height: 1.1;
  }

  #breast-reconstruction .recon-journey .fancy-img-with-text {
    max-height: none;
  }

  #breast-reconstruction .recon-journey .fancy-img-with-text > .container {
    padding-left: var(--emu-common-spacing-none);
  }
}

#breast-reconstruction .recon-journey .fancy-img-with-text__img {
  max-width: 525px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-journey .fancy-img-with-text__img {
    max-width: none;
  }
}

#breast-reconstruction .recon-journey .fancy-img-with-text__img-disclaimer {
  letter-spacing: .05em;
  font-size: 12px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-journey .fancy-img-with-text__img-disclaimer {
    letter-spacing: .03em;
    line-height: 20px;
  }
}

@media (min-width: 768px) and (min-width: 1025px) {
  #breast-reconstruction .recon-journey .fancy-img-with-text__img-disclaimer {
    letter-spacing: normal;
    line-height: 1.1;
  }
}

#breast-reconstruction .recon-journey .fancy-img-with-text__img img {
  padding: var(--emu-common-spacing-none);
  width: auto;
  max-width: 100%;
  top: 0;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-journey .fancy-img-with-text__img img {
    margin: var(--emu-common-spacing-none);
  }

  #breast-reconstruction .before-after__intro {
    padding-top: 45px;
    padding-bottom: 35px;
  }
}

#breast-reconstruction .before-after__intro .title-text__title {
  max-width: 100%;
}

@media (min-width: 768px) {
  #breast-reconstruction .before-after__intro .title-text__title {
    line-height: 1.1;
  }
}

@media (min-width: 1025px) {
  #breast-reconstruction .before-after__intro .title-text__title {
    line-height: 56px;
  }
}

#breast-reconstruction .before-after__intro .title-text__txt {
  padding: var(--emu-common-spacing-none);
  letter-spacing: .07em;
  max-width: 95%;
  margin-top: 10px;
}

@media (min-width: 768px) {
  #breast-reconstruction .before-after__intro .title-text__txt {
    max-width: 75%;
    margin-top: 25px;
  }

  #breast-reconstruction .before-after .carousel {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

@media (min-width: 992px) {
  #breast-reconstruction .before-after .carousel {
    padding-left: 15px;
    padding-right: 15px;
  }
}

#breast-reconstruction .before-after__carousel {
  margin-bottom: 5px;
}

@media (min-width: 600px) {
  #breast-reconstruction .before-after__carousel {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

@media (min-width: 992px) {
  #breast-reconstruction .before-after__carousel {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media (min-width: 600px) {
  #breast-reconstruction .before-after__carousel-container .tns-controls {
    display: block;
  }
}

#breast-reconstruction .before-after__carousel .emu-carousel__action {
  top: 30%;
}

@media (min-width: 768px) {
  #breast-reconstruction .before-after__carousel .emu-carousel__action {
    top: 38%;
  }
}

@media (min-width: 992px) {
  #breast-reconstruction .before-after__carousel .emu-carousel__action {
    top: 35%;
  }
}

@media (min-width: 1025px) {
  #breast-reconstruction .before-after__carousel .emu-carousel__action {
    top: 43%;
  }
}

@media (min-width: 1200px) {
  #breast-reconstruction .before-after__carousel .emu-carousel__action {
    top: 40%;
  }
}

#breast-reconstruction .before-after__carousel .emu-carousel__action-next {
  right: -30px;
}

@media (min-width: 768px) {
  #breast-reconstruction .before-after__carousel .emu-carousel__action-next {
    right: -24px;
  }
}

@media (min-width: 1025px) {
  #breast-reconstruction .before-after__carousel .emu-carousel__action-next {
    right: -40px;
  }
}

@media (min-width: 1200px) {
  #breast-reconstruction .before-after__carousel .emu-carousel__action-next {
    right: -55px;
  }
}

#breast-reconstruction .before-after__carousel .emu-carousel__action-prev {
  left: -25px;
}

@media (min-width: 768px) {
  #breast-reconstruction .before-after__carousel .emu-carousel__action-prev {
    top: 39%;
  }
}

@media (min-width: 992px) {
  #breast-reconstruction .before-after__carousel .emu-carousel__action-prev {
    top: 37%;
  }
}

@media (min-width: 1025px) {
  #breast-reconstruction .before-after__carousel .emu-carousel__action-prev {
    top: 42%;
    left: -40px;
  }
}

@media (min-width: 1200px) {
  #breast-reconstruction .before-after__carousel .emu-carousel__action-prev {
    top: 40%;
    left: -55px;
  }
}

#breast-reconstruction .before-after__carousel .emu-carousel__action span:before {
  background-image: url("resources/images/carousel-left-arrow-wide.png");
  background-size: cover;
  height: 91px;
}

@media (min-width: 768px) {
  #breast-reconstruction .before-after__carousel .emu-carousel__action span:before {
    background-size: 100% 100%;
    height: 60px;
  }
}

@media (min-width: 1025px) {
  #breast-reconstruction .before-after__carousel .emu-carousel__action span:before {
    height: 91px;
  }
}

#breast-reconstruction .before-after__carousel .tns-nav {
  padding-top: 6px;
  padding-bottom: var(--emu-common-spacing-xs);
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
  gap: var(--emu-common-spacing-none);
  width: 100%;
  bottom: -100px;
  left: 0;
  transform: none;
}

@media (min-width: 600px) {
  #breast-reconstruction .before-after__carousel .tns-nav {
    display: none;
  }
}

#breast-reconstruction .before-after__carousel .tns-nav > button {
  border-color: var(--emu-common-colors-brand-violet-500);
  margin-left: 5px;
  margin-right: 10px;
}

#breast-reconstruction .before-after__carousel .tns-nav > button.tns-nav-active {
  background-color: var(--emu-common-colors-brand-violet-500);
}

@media (min-width: 768px) {
  #breast-reconstruction .before-after__slide-content {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#breast-reconstruction .before-after__slide-content > .container {
  margin: var(--emu-common-spacing-none);
  width: 50%;
  padding: 5px 15px;
}

@media (min-width: 768px) {
  #breast-reconstruction .before-after__slide-content > .container {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
    width: 25%;
  }
}

#breast-reconstruction .before-after__slide-content-disclaimer {
  letter-spacing: .05em;
  padding-top: 22px;
  font-size: 16px;
  line-height: 22px;
}

@media (min-width: 768px) {
  #breast-reconstruction .before-after__slide-content-disclaimer {
    padding-top: 17px;
    padding-left: 15px;
  }
}

#breast-reconstruction .before-after__disclaimer {
  letter-spacing: .05em;
  padding-left: 15px;
  line-height: 22px;
}

@media (min-width: 768px) {
  #breast-reconstruction .before-after__disclaimer {
    margin-bottom: var(--emu-common-spacing-medium);
  }
}

#breast-reconstruction .recon-ext-links__top-wave {
  padding-top: 85px;
}

#breast-reconstruction .recon-ext-links__content-container {
  padding-top: 35px;
  padding-bottom: 25px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-ext-links__content-container {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: 50px;
  }
}

#breast-reconstruction .recon-ext-links .title-text {
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-ext-links .title-text {
    margin-bottom: 50px;
  }
}

#breast-reconstruction .recon-ext-links .title-text__title {
  font-size: var(--emu-common-font-sizes-narrow-xl);
  line-height: var(--emu-common-line-heights-narrow-xxl);
  letter-spacing: -.4px;
  max-width: 95%;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-ext-links .title-text__title {
    font-size: var(--emu-common-font-sizes-wide-xl);
    max-width: 100%;
    line-height: 1.1;
  }
}

@media (min-width: 1025px) {
  #breast-reconstruction .recon-ext-links .title-text__title {
    line-height: var(--emu-common-line-heights-wide-xxl);
    letter-spacing: -.2px;
  }
}

#breast-reconstruction .recon-ext-links .title-text__title h1, #breast-reconstruction .recon-ext-links .title-text__title h2, #breast-reconstruction .recon-ext-links .title-text__title h3, #breast-reconstruction .recon-ext-links .title-text__title h4 {
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

#breast-reconstruction .recon-ext-links .title-text__txt {
  padding: var(--emu-common-spacing-none);
  letter-spacing: .05em;
  margin-bottom: var(--emu-common-spacing-small);
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-ext-links .title-text__txt {
    font-size: var(--emu-common-font-sizes-wide-body);
    line-height: var(--emu-common-line-heights-wide-body);
    letter-spacing: .07em;
    max-width: 88%;
    margin-bottom: 20px;
  }
}

#breast-reconstruction .recon-ext-links .title-text__txt p + p, #breast-reconstruction .recon-ext-links .title-text__txt p + ul {
  margin-top: var(--emu-common-spacing-small);
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-ext-links .title-text__txt p + p, #breast-reconstruction .recon-ext-links .title-text__txt p + ul {
    margin-top: 20px;
  }
}

#breast-reconstruction .recon-ext-links .title-text__cta {
  letter-spacing: .05em;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 25px;
  margin-bottom: 45px;
  font-size: 16px;
  line-height: 22px;
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-ext-links .title-text__cta {
    letter-spacing: .07em;
    margin-bottom: 20px;
    padding: 15px 30px;
    font-size: 14px;
    line-height: 21px;
  }
}

#breast-reconstruction .recon-ext-links .title-text__cta:hover, #breast-reconstruction .recon-ext-links .title-text__cta:focus, #breast-reconstruction .recon-ext-links .title-text__cta:active {
  color: var(--emu-common-colors-white);
}

@media (min-width: 768px) {
  #breast-reconstruction .recon-ext-links .title-text__cta {
    margin-top: 10px;
  }
}

#breast-reconstruction .faq__title {
  letter-spacing: -.4px;
  padding-top: 40px;
  padding-bottom: 20px;
}

@media (min-width: 768px) {
  #breast-reconstruction .faq__title {
    line-height: 1.1;
  }
}

@media (min-width: 1025px) {
  #breast-reconstruction .faq__title {
    line-height: 56px;
  }
}

#breast-reconstruction .faq__title h1, #breast-reconstruction .faq__title h2, #breast-reconstruction .faq__title h3, #breast-reconstruction .faq__title h4 {
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
}

#breast-reconstruction .faq__title .emphasis {
  display: inline;
}

#breast-reconstruction .faq__main {
  padding-top: var(--emu-common-spacing-none);
  margin-top: var(--emu-common-spacing-none);
}

.error__content-wrapper {
  padding-top: 88px;
  padding-bottom: 88px;
}

@media (min-width: 768px) {
  .error__content-wrapper {
    padding-top: 164px;
    padding-bottom: 164px;
  }
}

@media (min-width: 1025px) {
  .error__content-wrapper {
    padding-top: 88px;
    padding-bottom: 88px;
  }
}

.error__content-wrapper-title.cmp-text {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-small);
}

.error__content-wrapper-title h1 {
  letter-spacing: 4.8px;
  font-size: 96px;
  line-height: 96px;
  font-family: var(--emu-common-font-families-roboto-bold);
  font-weight: var(--emu-common-font-weight-bold);
}

@media (min-width: 768px) {
  .error__content-wrapper-title h1 {
    letter-spacing: 8px;
    font-size: 160px;
    line-height: 160px;
  }
}

.error__content-wrapper-pretitle.cmp-text {
  margin-top: var(--emu-common-spacing-large);
  margin-bottom: var(--emu-common-spacing-none);
}

.error__content-wrapper-pretitle.cmp-text h2 {
  letter-spacing: .184px;
  font-size: 18.4px;
  line-height: 32px;
  font-family: var(--emu-common-font-families-roboto-bold);
  font-weight: var(--emu-common-font-weight-medium);
}

.error__content-wrapper-description.cmp-text {
  letter-spacing: .68px;
  font-size: 13.6px;
}

@media (min-width: 768px) {
  .error__content-wrapper-description.cmp-text {
    letter-spacing: .408px;
    line-height: 20px;
  }
}

@media (min-width: 1025px) {
  .error__content-wrapper-description.cmp-text {
    letter-spacing: 0;
    line-height: 14px;
  }
}

@media (min-width: 1200px) {
  .error__content-wrapper-description.cmp-text {
    line-height: 15px;
  }
}

@media (min-width: 768px) {
  #home .home-teaser {
    background: linear-gradient(180deg, var(--emu-common-colors-white) 0%, var(--emu-common-colors-white) 50%, var(--emu-common-colors-brand-blue-200) 100%);
  }
}

@media (min-width: 1200px) {
  #home .home-teaser {
    background: none;
  }
}

#home .home-teaser .content-container {
  padding-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #home .home-teaser .content-container {
    left: unset;
    padding-top: 70px;
  }
}

@media (min-width: 1025px) {
  #home .home-teaser .content-container {
    padding-right: var(--emu-common-spacing-none);
    padding-top: 70px;
  }
}

#home .home-teaser .content {
  text-align: left;
}

@media (min-width: 768px) {
  #home .home-teaser .content {
    text-align: right;
  }
}

#home .home-teaser .title-lockup {
  color: var(--emu-common-colors-brand-blue-500);
}

#home .home-teaser .emu-teaser {
  max-width: var(--emu-semantic-sizing-breakpoints-xxx-large);
  margin-left: auto;
  margin-right: auto;
}

#home .home-teaser .emu-teaser__title {
  letter-spacing: -.5px;
}

@media (min-width: 768px) {
  #home .home-teaser .emu-teaser__title {
    letter-spacing: -.3px;
    margin-left: auto;
  }
}

@media (min-width: 1025px) {
  #home .home-teaser .emu-teaser__title {
    letter-spacing: -.5px;
    margin-left: auto;
  }
}

#home .home-teaser .emu-teaser__title b {
  letter-spacing: -1.3px;
}

@media (min-width: 768px) {
  #home .home-teaser .emu-teaser__title b {
    letter-spacing: -.49px;
  }
}

@media (min-width: 1025px) {
  #home .home-teaser .emu-teaser__title b {
    letter-spacing: -.8px;
  }
}

@media (min-width: 1200px) {
  #home .home-teaser .emu-teaser__title b {
    letter-spacing: -.9px;
  }
}

#home .home-teaser .emu-teaser__title sup {
  font-size: 45%;
  top: -5px;
  left: -3px;
}

@media (min-width: 1440px) {
  #home .home-teaser .emu-teaser__title sup {
    top: -5px;
  }
}

#home .home-teaser .emu-teaser__description {
  padding-top: 15px;
}

@media (min-width: 768px) {
  #home .home-teaser .emu-teaser__description {
    padding-top: 13px;
  }
}

@media (min-width: 992px) {
  #home .home-teaser .emu-teaser__description {
    max-width: 40%;
  }
}

@media (min-width: 1024px) {
  #home .home-teaser .emu-teaser__description {
    max-width: 42%;
  }
}

@media (min-width: 1440px) {
  #home .home-teaser .emu-teaser__description {
    max-width: 43%;
  }
}

#home .home-teaser .emu-teaser__description p {
  letter-spacing: .9px;
}

@media (min-width: 768px) {
  #home .home-teaser .emu-teaser__description p {
    letter-spacing: .48px;
  }
}

@media (min-width: 1025px) {
  #home .home-teaser .emu-teaser__description p {
    letter-spacing: -.01px;
  }
}

#home .home-teaser .emu-teaser__description p sup {
  top: -2px;
  left: -1px;
}

@media (min-width: 1025px) {
  #home .home-teaser .emu-teaser__description p sup {
    top: -1px;
  }
}

#home .home-teaser .emu-teaser__description p b {
  font-weight: inherit;
}

@media (min-width: 768px) {
  #home .home-teaser .emu-teaser__description p b {
    font-size: var(--emu-common-font-sizes-wide-large);
    line-height: 30px;
    font-weight: var(--emu-common-font-weight-regular);
    letter-spacing: .48px;
    white-space: nowrap !important;
  }
}

@media (min-width: 1025px) {
  #home .home-teaser .emu-teaser__description p b {
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: normal;
  }
}

@media (min-width: 1440px) {
  #home .home-teaser .emu-teaser__description p b {
    letter-spacing: -.01px;
  }
}

#home .home-teaser .teaser-image-container {
  padding-top: var(--emu-common-spacing-none);
  margin-top: -4px;
}

@media (min-width: 768px) {
  #home .home-teaser .teaser-image-container {
    margin-top: var(--emu-common-spacing-none);
    padding-top: 80px;
  }
}

@media (min-width: 1200px) {
  #home .home-teaser .teaser-image-container {
    padding-top: var(--emu-common-spacing-none);
  }

  #home .home-teaser .cmp-teaser__image--desktop {
    display: none;
  }

  #home .home-banner {
    z-index: var(--emu-common-other-z-index-behind);
    width: 100%;
    min-height: 825px;
    position: relative;
  }
}

@media (min-width: 1300px) {
  #home .home-banner {
    background-position-y: 0;
  }
}

@media (min-width: 1500px) {
  #home .home-banner {
    min-height: 1200px;
  }
}

#home .biocell {
  height: 137px;
  position: relative;
  top: -20px;
}

@media (min-width: 768px) {
  #home .biocell {
    height: 155px;
    margin-top: -15%;
    top: 0;
  }
}

@media (min-width: 1025px) {
  #home .biocell {
    height: 200px;
  }
}

@media (min-width: 2400px) {
  #home .biocell {
    height: 450px;
    margin-top: -10%;
  }
}

#home .biocell__image {
  background: linear-gradient(180deg, var(--emu-semantic-colors-none) 0%, var(--emu-semantic-colors-none) 35%, var(--emu-semantic-colors-brand-primary-600) 35.1%);
  margin-bottom: -2px;
  padding-bottom: 20px;
}

#home .biocell__image > img {
  width: 100%;
  height: auto;
}

#home .biocell__image-container {
  background-color: var(--emu-common-colors-transparent);
}

#home .biocell-strip {
  background-color: var(--emu-semantic-colors-brand-grey-400);
  width: 100%;
  position: absolute;
  overflow: hidden;
}

@media (min-width: 768px) {
  #home .biocell-strip {
    top: 38%;
  }
}

@media (min-width: 1025px) {
  #home .biocell-strip {
    top: 65%;
  }
}

#home .biocell-strip__container {
  padding-top: 6px;
  padding-bottom: 3px;
}

@media (min-width: 768px) {
  #home .biocell-strip__container {
    max-width: var(--emu-semantic-sizing-breakpoints-xxx-large);
    padding-left: 15px;
    padding-right: 15px;
    padding-top: var(--emu-common-spacing-xs);
    margin-left: auto;
    margin-right: auto;
  }
}

#home .biocell-strip__content {
  margin-left: -2px;
  margin-right: -2px;
}

#home .biocell-strip__btn.emu-button-v2.emu-button__primary-outline.emu-button {
  color: var(--emu-semantic-colors-primary-0);
  border: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 6px 14px;
}

@media (min-width: 768px) {
  #home .biocell-strip__btn.emu-button-v2.emu-button__primary-outline.emu-button {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

#home .biocell-strip__btn.emu-button-v2.emu-button__primary-outline.emu-button > img {
  vertical-align: middle;
}

#home .biocell-strip__btn.emu-button-v2.emu-button__primary-outline.emu-button > span {
  letter-spacing: 3.3px;
  line-height: var(--emu-semantic-sizing-one-line-height-narrow);
  text-align: left;
  font-size: 13px;
  font-weight: var(--emu-common-font-weight-bold);
  margin-left: 40px;
}

@media (min-width: 768px) {
  #home .biocell-strip__btn.emu-button-v2.emu-button__primary-outline.emu-button > span {
    font-size: var(--emu-common-font-sizes-narrow-body);
    letter-spacing: 5.5px;
  }
}

#home .biocell-strip__btn.emu-button-v2.emu-button__primary-outline.emu-button > span > span sup {
  font-size: 3px;
  top: -2px;
  left: -2px;
}

@media (min-width: 768px) {
  #home .biocell-strip__btn.emu-button-v2.emu-button__primary-outline.emu-button > span > span sup {
    font-size: 9px;
    top: -1px;
    left: -5px;
  }
}

#home .biocell-strip__btn.emu-button-v2.emu-button__primary-outline.emu-button:hover {
  background-color: var(--emu-semantic-colors-none);
}

#home .biocell-strip__btn.emu-button-v2.emu-button__primary-outline.emu-button:active {
  background-color: var(--emu-semantic-colors-none);
  outline: none;
}

#home .biocell-strip__btn.emu-button-v2.emu-button__primary-outline.emu-button:focus {
  background-color: var(--emu-semantic-colors-none);
}

#home .biocell-modal .is-open .modal-content {
  transform: translateY(0);
}

#home .biocell-modal .modal-content {
  margin: var(--emu-common-spacing-small);
  padding: var(--emu-semantic-sizing-none);
  top: var(--emu-common-sizing-none);
  border-radius: 4.8px;
  width: 95%;
  max-width: 95%;
  transform: translateY(-50px);
}

@media (min-width: 576px) {
  #home .biocell-modal .modal-content {
    margin: 28px auto;
  }
}

@media (min-width: 992px) {
  #home .biocell-modal .modal-content {
    width: 950px;
    max-width: 950px;
  }
}

#home .biocell-modal .modal-content > button {
  color: var(--emu-semantic-colors-primary-0);
  opacity: 1;
  text-shadow: 0 1px 0 var(--emu-semantic-colors-primary-0);
  font-size: 44px;
  line-height: 1.1px;
  font-weight: var(--emu-common-font-weight-bold);
  padding-left: var(--emu-semantic-sizing-none);
  padding-right: var(--emu-semantic-sizing-none);
  -webkit-tap-highlight-color: var(--emu-common-colors-transparent);
  width: -moz-fit-content;
  width: fit-content;
  top: 24px;
  left: 16px;
  right: 100%;
}

@media (min-width: 992px) {
  #home .biocell-modal .modal-content > button {
    color: var(--emu-common-colors-black);
    margin-top: -16px;
  }
}

#home .biocell-modal .modal-content > button:hover, #home .biocell-modal .modal-content > button:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

#home .biocell-modal .modal-content > button > span {
  padding-left: 6px;
  padding-right: var(--emu-common-spacing-small);
  padding-top: var(--emu-common-spacing-medium);
  padding-bottom: var(--emu-common-spacing-medium);
  font-size: 48px;
}

@media (min-width: 992px) {
  #home .biocell-modal .modal-content > button > span {
    font-size: 26px;
  }
}

#home .home-inspira {
  overflow: visible;
}

@media (min-width: 1025px) {
  #home .home-inspira {
    overflow: hidden;
  }
}

#home .home-inspira .natrelle-inspira__wrapper {
  margin-top: -50px;
}

@media (min-width: 768px) {
  #home .home-inspira .natrelle-inspira__wrapper {
    margin-top: var(--emu-common-spacing-none);
  }
}

@media (min-width: 992px) {
  #home .home-inspira .natrelle-inspira__wrapper {
    margin-top: -.2px;
  }
}

@media (min-width: 768px) {
  #home .patients-first__btn {
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
}

#home .choose-surgeon.image-text {
  background-color: var(--emu-common-colors-brand-blue-600);
  position: relative;
  bottom: -1px;
  background-size: 100% !important;
}

@media (min-width: 768px) {
  #home .choose-surgeon.image-text {
    background-color: unset;
    bottom: unset;
  }
}

#home .choose-surgeon.image-text:before {
  content: "";
  z-index: -1;
  background: linear-gradient(180deg, var(--emu-common-colors-brand-blue-50) 0%, var(--emu-common-colors-brand-blue-50) 10%, var(--emu-common-colors-brand-blue-50) 20%, var(--emu-common-colors-brand-blue-500) 30%, var(--emu-common-colors-brand-blue-500) 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 767px) {
  #home .choose-surgeon.image-text:before {
    display: none;
  }
}

#home .choose-surgeon.image-text .cmp-image__image {
  transform: translateY(-1px);
}

@media (min-width: 1200px) {
  #home .choose-surgeon.image-text__flex-container > .container:last-child {
    max-width: 47%;
  }
}

@media (min-width: 992px) {
  #home .choose-surgeon.image-text__title {
    padding-top: 35px;
  }
}

#home .choose-surgeon.image-text__title > h2, #home .choose-surgeon.image-text .u-title-secondary--pink i {
  font-weight: var(--emu-common-font-weight-medium);
}

#home .augmentation-sec {
  overflow: hidden;
}

#home .augmentation-sec__wrapper > .container {
  margin-top: -2px;
  padding-top: 45px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  #home .augmentation-sec__wrapper > .container {
    margin-top: var(--emu-common-spacing-none);
    padding-top: 52px;
  }
}

#home .augmentation-sec__image {
  margin-right: -4px;
}

#home .augmentation-sec__image img {
  width: 100%;
  margin-bottom: -4px;
  transform: translateY(2px);
}

@media (min-width: 768px) {
  #home .augmentation-sec .real-women .image-text__flex-container > .container:first-child {
    max-width: 55%;
  }

  #home .augmentation-sec .real-women .image-text__flex-container > .container:last-child {
    max-width: 45%;
  }
}

#home .augmentation-sec .real-women .image-text__image-container {
  max-width: 100%;
  position: relative;
}

#home .augmentation-sec .real-women .image-text__image-container-image {
  margin: var(--emu-common-spacing-none);
}

#home .augmentation-sec .real-women .image-text__content {
  padding-top: 35px;
}

@media (min-width: 768px) {
  #home .augmentation-sec .real-women .image-text__content {
    margin-right: var(--emu-semantic-spacing-none);
    align-items: flex-start;
    justify-content: unset;
    padding-top: var(--emu-semantic-spacing-none);
  }

  #home .augmentation-sec .real-women .image-text__content .button + .text {
    padding-top: 25%;
  }
}

#home .augmentation-sec .real-women .image-text__title.cmp-text {
  margin-top: var(--emu-semantic-spacing-none);
}

@media (min-width: 768px) {
  #home .augmentation-sec .real-women .image-text__title.cmp-text {
    text-align: left;
    padding-top: var(--emu-common-spacing-none);
    margin-top: 50px;
  }
}

@media (min-width: 1025px) {
  #home .augmentation-sec .real-women .image-text__title.cmp-text {
    margin-top: 47px;
  }
}

@media (min-width: 1200px) {
  #home .augmentation-sec .real-women .image-text__title.cmp-text {
    width: 640px;
    margin-top: 50px;
  }
}

@media (min-width: 1025px) {
  #home .augmentation-sec .real-women .image-text__btn {
    margin-top: 33px;
  }
}

@media (min-width: 1440px) {
  #home .augmentation-sec .real-women .image-text__btn {
    margin-top: 30px;
  }
}

#home .augmentation-sec .real-women .image-text__btn.emu-button-v2.emu-button__primary-outline.emu-button {
  text-align: center;
  width: 320px;
}

@media (min-width: 768px) {
  #home .augmentation-sec .real-women .image-text__btn.emu-button-v2.emu-button__primary-outline.emu-button {
    width: 100%;
  }
}

#home .augmentation-sec .real-women .image-text__disclaimer.cmp-text {
  margin-bottom: var(--emu-common-spacing-none);
  text-align: center;
  font-size: var(--emu-common-font-sizes-narrow-xs);
  letter-spacing: normal;
  width: 100%;
  max-width: 100%;
  position: absolute;
  bottom: 16px;
}

@media (min-width: 768px) {
  #home .augmentation-sec .real-women .image-text__disclaimer.cmp-text {
    margin-top: var(--emu-common-spacing-none);
    font-size: var(--emu-common-font-sizes-narrow-small);
    position: static;
  }
}

#home .augmentation-sec .breast-visualizer {
  margin-top: 20px;
}

@media (min-width: 768px) {
  #home .augmentation-sec .breast-visualizer {
    margin-top: var(--emu-common-spacing-none);
    padding-top: 35px;
  }
}

@media (min-width: 1440px) {
  #home .augmentation-sec .breast-visualizer {
    padding-top: var(--emu-common-spacing-none);
  }
}

#home .augmentation-sec .breast-visualizer .image-text__container {
  overflow: visible;
}

@media (min-width: 768px) {
  #home .augmentation-sec .breast-visualizer .image-text__flex-container .container {
    max-width: 50%;
  }
}

#home .augmentation-sec .breast-visualizer .image-text__content {
  padding-top: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #home .augmentation-sec .breast-visualizer .image-text__content {
    align-items: flex-start;
    justify-content: unset;
  }
}

@media (min-width: 1025px) {
  #home .augmentation-sec .breast-visualizer .image-text__content {
    margin-right: var(--emu-common-spacing-none);
  }
}

#home .augmentation-sec .breast-visualizer .image-text__content-btn {
  margin-top: 45px;
  margin-bottom: var(--emu-common-spacing-none);
  text-align: center;
}

@media (min-width: 768px) {
  #home .augmentation-sec .breast-visualizer .image-text__content-btn {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

@media (min-width: 1025px) {
  #home .augmentation-sec .breast-visualizer .image-text__content-btn {
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: 25px;
  }
}

#home .augmentation-sec .breast-visualizer .image-text__content-btn.emu-button-v2.emu-button__primary-outline.emu-button {
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 45px;
}

@media (min-width: 768px) {
  #home .augmentation-sec .breast-visualizer .image-text__content-btn.emu-button-v2.emu-button__primary-outline.emu-button {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1025px) {
  #home .augmentation-sec .breast-visualizer .image-text__content-btn.emu-button-v2.emu-button__primary-outline.emu-button {
    margin-top: 25px;
    margin-bottom: 25px;
    padding: 15px 30px;
  }
}

#home .augmentation-sec .breast-visualizer .image-text__content-btn span {
  letter-spacing: 4.2px;
  width: 100%;
  line-height: 16px;
}

@media (min-width: 768px) {
  #home .augmentation-sec .breast-visualizer .image-text__content-btn span {
    letter-spacing: 3.6px;
    font-size: var(--emu-semantic-font-sizes-wide-xs);
  }
}

@media (min-width: 1025px) {
  #home .augmentation-sec .breast-visualizer .image-text__content-btn span {
    letter-spacing: 4.5px;
    font-size: 15px;
    line-height: var(--emu-semantic-line-heights-wide-xs);
  }
}

#home .augmentation-sec .breast-visualizer .image-text__title {
  text-align: center;
  margin-top: 19px;
  margin-bottom: var(--emu-common-spacing-large);
}

@media (min-width: 768px) {
  #home .augmentation-sec .breast-visualizer .image-text__title {
    text-align: left;
    margin-top: 27px;
    margin-bottom: 25px;
    padding-top: 5%;
  }
}

@media (min-width: 992px) {
  #home .augmentation-sec .breast-visualizer .image-text__title {
    padding-top: 4%;
  }
}

@media (min-width: 1025px) {
  #home .augmentation-sec .breast-visualizer .image-text__title {
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-common-spacing-small);
  }
}

#home .augmentation-sec .breast-visualizer .image-text__title h2 {
  font-size: 30.4px;
  line-height: 39.52px;
  font-weight: var(--emu-common-font-weight-light);
  letter-spacing: .9px;
}

@media (min-width: 768px) {
  #home .augmentation-sec .breast-visualizer .image-text__title h2 {
    font-size: 24px;
    line-height: 26.4px;
  }
}

@media (min-width: 1025px) {
  #home .augmentation-sec .breast-visualizer .image-text__title h2 {
    font-size: var(--emu-common-font-sizes-wide-xxl);
    line-height: 58px;
  }
}

@media (min-width: 1200px) {
  #home .augmentation-sec .breast-visualizer .image-text__title h2 {
    letter-spacing: 1.4px;
  }
}

#home .augmentation-sec .breast-visualizer .image-text__title h2 b {
  letter-spacing: .03px;
  font-style: normal;
}

#home .augmentation-sec .breast-visualizer .image-text__title h2 sup {
  font-size: 11px;
  top: 0;
  left: -2px;
}

@media (min-width: 768px) {
  #home .augmentation-sec .breast-visualizer .image-text__title h2 sup {
    font-size: 9px;
  }
}

@media (min-width: 1025px) {
  #home .augmentation-sec .breast-visualizer .image-text__title h2 sup {
    font-size: 17px;
  }
}

#home .augmentation-sec .breast-visualizer .image-text__logo {
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  #home .augmentation-sec .breast-visualizer .image-text__logo {
    max-width: 100%;
  }
}

#home .augmentation-sec .breast-visualizer .image-text__logo > .cmp-image__image {
  margin-bottom: var(--emu-common-spacing-medium);
  text-indent: 0;
  width: 100%;
}

@media (min-width: 576px) {
  #home .augmentation-sec .breast-visualizer .image-text__logo > .cmp-image__image {
    margin-bottom: var(--emu-semantic-spacing-none);
    margin-left: var(--emu-common-spacing-none);
    width: 290px;
  }
}

@media (min-width: 992px) {
  #home .augmentation-sec .breast-visualizer .image-text__logo > .cmp-image__image {
    padding-top: var(--emu-common-spacing-xxs);
    max-width: 98%;
    margin-left: -17px;
  }
}

@media (min-width: 768px) {
  #home .breast-reconstruction--content-wrapper {
    padding-top: 4%;
  }
}

#home .breast-reconstruction-container {
  background-image: none;
  background-repeat: no-repeat;
  background-color: var(--emu-common-colors-brand-violet-400);
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  background-size: 100% !important;
}

@media (min-width: 768px) {
  #home .breast-reconstruction-container {
    background-color: unset;
  }

  #home .breast-reconstruction-container:before {
    content: "";
    z-index: var(--emu-common-other-z-index-behind);
    background: linear-gradient(180deg, var(--emu-common-colors-brand-blue-50) 0%, var(--emu-common-colors-brand-blue-50) 3%, var(--emu-common-colors-brand-violet-400) 7%, var(--emu-common-colors-brand-violet-400) 9%, var(--emu-common-colors-brand-violet-400) 10%, var(--emu-common-colors-brand-violet-400) 20%, var(--emu-common-colors-brand-violet-400) 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

@media (min-width: 1025px) {
  #home .breast-reconstruction-container:before {
    background: linear-gradient(180deg, var(--emu-common-colors-brand-blue-50) 0%, var(--emu-common-colors-brand-blue-50) 5%, var(--emu-common-colors-brand-violet-400) 10%, var(--emu-common-colors-brand-violet-400) 20%, var(--emu-common-colors-brand-violet-400) 100%);
    background-repeat: no-repeat;
    background-size: 100%;
  }
}

#home .breast-reconstruction-container--mobile {
  margin-bottom: -2px;
  overflow: hidden;
}

#home .breast-reconstruction-container--mobile .image {
  margin-right: -4px;
}

#home .breast-reconstruction-container--mobile .image img {
  width: 100%;
}

#home .breast-reconstruction-container .content-box__title {
  padding-bottom: 4%;
}

@media (min-width: 768px) {
  #home .breast-reconstruction-container .content-box__title {
    padding-bottom: var(--emu-semantic-spacing-none);
  }
}

#home .breast-reconstruction-container .content-box__title h2 {
  font-weight: var(--emu-common-font-weight-medium);
}

@media (min-width: 768px) {
  #home .breast-reconstruction-container .content-box__title h2 {
    letter-spacing: -1px;
  }
}

#home .breast-reconstruction-container .content-box__title h2 b {
  letter-spacing: -.2px;
  padding-bottom: 2%;
}

#home .breast-reconstruction-container .content-box__description {
  padding-top: var(--emu-semantic-spacing-none);
  max-width: 100%;
}

@media (min-width: 1200px) {
  #home .breast-reconstruction-container .content-box__description {
    text-indent: var(--emu-common-spacing-none);
  }
}

#home .breast-reconstruction-container .content-box .button {
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  #home .breast-reconstruction-container .content-box .button {
    margin-left: var(--emu-semantic-spacing-none);
    margin-right: var(--emu-semantic-spacing-none);
  }
}

#home .breast-reconstruction-container .content-box__button {
  justify-content: center;
  width: 100%;
  margin-top: 41px;
  margin-bottom: 55px;
}

@media (max-width: 767px) {
  #home .breast-reconstruction-container .content-box__button {
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media (min-width: 768px) {
  #home .breast-reconstruction-container .content-box__button {
    margin-top: 25px;
    margin-bottom: 15px;
    margin-left: var(--emu-semantic-spacing-none);
    max-width: -moz-fit-content;
    max-width: fit-content;
  }

  #home .breast-reconstruction-container .content-box__button span span {
    letter-spacing: 4.3px;
    font-size: 15px;
    line-height: 18px;
  }
}

#home .breast-reconstruction-container .image-text__image-container {
  max-width: 100%;
  position: relative;
}

#home .breast-reconstruction-container .image-text__content {
  padding-top: var(--emu-common-spacing-none);
  margin-top: 53px;
}

@media (min-width: 768px) {
  #home .breast-reconstruction-container .image-text__content {
    margin: var(--emu-common-spacing-none);
    justify-content: center;
    align-items: flex-start;
  }
}

#home .breast-reconstruction-container .image-text__flex-container .container:first-child {
  max-width: 50%;
}

#home .breast-reconstruction-container .image-text__title {
  margin-top: -4px;
  padding-bottom: 2%;
}

@media (min-width: 768px) {
  #home .breast-reconstruction-container .image-text__title {
    padding: var(--emu-common-spacing-none);
    text-align: left;
    margin-top: var(--emu-common-spacing-none);
  }
}

#home .breast-reconstruction-container .image-text__title h2 {
  font-weight: var(--emu-common-font-weight-regular);
}

@media (min-width: 768px) {
  #home .breast-reconstruction-container .image-text__title h2 {
    font-size: var(--emu-common-font-sizes-narrow-xl);
    line-height: var(--emu-common-line-heights-narrow-xl);
  }
}

@media (min-width: 1025px) {
  #home .breast-reconstruction-container .image-text__title h2 {
    font-size: var(--emu-common-font-sizes-wide-xxl);
    line-height: 58px;
  }
}

#home .breast-reconstruction-container .image-text__title h2 i {
  padding-bottom: 3.9px;
}

#home .breast-reconstruction-container .image-text__quote {
  padding: var(--emu-common-spacing-none);
  max-width: 88%;
  padding-top: 3px;
}

@media (min-width: 768px) {
  #home .breast-reconstruction-container .image-text__quote {
    max-width: 100%;
  }

  #home .breast-reconstruction-container .image-text__quote.cmp-text {
    letter-spacing: .42px;
    font-size: 14px;
    line-height: 20px;
  }
}

@media (min-width: 1025px) {
  #home .breast-reconstruction-container .image-text__quote.cmp-text {
    font-size: var(--emu-common-font-sizes-narrow-xl);
    letter-spacing: 2.24px;
    line-height: 36px;
  }
}

@media (min-width: 768px) {
  #home .breast-reconstruction-container .image-text__quote.cmp-text p br {
    display: block;
  }
}

#home .breast-reconstruction-container .image-text__btn {
  text-align: center;
  width: auto;
  margin: 61px auto 25px;
  display: block;
}

@media (max-width: 767px) {
  #home .breast-reconstruction-container .image-text__btn {
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media (min-width: 768px) {
  #home .breast-reconstruction-container .image-text__btn {
    margin-top: 25px;
    margin-bottom: 15px;
  }

  #home .breast-reconstruction-container .image-text__btn span span {
    letter-spacing: 4.3px;
    font-size: 15px;
    line-height: 18px;
  }
}

@media (min-width: 1025px) {
  #home .breast-reconstruction-container .image-text__patient-name {
    font-size: 24px;
    line-height: var(--emu-common-line-heights-narrow-body);
  }
}

#home .breast-reconstruction-container .image-text__disclaimer {
  margin-bottom: var(--emu-common-spacing-none);
  text-align: center;
  width: 100%;
  max-width: 100%;
  position: absolute;
  bottom: -15px;
}

@media (min-width: 768px) {
  #home .breast-reconstruction-container .image-text__disclaimer {
    margin-top: var(--emu-common-spacing-none);
    text-align: left;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    position: static;
  }
}

#home .breast-reconstruction-container .image-text__disclaimer.cmp-text {
  font-size: var(--emu-common-font-sizes-narrow-small);
  line-height: var(--emu-common-line-heights-narrow-xs);
}

#home .curve-clip {
  margin-top: -1px;
  margin-bottom: -1px;
}

[lang="fr_ca"] #home .home-inspira .natrelle-inspira__content-title h2 sup {
  display: inline;
}

[lang="fr_ca"] #home .choose-surgeon {
  margin-top: -5px;
}

@media (min-width: 992px) {
  [lang="fr_ca"] #home .choose-surgeon .image-text__flex-container > .container:last-child {
    max-width: 59%;
  }
}

@media (min-width: 1200px) {
  [lang="fr_ca"] #home .choose-surgeon .image-text__flex-container > .container:last-child {
    max-width: 58%;
  }
}

@media (min-width: 992px) {
  [lang="fr_ca"] #home .choose-surgeon .image-text__title {
    padding-top: 35px;
  }
}

@media (min-width: 768px) {
  [lang="fr_ca"] #home .choose-surgeon .image-text__disclaimer {
    margin-top: 15px;
  }
}

@media (min-width: 1025px) {
  [lang="fr_ca"] #home .choose-surgeon .image-text__disclaimer {
    margin-top: 25px;
  }
}

@media (min-width: 1200px) {
  [lang="fr_ca"] #home .choose-surgeon .image-text__disclaimer {
    max-width: 85%;
    margin-top: 10px;
    left: 15%;
  }
}

@media (min-width: 768px) {
  [lang="fr_ca"] #home .augmentation-sec__wrapper > .container {
    padding-top: 50px;
  }
}

[lang="fr_ca"] #home .augmentation-sec .content-box__title {
  padding-top: 20px;
}

@media (min-width: 768px) {
  [lang="fr_ca"] #home .augmentation-sec .content-box__title {
    padding-top: var(--emu-common-spacing-none);
  }
}

@media (min-width: 576px) {
  [lang="fr_ca"] #home .augmentation-sec .content-box__title h2 {
    letter-spacing: 1.1px;
  }
}

[lang="fr_ca"] #home .augmentation-sec .content-box__title h2 i {
  letter-spacing: -.5px;
}

[lang="fr_ca"] #home .augmentation-sec .content-box__description {
  max-width: 99%;
}

@media (min-width: 1200px) {
  [lang="fr_ca"] #home .augmentation-sec .content-box__description {
    text-indent: 0;
  }
}

[lang="fr_ca"] #home .augmentation-sec .real-women .image-text__content {
  padding-top: 28px;
}

@media (min-width: 768px) {
  [lang="fr_ca"] #home .augmentation-sec .real-women .image-text__content {
    padding-top: var(--emu-common-spacing-none);
  }
}

[lang="fr_ca"] #home .augmentation-sec .real-women .image-text__content .button + .text {
  padding-top: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  [lang="fr_ca"] #home .augmentation-sec .real-women .image-text__content .button + .text {
    padding-top: 12%;
  }
}

[lang="fr_ca"] #home .augmentation-sec .real-women .image-text__title.cmp-text {
  padding-top: var(--emu-common-spacing-none);
}

[lang="fr_ca"] #home .augmentation-sec .real-women .image-text__title.cmp-text h2 {
  letter-spacing: 1.6px;
}

@media (min-width: 768px) {
  [lang="fr_ca"] #home .augmentation-sec .real-women .image-text__title.cmp-text h2 {
    letter-spacing: 1.5px;
  }
}

[lang="fr_ca"] #home .augmentation-sec .real-women .image-text__title.cmp-text h2 i {
  display: block;
}

@media (min-width: 768px) {
  [lang="fr_ca"] #home .augmentation-sec .real-women .image-text__title.cmp-text h2 i {
    display: inline;
  }
}

@media (min-width: 1025px) {
  [lang="fr_ca"] #home .augmentation-sec .real-women .image-text__quote.cmp-text {
    letter-spacing: 2.4px;
  }
}

@media (min-width: 1200px) {
  [lang="fr_ca"] #home .augmentation-sec .real-women .image-text__quote.cmp-text {
    letter-spacing: 2.6px;
    max-width: 85%;
  }
}

[lang="fr_ca"] #home .augmentation-sec .real-women .image-text__disclaimer.cmp-text {
  bottom: 11px;
}

@media (min-width: 576px) {
  [lang="fr_ca"] #home .augmentation-sec .real-women .image-text__disclaimer.cmp-text {
    bottom: 21px;
  }
}

@media (min-width: 768px) {
  [lang="fr_ca"] #home .augmentation-sec .real-women .image-text__disclaimer.cmp-text {
    text-align: left;
  }
}

@media (min-width: 1300px) {
  [lang="fr_ca"] #home .augmentation-sec .breast-visualizer {
    padding-top: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1200px) {
  [lang="fr_ca"] #home .augmentation-sec .breast-visualizer .image-text__title {
    width: 95%;
    padding-top: 4%;
  }
}

@media (min-width: 992px) {
  [lang="fr_ca"] #home .augmentation-sec .breast-visualizer .image-text__title h2 {
    letter-spacing: 1px;
  }
}

@media (min-width: 1025px) {
  [lang="fr_ca"] #home .augmentation-sec .breast-visualizer .image-text__title h2 {
    letter-spacing: 2.2px;
  }
}

@media (min-width: 1200px) {
  [lang="fr_ca"] #home .augmentation-sec .breast-visualizer .image-text__title h2 {
    letter-spacing: .2px;
    line-height: 56px;
  }
}

[lang="fr_ca"] #home .augmentation-sec .breast-visualizer .image-text__title h2 > b {
  font-weight: var(--emu-common-font-weight-medium);
  letter-spacing: .2px;
}

@media (min-width: 768px) {
  [lang="fr_ca"] #home .augmentation-sec .breast-visualizer .image-text__title h2 > b {
    letter-spacing: normal;
  }
}

@media (min-width: 992px) {
  [lang="fr_ca"] #home .augmentation-sec .breast-visualizer .image-text__title h2 > b {
    letter-spacing: -1.4px;
  }
}

@media (min-width: 1025px) {
  [lang="fr_ca"] #home .augmentation-sec .breast-visualizer .image-text__title h2 > b {
    letter-spacing: 1px;
  }
}

@media (min-width: 1200px) {
  [lang="fr_ca"] #home .augmentation-sec .breast-visualizer .image-text__title h2 > b {
    letter-spacing: -2px;
  }
}

[lang="fr_ca"] #home .patients-first__title {
  max-width: 100%;
}

[lang="fr_ca"] #home .patients-first__content {
  max-width: 95%;
}

[lang="fr_ca"] #home .patients-first__content sup {
  display: inline;
}

@media (min-width: 768px) {
  [lang="fr_ca"] #home .breast-reconstruction-container .content-box__title h2 {
    letter-spacing: normal;
  }
}

[lang="fr_ca"] #home .breast-reconstruction-container .content-box__title h2 i {
  padding-bottom: 2%;
}

[lang="fr_ca"] #home .breast-reconstruction-container .content-box__description {
  margin-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  [lang="fr_ca"] #home .breast-reconstruction-container .content-box__description {
    margin-bottom: var(--emu-common-spacing-medium);
    letter-spacing: .43px;
    padding-top: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1025px) {
  [lang="fr_ca"] #home .breast-reconstruction-container .content-box__description {
    letter-spacing: 1.26px;
  }
}

[lang="fr_ca"] #home .breast-reconstruction-container .content-box .button {
  max-width: 85%;
}

[lang="fr_ca"] #home .breast-reconstruction-container .content-box__button {
  margin-bottom: 50px;
}

@media (min-width: 768px) {
  [lang="fr_ca"] #home .breast-reconstruction-container .content-box__button {
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin-bottom: 15px;
  }

  [lang="fr_ca"] #home .breast-reconstruction-container .content-box__button span {
    letter-spacing: 4.55px;
  }
}

[lang="fr_ca"] #home .breast-reconstruction-container .image-text__content {
  margin-top: 75px;
}

@media (min-width: 768px) {
  [lang="fr_ca"] #home .breast-reconstruction-container .image-text__content {
    margin-top: var(--emu-common-spacing-none);
  }

  [lang="fr_ca"] #home .breast-reconstruction-container .image-text__flex-container .container:last-child {
    max-width: 50%;
  }
}

[lang="fr_ca"] #home .breast-reconstruction-container .image-text__image-container-image {
  margin-top: var(--emu-common-spacing-none);
}

@media (min-width: 1200px) {
  [lang="fr_ca"] #home .breast-reconstruction-container .image-text__title {
    margin-top: -11px;
  }
}

[lang="fr_ca"] #home .breast-reconstruction-container .image-text__title h2 {
  letter-spacing: normal;
}

@media (min-width: 768px) {
  [lang="fr_ca"] #home .breast-reconstruction-container .image-text__title h2 {
    letter-spacing: -1.5px;
  }
}

@media (min-width: 992px) {
  [lang="fr_ca"] #home .breast-reconstruction-container .image-text__title h2 {
    letter-spacing: normal;
  }
}

@media (min-width: 1025px) {
  [lang="fr_ca"] #home .breast-reconstruction-container .image-text__title h2 {
    letter-spacing: .5px;
  }
}

@media (min-width: 1200px) {
  [lang="fr_ca"] #home .breast-reconstruction-container .image-text__title h2 {
    letter-spacing: -1.5px;
  }
}

[lang="fr_ca"] #home .breast-reconstruction-container .image-text__title h2 i {
  letter-spacing: 1.3px;
  padding-bottom: 2%;
}

@media (min-width: 1025px) {
  [lang="fr_ca"] #home .breast-reconstruction-container .image-text__title h2 i {
    letter-spacing: 1.7px;
  }

  [lang="fr_ca"] #home .breast-reconstruction-container .image-text__quote {
    letter-spacing: 2.2px;
  }
}

@media (min-width: 1200px) {
  [lang="fr_ca"] #home .breast-reconstruction-container .image-text__quote {
    letter-spacing: 2.5px;
    max-width: 90%;
  }
}

[lang="fr_ca"] #home .breast-reconstruction-container .image-text__patient-name {
  letter-spacing: 5.7px;
}

@media (min-width: 768px) {
  [lang="fr_ca"] #home .breast-reconstruction-container .image-text__patient-name {
    letter-spacing: .6px;
  }
}

@media (min-width: 1025px) {
  [lang="fr_ca"] #home .breast-reconstruction-container .image-text__patient-name {
    letter-spacing: 2px;
  }
}

@media (min-width: 1200px) {
  [lang="fr_ca"] #home .breast-reconstruction-container .image-text__patient-name {
    letter-spacing: 3px;
    max-width: 90%;
    font-size: 32px;
    line-height: 36px;
  }
}

[lang="fr_ca"] #home .breast-reconstruction-container .image-text__disclaimer {
  bottom: -35px;
}

[lang="fr_ca"] #home .breast-reconstruction-container .image-text__btn {
  max-width: 80%;
  margin-top: 46px;
}

@media (min-width: 768px) {
  [lang="fr_ca"] #home .breast-reconstruction-container .image-text__btn {
    max-width: 100%;
    margin-top: 25px;
  }
}

[lang="fr_ca"] #home .breast-reconstruction-container .image-text__btn span {
  line-height: var(--emu-common-line-heights-narrow-small);
}

@media (min-width: 992px) {
  [lang="fr_ca"] #home .breast-reconstruction-container .image-text__btn span {
    letter-spacing: 4.7px;
  }

  [lang="fr_ca"] #home .breast-reconstruction-container .image-text__flex-container .container:last-child {
    max-width: 50%;
  }
}

@media (min-width: 1200px) {
  [lang="fr_ca"] #home .breast-reconstruction-container .image-text__quote {
    max-width: 90%;
  }
}

@media (min-width: 1025px) {
  [lang="fr_ca"] #home .breast-reconstruction-container .image-text__quote.cmp-text {
    letter-spacing: 2.2px;
  }
}

@media (min-width: 1200px) {
  [lang="fr_ca"] #home .breast-reconstruction-container .image-text__quote.cmp-text {
    letter-spacing: 2.3px;
  }
}

@media (min-width: 1025px) {
  [lang="fr_ca"] #home .breast-reconstruction-container .image-text__patient-name {
    letter-spacing: 2.24px;
    font-size: 32px;
    line-height: 36px;
  }

  [lang="fr_ca"] #home .home-teaser .emu-teaser__title {
    max-width: 92%;
  }
}

[lang="fr_ca"] #home .home-teaser .emu-teaser__title sup {
  font-size: 40%;
  font-weight: var(--emu-common-font-weight-medium);
  font-family: var(--emu-common-font-families-aleo);
}

@media (min-width: 768px) {
  [lang="fr_ca"] #home .home-teaser .emu-teaser__description {
    max-width: 42%;
  }
}

@media (min-width: 992px) {
  [lang="fr_ca"] #home .home-teaser .emu-teaser__description {
    max-width: 43%;
  }
}

@media (min-width: 768px) {
  [lang="fr_ca"] #home .biocell-strip__btn.emu-button-v2.emu-button__primary-outline.emu-button > span {
    margin-left: var(--emu-semantic-spacing-none);
  }
}

[lang="fr_ca"] #home .biocell-strip__btn.emu-button-v2.emu-button__primary-outline.emu-button > span > span sup {
  font-size: 7px;
  font-family: var(--emu-common-font-families-graphik);
  font-weight: var(--emu-common-font-weight-regular);
  letter-spacing: normal;
}

@media (min-width: 768px) {
  [lang="fr_ca"] #home .biocell-strip__btn.emu-button-v2.emu-button__primary-outline.emu-button > span > span sup {
    top: 0;
  }
}

[lang="fr_ca"] #home .biocell-modal .modal__title h2 sup {
  font-family: var(--emu-common-font-families-aleo);
  font-weight: var(--emu-common-font-weight-medium);
  left: -1px;
}

#natrelle-collection .natrelle-inspira-implants .image-text__container {
  padding-top: 6%;
  overflow: visible;
}

@media (min-width: 768px) {
  #natrelle-collection .natrelle-inspira-implants .image-text__container {
    padding-bottom: 2%;
  }
}

#natrelle-collection .natrelle-inspira-implants .image-text__flex-container {
  flex-direction: column;
  min-height: 630px;
  position: relative;
}

@media (min-width: 768px) {
  #natrelle-collection .natrelle-inspira-implants .image-text__flex-container {
    flex-direction: row;
    min-height: 470px;
  }

  #natrelle-collection .natrelle-inspira-implants .image-text__flex-container .container:first-child {
    max-width: 73%;
  }

  #natrelle-collection .natrelle-inspira-implants .image-text__flex-container .container:last-child {
    max-width: 27%;
    padding-left: 3%;
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .natrelle-inspira-implants .image-text__flex-container .container:last-child {
    padding-left: 6%;
  }
}

#natrelle-collection .natrelle-inspira-implants .image-text__image-container-image {
  margin-top: var(--emu-semantic-spacing-none);
  margin-bottom: var(--emu-semantic-spacing-none);
}

#natrelle-collection .natrelle-inspira-implants .image-text__image-container-image img {
  width: 100%;
  max-width: 300px;
  position: absolute;
  top: 79%;
  right: -15px;
}

@media (min-width: 768px) {
  #natrelle-collection .natrelle-inspira-implants .image-text__image-container-image img {
    top: 23px;
    right: unset;
    max-width: 180px;
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .natrelle-inspira-implants .image-text__image-container-image img {
    min-width: 240px;
  }
}

#natrelle-collection .natrelle-inspira-implants .image-text__content {
  padding-bottom: 110px;
}

@media (min-width: 768px) {
  #natrelle-collection .natrelle-inspira-implants .image-text__content {
    margin-right: var(--emu-semantic-spacing-none);
    padding-bottom: var(--emu-semantic-spacing-none);
    justify-content: normal;
  }
}

#natrelle-collection .natrelle-inspira-implants .image-text__title {
  margin-top: var(--emu-semantic-spacing-none);
}

@media (min-width: 768px) {
  #natrelle-collection .natrelle-inspira-implants .image-text__title {
    padding-top: var(--emu-semantic-spacing-none);
  }

  #natrelle-collection .natrelle-inspira-implants .image-text__title h2 {
    font-size: 38px;
    line-height: 48px;
  }
}

@media (min-width: 992px) {
  #natrelle-collection .natrelle-inspira-implants .image-text__title h2 {
    letter-spacing: -.1px;
    font-size: 50px;
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .natrelle-inspira-implants .image-text__title h2 {
    line-height: 70.4px;
  }
}

@media (min-width: 1201px) {
  #natrelle-collection .natrelle-inspira-implants .image-text__title h2 {
    font-size: 57px;
  }
}

#natrelle-collection .natrelle-inspira-implants .image-text__title h2 sup {
  font-size: 45%;
  font-weight: var(--emu-semantic-font-weight-black);
  top: -5.5px;
  left: -4.5px;
}

@media (min-width: 768px) {
  #natrelle-collection .natrelle-inspira-implants .image-text__title h2 sup {
    top: -5px;
  }
}

#natrelle-collection .natrelle-inspira-implants .image-text__title h2 i {
  letter-spacing: -.9px;
}

@media (min-width: 1200px) {
  #natrelle-collection .natrelle-inspira-implants .image-text__title h2 i {
    letter-spacing: -1.1px;
  }
}

#natrelle-collection .natrelle-inspira-implants .image-text__description {
  padding-top: 3%;
}

@media (min-width: 768px) {
  #natrelle-collection .natrelle-inspira-implants .image-text__description {
    max-width: 92%;
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .natrelle-inspira-implants .image-text__description {
    max-width: 100%;
  }
}

#natrelle-collection .natrelle-inspira-implants .image-text__description.cmp-text {
  letter-spacing: .03em;
}

@media (min-width: 1025px) {
  #natrelle-collection .natrelle-inspira-implants .image-text__description.cmp-text {
    letter-spacing: .01em;
  }
}

#natrelle-collection .natrelle-inspira-implants .image-text__description sup {
  font-size: 65%;
  top: 0;
  left: 0;
}

#natrelle-collection .natrelle-inspira-implants .image-text__description sup + sup {
  font-size: 45%;
  top: -1px;
  left: -1px;
}

#natrelle-collection .five-fullness-sec img {
  width: 100%;
}

#natrelle-collection .five-fullness-sec__mobile-top-wave img {
  background: linear-gradient(180deg, var(--emu-common-colors-brand-blue-50) 0%, var(--emu-common-colors-brand-blue-50) 50%, var(--emu-common-colors-brand-blue-50) 7%, var(--emu-common-colors-brand-blue-50) 2.25%, var(--emu-common-colors-brand-blue-50) 5%, #99dde9 99%, #99dde9 100%);
}

#natrelle-collection .five-fullness-sec__desktop-top-wave {
  background-color: var(--emu-common-colors-brand-blue-100);
}

#natrelle-collection .five-fullness-sec__desktop-top-wave img {
  background-color: var(--emu-common-colors-brand-blue-50);
  max-width: 1440px;
}

#natrelle-collection .five-fullness-sec__wrapper {
  background-color: var(--emu-common-colors-brand-blue-100);
  padding-top: 3%;
}

@media (min-width: 768px) {
  #natrelle-collection .five-fullness-sec__wrapper {
    margin-top: var(--emu-semantic-spacing-none);
  }
}

@media (min-width: 992px) {
  #natrelle-collection .five-fullness-sec .u-container-max-width {
    max-width: 720px;
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .five-fullness-sec .u-container-max-width {
    max-width: var(--emu-common-sizing-xxxl);
  }
}

#natrelle-collection .five-fullness-sec .content-box__title {
  margin-bottom: var(--emu-common-spacing-small);
}

#natrelle-collection .five-fullness-sec .content-box__title h2 {
  font-weight: var(--emu-common-font-weight-regular);
  letter-spacing: -.4px;
}

@media (min-width: 768px) {
  #natrelle-collection .five-fullness-sec .content-box__title h2 {
    font-size: var(--emu-semantic-font-sizes-narrow-xxl);
    letter-spacing: -.5px;
    line-height: 48px;
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .five-fullness-sec .content-box__title h2 {
    font-size: var(--emu-common-font-sizes-wide-xxl);
    line-height: var(--emu-common-line-heights-wide-xxl);
    letter-spacing: -.7px;
  }
}

@media (min-width: 1440px) {
  #natrelle-collection .five-fullness-sec .content-box__title h2 {
    letter-spacing: -.4px;
  }
}

#natrelle-collection .five-fullness-sec .content-box__description {
  margin-bottom: var(--emu-common-spacing-medium);
  max-width: 100%;
  padding-top: 1%;
}

@media (min-width: 768px) {
  #natrelle-collection .five-fullness-sec .content-box__description {
    font-size: var(--emu-common-font-sizes-wide-body);
    line-height: var(--emu-common-line-heights-wide-body);
    max-width: 92%;
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .five-fullness-sec .content-box__description {
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  #natrelle-collection .five-fullness-sec .content-box__description {
    text-indent: unset;
  }
}

#natrelle-collection .five-fullness-sec .content-box__description .emphasis {
  display: inline-block;
}

#natrelle-collection .five-fullness-sec .content-box__description p {
  margin-bottom: var(--emu-common-spacing-small);
  letter-spacing: .54px;
}

@media (min-width: 1025px) {
  #natrelle-collection .five-fullness-sec .content-box__description p {
    letter-spacing: .18px;
  }
}

#natrelle-collection .five-fullness-sec .content-box__description p sup {
  font-size: 9px;
  top: -2px;
  left: 0;
}

#natrelle-collection .five-fullness-sec__sub-heading h3 {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  letter-spacing: .2em;
  line-height: 32px;
  font-weight: var(--emu-common-font-weight-bold);
  font-family: var(--emu-common-font-families-graphik);
}

@media (min-width: 768px) {
  #natrelle-collection .five-fullness-sec__sub-heading h3 {
    letter-spacing: normal;
    line-height: 28.8px;
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .five-fullness-sec__sub-heading h3 {
    max-width: 75%;
  }
}

#natrelle-collection .five-fullness-sec__sub-heading h3 sup {
  letter-spacing: normal;
  font-size: 45%;
  top: -.2em;
}

#natrelle-collection .five-fullness-sec__projections {
  padding-top: 10px;
}

@media (min-width: 768px) {
  #natrelle-collection .five-fullness-sec__projections {
    padding-top: 35px;
  }
}

#natrelle-collection .five-fullness-sec__projections-title {
  margin-bottom: 24px;
}

@media (min-width: 768px) {
  #natrelle-collection .five-fullness-sec__projections-title {
    margin-bottom: var(--emu-common-spacing-small);
  }
}

#natrelle-collection .five-fullness-sec__projections-title h3 {
  font-family: var(--emu-common-font-families-graphik);
  font-weight: var(--emu-common-font-weight-bold);
}

@media (min-width: 768px) {
  #natrelle-collection .five-fullness-sec__projections-title h3 {
    line-height: 24px;
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .five-fullness-sec__projections-title h3 {
    max-width: 100%;
  }
}

#natrelle-collection .five-fullness-sec__projections-description.content-box__description {
  padding-top: var(--emu-semantic-spacing-none);
  margin-bottom: var(--emu-semantic-spacing-none);
  max-width: 100%;
}

@media (min-width: 768px) {
  #natrelle-collection .five-fullness-sec__projections-description.content-box__description {
    max-width: 92%;
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .five-fullness-sec__projections-description.content-box__description {
    letter-spacing: .18px;
    max-width: 100%;
  }
}

#natrelle-collection .five-fullness-sec__five-type-title {
  margin-bottom: var(--emu-common-spacing-small);
  padding-top: 4%;
  padding-bottom: 4%;
}

#natrelle-collection .five-fullness-sec__five-type-image {
  overflow: hidden;
}

@media (min-width: 768px) {
  #natrelle-collection .five-fullness-sec__five-type-image {
    max-width: 100%;
    padding-top: 40px;
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .five-fullness-sec__five-type-image {
    max-width: var(--emu-common-sizing-xxxl);
  }
}

#natrelle-collection .five-fullness-sec__five-type-image .cmp-image {
  margin-left: -15px;
  margin-right: -17px;
}

@media (min-width: 768px) {
  #natrelle-collection .five-fullness-sec__five-type-image .cmp-image {
    margin-left: var(--emu-semantic-spacing-none);
    margin-right: var(--emu-semantic-spacing-none);
  }
}

@media (max-width: 1025px) {
  #natrelle-collection .five-fullness-sec__five-type-image.u-container-max-width {
    max-width: 100%;
  }
}

#natrelle-collection .five-fullness-sec__shell-block {
  margin-top: -5%;
}

@media (min-width: 768px) {
  #natrelle-collection .five-fullness-sec__shell-block {
    margin-top: var(--emu-semantic-spacing-none);
    display: flex;
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .five-fullness-sec__shell-block {
    margin-top: -5%;
  }
}

@media (min-width: 768px) {
  #natrelle-collection .five-fullness-sec__shell-block .container:first-child {
    max-width: 40%;
  }

  #natrelle-collection .five-fullness-sec__shell-block .container:last-child {
    max-width: 60%;
  }
}

#natrelle-collection .five-fullness-sec__shell-block-title {
  margin-bottom: var(--emu-common-spacing-small);
  padding-top: 7%;
}

@media (min-width: 768px) {
  #natrelle-collection .five-fullness-sec__shell-block-title {
    padding-top: 14%;
  }
}

@media (min-width: 992px) {
  #natrelle-collection .five-fullness-sec__shell-block-title.five-fullness-sec__sub-heading h3 {
    letter-spacing: normal;
  }
}

#natrelle-collection .five-fullness-sec__shell-block-description.content-box__description {
  margin-bottom: var(--emu-semantic-spacing-none);
  padding-top: 5%;
}

@media (min-width: 768px) {
  #natrelle-collection .five-fullness-sec__shell-block-description.content-box__description {
    padding-top: var(--emu-semantic-spacing-none);
    margin-bottom: var(--emu-common-spacing-medium);
    max-width: 92%;
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .five-fullness-sec__shell-block-description.content-box__description {
    letter-spacing: .2px;
    max-width: 100%;
  }
}

#natrelle-collection .five-fullness-sec__shell-block-description.content-box__description p {
  margin-bottom: var(--emu-semantic-spacing-none);
}

#natrelle-collection .five-fullness-sec__shell-block-description.content-box__description p sup {
  font-size: 45%;
}

#natrelle-collection .five-fullness-sec__shell-block-description.content-box__description p sup + sup + sup {
  font-size: 65%;
  top: .1em;
}

#natrelle-collection .round-breast-implants__above-vector-img > .cmp-image__image {
  background: linear-gradient(180deg, #99dde9 0%, #99dde9 50%, #99dde9 7%, #99dde9 2.25%, #99dde9 5%, var(--emu-common-colors-brand-blue-50) 99%, var(--emu-common-colors-brand-blue-50) 100%);
  width: 100%;
}

#natrelle-collection .round-breast-implants .patients-first {
  padding-top: 4%;
  padding-bottom: 6%;
}

#natrelle-collection .round-breast-implants .patients-first__container {
  padding-top: 15px;
  padding-bottom: 15px;
}

@media (min-width: 768px) {
  #natrelle-collection .round-breast-implants .patients-first__container {
    padding-top: var(--emu-semantic-sizing-none);
    padding-bottom: var(--emu-semantic-sizing-none);
  }
}

#natrelle-collection .round-breast-implants .patients-first__title {
  text-align: unset;
  margin-bottom: var(--emu-common-spacing-small);
  max-width: 100%;
  padding-top: 3%;
  padding-bottom: 2%;
}

@media (min-width: 768px) {
  #natrelle-collection .round-breast-implants .patients-first__title {
    padding-top: 3%;
  }

  #natrelle-collection .round-breast-implants .patients-first__title > h2 {
    font-size: 25.6px;
    line-height: var(--emu-common-line-heights-wide-xxl);
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .round-breast-implants .patients-first__title > h2 {
    font-size: var(--emu-common-font-sizes-wide-xxl);
    line-height: 60px;
  }
}

@media (min-width: 1200px) {
  #natrelle-collection .round-breast-implants .patients-first__title > h2 {
    line-height: var(--emu-common-line-heights-wide-xxl);
  }
}

#natrelle-collection .round-breast-implants .patients-first__title > h2 > i {
  letter-spacing: -.7px;
}

@media (min-width: 768px) {
  #natrelle-collection .round-breast-implants .patients-first__title > h2 > i {
    letter-spacing: -.5px;
  }
}

@media (min-width: 992px) {
  #natrelle-collection .round-breast-implants .patients-first__title > h2 > i {
    letter-spacing: -1px;
  }
}

#natrelle-collection .round-breast-implants .patients-first__title > h2 sup {
  font-size: 47%;
  font-weight: var(--emu-semantic-font-weight-black);
  font-style: normal;
  top: 0;
  left: -1px;
}

@media (min-width: 992px) {
  #natrelle-collection .round-breast-implants .patients-first__title > h2 sup {
    top: -7px;
    left: -3px;
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .round-breast-implants .patients-first__title > h2 sup {
    top: -4px;
  }
}

#natrelle-collection .round-breast-implants .patients-first__content {
  margin-bottom: var(--emu-common-spacing-medium);
  max-width: 100%;
  padding-top: 4%;
  padding-bottom: 4%;
}

@media (min-width: 768px) {
  #natrelle-collection .round-breast-implants .patients-first__content {
    padding-top: var(--emu-semantic-spacing-none);
    padding-bottom: var(--emu-semantic-spacing-none);
    width: 65%;
  }
}

#natrelle-collection .round-breast-implants .patients-first__content.text > p {
  letter-spacing: .55px;
}

@media (min-width: 768px) {
  #natrelle-collection .round-breast-implants .patients-first__content.text > p {
    font-size: var(--emu-semantic-font-sizes-wide-body);
    line-height: var(--emu-semantic-line-heights-wide-body);
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .round-breast-implants .patients-first__content.text > p {
    letter-spacing: .2px;
  }
}

#natrelle-collection .round-breast-implants .patients-first__content.text > p > br {
  display: none;
}

@media (min-width: 992px) {
  #natrelle-collection .round-breast-implants .patients-first__content.text > p > br {
    display: block;
  }
}

@media (min-width: 768px) {
  #natrelle-collection .round-breast-implants .patients-first__image {
    max-width: 255px;
    top: 0;
    right: 0;
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .round-breast-implants .patients-first__image {
    max-width: 360px;
  }
}

#natrelle-collection .round-breast-implants .patients-first__image-mobile {
  margin-top: var(--emu-semantic-spacing-none);
  margin-bottom: var(--emu-semantic-spacing-none);
  width: 100%;
}

#natrelle-collection .round-breast-implants .patients-first__image-mobile > .cmp-image__image {
  width: 345px;
  margin-left: -15px;
}

#natrelle-collection .generation-true-form {
  margin-top: -10px;
}

@media (min-width: 768px) {
  #natrelle-collection .generation-true-form {
    margin-top: var(--emu-common-spacing-none);
  }
}

#natrelle-collection .generation-true-form__vector-img > .cmp-image__image {
  background: linear-gradient(45deg, var(--emu-common-colors-brand-blue-50), var(--emu-common-colors-brand-blue-50));
  width: 100%;
}

#natrelle-collection .generation-true-form__vector-img-mobile > .cmp-image__image {
  background: linear-gradient(180deg, var(--emu-common-colors-brand-blue-50) 0%, var(--emu-common-colors-brand-blue-50) 10%, var(--emu-common-colors-brand-blue-500) 2%, var(--emu-common-colors-brand-blue-500) 2.25%, var(--emu-common-colors-brand-blue-500) 7%, var(--emu-common-colors-brand-blue-500) 10%, var(--emu-common-colors-brand-blue-500) 100%);
  width: 100%;
  min-height: 40px;
}

@media (min-width: 576px) {
  #natrelle-collection .generation-true-form__vector-img-mobile > .cmp-image__image {
    min-height: 45px;
  }
}

#natrelle-collection .generation-true-form__container {
  padding-bottom: 33px;
}

@media (min-width: 768px) {
  #natrelle-collection .generation-true-form__container {
    padding-bottom: var(--emu-semantic-spacing-none);
  }
}

#natrelle-collection .generation-true-form__wrapper {
  margin-top: -7px;
  padding-top: 15%;
  padding-bottom: 8%;
}

@media (min-width: 768px) {
  #natrelle-collection .generation-true-form__wrapper {
    padding-top: 2%;
    padding-bottom: 5%;
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .generation-true-form__wrapper {
    margin-top: -7px;
  }
}

#natrelle-collection .generation-true-form .content-box__title {
  margin-bottom: var(--emu-common-spacing-small);
}

#natrelle-collection .generation-true-form .content-box__title > h2 {
  font-weight: var(--emu-semantic-font-weight-medium);
  letter-spacing: -.3px;
}

@media (min-width: 768px) {
  #natrelle-collection .generation-true-form .content-box__title > h2 {
    font-size: var(--emu-common-font-sizes-narrow-xxl);
    line-height: 48px;
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .generation-true-form .content-box__title > h2 {
    font-size: var(--emu-common-font-sizes-wide-xxl);
    line-height: var(--emu-common-line-heights-wide-xxl);
  }
}

#natrelle-collection .generation-true-form .content-box__title > h2 > b {
  letter-spacing: -.39px;
}

@media (min-width: 1025px) {
  #natrelle-collection .generation-true-form .content-box__title > h2 > b {
    letter-spacing: -1.5px;
  }
}

#natrelle-collection .generation-true-form .content-box__description {
  margin-bottom: var(--emu-common-spacing-medium);
  max-width: 100%;
  padding-top: .9%;
}

@media (min-width: 768px) {
  #natrelle-collection .generation-true-form .content-box__description {
    font-size: var(--emu-common-font-sizes-narrow-body);
    line-height: var(--emu-semantic-line-heights-narrow-body);
    max-width: 92%;
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .generation-true-form .content-box__description {
    max-width: 100%;
    padding-top: .9%;
  }
}

@media (min-width: 1200px) {
  #natrelle-collection .generation-true-form .content-box__description {
    text-indent: unset;
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .generation-true-form .content-box__description > p {
    letter-spacing: .18px;
  }
}

#natrelle-collection .generation-true-form .content-box__description > p u {
  font-size: var(--emu-common-font-sizes-wide-small);
  font-family: var(--emu-common-font-families-graphik);
  line-height: 0;
}

#natrelle-collection .generation-true-form .content-box__description--list {
  padding-top: 1%;
}

@media (min-width: 768px) {
  #natrelle-collection .generation-true-form .content-box__description--list {
    max-width: 100%;
  }
}

#natrelle-collection .generation-true-form .content-box__description--list > p {
  padding-top: 1%;
}

@media (min-width: 1025px) {
  #natrelle-collection .generation-true-form .content-box__description--list > p {
    padding-top: .9%;
  }
}

#natrelle-collection .generation-true-form .content-box__description--list > ul {
  margin-top: var(--emu-semantic-spacing-none);
  margin-bottom: var(--emu-semantic-spacing-none);
  letter-spacing: 0;
  padding-left: 7%;
}

@media (min-width: 768px) {
  #natrelle-collection .generation-true-form .content-box__description--list > ul {
    padding-left: 3%;
  }
}

#natrelle-collection .generation-true-form .content-box__description--list > ul > li {
  margin-bottom: var(--emu-semantic-spacing-none);
  letter-spacing: normal;
  font-family: var(--emu-common-font-families-graphik);
}

@media (min-width: 576px) {
  #natrelle-collection .generation-true-form__silicon-block {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  #natrelle-collection .generation-true-form__silicon-block {
    padding-left: 4%;
    padding-right: 4%;
  }
}

#natrelle-collection .generation-true-form__fifth-truform {
  padding-top: 3%;
}

#natrelle-collection .generation-true-form__fifth-truform .content-box__title {
  margin-bottom: var(--emu-semantic-spacing-none);
}

#natrelle-collection .generation-true-form__fifth-truform .content-box__title > h2 {
  font-size: 33.6px;
  font-weight: var(--emu-semantic-font-weight-regular);
  margin-bottom: var(--emu-semantic-spacing-none);
  padding-bottom: 4%;
}

@media (min-width: 768px) {
  #natrelle-collection .generation-true-form__fifth-truform .content-box__title > h2 {
    font-size: var(--emu-common-font-sizes-narrow-xxl);
    padding-bottom: var(--emu-semantic-spacing-none);
    letter-spacing: -.29px;
    line-height: 48px;
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .generation-true-form__fifth-truform .content-box__title > h2 {
    font-size: var(--emu-common-font-sizes-wide-xxl);
    line-height: var(--emu-common-line-heights-wide-xxl);
    letter-spacing: -.5px;
  }
}

@media (min-width: 1440px) {
  #natrelle-collection .generation-true-form__fifth-truform .content-box__title > h2 {
    font-size: var(--emu-common-font-sizes-wide-xxl);
    letter-spacing: -.39px;
  }
}

#natrelle-collection .generation-true-form__fifth-truform .content-box__title > h2 > sup {
  display: inline;
  top: 3px;
  left: -3px;
}

@media (min-width: 1025px) {
  #natrelle-collection .generation-true-form__fifth-truform .content-box__title > h2 > sup {
    left: -6px;
  }
}

#natrelle-collection .generation-true-form__fifth-truform .content-box__title > h2 > sup + sup {
  font-size: 43%;
  font-weight: var(--emu-semantic-font-weight-black);
  top: -3px;
  left: 0;
}

@media (min-width: 1025px) {
  #natrelle-collection .generation-true-form__fifth-truform .content-box__title > h2 > sup + sup {
    top: -7px;
  }
}

@media (min-width: 768px) {
  #natrelle-collection .generation-true-form__fifth-truform .content-box__description {
    padding-top: .9%;
  }
}

#natrelle-collection .generation-true-form__fifth-truform .content-box__description > p sup {
  font-size: 50%;
  top: -2px;
  left: -2px;
}

#natrelle-collection .generation-true-form__carousel-container {
  padding-top: 10px;
}

@media (min-width: 768px) {
  #natrelle-collection .generation-true-form__carousel-container {
    padding-top: var(--emu-semantic-spacing-none);
  }
}

#natrelle-collection .generation-true-form__carousel-container .carousel {
  padding-top: var(--emu-semantic-spacing-none);
  padding-left: var(--emu-semantic-spacing-none);
  padding-right: var(--emu-semantic-spacing-none);
  background-color: var(--emu-common-colors-transparent);
  margin-bottom: -3px;
}

#natrelle-collection .generation-true-form__carousel-container .carousel.panelcontainer {
  max-width: 100%;
  display: block;
}

@media (min-width: 768px) {
  #natrelle-collection .generation-true-form__carousel {
    padding-bottom: var(--emu-semantic-spacing-none);
  }
}

#natrelle-collection .generation-true-form__carousel.emu-carousel .tns-nav {
  padding-left: var(--emu-semantic-spacing-none);
  position: absolute;
  bottom: -28px;
}

#natrelle-collection .generation-true-form__carousel.emu-carousel .tns-nav button {
  width: 15px;
  height: 15px;
  margin-left: 5px;
  margin-right: 5px;
}

#natrelle-collection .generation-true-form__carousel.emu-carousel {
  border-color: var(--emu-common-colors-brand-blue-500);
  background-color: var(--emu-common-colors-brand-blue-500);
  padding-bottom: var(--emu-semantic-spacing-none);
}

#natrelle-collection .generation-true-form__carousel.emu-carousel .emu-carousel__item {
  border-color: var(--emu-common-colors-brand-blue-500);
  background-color: var(--emu-common-colors-brand-blue-500);
}

#natrelle-collection .generation-true-form__carousel .tns-controls {
  display: none;
}

#natrelle-collection .generation-true-form__carousel .emu-carousel__content {
  min-height: 490px;
}

@media (min-width: 768px) {
  #natrelle-collection .generation-true-form__carousel .emu-carousel__content {
    background-color: var(--emu-common-colors-brand-blue-500);
    min-height: auto;
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
  }

  #natrelle-collection .generation-true-form__carousel .emu-carousel__content .emu-carousel__item {
    width: 100%;
    max-width: 33.66%;
  }

  #natrelle-collection .generation-true-form__carousel-item {
    padding-top: var(--emu-semantic-spacing-none);
    min-height: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}

#natrelle-collection .generation-true-form__image-wrap {
  max-width: 100%;
  position: relative;
}

#natrelle-collection .generation-true-form__image-wrap img {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  #natrelle-collection .generation-true-form__image-wrap img {
    width: 100%;
    max-width: 200px;
  }
}

@media (min-width: 1200px) {
  #natrelle-collection .generation-true-form__image-wrap img {
    width: 85%;
  }
}

#natrelle-collection .generation-true-form__image-wrap .cmp-text {
  cursor: default;
  font-family: var(--emu-common-font-families-graphik);
  padding-top: 14%;
  padding-bottom: 5%;
}

@media (min-width: 768px) {
  #natrelle-collection .generation-true-form__image-wrap .cmp-text {
    padding-top: 18%;
    padding-bottom: var(--emu-semantic-spacing-none);
    font-size: 15px;
    line-height: 21px;
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .generation-true-form__image-wrap .cmp-text {
    font-size: var(--emu-semantic-font-sizes-wide-body);
    padding-top: 18%;
  }
}

#natrelle-collection .generation-true-form__image-wrap .cmp-text > p {
  margin-bottom: var(--emu-semantic-spacing-none);
}

#natrelle-collection .generation-true-form__image-wrap .cmp-text > p sup {
  font-size: 54%;
}

#natrelle-collection .generation-true-form__bg-img .cmp-image__image {
  background: linear-gradient(180deg, var(--emu-common-colors-brand-blue-500) 0%, var(--emu-common-colors-brand-blue-500) 10%, var(--emu-common-colors-white) 15%, var(--emu-common-colors-white) 100%);
  width: 100%;
}

@media (min-width: 768px) {
  #natrelle-collection .generation-true-form__bg-img .cmp-image__image {
    background: none;
  }
}

#natrelle-collection .aesthetics-section .patients-first {
  margin-top: -.2%;
  padding-top: 35px;
  padding-bottom: 10%;
}

@media (min-width: 768px) {
  #natrelle-collection .aesthetics-section .patients-first {
    margin-top: var(--emu-semantic-spacing-none);
    padding-top: 5%;
    padding-bottom: 5%;
  }
}

#natrelle-collection .aesthetics-section .patients-first__title {
  max-width: 100%;
  margin-bottom: 39px;
}

@media (min-width: 768px) {
  #natrelle-collection .aesthetics-section .patients-first__title {
    margin-bottom: 24px;
  }

  #natrelle-collection .aesthetics-section .patients-first__title > h2 {
    font-size: var(--emu-semantic-font-sizes-narrow-xxl);
    line-height: 48px;
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .aesthetics-section .patients-first__title > h2 {
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
    line-height: var(--emu-semantic-line-heights-wide-xxl);
  }
}

@media (min-width: 1440px) {
  #natrelle-collection .aesthetics-section .patients-first__title > h2 {
    letter-spacing: -.1px;
  }
}

#natrelle-collection .aesthetics-section .patients-first__title > h2 > i {
  display: block;
}

@media (min-width: 768px) {
  #natrelle-collection .aesthetics-section .patients-first__title > h2 > i {
    letter-spacing: -1px;
    display: inline-block;
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .aesthetics-section .patients-first__title > h2 > i {
    letter-spacing: -1.5px;
  }
}

@media (min-width: 768px) {
  #natrelle-collection .aesthetics-section .patients-first__image {
    top: 5%;
    right: -1px;
  }
}

@media (min-width: 992px) {
  #natrelle-collection .aesthetics-section .patients-first__image {
    top: 6%;
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .aesthetics-section .patients-first__image {
    top: 5%;
  }
}

#natrelle-collection .aesthetics-section .patients-first__image-mobile {
  margin-top: var(--emu-common-spacing-none);
  width: 80%;
  margin-bottom: 15px;
}

#natrelle-collection .aesthetics-section .patients-first__content {
  margin-bottom: 5%;
  margin-bottom: var(--emu-common-spacing-none);
  max-width: 88%;
  padding-top: 9%;
  padding-bottom: 5%;
}

@media (min-width: 768px) {
  #natrelle-collection .aesthetics-section .patients-first__content {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-common-spacing-large);
    max-width: 93%;
  }
}

@media (min-width: 992px) {
  #natrelle-collection .aesthetics-section .patients-first__content {
    max-width: 92%;
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .aesthetics-section .patients-first__content {
    max-width: 88%;
  }
}

#natrelle-collection .aesthetics-section .patients-first__content.text > p {
  letter-spacing: .6px;
}

@media (min-width: 768px) {
  #natrelle-collection .aesthetics-section .patients-first__content.text > p {
    font-size: var(--emu-common-font-sizes-wide-body);
    line-height: var(--emu-common-line-heights-narrow-body);
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .aesthetics-section .patients-first__content.text > p {
    letter-spacing: 1.26px;
  }
}

#natrelle-collection .aesthetics-section .patients-first__btn.emu-button.emu-button-v2.emu-button__primary-outline {
  width: 280px;
  margin-top: 15px;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  #natrelle-collection .aesthetics-section .patients-first__btn.emu-button.emu-button-v2.emu-button__primary-outline {
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-common-spacing-none);
    width: 100%;
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
}

#natrelle-collection .faq__top-mobile-wave {
  margin-right: -2px;
}

#natrelle-collection .faq__top-mobile-wave .cmp-image__image {
  background: linear-gradient(#daf2f6 0% 35%, #003359 35.1% 2.25%, #003359 7% 10%, #003359 100%);
}

#natrelle-collection .faq__wave {
  height: 85px;
  margin-top: -2px;
  overflow: hidden;
}

@media (min-width: 768px) {
  #natrelle-collection .faq__wave {
    height: 80px;
    margin-top: -14px;
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .faq__wave {
    margin-top: var(--emu-common-spacing-none);
    height: auto;
  }
}

@media (min-width: 768px) {
  #natrelle-collection .faq__wave img {
    background: linear-gradient(45deg, var(--emu-common-colors-brand-blue-50), var(--emu-common-colors-brand-blue-50));
  }
}

@media (min-width: 1200px) {
  #natrelle-collection .faq__wave img {
    max-width: 1442px;
  }
}

#natrelle-collection .faq__title {
  padding-bottom: 2.5%;
  padding-top: var(--emu-common-spacing-none);
  letter-spacing: -.6px;
  line-height: 42px;
}

@media (min-width: 768px) {
  #natrelle-collection .faq__title {
    color: var(--emu-common-colors-white);
    letter-spacing: -.1px;
    padding-top: 4.5%;
    font-size: 25.28px;
    line-height: 34px;
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .faq__title {
    font-size: 35.2px;
    line-height: 58.5px;
  }
}

@media (min-width: 1200px) {
  #natrelle-collection .faq__title {
    font-size: 43.2px;
    line-height: 57px;
  }
}

#natrelle-collection .faq__title-container {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: var(--emu-common-spacing-xs);
  margin-top: -5px;
}

@media (min-width: 1025px) {
  #natrelle-collection .faq__title-container {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 992px) {
  #natrelle-collection .faq__title-container > .text {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .faq__title-container > .text {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    max-width: 1170px;
  }
}

#natrelle-collection .faq__title br {
  display: none;
}

@media (min-width: 1025px) {
  #natrelle-collection .faq__title br {
    display: block;
  }
}

#natrelle-collection .faq__main {
  padding-bottom: 22px;
}

@media (min-width: 768px) {
  #natrelle-collection .faq__main {
    padding: 5px 15px 50px;
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .faq__main {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 992px) {
  #natrelle-collection .faq__main .accordion {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media (min-width: 1025px) {
  #natrelle-collection .faq__main .accordion {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    max-width: 1170px;
  }
}

#natrelle-collection .faq__main .aaaem-accordion .aaaem-accordion__panel {
  margin-top: -15px;
}

@media (min-width: 768px) {
  #natrelle-collection .faq__main .aaaem-accordion .aaaem-accordion__panel {
    margin-bottom: 35px;
  }
}

#natrelle-collection .faq__main .aaaem-accordion .aaaem-accordion__panel.-ba-is-active {
  margin-bottom: 20px;
  margin-top: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #natrelle-collection .faq__main .aaaem-accordion .aaaem-accordion__panel.-ba-is-active {
    margin-top: -5px;
  }
}

#natrelle-collection #fas .nca-fas__above-vector-img {
  background-color: var(--emu-common-colors-brand-blue-500);
  margin-bottom: -6px;
}

@media (min-width: 768px) {
  #natrelle-collection #fas .nca-fas__above-vector-img {
    display: none;
  }
}

@media (min-width: 1025px) {
  #natrelle-collection #fas .nca-fas__bg-img {
    margin-top: var(--emu-semantic-spacing-none);
  }
}

#natrelle-collection #fas .nca-fas__inner-container .content-box__title h2 {
  line-height: 48px;
}

@media (min-width: 1025px) {
  #natrelle-collection #fas .nca-fas__inner-container .content-box__title h2 {
    line-height: var(--emu-common-line-heights-wide-xxl);
  }
}

#natrelle-collection #fas .nca-fas__inner-container .content-box__description {
  letter-spacing: 1.26px;
  line-height: 29px;
}

@media (min-width: 768px) {
  #natrelle-collection #fas .nca-fas__inner-container .content-box__description {
    font-size: var(--emu-common-font-sizes-narrow-body);
    line-height: var(--emu-common-line-heights-wide-body);
    letter-spacing: .54px;
  }
}

@media (min-width: 1025px) {
  #natrelle-collection #fas .nca-fas__inner-container .content-box__description {
    letter-spacing: .18px;
    line-height: 30px;
  }
}

@media (min-width: 768px) {
  #natrelle-collection #fas .nca-fas__inner-container .content-box__description.emphasis br {
    display: block;
  }
}

[lang="fr_ca"] #natrelle-collection .natrelle-inspira-implants .image-text__title h2 sup {
  font-family: var(--emu-common-font-families-aleo);
  font-weight: var(--emu-common-font-weight-medium);
  font-size: 55%;
  display: inline;
  top: .4px;
  left: 0;
}

@media (max-width: 767px) {
  [lang="fr_ca"] #natrelle-collection .natrelle-inspira-implants .image-text__image-container-image img {
    max-width: 250px;
    top: 88%;
  }
}

#breast-surgery-resources .resources {
  background: linear-gradient(360deg, var(--emu-common-colors-brand-blue-100) 0%, #99dde800 100%);
}

#breast-surgery-resources .resources__intro {
  margin-top: 24px;
}

@media (min-width: 768px) {
  #breast-surgery-resources .resources__intro {
    margin-top: 48px;
  }
}

@media (min-width: 1200px) {
  #breast-surgery-resources .resources__intro {
    margin-top: var(--emu-common-spacing-xl);
  }
}

#breast-surgery-resources .resources__intro-inner {
  padding-left: 15px;
  padding-right: 15px;
}

#breast-surgery-resources .resources__intro-title {
  margin-bottom: var(--emu-common-spacing-medium);
}

@media (min-width: 768px) {
  #breast-surgery-resources .resources__intro-title {
    letter-spacing: -.4px;
    font-size: 32px;
    line-height: 1.15;
  }
}

@media (min-width: 1025px) {
  #breast-surgery-resources .resources__intro-title {
    margin-bottom: 24px;
    font-size: 56px;
  }
}

@media (min-width: 768px) {
  #breast-surgery-resources .resources__intro-title h1, #breast-surgery-resources .resources__intro-title h2, #breast-surgery-resources .resources__intro-title h3 {
    font-size: inherit;
    line-height: inherit;
  }
}

#breast-surgery-resources .resources__intro-text {
  letter-spacing: 1.26px;
  margin-bottom: var(--emu-common-spacing-medium);
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  #breast-surgery-resources .resources__intro-text {
    max-width: 100%;
  }
}

@media (min-width: 1025px) {
  #breast-surgery-resources .resources__intro-text {
    font-size: var(--emu-common-font-sizes-wide-small);
    letter-spacing: 1.12px;
  }
}

#breast-surgery-resources .resources__intro-text p {
  margin-bottom: var(--emu-common-spacing-none);
}

#breast-surgery-resources .resources__content {
  padding-bottom: 35px;
}

@media (min-width: 768px) {
  #breast-surgery-resources .resources__content {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 85px;
    padding-bottom: 85px;
  }
}

#breast-surgery-resources .resources__content-inner {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 992px) {
  #breast-surgery-resources .resources__content-inner {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 768px) {
  #breast-surgery-resources .resources .carousel {
    margin: var(--emu-common-spacing-none);
  }
}

#breast-surgery-resources .resources__carousel {
  border: none;
}

#breast-surgery-resources .resources__carousel .tns-nav {
  padding: var(--emu-common-spacing-none);
  gap: var(--emu-common-spacing-none);
  margin-top: var(--emu-common-spacing-small);
  height: 30px;
  top: 40%;
  bottom: auto;
}

@media (min-width: 576px) {
  #breast-surgery-resources .resources__carousel .tns-nav {
    top: auto;
    bottom: -30px;
  }
}

#breast-surgery-resources .resources__carousel .tns-nav > button {
  background-color: var(--emu-common-colors-black);
  opacity: .25;
  border: none;
  width: 10px;
  height: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 5px;
}

#breast-surgery-resources .resources__carousel .tns-nav > button.tns-nav-active {
  opacity: .75;
}

#breast-surgery-resources .resources__carousel .emu-carousel__item-container {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 576px) {
  #breast-surgery-resources .resources__carousel-item {
    align-items: center;
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
  }

  #breast-surgery-resources .resources__carousel-item > .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  #breast-surgery-resources .resources__carousel-item > .container:first-child {
    flex: 16.667%;
  }

  #breast-surgery-resources .resources__carousel-item > .container:last-child {
    flex: 83.333%;
  }
}

#breast-surgery-resources .resources__carousel-item-img-container {
  justify-content: center;
  align-items: center;
  max-width: 200px;
  min-height: 350px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

#breast-surgery-resources .resources__carousel-item-img-container img {
  margin-top: var(--emu-common-spacing-medium);
  margin-right: auto;
  margin-bottom: var(--emu-common-spacing-medium);
  margin-left: auto;
}

#breast-surgery-resources .resources__carousel-item-title {
  margin-top: 48px;
  margin-bottom: var(--emu-common-spacing-medium);
  letter-spacing: -.6px;
}

#breast-surgery-resources .resources__carousel-item-text {
  letter-spacing: 1.26px;
  margin-bottom: 20px;
}

#breast-surgery-resources .resources__carousel-item-cta {
  text-align: center;
  letter-spacing: 4.2px;
  justify-content: center;
  width: 100%;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  padding: 15px 25px;
  line-height: 22px;
}

@media (min-width: 992px) {
  #breast-surgery-resources .resources__tabs {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1200px) {
  #breast-surgery-resources .resources__tabs {
    max-width: var(--emu-common-sizing-xxxl);
  }
}

#breast-surgery-resources .resources__tabs-item-inner {
  align-items: center;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

#breast-surgery-resources .resources__tabs-item-inner > .container {
  padding-left: 15px;
  padding-right: 15px;
}

#breast-surgery-resources .resources__tabs-item-inner > .container:first-child {
  flex: 16.667%;
}

@media (min-width: 1025px) {
  #breast-surgery-resources .resources__tabs-item-inner > .container:first-child {
    padding-left: var(--emu-common-spacing-none);
  }
}

#breast-surgery-resources .resources__tabs-item-inner > .container:last-child {
  flex: 83.333%;
}

#breast-surgery-resources .resources__tabs-item-title {
  font-size: var(--emu-common-font-sizes-narrow-xl);
  margin-bottom: var(--emu-common-spacing-medium);
  letter-spacing: -.6px;
  line-height: 1.15;
}

@media (min-width: 1025px) {
  #breast-surgery-resources .resources__tabs-item-title {
    font-size: var(--emu-common-font-sizes-wide-xxl);
  }
}

#breast-surgery-resources .resources__tabs-item-title h1, #breast-surgery-resources .resources__tabs-item-title h2, #breast-surgery-resources .resources__tabs-item-title h3 {
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

#breast-surgery-resources .resources__tabs-item-text {
  letter-spacing: .07em;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.15;
}

@media (min-width: 1025px) {
  #breast-surgery-resources .resources__tabs-item-text {
    font-size: var(--emu-common-line-heights-wide-xs);
    line-height: var(--emu-common-line-heights-wide-body);
  }
}

#breast-surgery-resources .resources__tabs-item-text p {
  margin-bottom: var(--emu-common-spacing-none);
}

#breast-surgery-resources .resources__tabs-item-cta {
  font-size: var(--emu-common-font-sizes-narrow-small);
  line-height: var(--emu-common-line-heights-narrow-xs);
  letter-spacing: .3em;
  padding: 10px 15px;
}

@media (min-width: 1025px) {
  #breast-surgery-resources .resources__tabs-item-cta {
    padding: 15px 30px;
    font-size: 14px;
    line-height: 16px;
  }
}

#breast-surgery-resources .patients-first {
  padding-top: 35px;
  padding-bottom: 35px;
}

@media (min-width: 768px) {
  #breast-surgery-resources .patients-first {
    padding-top: var(--emu-common-spacing-xl);
    padding-bottom: var(--emu-common-spacing-xl);
  }
}

#breast-surgery-resources .patients-first__title {
  font-size: var(--emu-common-font-sizes-narrow-xl);
  margin-bottom: var(--emu-common-spacing-medium);
  line-height: 1.1;
}

@media (min-width: 768px) {
  #breast-surgery-resources .patients-first__title {
    margin-bottom: 24px;
    line-height: 40px;
  }
}

@media (min-width: 1025px) {
  #breast-surgery-resources .patients-first__title {
    font-size: var(--emu-common-font-sizes-wide-xxl);
    line-height: var(--emu-common-line-heights-wide-xxl);
    letter-spacing: -.8px;
  }
}

@media (max-width: 767px) {
  #breast-surgery-resources .patients-first__title i {
    font-size: var(--emu-common-font-sizes-narrow-xxl);
    line-height: var(--emu-common-line-heights-narrow-xxl);
  }
}

#breast-surgery-resources .patients-first__title h1, #breast-surgery-resources .patients-first__title h2, #breast-surgery-resources .patients-first__title h3 {
  font-size: inherit;
  line-height: inherit;
}

@media (min-width: 768px) {
  #breast-surgery-resources .patients-first__image {
    top: 0;
    right: 0;
  }
}

#breast-surgery-resources .patients-first__image-mobile {
  margin-top: 26px;
  margin-bottom: 25px;
}

#breast-surgery-resources .patients-first__content {
  padding-top: var(--emu-common-spacing-none);
  letter-spacing: .07em;
  max-width: 90%;
}

@media (min-width: 768px) {
  #breast-surgery-resources .patients-first__content {
    margin-bottom: var(--emu-common-spacing-large);
    max-width: 88%;
    font-size: 16px;
  }
}

@media (min-width: 1025px) {
  #breast-surgery-resources .patients-first__content sup {
    margin-right: -1px;
  }
}

#breast-surgery-resources .patients-first__content p {
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

#breast-surgery-resources .patients-first .button {
  width: 100%;
  max-width: 95%;
}

#breast-surgery-resources .patients-first__btn {
  width: 100%;
  margin-bottom: 35px;
  padding-left: 25px;
  padding-right: 25px;
}

@media (min-width: 768px) {
  #breast-surgery-resources .patients-first__btn {
    margin: var(--emu-common-spacing-none);
    width: -moz-fit-content;
    width: fit-content;
    max-width: none;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1025px) {
  #breast-surgery-resources .patients-first__btn {
    padding-left: 30px;
    padding-right: 30px;
  }

  #breast-surgery-resources .patients-first__btn span {
    letter-spacing: .3em;
  }
}

#breast-surgery-resources .faq__mobile-top-wave {
  margin: var(--emu-common-spacing-none);
}

#breast-surgery-resources .faq__title {
  padding-top: 35px;
  padding-bottom: var(--emu-common-spacing-small);
}

@media (min-width: 768px) {
  #breast-surgery-resources .faq__title {
    padding: var(--emu-common-spacing-none);
    color: var(--emu-component-ingredients-title-color-light);
    background-color: var(--emu-common-colors-transparent);
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    top: 30%;
  }
}

@media (min-width: 1025px) {
  #breast-surgery-resources .faq__title {
    top: 35%;
  }
}

@media (min-width: 768px) {
  #breast-surgery-resources .faq__title h1, #breast-surgery-resources .faq__title h2, #breast-surgery-resources .faq__title h3, #breast-surgery-resources .faq__title h4 {
    margin-bottom: var(--emu-common-spacing-small);
  }

  #breast-surgery-resources .faq__title-container {
    max-height: 250px;
    position: relative;
  }
}

#breast-surgery-resources .faq__title .emphasis {
  display: inline;
}

#breast-surgery-resources .faq__main {
  margin-top: var(--emu-common-spacing-none);
  padding-top: 5px;
  padding-bottom: 5px;
}

@media (min-width: 768px) {
  #breast-surgery-resources .faq__main {
    padding-top: 50px;
    padding-bottom: var(--emu-common-spacing-none);
  }
}

#breast-surgery-resources .faq__main .aaaem-accordion {
  width: 100%;
}

#breast-surgery-resources .faq__main .has-special-list ul {
  margin-bottom: var(--emu-common-spacing-large);
}

#breast-surgery-resources .faq__main .has-special-list li {
  line-height: 24px;
}

#breast-surgery-resources .faq__main .has-special-list a {
  font-family: var(--emu-common-font-families-roboto);
  text-underline-offset: 1px;
}

@media (min-width: 1600px) {
  #breast-surgery-resources .fas-wrapper #fas .nca-fas {
    margin-top: var(--emu-common-spacing-none);
  }
}

#breast-surgery-resources .fas-wrapper #fas .nca-fas__above-vector {
  display: none;
}

#breast-surgery-resources .fas-wrapper #fas .nca-fas__inner-container-content {
  padding-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #breast-surgery-resources .fas-wrapper #fas .nca-fas__inner-container-content {
    top: 100px;
  }
}

#breast-surgery-resources .fas-wrapper #fas .nca-fas__inner-container-content .content-box__description {
  line-height: 23px;
}

@media (min-width: 768px) {
  #breast-surgery-resources .fas-wrapper #fas .nca-fas__inner-container-content .content-box__description {
    font-size: var(--emu-common-font-sizes-wide-body);
    letter-spacing: .03em;
    line-height: 30px;
  }
}

@media (min-width: 1025px) {
  #breast-surgery-resources .fas-wrapper #fas .nca-fas__inner-container-content .content-box__description {
    letter-spacing: normal;
  }
}

@media (min-width: 768px) {
  #breast-surgery-resources .fas-wrapper #fas .nca-fas__inner-container-content .content-box__description.emphasis br {
    display: block;
  }
}

@media (min-width: 1025px) {
  #breast-surgery-resources .fas-wrapper #fas .nca-fas__inner-container-content .content-box__button span {
    letter-spacing: .3em;
    font-size: 14px;
  }
}

@media (min-width: 1200px) {
  #breast-surgery-resources .fas-wrapper #fas .nca-fas__bg-img {
    margin-top: var(--emu-common-spacing-none);
  }
}

@media (min-width: 768px) {
  #breast-surgery-resources .fas-wrapper #fas .nca-fas__bg-img img {
    display: none;
  }

  #breast-surgery-resources .fas-wrapper #fas .nca-fas__bg-img .resources-page-image {
    display: block !important;
  }

  #breast-surgery-resources .fas-wrapper #fas .nca-fas__bg-img .resources-page-image img {
    display: block;
  }
}

#breast-surgery-resources .glossary {
  background-color: var(--emu-common-colors-brand-blue-400);
}

@media (min-width: 1025px) {
  #breast-surgery-resources .glossary .accordion {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 992px) {
  #breast-surgery-resources .glossary .accordion.panelcontainer {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    max-width: 720px;
  }
}

@media (min-width: 1025px) {
  #breast-surgery-resources .glossary .accordion.panelcontainer {
    max-width: 1170px;
  }
}

#breast-surgery-resources .glossary .accordion .aaaem-accordion {
  padding-top: 17px;
  padding-bottom: 17px;
}

@media (min-width: 768px) {
  #breast-surgery-resources .glossary .accordion .aaaem-accordion {
    padding-top: 50px;
    padding-bottom: var(--emu-common-spacing-none);
  }
}

#breast-surgery-resources .glossary .accordion .aaaem-accordion__header {
  background-color: var(--emu-common-colors-transparent);
  box-shadow: unset;
  padding-top: var(--emu-common-spacing-large);
  padding-bottom: var(--emu-common-spacing-large);
  border: none;
  padding-left: 12px;
  padding-right: 12px;
}

@media (min-width: 768px) {
  #breast-surgery-resources .glossary .accordion .aaaem-accordion__header {
    padding-top: var(--emu-common-spacing-medium);
    padding-right: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-medium);
    padding-left: var(--emu-common-spacing-none);
  }

  #breast-surgery-resources .glossary .accordion .aaaem-accordion__header.-ba-is-active {
    padding-bottom: var(--emu-common-spacing-none);
  }
}

#breast-surgery-resources .glossary .accordion .aaaem-accordion__button {
  border: var(--emu-common-border-width-thin) solid var(--emu-common-colors-transparent);
  justify-content: center;
}

@media (min-width: 768px) {
  #breast-surgery-resources .glossary .accordion .aaaem-accordion__button {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

#breast-surgery-resources .glossary .accordion .aaaem-accordion__icon {
  margin-left: 20px;
}

#breast-surgery-resources .glossary .accordion .aaaem-accordion__title {
  color: var(--emu-common-colors-white);
  font-size: var(--emu-common-font-sizes-narrow-xl);
  line-height: 1.1;
  font-family: var(--emu-common-font-families-aleo);
  letter-spacing: var(--emu-semantic-spacing-none);
  flex-grow: 0;
  width: max-content;
}

@media (min-width: 768px) {
  #breast-surgery-resources .glossary .accordion .aaaem-accordion__title {
    font-size: var(--emu-common-font-sizes-wide-xxl);
    line-height: var(--emu-common-line-heights-wide-xxl);
  }
}

#breast-surgery-resources .glossary .accordion .aaaem-accordion__panel {
  background-color: var(--emu-common-colors-brand-blue-400);
  box-shadow: unset;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 1200px) {
  #breast-surgery-resources .glossary .accordion .aaaem-accordion__panel {
    max-width: 1200px;
  }
}

#breast-surgery-resources .glossary .accordion .aaaem-accordion__panel .js-badger-accordion-panel-inner {
  padding-top: var(--emu-common-spacing-large);
  padding-bottom: var(--emu-common-spacing-small);
  padding-left: var(--emu-common-spacing-medium);
  padding-right: var(--emu-common-spacing-medium);
  margin-top: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #breast-surgery-resources .glossary .accordion .aaaem-accordion__panel .js-badger-accordion-panel-inner {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media (min-width: 992px) {
  #breast-surgery-resources .glossary .accordion .aaaem-accordion__panel .js-badger-accordion-panel-inner {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#breast-surgery-resources .glossary .accordion .aaaem-accordion__panel .js-badger-accordion-panel-inner .cmp-text {
  width: 99%;
}

@media (min-width: 768px) {
  #breast-surgery-resources .glossary .accordion .aaaem-accordion__panel .js-badger-accordion-panel-inner .cmp-text {
    width: 100%;
  }

  #breast-surgery-resources .glossary .accordion .aaaem-accordion__panel .js-badger-accordion-panel-inner .cmp-text p {
    width: 98%;
  }
}

#breast-surgery-resources .glossary .accordion .aaaem-accordion__item {
  margin: var(--emu-common-spacing-none);
}

@media (min-width: 992px) {
  #breast-surgery-resources .glossary__content-wrapper {
    padding-bottom: 24px;
  }
}

#breast-surgery-resources .glossary__content-wrapper > div:first-child {
  grid-template-columns: 1fr;
  display: grid;
}

@media (min-width: 768px) {
  #breast-surgery-resources .glossary__content-wrapper > div:first-child {
    grid-template-columns: repeat(3, 1fr);
  }
}

#breast-surgery-resources .glossary__content-wrapper > div:first-child > .text {
  float: none;
}

@media (min-width: 768px) {
  #breast-surgery-resources .glossary__content-wrapper > div:first-child > .text {
    padding-left: 15px;
    padding-right: 15px;
  }
}

#breast-surgery-resources .glossary__content-wrapper > div:first-child.aem-Grid:before, #breast-surgery-resources .glossary__content-wrapper > div:first-child.aem-Grid:after {
  display: none;
}

#breast-surgery-resources .glossary__content-wrapper > div:first-child .cmp-text h4 {
  text-transform: uppercase;
  color: var(--emu-common-colors-brand-pink-500);
  font-size: var(--emu-common-font-sizes-wide-small);
  font-family: var(--emu-semantic-font-families-body);
  font-weight: 600;
  line-height: var(--emu-common-line-heights-narrow-body);
  letter-spacing: 1.1px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  #breast-surgery-resources .glossary__content-wrapper > div:first-child .cmp-text h4 {
    letter-spacing: .07em;
  }
}

#breast-surgery-resources .glossary__content-wrapper > div:first-child .cmp-text h4 sup {
  font-size: 8px;
  top: -1px;
  left: -2px;
}

#breast-surgery-resources .glossary__content-wrapper > div:first-child .cmp-text p {
  letter-spacing: .03em;
  color: var(--emu-common-colors-white);
  line-height: var(--emu-common-line-heights-narrow-body);
  margin-bottom: 26px;
}

#breast-surgery-resources .ext-links__wave .cmp-image__image {
  background-color: var(--emu-common-colors-brand-blue-400);
  width: 100%;
}

#breast-surgery-resources .ext-links__content-wrapper {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 30px 15px 28px;
}

@media (min-width: 768px) {
  #breast-surgery-resources .ext-links__content-wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

#breast-surgery-resources .ext-links__content-box {
  margin-top: 50px;
  margin-bottom: 50px;
  padding-bottom: 42px;
}

@media (min-width: 768px) {
  #breast-surgery-resources .ext-links__content-box {
    padding-bottom: 40px;
  }
}

@media (min-width: 1025px) {
  #breast-surgery-resources .ext-links__content-box {
    margin-top: 50px;
  }
}

#breast-surgery-resources .ext-links__content-title h2 {
  font-size: var(--emu-common-font-sizes-narrow-xl);
  line-height: 1.3;
  font-weight: var(--emu-common-font-weight-medium);
}

@media (min-width: 768px) {
  #breast-surgery-resources .ext-links__content-title h2 {
    font-size: var(--emu-common-font-sizes-wide-xl);
    margin-bottom: var(--emu-common-spacing-medium);
    line-height: 1.1;
  }
}

@media (min-width: 1025px) {
  #breast-surgery-resources .ext-links__content-title h2 {
    line-height: var(--emu-common-line-heights-wide-xxl);
  }
}

#breast-surgery-resources .ext-links__content-btn {
  margin-bottom: var(--emu-common-spacing-none);
  margin-top: 35px;
}

@media (min-width: 768px) {
  #breast-surgery-resources .ext-links__content-btn {
    font-size: var(--emu-common-font-sizes-narrow-small);
    margin: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1440px) {
  #breast-surgery-resources .ext-links__content-btn {
    font-size: 15px;
  }
}

#breast-surgery-resources .ext-links__content-btn.emu-button-v2.emu-button.emu-button {
  width: auto;
  padding-top: 15px;
  padding-bottom: 15px;
}

@media (min-width: 768px) {
  #breast-surgery-resources .ext-links__content-btn.emu-button-v2.emu-button.emu-button {
    padding-top: 9px;
    padding-bottom: 9px;
  }
}

@media (min-width: 1025px) {
  #breast-surgery-resources .ext-links__content-btn.emu-button-v2.emu-button.emu-button {
    padding: 15px 30px;
  }
}

#breast-surgery-resources .ext-links__content-btn.emu-button-v2.emu-button.emu-button .cmp-button__text {
  line-height: 16px;
  display: block;
}

@media (min-width: 768px) {
  #breast-surgery-resources .ext-links__content-btn.emu-button-v2.emu-button.emu-button .cmp-button__text {
    letter-spacing: .3em;
  }
}

@media (min-width: 1025px) {
  #breast-surgery-resources .ext-links__content-btn.emu-button-v2.emu-button.emu-button .cmp-button__text {
    font-size: 14px;
  }
}

[lang="fr_ca"] #breast-surgery-resources .glossary .accordion .aaaem-accordion__panel .js-badger-accordion-panel-inner .cmp-text p {
  width: 93%;
}

[lang="fr_ca"] #breast-surgery-resources .resources__intro-text {
  max-width: 100%;
}

[lang="fr_ca"] #breast-surgery-resources .ext-links .content-box__title h2 b {
  letter-spacing: -1.5px;
}

[lang="fr_ca"] #breast-surgery-resources .patients-first__title {
  max-width: 100%;
}

[lang="fr_ca"] #breast-surgery-resources .resources__tabs-item-cta {
  text-align: center;
}

[lang="fr_ca"] #breast-surgery-resources .aaaem-accordion__header {
  padding-top: 12px;
  padding-bottom: 12px;
}

[lang="fr_ca"] #breast-surgery-resources .resources__carousel .tns-nav {
  top: 35%;
}

@media (min-width: 576px) {
  [lang="fr_ca"] #breast-surgery-resources .resources__carousel .tns-nav {
    top: auto;
    bottom: -30px;
  }
}

#site-map .site-map__title {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

#site-map .site-map__title h1 {
  letter-spacing: -1.3px;
  padding-top: 5%;
  padding-bottom: 15%;
  font-size: 60px;
  line-height: 72px;
}

@media (min-width: 768px) {
  #site-map .site-map__title h1 {
    padding-top: 75px;
    padding-bottom: 30px;
  }
}

#site-map .site-map__wrapper > .container:not(:last-child) {
  max-width: 100%;
  margin: auto;
  padding-bottom: 6.5%;
}

@media (min-width: 768px) {
  #site-map .site-map__wrapper > .container:not(:last-child) {
    max-width: 85%;
  }
}

@media (min-width: 1025px) {
  #site-map .site-map__wrapper > .container:not(:last-child) {
    max-width: 810px;
  }
}

@media (min-width: 1200px) {
  #site-map .site-map__wrapper > .container:not(:last-child) {
    margin-bottom: var(--emu-common-spacing-xs);
  }
}

#site-map .site-map__list-wrapper {
  flex-wrap: wrap;
  display: flex;
}

#site-map .site-map__list-wrapper > .text {
  width: 100%;
}

@media (min-width: 576px) {
  #site-map .site-map__list-wrapper > .text {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 768px) {
  #site-map .site-map__list-wrapper > .text {
    justify-content: center;
    display: flex;
  }

  #site-map .site-map__list-wrapper .site-map__list {
    margin-bottom: 13px;
  }
}

#site-map .site-map__list-wrapper .site-map__list > ul {
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
  font-family: var(--emu-common-font-families-roboto);
}

#site-map .site-map__list-wrapper .site-map__list > ul > li {
  margin: var(--emu-common-spacing-none);
  list-style-type: none;
}

#site-map .site-map__list-wrapper .site-map__list > ul > li > b a {
  color: var(--emu-common-colors-dark-grey-400);
  font-size: var(--emu-common-font-sizes-narrow-body);
  letter-spacing: .54px;
  margin-top: 10px;
  margin-bottom: 12px;
  line-height: 24px;
  display: inline-block;
}

@media (min-width: 768px) {
  #site-map .site-map__list-wrapper .site-map__list > ul > li > b a {
    margin-bottom: var(--emu-common-spacing-medium);
    padding-top: 5%;
  }
}

@media (min-width: 1025px) {
  #site-map .site-map__list-wrapper .site-map__list > ul > li > b a {
    padding-top: var(--emu-common-spacing-none);
  }
}

#site-map .site-map__list-wrapper .site-map__list > ul > li > ul {
  margin-top: -6px;
  margin-bottom: 35.5px;
  padding-left: 8%;
}

@media (min-width: 768px) {
  #site-map .site-map__list-wrapper .site-map__list > ul > li > ul {
    margin-top: var(--emu-common-spacing-none);
    padding-left: 24px;
  }
}

#site-map .site-map__list-wrapper .site-map__list > ul > li > ul > li {
  font-size: 16px;
  line-height: 17.6px;
  font-family: var(--emu-common-font-families-roboto);
  margin-bottom: 7.5px;
  list-style-type: disc;
}

@media (min-width: 1200px) {
  #site-map .site-map__list-wrapper .site-map__list > ul > li > ul > li {
    margin-bottom: 7.25px;
  }
}

#site-map .site-map__list-wrapper .site-map__list > ul > li > ul > li b {
  display: inline-block;
}

#site-map .site-map__list-wrapper .site-map__list > ul > li > ul > li a {
  letter-spacing: .45px;
  color: var(--emu-common-colors-brand-blue-500);
  font-size: 15px;
  line-height: 18.5px;
}

@media (min-width: 768px) {
  #site-map .site-map__list-wrapper .site-map__list > ul > li > ul > li a {
    font-size: 14px;
  }
}

#site-map .site-map__list-wrapper .site-map__list a {
  text-decoration: none;
}

#site-map .site-map__list-wrapper .site-map__list a:hover, #site-map .site-map__list-wrapper .site-map__list a:active, #site-map .site-map__list-wrapper .site-map__list a:focus {
  text-decoration: underline;
}

#site-map .site-map__list-wrapper .site-map__list a sup {
  font-family: var(--emu-common-font-families-superscript-regular);
  font-size: 50%;
  top: -1px;
  left: 1px;
}

#site-map .site-map__squoosh {
  margin-bottom: -6px;
  margin-top: var(--emu-common-spacing-xxs);
}

@media (min-width: 1200px) {
  #site-map .site-map__squoosh {
    margin-top: var(--emu-common-spacing-xs);
  }
}

#site-map .site-map__squoosh img {
  width: 100%;
}

#site-map .header .emu-navigation__menu--level-0 li.emu-navigation__item--active > a, #site-map .header .emu-navigation__menu--level-0 li.emu-navigation__item--active > span a {
  color: var(--emu-common-colors-white);
}

#warranty .warranty__title {
  font-size: var(--emu-common-font-sizes-narrow-xxl);
  line-height: var(--emu-common-line-heights-narrow-xxl);
  letter-spacing: -.4px;
  font-weight: var(--emu-common-font-weight-bold);
}

@media (min-width: 768px) {
  #warranty .warranty__title {
    font-size: 44.8px;
    line-height: 1.15;
  }
}

#warranty .warranty__title h1, #warranty .warranty__title h2, #warranty .warranty__title h3 {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
}

#warranty .warranty__title sup {
  font-size: 40%;
  font-style: normal;
  font-weight: var(--emu-common-font-weight-bold);
  top: -4px;
  left: -1px;
}

#warranty .warranty__title .emphasis sup {
  font-size: 75%;
  top: 6px;
  left: -1px;
}

@media (min-width: 768px) {
  #warranty .warranty__title i {
    display: block;
  }
}

@media (min-width: 992px) {
  #warranty .warranty__title i {
    display: inline;
  }
}

#warranty .warranty__title-container {
  margin-top: 48px;
}

@media (min-width: 768px) {
  #warranty .warranty__title-container {
    margin-top: 80px;
  }

  #warranty .warranty__tabs-container {
    margin-top: 45px;
    margin-bottom: 65px;
  }
}

#warranty .warranty__tabs-title {
  padding-top: 25px;
}

@media (min-width: 768px) {
  #warranty .warranty__tabs-title {
    padding-top: var(--emu-common-spacing-none);
    font-family: var(--emu-common-font-families-graphik);
    padding-bottom: 4%;
  }
}

@media (min-width: 992px) {
  #warranty .warranty__tabs-title {
    text-align: center;
  }
}

@media (min-width: 768px) {
  #warranty .warranty__tabs-title b {
    font-size: 30px;
  }
}

#warranty .warranty__tabs-title p {
  margin-bottom: var(--emu-common-spacing-large);
  letter-spacing: .05em;
}

@media (min-width: 768px) {
  #warranty .warranty__tabs-title p {
    letter-spacing: normal;
    margin-bottom: var(--emu-common-spacing-none);
    font-size: 28px;
    line-height: 44.3px;
  }
}

@media (min-width: 992px) {
  #warranty .warranty__tabs-title p {
    font-size: 30px;
    line-height: 48px;
    display: inline;
  }
}

@media (min-width: 1200px) {
  #warranty .warranty__tabs-title p {
    display: block;
  }
}

#warranty .warranty__tabs-title p:last-child {
  letter-spacing: 4px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {
  #warranty .warranty__tabs-title p:last-child {
    margin-bottom: var(--emu-common-spacing-none);
    letter-spacing: normal;
  }
}

#warranty .warranty__tabs-title p sup {
  letter-spacing: normal;
  font-size: 45%;
}

#warranty .warranty__tabs-top-wave {
  margin-bottom: -7px;
}

#warranty .warranty__tabs-bottom-wave {
  margin-top: -7px;
}

#warranty .warranty__tabs-item {
  align-items: center;
  margin-top: 40px;
  display: flex;
}

@media (min-width: 768px) {
  #warranty .warranty__tabs-item {
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: 5%;
    margin-left: -15px;
    margin-right: -15px;
  }
}

#warranty .warranty__tabs-item--first {
  margin-top: 22px;
}

@media (min-width: 768px) {
  #warranty .warranty__tabs-item--first {
    margin-top: var(--emu-common-spacing-none);
  }
}

#warranty .warranty__tabs-item > .image {
  flex: 33.333%;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  #warranty .warranty__tabs-item > .image {
    flex: 16.6667%;
  }
}

@media (min-width: 1025px) {
  #warranty .warranty__tabs-item > .image {
    padding-left: var(--emu-common-spacing-none);
  }
}

#warranty .warranty__tabs-item > .text {
  flex: 66.667%;
  padding-left: 15px;
}

@media (min-width: 768px) {
  #warranty .warranty__tabs-item > .text {
    flex: 83.333%;
  }
}

#warranty .warranty__tabs-item-text {
  letter-spacing: 1.5px;
  font-family: var(--emu-common-font-families-graphik);
}

@media (min-width: 768px) {
  #warranty .warranty__tabs-item-text {
    letter-spacing: .13em;
    font-size: 18px;
    line-height: 35px;
  }
}

#warranty .warranty__tabs-item-text p {
  margin-bottom: var(--emu-common-spacing-none);
}

#warranty .warranty__tabs-item-text sup {
  font-size: 10px;
  font-family: var(--emu-common-font-families-superscript-regular);
  top: -1px;
}

@media (min-width: 768px) {
  #warranty .warranty__tabs-item-text sup {
    font-size: 7px;
    top: -2px;
  }

  #warranty .warranty__tabs-item-text b {
    font-size: 16px;
  }
}

#warranty .warranty__tabs-main .aaaem-tabs__tabpanel {
  overflow: visible;
}

#warranty .warranty__tabs-main .aaaem-tabs__tab {
  width: 320px;
}

@media (min-width: 768px) {
  #warranty .warranty__tabs-main .aaaem-tabs__tab {
    width: 45%;
  }
}

@media (min-width: 1200px) {
  #warranty .warranty__tabs-main .aaaem-tabs__tab {
    width: 535px;
  }
}

#warranty .warranty__tabs-main-container {
  padding-bottom: 35px;
}

@media (min-width: 768px) {
  #warranty .warranty__tabs-main-container {
    padding-bottom: var(--emu-common-spacing-none);
  }
}

@media (max-width: 767px) {
  #warranty .warranty__tabs-main-container--saline {
    padding-bottom: var(--emu-common-spacing-none);
  }

  #warranty .warranty__tabs-main-container--saline > .container {
    margin-left: -15px;
    margin-right: -15px;
  }

  #warranty .warranty__tabs-main-container--saline .text {
    color: var(--emu-common-colors-white);
  }

  #warranty .warranty__tabs-main-container--saline .warranty__tabs-main-container-inner {
    background-color: var(--emu-common-colors-brand-blue-500);
    padding: 35px 15px 40px;
  }

  #warranty .warranty__tabs-main-container--saline .warranty__tabs-title {
    padding-top: var(--emu-common-spacing-none);
  }

  #warranty .warranty__tabs-main-container--saline .warranty__tabs-title p {
    font-size: var(--emu-common-font-sizes-narrow-large);
    letter-spacing: 1.1px;
    margin-bottom: 5px;
  }

  #warranty .warranty__tabs-main-container--saline .warranty__tabs-title p b {
    font-size: var(--emu-common-font-sizes-narrow-body);
  }

  #warranty .warranty__tabs-main-container--saline .warranty__tabs-title p:last-child {
    font-size: var(--emu-common-font-sizes-narrow-body);
    letter-spacing: 4px;
    margin-bottom: 12px;
  }
}

#warranty .warranty__confidence {
  padding-top: 60px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  #warranty .warranty__confidence {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: 85px;
  }

  #warranty .warranty__confidence > .container {
    margin-left: 75px;
    margin-right: 75px;
  }
}

#warranty .warranty__confidence-intro sup {
  font-size: 45%;
  top: -2px;
}

#warranty .warranty__confidence-intro-title {
  letter-spacing: 3px;
  font-size: 24px;
  line-height: 28px;
  font-family: var(--emu-common-font-families-roboto);
  margin-bottom: var(--emu-common-spacing-small);
}

@media (min-width: 768px) {
  #warranty .warranty__confidence-intro-title {
    letter-spacing: normal;
    font-size: 30px;
    line-height: 30px;
  }
}

#warranty .warranty__confidence-intro-title h1, #warranty .warranty__confidence-intro-title h2, #warranty .warranty__confidence-intro-title h3, #warranty .warranty__confidence-intro-title h4 {
  font: inherit;
}

#warranty .warranty__confidence-intro-text {
  margin-bottom: var(--emu-common-spacing-medium);
  letter-spacing: 2px;
  padding-top: 10px;
}

@media (min-width: 768px) {
  #warranty .warranty__confidence-intro-text {
    letter-spacing: .03em;
  }
}

@media (min-width: 1025px) {
  #warranty .warranty__confidence-intro-text {
    letter-spacing: normal;
  }

  #warranty .warranty__confidence-intro-text sup {
    margin-right: var(--emu-common-spacing-xs);
  }
}

#warranty .warranty__confidence-intro-text p {
  margin: var(--emu-common-spacing-none);
}

#warranty .warranty__confidence-img-text {
  align-items: flex-start;
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
}

@media (min-width: 768px) {
  #warranty .warranty__confidence-img-text {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: 55px;
  }
}

#warranty .warranty__confidence-img-text > .image {
  flex: 33.333%;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  #warranty .warranty__confidence-img-text > .image {
    flex: 16.667%;
  }
}

#warranty .warranty__confidence-img-text > .container {
  flex: 66.667%;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  #warranty .warranty__confidence-img-text > .container {
    flex: 83.333%;
  }
}

#warranty .warranty__confidence-img-text .warranty__confidence-img {
  padding-top: 16%;
}

#warranty .warranty__confidence-img-text .warranty__confidence-text h5 {
  font-family: var(--emu-common-font-families-roboto);
  font-size: var(--emu-common-font-sizes-narrow-medium);
  line-height: var(--emu-common-line-heights-narrow-body);
  letter-spacing: 3px;
  padding-top: 20px;
}

@media (min-width: 768px) {
  #warranty .warranty__confidence-img-text .warranty__confidence-text h5 {
    padding-top: var(--emu-common-spacing-medium);
    letter-spacing: .13em;
    font-size: 14px;
    line-height: 30px;
  }
}

@media (min-width: 992px) {
  #warranty .warranty__confidence-img-text .warranty__confidence-text h5 {
    padding-top: 48px;
    line-height: 35px;
  }
}

@media (min-width: 1025px) {
  #warranty .warranty__confidence-img-text .warranty__confidence-text h5 {
    font-size: var(--emu-common-font-sizes-wide-small);
  }
}

#warranty .warranty__confidence-img-text .warranty__confidence-text h5 br {
  display: none;
}

@media (min-width: 1200px) {
  #warranty .warranty__confidence-img-text .warranty__confidence-text h5 br {
    display: block;
  }
}

#warranty .warranty__confidence-img-text .warranty__confidence-text h5 b {
  font-family: var(--emu-common-font-families-graphik);
}

@media (min-width: 768px) {
  #warranty .warranty__confidence-img-text .warranty__confidence-text h5 b {
    font-size: var(--emu-common-font-sizes-wide-small);
    line-height: 1.1;
  }
}

#warranty .warranty__confidence-img-text .warranty__confidence-text p {
  margin-bottom: var(--emu-common-spacing-none);
  padding-top: var(--emu-common-spacing-medium);
  letter-spacing: 2px;
}

@media (min-width: 768px) {
  #warranty .warranty__confidence-img-text .warranty__confidence-text p {
    letter-spacing: 1px;
    font-size: var(--emu-common-font-sizes-wide-small);
  }
}

@media (min-width: 992px) {
  #warranty .warranty__confidence-img-text .warranty__confidence-text p {
    letter-spacing: .07em;
  }
}

#warranty .warranty__confidence-img-text .warranty__confidence-text a {
  font-size: 16px;
  font-family: var(--emu-common-font-families-graphik);
}

#warranty .warranty__confidence-img-text .warranty__confidence-text a, #warranty .warranty__confidence-img-text .warranty__confidence-text a:hover, #warranty .warranty__confidence-img-text .warranty__confidence-text a:active, #warranty .warranty__confidence-img-text .warranty__confidence-text a:focus {
  color: inherit;
  text-decoration: none;
}

@media (min-width: 768px) {
  #warranty .warranty__confidence-img-text .warranty__confidence-text a, #warranty .warranty__confidence-img-text .warranty__confidence-text a:hover, #warranty .warranty__confidence-img-text .warranty__confidence-text a:active, #warranty .warranty__confidence-img-text .warranty__confidence-text a:focus {
    text-decoration: underline;
  }
}

#warranty .warranty__confidence-cta {
  letter-spacing: .3em;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
  margin-top: 35px;
  margin-bottom: 35px;
  padding: 15px 25px;
  font-size: 14px;
  line-height: 16px;
}

@media (min-width: 768px) {
  #warranty .warranty__confidence-cta {
    margin: var(--emu-common-spacing-none);
    font-size: var(--emu-common-font-sizes-wide-xs);
    padding: 10px 15px;
    line-height: 14px;
  }
}

@media (min-width: 1025px) {
  #warranty .warranty__confidence-cta {
    padding: 15px 30px;
    font-size: 14px;
    line-height: 16px;
  }
}

#warranty .warranty__confidence-cta span {
  text-align: center;
}

#warranty .warranty__terms-top-wave, #warranty .warranty__terms-bottom-wave {
  overflow: hidden;
}

#warranty .warranty__terms-top-wave img, #warranty .warranty__terms-bottom-wave img {
  margin-top: -7px;
  margin-bottom: -7px;
}

@media (min-width: 768px) {
  #warranty .warranty__terms-top-wave img, #warranty .warranty__terms-bottom-wave img {
    margin-top: var(--emu-common-spacing-none);
  }
}

@media (min-width: 992px) {
  #warranty .warranty__terms-top-wave img, #warranty .warranty__terms-bottom-wave img {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

#warranty .warranty__terms-main {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  #warranty .warranty__terms-wrapper {
    margin-left: 75px;
    margin-right: 75px;
  }
}

#warranty .warranty__terms-intro-title {
  font-size: 20.8px;
  line-height: var(--emu-common-line-heights-narrow-body);
  letter-spacing: 2px;
  font-family: var(--emu-common-font-families-roboto);
  font-weight: var(--emu-common-font-weight-regular);
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  #warranty .warranty__terms-intro-title {
    letter-spacing: normal;
    font-size: 30px;
    line-height: 30px;
  }
}

#warranty .warranty__terms-intro-title h1, #warranty .warranty__terms-intro-title h2, #warranty .warranty__terms-intro-title h3, #warranty .warranty__terms-intro-title h4 {
  font: inherit;
}

#warranty .warranty__terms-intro-text {
  letter-spacing: .05em;
  margin-bottom: var(--emu-common-spacing-medium);
}

@media (min-width: 768px) {
  #warranty .warranty__terms-intro-text {
    letter-spacing: .03em;
  }
}

@media (min-width: 992px) {
  #warranty .warranty__terms-intro-text {
    letter-spacing: normal;
  }
}

#warranty .warranty__terms-intro-text p {
  margin-bottom: var(--emu-common-spacing-none);
}

#warranty .warranty__terms-intro-text sup {
  font-size: 45%;
}

#warranty .warranty__terms-text {
  padding-top: 28px;
}

#warranty .warranty__terms-text p, #warranty .warranty__terms-text li {
  margin-bottom: var(--emu-common-spacing-none);
}

#warranty .warranty__terms-text ul {
  margin-top: var(--emu-common-spacing-none);
  margin-left: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-medium);
  padding-top: 20px;
  padding-left: 25px;
}

@media (min-width: 768px) {
  #warranty .warranty__terms-text ul {
    padding-top: 5px;
  }
}

#warranty .warranty__terms-text li {
  font-family: var(--emu-common-font-families-graphik);
}

#warranty .warranty__terms-text sup {
  font-size: 45%;
}

@media (min-width: 768px) {
  #warranty .warranty__terms-text b {
    font-size: var(--emu-common-font-sizes-wide-small);
    line-height: 1.1;
  }
}

#warranty .warranty__terms-text--no-list {
  letter-spacing: .05em;
  margin-bottom: var(--emu-common-spacing-medium);
}

@media (min-width: 768px) {
  #warranty .warranty__terms-text--no-list {
    letter-spacing: .03em;
  }
}

@media (min-width: 992px) {
  #warranty .warranty__terms-text--no-list {
    letter-spacing: normal;
  }
}

#warranty .warranty__terms-text--no-list:not(.warranty__terms-text--no-list-special) {
  padding-top: 20px;
}

@media (max-width: 767px) {
  #warranty .warranty__terms-diff-wrapper {
    flex-direction: column-reverse;
    display: flex;
  }
}

@media (min-width: 768px) {
  #warranty .warranty__terms-diff-wrapper {
    padding-bottom: 14px;
  }
}

@media (max-width: 767px) {
  #warranty .warranty__terms-diff-wrapper > .text:first-child .warranty__terms-text {
    padding-top: 20px;
  }
}

@media (min-width: 768px) {
  #warranty .warranty__terms-diff-wrapper > .text:first-child .warranty__terms-text p {
    font-size: var(--emu-common-font-sizes-wide-small);
    line-height: 1.1;
  }
}

@media (max-width: 767px) {
  #warranty .warranty__terms-diff-wrapper > .text:last-child .warranty__terms-text {
    padding-top: 10px;
  }
}

#warranty .warranty__spl-cons {
  padding-top: 35px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  #warranty .warranty__spl-cons {
    padding-top: 25px;
    padding-bottom: 52px;
  }

  #warranty .warranty__spl-cons > .container {
    margin-left: 75px;
    margin-right: 75px;
  }
}

#warranty .warranty__spl-cons-wrapper {
  padding-top: 25px;
}

#warranty .warranty__spl-cons-title {
  font-size: 22.4px;
  font-weight: var(--emu-common-font-weight-regular);
  font-family: var(--emu-common-font-families-graphik);
  letter-spacing: 4px;
  margin-bottom: var(--emu-common-spacing-small);
}

@media (min-width: 768px) {
  #warranty .warranty__spl-cons-title {
    letter-spacing: normal;
    font-size: 30px;
    line-height: 30px;
  }
}

#warranty .warranty__spl-cons-title h1, #warranty .warranty__spl-cons-title h2, #warranty .warranty__spl-cons-title h3, #warranty .warranty__spl-cons-title h4 {
  font: inherit;
}

#warranty .warranty__spl-cons-text {
  margin-bottom: var(--emu-common-spacing-medium);
  letter-spacing: .05em;
  padding-top: 10px;
  font-size: 16px;
}

@media (min-width: 768px) {
  #warranty .warranty__spl-cons-text {
    padding-top: var(--emu-common-spacing-none);
    letter-spacing: .03em;
    font-size: 14px;
  }
}

@media (min-width: 1025px) {
  #warranty .warranty__spl-cons-text {
    font-size: var(--emu-common-font-sizes-wide-small);
    letter-spacing: normal;
  }
}

#warranty .warranty__spl-cons-text sup {
  font-size: 45%;
}

#warranty .warranty__spl-cons-text p {
  margin-bottom: var(--emu-common-spacing-none);
}

#warranty .warranty__spl-cons-disclaimer {
  padding-top: 45px;
}

@media (min-width: 768px) {
  #warranty .warranty__spl-cons-disclaimer {
    padding-top: 25px;
  }
}

#warranty .warranty__spl-cons-disclaimer-text {
  letter-spacing: .05em;
  font-size: 11px;
  line-height: 14px;
}

@media (min-width: 768px) {
  #warranty .warranty__spl-cons-disclaimer-text {
    font-size: var(--emu-common-font-sizes-wide-xs);
    letter-spacing: .03em;
  }
}

@media (min-width: 1025px) {
  #warranty .warranty__spl-cons-disclaimer-text {
    letter-spacing: normal;
  }
}

#warranty .warranty__spl-cons-disclaimer-text p {
  margin-bottom: var(--emu-common-spacing-none);
}

#warranty .warranty__spl-cons-disclaimer-text sup {
  margin-top: 6px;
  top: -2px;
  left: -1px;
}

#warranty .warranty__spl-cons-disclaimer-text .emphasis sup {
  margin-top: var(--emu-common-spacing-none);
  top: 0;
  left: 0;
}

#warranty .header .emu-navigation__menu--level-0 li.emu-navigation__item--active > a, #warranty .header .emu-navigation__menu--level-0 li.emu-navigation__item--active > span a {
  color: var(--emu-common-colors-white);
}

[lang="fr_ca"] #warranty .warranty__title {
  font-size: 30px;
  line-height: var(--emu-common-line-heights-narrow-large);
}

@media (min-width: 768px) {
  [lang="fr_ca"] #warranty .warranty__title {
    font-size: 44.8px;
    line-height: 51.5px;
  }
}

[lang="fr_ca"] #warranty .warranty__title sup {
  font-size: 50%;
  font-weight: var(--emu-common-font-weight-medium);
  top: -1px;
}

.accordion.panelcontainer {
  flex-wrap: wrap;
  display: flex;
}

@media (min-width: 992px) {
  .accordion.panelcontainer {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1025px) {
  .accordion.panelcontainer {
    max-width: 1170px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.aaaem-accordion__item:first-of-type {
  margin-top: 15px;
}

@media (min-width: 768px) {
  .aaaem-accordion__item:first-of-type {
    margin-top: 20px;
  }
}

.aaaem-accordion__header {
  border: var(--emu-common-border-width-thin) solid var(--emu-common-colors-white);
  transition: all ease-in-out var(--emu-component-lists-accordion-item-body-transition);
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 8px 15px #0000001f;
}

.aaaem-accordion__header.-ba-is-active {
  border-radius: 20px 20px 0 0;
}

.aaaem-accordion__header.-ba-is-active .aaaem-accordion__button.plus-minus-icon .aaaem-accordion__icon {
  background-image: url("resources/images/accordion-close-icon-blue.svg");
}

.aaaem-accordion__title {
  letter-spacing: .2em;
  font-size: 16px;
  line-height: 28px;
  font-weight: var(--emu-common-font-weight-medium);
  width: 100%;
  display: block;
}

@media (min-width: 768px) {
  .aaaem-accordion__title {
    letter-spacing: .3em;
    line-height: 20px;
  }
}

.aaaem-accordion__title sup {
  margin: var(--emu-common-spacing-none);
  font-size: 45%;
  top: -2px;
  left: -1px;
}

.aaaem-accordion__title sup.sup-md {
  font-size: 100%;
  top: 2px;
}

@media (min-width: 768px) {
  .aaaem-accordion__button {
    align-items: center;
    padding-top: 5px;
  }
}

.aaaem-accordion__button.plus-minus-icon .aaaem-accordion__icon {
  background-image: url("resources/images/accordion-open-icon-blue.svg");
  background-size: cover;
}

.aaaem-accordion__button.plus-minus-icon .aaaem-accordion__icon:before, .aaaem-accordion__button.plus-minus-icon .aaaem-accordion__icon:after {
  display: none;
}

.aaaem-accordion .aaaem-accordion__panel {
  transition: all ease-in-out var(--emu-component-lists-accordion-item-body-transition);
  z-index: var(--emu-common-other-z-index-layer);
  margin-top: -20px;
  margin-bottom: 35px;
  position: relative;
}

@media (min-width: 768px) {
  .aaaem-accordion .aaaem-accordion__panel {
    margin-bottom: 40px;
  }
}

.aaaem-accordion .aaaem-accordion__panel.-ba-is-active {
  margin-top: var(--emu-common-spacing-none);
  border-radius: 0 0 20px 20px;
  margin-bottom: 15px;
  box-shadow: 0 8px 15px #0000001f;
}

@media (min-width: 768px) {
  .aaaem-accordion .aaaem-accordion__panel.-ba-is-active {
    margin-top: -4px;
    margin-bottom: 20px;
  }
}

.aaaem-accordion .aaaem-accordion__panel sup {
  margin: var(--emu-common-spacing-none);
  font-size: 45%;
  left: -1px;
}

.aaaem-accordion .aaaem-accordion__panel .js-badger-accordion-panel-inner {
  margin-top: -4px;
  padding-bottom: 5px;
  transition: all .2s linear .2s;
}

@media (min-width: 768px) {
  .aaaem-accordion .aaaem-accordion__panel .js-badger-accordion-panel-inner {
    padding-bottom: 17px;
  }
}

.aaaem-accordion .aaaem-accordion__panel .js-badger-accordion-panel-inner .cmp-text {
  letter-spacing: .07em;
  color: var(--emu-common-colors-black);
  width: 97%;
  font-size: 16px;
}

@media (min-width: 768px) {
  .aaaem-accordion .aaaem-accordion__panel .js-badger-accordion-panel-inner .cmp-text {
    width: 98%;
    line-height: 24px;
  }
}

.before-after__mobile-top-wave {
  background: linear-gradient(0deg, var(--emu-common-colors-brand-pink-300) 0%, var(--emu-common-colors-brand-pink-300) 5%, var(--emu-common-colors-white) 6%, var(--emu-common-colors-white) 100%);
}

.before-after__intro {
  padding-top: 35px;
  padding-bottom: 35px;
}

@media (min-width: 768px) {
  .before-after__intro {
    padding-top: 4%;
    padding-bottom: var(--emu-common-spacing-none);
  }
}

.before-after__intro-inner {
  padding-left: 15px;
  padding-right: 15px;
}

.before-after__intro .title-text__title {
  margin-bottom: var(--emu-common-spacing-none);
  letter-spacing: -.5px;
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .before-after__intro .title-text__title {
    margin-bottom: var(--emu-common-spacing-small);
  }
}

@media (min-width: 1025px) {
  .before-after__intro .title-text__title {
    letter-spacing: -.8px;
  }
}

.before-after__intro .title-text__txt {
  letter-spacing: .03em;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--emu-common-spacing-none);
  max-width: 85%;
  padding-top: 2%;
  padding-bottom: 5%;
}

@media (min-width: 768px) {
  .before-after__intro .title-text__txt {
    font-size: 18px;
  }
}

@media (min-width: 1025px) {
  .before-after__intro .title-text__txt {
    letter-spacing: normal;
  }
}

.before-after__intro .title-text__txt sup {
  font-size: 45%;
  top: -1px;
  left: -1px;
}

.before-after__carousel-container {
  padding: 3% 2% .5%;
}

@media (min-width: 576px) {
  .before-after__carousel-container {
    padding: 35px;
  }
}

@media (min-width: 992px) {
  .before-after__carousel-container {
    padding: 3% 2% .5%;
  }
}

@media (min-width: 1025px) {
  .before-after__carousel-container {
    overflow-x: hidden;
  }

  .before-after__carousel-container .carousel.panelcontainer {
    margin-bottom: 22px;
  }
}

.before-after__carousel[data-component="carousel-v2"].emu-carousel.emu-carousel {
  border: none;
}

@media (min-width: 768px) {
  .before-after__carousel[data-component="carousel-v2"].emu-carousel.emu-carousel .tns-controls.js-show {
    display: block;
  }
}

.before-after__carousel[data-component="carousel-v2"].emu-carousel.emu-carousel .emu-carousel__action {
  padding: var(--emu-common-spacing-none);
  top: 37%;
}

@media (min-width: 1025px) {
  .before-after__carousel[data-component="carousel-v2"].emu-carousel.emu-carousel .emu-carousel__action {
    top: 54%;
  }
}

.before-after__carousel[data-component="carousel-v2"].emu-carousel.emu-carousel .emu-carousel__action-next {
  right: -25px;
}

@media (min-width: 1025px) {
  .before-after__carousel[data-component="carousel-v2"].emu-carousel.emu-carousel .emu-carousel__action-next {
    right: -56px;
  }
}

.before-after__carousel[data-component="carousel-v2"].emu-carousel.emu-carousel .emu-carousel__action-prev {
  left: -20px;
}

@media (min-width: 992px) {
  .before-after__carousel[data-component="carousel-v2"].emu-carousel.emu-carousel .emu-carousel__action-prev {
    left: -25px;
  }
}

@media (min-width: 1025px) {
  .before-after__carousel[data-component="carousel-v2"].emu-carousel.emu-carousel .emu-carousel__action-prev {
    left: -56px;
  }
}

.before-after__carousel[data-component="carousel-v2"].emu-carousel.emu-carousel .emu-carousel__action span:before {
  background-repeat: no-repeat;
  background-size: 40px 60px;
}

@media (min-width: 1025px) {
  .before-after__carousel[data-component="carousel-v2"].emu-carousel.emu-carousel .emu-carousel__action span:before {
    background-size: cover;
  }
}

.before-after__carousel[data-component="carousel-v2"].emu-carousel.emu-carousel .tns-nav {
  padding-right: 13px;
  bottom: -96px;
}

.before-after__slide-content {
  flex-wrap: wrap;
  display: flex;
}

@media (min-width: 768px) {
  .before-after__slide-content {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  .before-after__slide-content {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

.before-after__slide-content > .container {
  width: 44%;
  margin: 10px;
  padding: 5px;
}

@media (min-width: 768px) {
  .before-after__slide-content > .container {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
    margin: var(--emu-common-spacing-none);
    width: 25%;
    position: relative;
  }
}

.before-after__slide-content-img .cmp-image__title {
  color: var(--emu-common-colors-white);
  text-align: center;
  font-family: var(--emu-common-font-families-graphik);
  font-weight: var(--emu-common-font-weight-bold);
  letter-spacing: .07em;
  justify-content: center;
  align-items: flex-end;
  width: 89.5%;
  height: 50px;
  padding-bottom: 5px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 15px;
}

@media (min-width: 768px) {
  .before-after__slide-content-img .cmp-image__title {
    padding-bottom: 10px;
  }
}

.before-after__slide-content-disclaimer {
  letter-spacing: .07em;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 15px;
  line-height: 22px;
}

@media (min-width: 768px) {
  .before-after__slide-content-disclaimer {
    padding-top: 17px;
    padding-left: 20px;
    padding-bottom: var(--emu-common-spacing-none);
    line-height: 28px;
  }
}

@media (min-width: 992px) {
  .before-after__slide-content-disclaimer {
    padding-left: 15px;
  }
}

@media (min-width: 1025px) {
  .before-after__slide-content-disclaimer {
    font-size: 16px;
    line-height: 1.1;
  }
}

.before-after__slide-content-disclaimer p {
  margin-bottom: var(--emu-common-spacing-none);
}

.before-after__slide-content-disclaimer sup {
  font-size: 45%;
}

.before-after__disclaimer {
  letter-spacing: .03em;
  margin-bottom: 5px;
  padding-top: 0%;
  padding-left: 4%;
  font-size: 12px;
}

@media (min-width: 768px) {
  .before-after__disclaimer {
    padding-left: 20px;
  }
}

@media (min-width: 992px) {
  .before-after__disclaimer {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  .before-after__disclaimer {
    max-width: var(--emu-common-sizing-xxxl);
  }
}

@media (min-width: 1025px) {
  .before-after__disclaimer {
    letter-spacing: normal;
  }
}

.before-after__disclaimer sup {
  font-size: 55%;
}

.before-after__disclaimer p {
  margin-bottom: var(--emu-common-spacing-none);
}

.button .emu-button {
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.button .btn--violet.emu-button-v2.emu-button.emu-button {
  border-color: var(--emu-common-colors-brand-violet-500);
  color: var(--emu-common-colors-brand-violet-500);
}

.button .btn--violet.emu-button-v2.emu-button.emu-button:hover, .button .btn--violet.emu-button-v2.emu-button.emu-button:focus {
  background-color: var(--emu-common-colors-brand-violet-500) !important;
  color: var(--emu-common-colors-white) !important;
}

.button .btn--grey.emu-button-v2.emu-button.emu-button {
  border-color: var(--emu-common-colors-dark-grey-400);
  color: var(--emu-common-colors-dark-grey-400);
}

.button .btn--grey.emu-button-v2.emu-button.emu-button:hover, .button .btn--grey.emu-button-v2.emu-button.emu-button:focus {
  background-color: var(--emu-common-colors-dark-grey-400);
  color: var(--emu-common-colors-brand-blue-50);
}

.button .btn--grey.emu-button-v2.emu-button.emu-button:active {
  background-color: var(--emu-common-colors-transparent);
}

.button .btn--light-pink.emu-button-v2.emu-button.emu-button {
  border-color: var(--emu-common-colors-brand-pink-400);
  color: var(--emu-common-colors-brand-pink-400);
}

.button .btn--light-pink.emu-button-v2.emu-button.emu-button:hover, .button .btn--light-pink.emu-button-v2.emu-button.emu-button:focus {
  background-color: var(--emu-common-colors-brand-pink-400);
  color: var(--emu-common-colors-brand-violet-400);
}

.button .btn--light-pink.emu-button-v2.emu-button.emu-button:active {
  background-color: var(--emu-common-colors-transparent);
}

@media (max-width: 768px) {
  .carousel [data-component="carousel-v2"].emu-carousel .tns-controls.js-show {
    display: none;
  }
}

.carousel.panelcontainer {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

@media (min-width: 992px) {
  .carousel.panelcontainer {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 1200px) {
  .carousel.panelcontainer {
    max-width: var(--emu-common-sizing-xxxl);
  }
}

.carousel.panelcontainer .emu-carousel {
  padding-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  .carousel {
    margin-bottom: 30px;
  }
}

.carousel .tns-outer > .tns-nav {
  z-index: var(--emu-common-other-z-index-layer);
  bottom: -92px;
}

@media (min-width: 768px) {
  .carousel .tns-outer > .tns-nav {
    display: none;
  }
}

.carousel .tns-outer > .tns-nav > button {
  margin-left: var(--emu-semantic-spacing-none);
  margin-right: var(--emu-semantic-spacing-none);
  width: 15px;
  height: 15px;
}

@media (min-width: 768px) {
  .carousel .emu-carousel__action {
    top: 38%;
  }
}

@media (min-width: 992px) {
  .carousel .emu-carousel__action {
    top: 37%;
  }
}

@media (min-width: 1025px) {
  .carousel .emu-carousel__action {
    top: 53%;
  }
}

.carousel .emu-carousel__action span:before {
  content: "";
  background-image: url("resources/images/carousel-left-arrow.png");
  background-size: cover;
  min-width: 40px;
  height: 60px;
  display: block;
}

@media (min-width: 1025px) {
  .carousel .emu-carousel__action span:before {
    height: 91px;
  }
}

.carousel .emu-carousel__action span svg {
  display: none;
}

@media (min-width: 768px) {
  .carousel .emu-carousel__action-prev {
    left: -40px;
  }
}

@media (min-width: 1025px) {
  .carousel .emu-carousel__action-prev {
    left: -70px;
  }
}

@media (min-width: 768px) {
  .carousel .emu-carousel__action-next {
    right: -40px;
  }
}

@media (min-width: 1025px) {
  .carousel .emu-carousel__action-next {
    right: -70px;
  }
}

.carousel .emu-carousel__action-next span:before {
  transform: rotate(180deg);
}

.carousel-text {
  padding: 3% 2% .5%;
}

@media (min-width: 576px) {
  .carousel-text {
    padding: 35px;
  }
}

@media (min-width: 768px) {
  .carousel-text {
    padding-left: 39px;
    padding-right: 39px;
  }
}

@media (min-width: 992px) {
  .carousel-text {
    padding: 3% 2.5% .5%;
  }
}

.carousel-text__slide-content {
  background-color: var(--emu-common-colors-white);
  flex-flow: column;
  width: 100%;
  display: flex;
}

@media (min-width: 768px) {
  .carousel-text__slide-content {
    flex-direction: row;
  }
}

.carousel-text__slide-content-outer {
  flex-direction: row;
  width: 100%;
  display: flex;
}

@media (min-width: 576px) {
  .carousel-text__slide-content-outer {
    flex-direction: column;
  }
}

@media (min-width: 768px) {
  .carousel-text__slide-content-outer {
    flex-direction: row;
  }
}

.carousel-text__slide-content-inner {
  position: relative;
}

.carousel-text__slide-content-inner-image {
  padding-top: 15px;
  padding-bottom: 15px;
}

@media (min-width: 768px) {
  .carousel-text__slide-content-inner-image {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
  }
}

@media (min-width: 576px) {
  .carousel-text__slide-content-inner-image img {
    width: 51.3%;
  }
}

@media (min-width: 768px) {
  .carousel-text__slide-content-inner-image img {
    width: 100%;
  }
}

.carousel-text__slide-content-inner-description {
  position: absolute;
}

.carousel-text__slide-content-inner-description.cmp-text {
  letter-spacing: 1.05px;
  font-size: 15px;
  font-weight: var(--emu-common-font-weight-bold);
}

@media (min-width: 768px) {
  .carousel-text__slide-content-inner-description.cmp-text {
    line-height: var(--emu-semantic-line-heights-narrow-body);
  }
}

@media (min-width: 1025px) {
  .carousel-text__slide-content-inner-description.cmp-text {
    letter-spacing: 1.12px;
    font-size: 16px;
    line-height: 17.6px;
  }
}

.carousel-text__slide-content-inner-description.cmp-text > p {
  margin-bottom: var(--emu-semantic-spacing-none);
}

.carousel-text__slide-content--tagline {
  letter-spacing: .7px;
  padding-top: 5px;
  padding-bottom: 5px;
}

@media (min-width: 768px) {
  .carousel-text__slide-content--tagline {
    padding-top: 17px;
    padding-bottom: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1200px) {
  .carousel-text__slide-content--tagline {
    max-width: var(--emu-common-sizing-xxxl);
  }
}

.carousel-text__slide-content--tagline.cmp-text {
  font-size: 16px;
  line-height: 22px;
}

.carousel-text__slide-content--tagline > p {
  margin: var(--emu-common-spacing-none);
}

.carousel-text__slide-content--tagline > p sup {
  font-size: 50%;
  left: -1.6px;
}

.carousel-text__disclaimer {
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  letter-spacing: .45px;
  padding-left: 4%;
  padding-top: var(--emu-common-spacing-none);
  margin-bottom: 5px;
}

@media (min-width: 768px) {
  .carousel-text__disclaimer {
    margin-bottom: var(--emu-common-spacing-medium);
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  .carousel-text__disclaimer {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 13.2px;
  }
}

@media (min-width: 1200px) {
  .carousel-text__disclaimer {
    max-width: var(--emu-common-sizing-xxxl);
  }
}

.carousel-text__disclaimer > p {
  margin-bottom: 5px;
}

@media (min-width: 768px) {
  .carousel-text__disclaimer > p {
    margin-bottom: var(--emu-common-spacing-medium);
  }
}

@media (min-width: 1025px) {
  .carousel-text__disclaimer > p {
    line-height: 13.2px;
  }
}

.carousel-text__disclaimer > p > sup {
  font-size: 5px;
}

.content-box__title h2 {
  font-weight: var(--emu-common-font-weight-light);
}

@media (min-width: 768px) {
  .content-box__title h2 {
    font-size: var(--emu-common-font-sizes-narrow-xl);
    line-height: var(--emu-common-line-heights-narrow-xl);
  }
}

@media (min-width: 1025px) {
  .content-box__title h2 {
    font-size: var(--emu-common-font-sizes-wide-xxl);
    line-height: 58px;
  }
}

.content-box__title h2 b {
  letter-spacing: -1px;
}

.content-box__description {
  letter-spacing: .03em;
  max-width: 95%;
  margin-bottom: 5px;
  padding-top: 5%;
}

@media (min-width: 768px) {
  .content-box__description {
    padding-top: var(--emu-common-spacing-none);
    letter-spacing: .03em;
    margin-bottom: var(--emu-common-spacing-medium);
    max-width: 100%;
    font-size: 14px;
    line-height: 20px;
  }
}

@media (min-width: 1025px) {
  .content-box__description {
    font-size: var(--emu-common-font-sizes-narrow-body);
    line-height: var(--emu-common-line-heights-narrow-body);
    letter-spacing: .07em;
  }
}

@media (min-width: 1200px) {
  .content-box__description {
    text-indent: -17px;
  }
}

.content-box__description p {
  margin-bottom: var(--emu-common-spacing-none);
}

.content-box__button {
  text-align: center;
  width: 320px;
  margin-top: 40px;
  margin-bottom: 35px;
}

@media (min-width: 768px) {
  .content-box__button {
    width: auto;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

@media (min-width: 1025px) {
  .content-box__button {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}

.content-box__button.emu-button-v2.emu-button.emu-button {
  justify-content: center;
  padding: 15px 25px;
}

@media (min-width: 768px) {
  .content-box__button.emu-button-v2.emu-button.emu-button {
    width: auto;
    padding: 10px 15px;
    display: inline-block;
  }
}

@media (min-width: 1025px) {
  .content-box__button.emu-button-v2.emu-button.emu-button {
    padding: 15px 30px;
  }
}

.content-box__button span {
  line-height: var(--emu-common-line-heights-wide-xs);
  letter-spacing: .3em;
}

@media (min-width: 768px) {
  .content-box__button span {
    font-size: var(--emu-common-font-sizes-narrow-small);
    line-height: 16px;
  }
}

@media (min-width: 1025px) {
  .content-box__button span {
    font-size: 15px;
    line-height: var(--emu-common-line-heights-narrow-small);
  }
}

@media (min-width: 768px) {
  .content-box--align-center {
    text-align: center;
  }
}

.fancy-img-with-text {
  flex-direction: column-reverse;
  justify-content: space-between;
  display: flex;
  position: relative;
}

@media (min-width: 768px) {
  .fancy-img-with-text {
    flex-direction: row;
    max-height: 600px;
  }
}

.cq-Editable-dom--container .fancy-img-with-text {
  max-height: none;
}

@media (min-width: 768px) {
  .fancy-img-with-text > .container:first-child {
    max-width: 45%;
  }

  .fancy-img-with-text > .container:last-child {
    max-width: 55%;
  }

  .fancy-img-with-text--reverse {
    flex-direction: row-reverse;
  }

  .fancy-img-with-text--reverse > .container:first-child {
    max-width: 55%;
    padding-left: 30px;
  }

  .fancy-img-with-text--reverse > .container:last-child {
    max-width: 45%;
  }
}

.fancy-img-with-text__img-container img {
  width: 100%;
  min-width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: block;
}

@media (min-width: 768px) {
  .fancy-img-with-text__img-container img {
    padding: var(--emu-common-spacing-none);
    z-index: var(--emu-common-other-z-index-layer);
    min-width: 300px;
    position: relative;
    top: -10px;
  }
}

@media (min-width: 1200px) {
  .fancy-img-with-text__img-container img {
    top: 0;
  }
}

.fancy-img-with-text__img-disclaimer {
  letter-spacing: .07em;
  color: var(--emu-common-colors-black);
  margin-bottom: var(--emu-common-spacing-medium);
  max-width: 95%;
  font-size: 10px;
}

.fancy-img-with-text__img-disclaimer p {
  margin-bottom: var(--emu-common-spacing-none);
}

.fancy-img-with-text .title-text__title {
  padding-top: var(--emu-common-spacing-medium);
  letter-spacing: -.2px;
}

@media (min-width: 768px) {
  .fancy-img-with-text .title-text__title {
    padding-top: 52.8px;
  }
}

.fancy-img-with-text .title-text__txt {
  padding-bottom: var(--emu-common-spacing-none);
}

.faq__mobile-top-wave {
  margin-top: -.8%;
  margin-bottom: -8px;
}

.faq__title {
  letter-spacing: -.2px;
  padding-top: 45px;
}

.faq__title--pink.faq__title {
  color: var(--emu-common-colors-brand-pink-400);
}

@media (min-width: 768px) {
  .faq__title {
    color: var(--emu-common-colors-black);
    letter-spacing: -.4px;
    padding-top: 4%;
    font-size: 32px;
    line-height: 48px;
  }
}

@media (min-width: 1025px) {
  .faq__title {
    line-height: var(--emu-common-line-heights-wide-xxl);
    font-size: var(--emu-common-font-sizes-wide-xxl);
    letter-spacing: -.8px;
  }
}

@media (min-width: 768px) {
  .faq__title h1, .faq__title h2, .faq__title h3, .faq__title h4 {
    font-size: inherit;
    line-height: inherit;
  }
}

.faq__title .emphasis {
  display: block;
}

@media (min-width: 768px) {
  .faq__title .emphasis {
    display: inline;
  }
}

.faq__description {
  letter-spacing: .54px;
  line-height: 25px;
}

@media (min-width: 768px) {
  .faq__description {
    font-size: 17px;
  }
}

@media (min-width: 1025px) {
  .faq__description {
    font-size: var(--emu-common-font-sizes-wide-small);
    letter-spacing: normal;
  }
}

.faq__description p {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--emu-semantic-spacing-none);
  width: 95%;
  padding-top: 10px;
}

@media (min-width: 768px) {
  .faq__description p {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (min-width: 1025px) {
  .faq__description p {
    padding-left: var(--emu-semantic-spacing-none);
    padding-right: var(--emu-semantic-spacing-none);
    width: 95%;
  }
}

.faq__main {
  margin-top: -6px;
  padding: 15px 15px 25px;
}

@media (min-width: 768px) {
  .faq__main {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    margin-top: var(--emu-common-spacing-none);
    padding-bottom: 50px;
  }
}

.faq__main p:has( + *) {
  margin-bottom: 10px;
}

.faq__main p:has( + *) b:only-child {
  margin-bottom: -5px;
  display: block;
}

.faq__main ul {
  padding-left: var(--emu-common-spacing-none);
  margin-left: 26.4px;
  margin-top: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  .faq__main ul {
    margin-left: 14.4px;
  }
}

.faq__main li {
  margin-bottom: var(--emu-common-spacing-none);
}

.faq__main a {
  font-family: var(--emu-common-font-families-graphik);
  border-bottom: var(--emu-common-border-width-thin) solid currentColor;
  padding-bottom: 1px;
}

.faq__main a u {
  text-decoration: none;
}

.faq__main a, .faq__main a:focus, .faq__main a:hover, .faq__main a:active {
  color: inherit;
  text-decoration: none;
}

#fas .nca-fas {
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 1440px) {
  #fas .nca-fas {
    background-position: bottom;
  }
}

@media (min-width: 1600px) {
  #fas .nca-fas {
    margin-top: 45px;
    background-size: 100% 100% !important;
  }
}

#fas .nca-fas__above-vector-img {
  margin-top: -1px;
}

@media (min-width: 1600px) {
  #fas .nca-fas__above-vector-img {
    height: 45px;
    position: relative;
    top: -45px;
    overflow: hidden;
  }
}

#fas .nca-fas__above-vector-img img {
  width: 100%;
}

#fas .nca-fas__container {
  padding-left: var(--emu-semantic-spacing-none);
  padding-right: var(--emu-semantic-spacing-none);
  max-width: 1440px;
}

#fas .nca-fas__wrapper {
  background-color: var(--emu-common-colors-brand-pink-500);
  flex-direction: column-reverse;
  height: 100%;
  margin-top: -1px;
  display: flex;
  position: relative;
}

@media (min-width: 768px) {
  #fas .nca-fas__wrapper {
    background-color: unset;
    display: block;
  }
}

#fas .nca-fas__bg-img {
  background-image: none;
  position: relative;
}

@media (min-width: 768px) {
  #fas .nca-fas__bg-img {
    background-image: unset;
  }
}

@media (min-width: 1200px) {
  #fas .nca-fas__bg-img {
    margin-top: -120px;
  }
}

@media (min-width: 1300px) {
  #fas .nca-fas__bg-img {
    text-align: center;
  }
}

#fas .nca-fas__bg-img img {
  width: 100%;
}

#fas .nca-fas__inner-container-content {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  padding-top: 50px;
  padding-bottom: 10px;
  display: flex;
  position: relative;
  top: 0;
}

@media (min-width: 768px) {
  #fas .nca-fas__inner-container-content {
    padding-top: var(--emu-semantic-spacing-none);
    padding-bottom: var(--emu-semantic-spacing-none);
    justify-content: unset;
    position: absolute;
    top: 150px;
  }
}

@media (min-width: 1200px) {
  #fas .nca-fas__inner-container-content {
    top: 200px;
  }
}

#fas .nca-fas__inner-container .content-box__title h2 {
  margin-bottom: var(--emu-common-spacing-small);
  font-weight: var(--emu-common-font-weight-medium);
  letter-spacing: -.5px;
  padding-bottom: 15px;
}

@media (min-width: 768px) {
  #fas .nca-fas__inner-container .content-box__title h2 {
    padding-bottom: var(--emu-semantic-spacing-none);
    margin-bottom: var(--emu-semantic-spacing-none);
  }
}

@media (min-width: 1025px) {
  #fas .nca-fas__inner-container .content-box__title h2 {
    line-height: var(--emu-common-line-heights-wide-xxl);
    letter-spacing: -.7px;
  }
}

#fas .nca-fas__inner-container .content-box__title h2 b .emphasis {
  letter-spacing: 0;
}

#fas .nca-fas__inner-container .content-box__description {
  padding-top: var(--emu-semantic-spacing-none);
  letter-spacing: .9px;
  max-width: 100%;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  #fas .nca-fas__inner-container .content-box__description {
    letter-spacing: .42px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@media (min-width: 1025px) {
  #fas .nca-fas__inner-container .content-box__description {
    letter-spacing: unset;
    font-size: 16px;
    line-height: 17.6px;
  }
}

@media (min-width: 1200px) {
  #fas .nca-fas__inner-container .content-box__description {
    text-indent: unset;
    font-size: var(--emu-common-font-sizes-wide-body);
    line-height: 30px;
  }
}

#fas .nca-fas__inner-container .content-box__description.emphasis br {
  display: none;
}

@media (min-width: 992px) {
  #fas .nca-fas__inner-container .content-box__description.emphasis br {
    display: block;
  }
}

#fas .nca-fas__inner-container .content-box__button {
  margin-top: var(--emu-semantic-spacing-none);
  margin-bottom: var(--emu-semantic-spacing-none);
  width: max-content;
}

@media (min-width: 768px) {
  #fas .nca-fas__inner-container .content-box__button {
    line-height: 0;
  }
}

#fas .nca-fas__inner-container .content-box__button span {
  line-height: 16px;
}

@media (min-width: 768px) {
  #fas .nca-fas__inner-container .content-box__button span {
    line-height: var(--emu-common-line-heights-narrow-xs);
  }
}

@media (min-width: 1025px) {
  #fas .nca-fas__inner-container .content-box__button span {
    letter-spacing: 3.7px;
    line-height: 16px;
  }
}

@media (min-width: 1025px) {
  .footer--outer {
    padding-left: var(--emu-common-spacing-large);
    padding-right: var(--emu-common-spacing-large);
  }
}

.footer__container.aaaem-container {
  padding-top: 30px;
  padding-left: var(--emu-common-spacing-medium);
  padding-bottom: var(--emu-common-spacing-large);
  padding-right: var(--emu-common-spacing-medium);
}

@media (min-width: 768px) {
  .footer__container.aaaem-container {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: var(--emu-common-spacing-large);
  }
}

@media (min-width: 1025px) {
  .footer__container.aaaem-container {
    padding-top: var(--emu-common-spacing-xl);
  }
}

@media (min-width: 768px) {
  .footer__main.aaaem-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  .footer__main.aaaem-container {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  .footer__main.aaaem-container {
    max-width: var(--emu-common-sizing-xxxl);
    margin-left: auto;
    margin-right: auto;
  }
}

.footer__logos.aaaem-container {
  margin-bottom: var(--emu-common-spacing-large);
}

@media (min-width: 768px) {
  .footer__logos.aaaem-container {
    justify-content: center;
    align-items: center;
    margin-bottom: 37px;
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
  }
}

@media (min-width: 1025px) {
  .footer__logos.aaaem-container {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    margin-bottom: 61px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.footer__logos > .image img {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .footer__logos > .image {
    flex: 1;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.footer__links.aaaem-container {
  flex-flow: column wrap;
  justify-content: center;
  margin-bottom: 48px;
  display: flex;
}

@media (min-width: 768px) {
  .footer__links.aaaem-container {
    margin-bottom: 36px;
  }
}

@media (min-width: 1025px) {
  .footer__links.aaaem-container {
    margin-bottom: 45px;
  }
}

.footer__links ul {
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
  list-style: none;
}

@media (min-width: 768px) {
  .footer__links ul {
    flex-flow: wrap;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}

.footer__links ul li {
  margin: var(--emu-common-spacing-none);
  text-transform: uppercase;
  font-size: 15px;
  line-height: 24px;
}

@media (min-width: 768px) {
  .footer__links ul li {
    text-align: center;
    font-size: 10px;
    line-height: 15px;
  }
}

@media (min-width: 1025px) {
  .footer__links ul li {
    font-size: 15px;
    line-height: 18px;
  }
}

.footer__links ul a {
  font-family: var(--emu-semantic-font-families-body);
  margin-top: 3.2px;
  margin-bottom: 3.2px;
  text-decoration: underline;
  display: inline-block;
}

@media (min-width: 768px) {
  .footer__links ul a {
    text-decoration: none;
  }

  .footer__links ul a:hover {
    text-decoration: underline;
  }
}

.footer__links ul a br {
  line-height: 0;
}

.footer__links .image img {
  margin-top: 38.4px;
  margin-left: auto;
  margin-right: auto;
}

.footer__copyrights .cmp-text {
  letter-spacing: .65px;
  font-size: 13px;
  line-height: 22px;
  font-family: var(--emu-semantic-font-families-body);
}

@media (min-width: 768px) {
  .footer__copyrights .cmp-text {
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-common-spacing-none);
    letter-spacing: .39px;
    max-width: 85%;
    margin-left: auto;
    margin-right: auto;
    font-size: 13px;
    line-height: 15.25px;
  }
}

@media (min-width: 1025px) {
  .footer__copyrights .cmp-text {
    letter-spacing: 0;
  }
}

@media (min-width: 768px) {
  .footer__copyrights p {
    margin-bottom: 20px;
  }
}

@media (min-width: 1025px) {
  .footer__copyrights p {
    margin-bottom: var(--emu-common-spacing-medium);
  }
}

@media (min-width: 768px) {
  .footer__copyrights p .emphasis, [lang="fr"] .footer__copyrights p .emphasis br {
    display: block;
  }
}

@media (min-width: 1025px) {
  [lang="fr"] .footer__copyrights .cmp-text {
    max-width: 100%;
  }
}

header.experiencefragment {
  z-index: var(--emu-common-other-z-index-header);
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
}

.header {
  width: 100%;
  position: relative;
}

.header__wrapper {
  background-color: var(--emu-common-colors-brand-blue-600);
  position: relative;
}

.header__wrapper.js-toggle-on .header__nav-container > .button {
  display: none;
}

.header__wrapper.js-toggle-on .header__nav-lang-wrapper {
  height: 100%;
  bottom: 0;
}

.header__wrapper.js-toggle-on .header__nav-lang-wrapper > .button {
  display: flex;
  position: absolute;
  top: 21px;
  right: 30px;
}

@media (min-width: 768px) {
  .header__wrapper.js-toggle-on .header__nav-lang-wrapper > .button {
    top: 17px;
    right: 31px;
  }
}

.header__wrapper.js-toggle-on .header__nav-lang-wrapper > .button img {
  max-height: 19px;
}

.header__wrapper.js-toggle-on .header__nav-lang-wrapper > .button .emu-button-v2.emu-button.header__menu-close-toggle {
  display: block;
}

@media (min-width: 992px) {
  .header__wrapper.js-toggle-on .header__nav-lang-wrapper > .button .emu-button-v2.emu-button.header__menu-close-toggle {
    display: none;
  }
}

.header .header__nav-container {
  padding-top: var(--emu-common-spacing-small);
  padding-bottom: var(--emu-common-spacing-small);
  width: 100%;
  max-width: 1240px;
  margin: auto;
}

@media (min-width: 768px) {
  .header .header__nav-container {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}

.header .header__nav-container .image {
  margin-right: var(--emu-common-spacing-medium);
  max-width: 55%;
}

@media (min-width: 768px) {
  .header .header__nav-container .image {
    max-width: none;
  }
}

@media (min-width: 992px) {
  .header .header__nav-container .image {
    flex: 0 0 15%;
  }
}

@media (min-width: 1201px) {
  .header .header__nav-container .image {
    flex: auto;
  }
}

.header .header__nav-container > .button {
  display: flex;
  position: absolute;
  top: 21px;
  right: 21px;
}

@media (min-width: 576px) {
  .header .header__nav-container > .button {
    top: 25px;
  }
}

@media (min-width: 992px) {
  .header .header__nav-container > .button {
    display: none;
  }
}

.header .header__nav-container > .button img {
  min-height: 38px;
}

.header .header__nav-container > .button .emu-button-v2.emu-button.header__menu-open-toggle {
  background-color: var(--emu-common-colors-transparent);
  padding: var(--emu-common-spacing-none);
  border: none;
  margin-top: 5px;
  display: flex;
}

@media (min-width: 576px) {
  .header .header__nav-container > .button .emu-button-v2.emu-button.header__menu-open-toggle {
    margin-top: var(--emu-common-spacing-none);
  }
}

.header__brand-logo {
  display: flex;
}

.header__brand-logo a {
  display: block;
}

.header__brand-logo img {
  width: 100%;
  max-width: 220px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: block;
}

.header__nav-lang-wrapper {
  background-color: var(--emu-common-colors-brand-blue-500);
  z-index: var(--emu-common-other-z-index-header);
  width: 100%;
  height: 0;
  transition: all .5s;
  position: fixed;
  bottom: 100%;
  left: 0;
  overflow: hidden;
}

@media (min-width: 992px) {
  .header__nav-lang-wrapper {
    background-color: var(--emu-common-colors-brand-blue-600);
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: auto;
    display: flex;
    position: relative;
  }
}

.header__nav-lang-wrapper a {
  font-family: var(--emu-common-font-families-roboto);
}

.header__nav-lang-wrapper .button .emu-button-v2.emu-button.header__menu-close-toggle {
  padding: var(--emu-common-spacing-none);
  background-color: var(--emu-common-colors-transparent);
  z-index: var(--emu-common-other-z-index-cookie-banner);
  border: none;
  display: none;
}

.header__nav-lang-wrapper .emu-navigation__content-wrapper {
  width: 100%;
  max-height: 100vh;
  position: relative;
  top: 0;
  left: 0;
  overflow: auto;
}

.cq-Editable-dom--container .header__nav-lang-wrapper .emu-navigation__content-wrapper {
  max-height: none;
}

.header__nav-lang-wrapper .emu-navigation__content-wrapper nav {
  position: relative;
}

.header .emu-navigation__menu--level-0 {
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

@media (min-width: 992px) {
  .header .emu-navigation__menu--level-0 {
    flex-direction: row;
  }
}

.header .emu-navigation__menu--level-0 li {
  margin: var(--emu-common-spacing-none);
  font-family: var(--emu-common-font-families-roboto-medium);
  font-size: 14px;
  line-height: 16px;
  font-weight: var(--emu-common-font-weight-medium);
  letter-spacing: 2px;
  width: 90%;
}

@media (min-width: 768px) {
  .header .emu-navigation__menu--level-0 li {
    font-size: var(--emu-common-font-sizes-narrow-small);
  }
}

@media (min-width: 992px) {
  .header .emu-navigation__menu--level-0 li {
    width: auto;
  }
}

@media (min-width: 1025px) {
  .header .emu-navigation__menu--level-0 li {
    font-size: 14px;
  }
}

@media (min-width: 1201px) {
  .header .emu-navigation__menu--level-0 li {
    letter-spacing: 4px;
  }
}

@media (min-width: 992px) {
  .header .emu-navigation__menu--level-0 li:first-child {
    display: none;
  }
}

@media (min-width: 1025px) {
  .header .emu-navigation__menu--level-0 li span:focus, .header .emu-navigation__menu--level-0 li > a:focus {
    color: var(--emu-common-colors-brand-pink-500);
  }
}

.header .emu-navigation__menu--level-0 li.emu-navigation__item--active > a, .header .emu-navigation__menu--level-0 li.emu-navigation__item--active > span a {
  color: var(--emu-common-colors-brand-pink-500);
}

.header .emu-navigation__menu--level-0 li a {
  border-bottom-width: var(--emu-common-border-width-thin);
  border-style: solid;
  border-color: var(--emu-common-colors-white);
}

@media (min-width: 768px) {
  .header .emu-navigation__menu--level-0 li a {
    padding-top: var(--emu-common-spacing-large);
    padding-bottom: var(--emu-common-spacing-large);
    padding-left: 10.4px;
    padding-right: 10.4px;
  }
}

@media (min-width: 992px) {
  .header .emu-navigation__menu--level-0 li a {
    padding-top: var(--emu-common-spacing-small);
    padding-bottom: var(--emu-common-spacing-small);
    border: none;
  }
}

@media (min-width: 1201px) {
  .header .emu-navigation__menu--level-0 li a {
    padding-top: var(--emu-common-spacing-small);
    padding-bottom: var(--emu-common-spacing-small);
    padding-left: 20.8px;
    padding-right: 20.8px;
  }
}

.header .emu-navigation__menu--level-0 li a:hover, .header .emu-navigation__menu--level-0 li a:active {
  border-color: var(--emu-common-colors-white);
}

.header .emu-navigation__menu--level-0 li a br {
  display: none;
}

@media (min-width: 768px) {
  .header .emu-navigation__menu--level-0 li a br {
    display: block;
  }
}

.header .emu-navigation__menu--level-0 li a sup {
  letter-spacing: normal;
  font-size: 50%;
  top: -1px;
  left: -1px;
}

@media (min-width: 1025px) {
  .header .emu-navigation__menu--level-0 li a sup {
    top: 0;
  }
}

.header__toggles {
  justify-content: center;
  align-items: center;
  margin-top: 49.6px;
  margin-bottom: 33.6px;
  display: flex;
}

@media (min-width: 768px) {
  .header__toggles {
    margin-top: 48px;
  }
}

@media (min-width: 992px) {
  .header__toggles {
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-common-spacing-none);
    padding-left: 10.4px;
    padding-right: 10.4px;
  }
}

@media (min-width: 1201px) {
  .header__toggles {
    padding-left: 20.8px;
    padding-right: var(--emu-common-spacing-none);
  }
}

.header__toggles ul {
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
  list-style: none;
  display: flex;
}

.header__toggles li {
  margin: var(--emu-common-spacing-none);
  border: var(--emu-common-border-width-thin) solid var(--emu-common-colors-brand-pink-500);
}

.header__toggles li:first-of-type, .header__toggles li:first-of-type .emphasis {
  border-radius: 30px 0 0 30px;
}

.header__toggles li:last-of-type, .header__toggles li:last-of-type .emphasis {
  border-radius: 0 30px 30px 0;
}

@media (min-width: 992px) {
  .header__toggles li {
    border-width: var(--emu-common-border-width-medium);
    border-color: var(--emu-common-colors-white);
  }
}

.header__toggles li .emphasis {
  background-color: var(--emu-common-colors-brand-pink-500);
  color: var(--emu-common-colors-brand-blue-500);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: inline-flex;
}

@media (min-width: 992px) {
  .header__toggles li .emphasis {
    background-color: var(--emu-common-colors-white);
  }
}

.header__toggles a {
  font-size: 10.6px;
  line-height: 16px;
  font-weight: var(--emu-common-font-weight-medium);
  font-family: var(--emu-common-font-families-roboto-bold);
  letter-spacing: 4px;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  width: 57px;
  height: 20px;
  display: flex;
}

@media (min-width: 992px) {
  .header__toggles a {
    letter-spacing: 2px;
    width: 37px;
    height: 26px;
  }
}

@media (min-width: 1201px) {
  .header__toggles a {
    letter-spacing: 4px;
    width: 41px;
  }
}

.header__toggles a, .header__toggles a:focus, .header__toggles a:active, .header__toggles a:hover {
  color: var(--emu-common-colors-brand-pink-500);
  text-decoration: none;
}

@media (min-width: 992px) {
  .header__toggles a, .header__toggles a:focus, .header__toggles a:active, .header__toggles a:hover {
    color: var(--emu-common-colors-white);
  }
}

@media (min-width: 1201px) {
  [lang="fr"] .header .emu-navigation__menu--level-0 li a {
    padding-left: var(--emu-common-spacing-medium);
    padding-right: var(--emu-common-spacing-medium);
  }
}

[lang="fr"] .header .emu-navigation__menu--level-0 li a sup {
  top: 1px;
  left: 0;
}

@media (min-width: 1201px) {
  [lang="fr"] .header__toggles {
    padding-left: var(--emu-common-spacing-medium);
  }
}



.hero {
  flex-direction: column-reverse;
  height: 100%;
  display: flex;
  position: relative;
}

@media (min-width: 768px) {
  .hero {
    display: revert;
  }
}

@media (min-width: 1600px) {
  .hero {
    overflow: hidden;
  }
}

.hero__img img {
  width: 100%;
  height: auto;
}

@media (min-width: 1025px) {
  .hero__img img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

@media (min-width: 1280px) {
  .hero__img img {
    object-fit: initial;
    height: auto;
  }
}

.cq-Editable-dom--container .hero__img img {
  object-fit: contain;
  max-height: 600px;
  position: static !important;
}

.hero__img-container {
  z-index: calc(var(--emu-common-other-z-index-behind)  + 1);
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (min-width: 768px) {
  .hero__img-container {
    top: 0;
    bottom: auto;
  }
}

@media (min-width: 1025px) {
  .hero__img-container {
    height: 100%;
  }
}

.cq-Editable-dom--container .hero__img-container {
  position: static !important;
}

.hero__cta-container {
  z-index: calc(var(--emu-common-other-z-index-layer)  + 2);
  cursor: pointer;
  position: absolute;
  top: 72%;
  right: 43%;
}

@media (min-width: 768px) {
  .hero__cta-container {
    top: 37%;
  }
}

@media (min-width: 1025px) {
  .hero__cta-container {
    top: 34%;
  }
}

.cq-Editable-dom--container .hero__cta-container {
  position: static !important;
}

.hero__cta.emu-button-v2.emu-button.emu-button-v2 {
  padding: var(--emu-common-spacing-none);
  display: block;
}

.hero__cta.emu-button-v2.emu-button.emu-button-v2, .hero__cta.emu-button-v2.emu-button.emu-button-v2:hover, .hero__cta.emu-button-v2.emu-button.emu-button-v2:focus, .hero__cta.emu-button-v2.emu-button.emu-button-v2:active {
  background-color: var(--emu-common-colors-transparent);
  border: var(--emu-common-border-width-none);
  outline: none;
}

.hero__cta.emu-button-v2.emu-button.emu-button-v2 span {
  display: none;
}

.hero__cta.emu-button-v2.emu-button.emu-button-v2 svg {
  margin: var(--emu-common-spacing-none);
  width: 60px;
  height: 60px;
  display: block;
}

@media (min-width: 1025px) {
  .hero__cta.emu-button-v2.emu-button.emu-button-v2 svg {
    width: 80px;
    height: 80px;
  }
}

.hero__content-container {
  z-index: var(--emu-common-other-z-index-layer);
  min-height: 660px;
  position: relative;
}

@media (min-width: 768px) {
  .hero__content-container {
    min-height: 515px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1025px) {
  .hero__content-container {
    min-height: 720px;
  }
}

.hero__content-inner {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  position: absolute;
  top: 5%;
  left: 0;
}

@media (min-width: 768px) {
  .hero__content-inner {
    padding-top: 55px;
    padding-left: var(--emu-common-spacing-none);
    width: 55%;
    position: static;
  }
}

@media (min-width: 1025px) {
  .hero__content-inner {
    width: 45%;
    padding-top: 80px;
  }
}

.cq-Editable-dom--container .hero__content-inner {
  position: static !important;
}

.hero__title {
  margin-bottom: var(--emu-common-spacing-small);
  letter-spacing: -.8px;
}

@media (min-width: 768px) {
  .hero__title {
    font-size: 50px;
    line-height: 46px;
  }
}

@media (min-width: 1025px) {
  .hero__title {
    font-size: var(--emu-common-font-sizes-wide-xxxl);
    line-height: var(--emu-common-line-heights-wide-xxxl);
  }
}

@media (min-width: 768px) {
  .hero__title h1, .hero__title h2, .hero__title h3 {
    font-size: inherit;
    line-height: inherit;
  }
}

.hero__para.cmp-text {
  letter-spacing: .07em;
  font-size: var(--emu-common-font-sizes-narrow-body);
  line-height: var(--emu-common-line-heights-narrow-body);
  max-width: 95%;
  margin-bottom: 16px;
  padding-top: 7px;
}

@media (min-width: 768px) {
  .hero__para.cmp-text {
    font-size: var(--emu-common-font-sizes-narrow-large);
    max-width: 410px;
    line-height: 30px;
  }
}

.hero__para.cmp-text p {
  margin-bottom: var(--emu-common-spacing-none);
}

.hero__person-label.hero__para.cmp-text {
  padding-top: var(--emu-common-spacing-none);
  margin-bottom: 5px;
  line-height: 30px;
}

@media (min-width: 768px) {
  .hero__person-label.hero__para.cmp-text {
    margin-bottom: var(--emu-common-spacing-medium);
  }
}

@media (min-width: 1025px) {
  .hero__person-label.hero__para.cmp-text {
    padding-top: 20px;
  }
}

.hero__disclaimer.hero__para.cmp-text {
  font-size: var(--emu-common-font-sizes-narrow-small);
  line-height: var(--emu-common-line-heights-narrow-xs);
  width: 75%;
}

@media (min-width: 768px) {
  .hero__disclaimer.hero__para.cmp-text {
    color: var(--emu-common-colors-black);
    padding-top: 25px;
    line-height: 10px;
    position: absolute;
    bottom: 80px;
  }
}

@media (min-width: 1025px) {
  .hero__disclaimer.hero__para.cmp-text {
    line-height: var(--emu-common-line-heights-wide-small);
    margin-bottom: 1%;
  }
}

@media (min-width: 1280px) {
  .hero__disclaimer.hero__para.cmp-text {
    margin-bottom: var(--emu-common-spacing-medium);
    padding-top: 7px;
  }
}

.cq-Editable-dom--container .hero__disclaimer.hero__para.cmp-text {
  position: static !important;
}

@media (min-width: 768px) {
  .hero__disclaimer br {
    display: none;
  }
}

.hero--right-algin-content {
  min-height: 700px;
  overflow: hidden;
}

@media (min-width: 768px) {
  .hero--right-algin-content {
    min-height: 385px;
    overflow: visible;
  }
}

@media (min-width: 900px) {
  .hero--right-algin-content {
    min-height: 450px;
  }
}

@media (min-width: 1200px) {
  .hero--right-algin-content {
    min-height: 600px;
  }
}

@media (min-width: 1440px) {
  .hero--right-algin-content {
    min-height: 720px;
  }
}

@media (min-width: 1600px) {
  .hero--right-algin-content {
    min-height: 820px;
  }
}

.hero--right-algin-content .cmp-text {
  color: inherit !important;
}

.hero--right-algin-content .hero__img-container {
  top: 0;
  bottom: unset;
}

.hero--right-algin-content .hero__img img {
  position: static;
}

.hero--right-algin-content .hero__cta-container {
  top: 20%;
}

@media (min-width: 768px) {
  .hero--right-algin-content .hero__cta-container {
    top: 50%;
  }

  .hero--right-algin-content .hero__cta.emu-button.emu-button-v2 svg {
    width: 80px;
    height: 80px;
  }
}

.hero--right-algin-content .hero__content-container {
  min-height: var(--emu-common-sizing-none);
  background: linear-gradient(180deg, transparent 2%, var(--emu-common-colors-white) 20%, var(--emu-common-colors-white) 100%);
  width: 100%;
  height: 100%;
  padding-top: 60px;
  padding-left: 35px;
  padding-right: 35px;
  position: absolute;
  top: 35%;
  left: 0;
  right: 35px;
}

@media (min-width: 768px) {
  .hero--right-algin-content .hero__content-container {
    padding-top: var(--emu-common-spacing-none);
    padding-right: 15px;
    padding-bottom: var(--emu-common-spacing-none);
    background: var(--emu-common-colors-transparent);
    min-height: 385px;
    padding-left: 15px;
    position: relative;
  }
}

@media (min-width: 900px) {
  .hero--right-algin-content .hero__content-container {
    min-height: 450px;
  }
}

@media (min-width: 1200px) {
  .hero--right-algin-content .hero__content-container {
    min-height: 600px;
  }
}

@media (min-width: 1440px) {
  .hero--right-algin-content .hero__content-container {
    min-height: 720px;
  }
}

.hero--right-algin-content .hero__content-inner {
  padding: var(--emu-common-spacing-none);
  position: static;
}

@media (min-width: 768px) {
  .hero--right-algin-content .hero__content-inner {
    margin-left: auto;
    margin-right: var(--emu-common-spacing-none);
    text-align: right;
    width: 50%;
    padding-top: 60px;
  }
}

@media (min-width: 900px) {
  .hero--right-algin-content .hero__content-inner {
    width: 44%;
  }
}

@media (min-width: 1200px) {
  .hero--right-algin-content .hero__content-inner {
    width: 64%;
  }
}

.hero--right-algin-content .hero__title {
  max-width: 75%;
}

@media (min-width: 768px) {
  .hero--right-algin-content .hero__title {
    max-width: none;
    margin-left: auto;
    font-size: 34px;
    line-height: 35px;
  }
}

@media (min-width: 1025px) {
  .hero--right-algin-content .hero__title {
    font-size: 52px;
    line-height: 62px;
  }

  .hero--right-algin-content .hero__title .emphasis {
    font-size: 60px;
    line-height: 70.4px;
  }
}

.hero--right-algin-content .hero__para {
  letter-spacing: .1em;
}

@media (min-width: 768px) {
  .hero--right-algin-content .hero__para {
    max-width: none;
    margin-left: auto;
    font-size: 18px;
    line-height: 28px;
  }
}

@media (min-width: 1025px) {
  .hero--right-algin-content .hero__para {
    max-width: 425px;
    padding-top: 7px;
    font-size: 22px;
    line-height: 30px;
  }
}

.hero--right-algin-content .hero__person-label.hero__para.cmp-text {
  margin-bottom: var(--emu-common-spacing-small);
  padding-top: 20px;
  line-height: 22px;
}

@media (min-width: 768px) {
  .hero--right-algin-content .hero__person-label.hero__para.cmp-text {
    padding-top: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1025px) {
  .hero--right-algin-content .hero__person-label.hero__para.cmp-text {
    font-size: 18px;
    line-height: 22px;
  }
}

@media (min-width: 1200px) {
  .hero--right-algin-content .hero__person-label.hero__para.cmp-text {
    padding-top: 20px;
  }
}

.hero--right-algin-content .hero__disclaimer.hero__para.cmp-text {
  width: 100%;
  max-width: 95%;
  padding-top: 7px;
  padding-bottom: 25px;
  padding-left: 35px;
  font-size: 10px;
  line-height: 12px;
  position: absolute;
  left: 0;
  right: 0;
}

@media (min-width: 768px) {
  .hero--right-algin-content .hero__disclaimer.hero__para.cmp-text {
    margin-bottom: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
    max-width: 425px;
    margin-left: auto;
    padding-left: 12px;
    font-size: 12px;
    line-height: 14.5px;
    bottom: -15px;
  }
}

@media (min-width: 1025px) {
  .hero--right-algin-content .hero__disclaimer.hero__para.cmp-text {
    margin-bottom: var(--emu-common-spacing-medium);
  }
}

.image-text .cmp-image__image {
  width: 100%;
}

@media (min-width: 768px) {
  .image-text svg {
    width: 100%;
  }
}

.image-text__container {
  overflow: hidden;
}

.image-text__flex-container {
  flex-direction: column-reverse;
  display: flex;
}

@media (min-width: 768px) {
  .image-text__flex-container {
    flex-direction: row;
    max-height: 700px;
  }

  .image-text__flex-container > .container:first-child {
    max-width: 40%;
  }
}

@media (min-width: 992px) {
  .image-text__flex-container > .container:first-child {
    max-width: 45%;
  }
}

@media (min-width: 768px) {
  .image-text__flex-container > .container:last-child {
    max-width: 55%;
  }
}

@media (min-width: 1200px) {
  .image-text__flex-container > .container:last-child {
    max-width: 48%;
  }
}

.image-text__image-container-image {
  margin-top: 12px;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .image-text__image-container-image {
    margin-top: var(--emu-semantic-spacing-none);
    margin-bottom: var(--emu-semantic-spacing-none);
  }

  .image-text__content {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;
    height: 100%;
    display: flex;
  }
}

@media (min-width: 992px) {
  .image-text__content {
    margin-right: 35px;
  }
}

.image-text__title {
  margin-top: 15%;
  margin-bottom: var(--emu-common-spacing-small);
}

@media (min-width: 768px) {
  .image-text__title {
    text-align: right;
    margin-bottom: var(--emu-common-spacing-small);
    margin-top: var(--emu-semantic-spacing-none);
    padding-top: 35px;
  }
}

@media (min-width: 992px) {
  .image-text__title {
    padding-top: 38px;
  }
}

@media (min-width: 768px) {
  .image-text__title h2 {
    letter-spacing: .1px;
    font-size: 32px;
    line-height: 35.2px;
  }
}

@media (min-width: 1025px) {
  .image-text__title h2 {
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
    line-height: 60px;
  }
}

.image-text__title h2 sup {
  font-size: 20px;
  left: -1px;
}

@media (min-width: 1440px) {
  .image-text__title h2 sup {
    font-weight: var(--emu-common-font-weight-black);
    top: -7px;
  }
}

.image-text__title h2 i {
  letter-spacing: -.4px;
  font-weight: var(--emu-common-font-weight-medium);
}

@media (min-width: 1025px) {
  .image-text__title h2 i {
    letter-spacing: -.9px;
  }
}

@media (min-width: 1200px) {
  .image-text__title h2 i {
    letter-spacing: -.19px;
  }
}

.image-text__disclaimer {
  max-width: 90%;
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .image-text__disclaimer {
    padding-top: var(--emu-semantic-spacing-none);
    max-width: 100%;
    margin-top: 21px;
    margin-bottom: 15px;
  }
}

@media (min-width: 992px) {
  .image-text__disclaimer {
    margin-top: 15px;
  }
}

@media (min-width: 1025px) {
  .image-text__disclaimer {
    margin-top: 25px;
    margin-bottom: var(--emu-semantic-spacing-none);
  }
}

@media (min-width: 1200px) {
  .image-text__disclaimer {
    position: relative;
    left: 35px;
  }
}

.image-text__disclaimer.cmp-text {
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  line-height: var(--emu-semantic-line-heights-narrow-small);
  letter-spacing: .1px;
}

@media (min-width: 768px) {
  .image-text__disclaimer.cmp-text {
    letter-spacing: .01px;
    line-height: 14px;
  }
}

.image-text__disclaimer.cmp-text sup {
  text-indent: -7px;
  display: inline-block;
}

.image-text__disclaimer.cmp-text p {
  margin-bottom: var(--emu-semantic-spacing-none);
}

.image-text__quote {
  max-width: 85%;
  margin-bottom: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
}

@media (min-width: 768px) {
  .image-text__quote {
    margin-bottom: var(--emu-common-spacing-medium);
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .image-text__quote {
    max-width: 97%;
  }
}

@media (min-width: 1440px) {
  .image-text__quote {
    max-width: 100%;
  }
}

.image-text__quote.cmp-text {
  letter-spacing: .54px;
}

@media (min-width: 768px) {
  .image-text__quote.cmp-text {
    letter-spacing: .96px;
    font-size: var(--emu-semantic-font-sizes-narrow-xl);
    line-height: 38px;
  }
}

@media (min-width: 1025px) {
  .image-text__quote.cmp-text {
    letter-spacing: 2.24px;
  }
}

.image-text__quote.cmp-text p {
  margin-bottom: var(--emu-semantic-spacing-none);
}

@media (min-width: 576px) {
  .image-text__quote.cmp-text br {
    display: none;
  }
}

@media (min-width: 992px) {
  .image-text__quote.cmp-text br:last-child {
    display: block;
  }
}

.image-text__patient-name {
  padding-top: var(--emu-semantic-spacing-none);
  margin-bottom: 5px;
}

@media (min-width: 768px) {
  .image-text__patient-name {
    margin-bottom: var(--emu-common-spacing-medium);
  }
}

@media (min-width: 1440px) {
  .image-text__patient-name {
    margin-top: 3px;
  }
}

.image-text__patient-name.cmp-text {
  letter-spacing: 5.4px;
}

@media (min-width: 768px) {
  .image-text__patient-name.cmp-text {
    letter-spacing: .42px;
    font-size: 14px;
    line-height: 20px;
  }
}

@media (min-width: 1025px) {
  .image-text__patient-name.cmp-text {
    font-size: var(--emu-semantic-font-sizes-wide-body);
    line-height: var(--emu-semantic-line-heights-wide-xs);
    letter-spacing: 1.26px;
  }
}

.image-text__patient-name.cmp-text p {
  margin-bottom: var(--emu-semantic-spacing-none);
}

.image-text__btn {
  position: unset;
  width: 320px;
  margin-top: 42px;
  margin-bottom: 75px;
}

@media (min-width: 768px) {
  .image-text__btn {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

@media (min-width: 1025px) {
  .image-text__btn {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}

.image-text__btn.emu-button-v2.emu-button__primary-outline.emu-button {
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
}

@media (min-width: 768px) {
  .image-text__btn.emu-button-v2.emu-button__primary-outline.emu-button {
    margin-left: var(--emu-semantic-spacing-none);
    margin-right: var(--emu-semantic-spacing-none);
    width: auto;
    padding: 10px 15px;
  }
}

@media (min-width: 1025px) {
  .image-text__btn.emu-button-v2.emu-button__primary-outline.emu-button {
    padding: 15px 30px;
  }
}

.image-text__btn span {
  letter-spacing: 4.2px;
  width: 100%;
  line-height: 16px;
}

@media (min-width: 768px) {
  .image-text__btn span {
    letter-spacing: 3.6px;
    font-size: var(--emu-semantic-font-sizes-wide-xs);
  }
}

@media (min-width: 1025px) {
  .image-text__btn span {
    letter-spacing: 4.5px;
    font-size: 15px;
    line-height: var(--emu-semantic-line-heights-wide-xs);
  }
}

.image-text--left-align .image-text__flex-container {
  flex-direction: column;
}

@media (min-width: 768px) {
  .image-text--left-align .image-text__flex-container {
    flex-direction: row-reverse;
  }
}

[data-component="modal"] .modal-wrapper {
  opacity: 0;
  pointer-events: none;
  padding-right: var(--scrollbar-width, 0);
  transition: all .3s linear .3s;
  display: block;
}

.cq-Editable-dom--container [data-component="modal"] .modal-wrapper {
  opacity: 1 !important;
}

[data-component="modal"] .modal-wrapper.is-open {
  opacity: 1;
  transition-delay: var(--emu-common-other-time-delay-none);
  pointer-events: initial;
}

@media (min-width: 768px) {
  [data-component="modal"] .modal-wrapper.is-open {
    transform: translateY(0);
  }
}

[data-component="modal"] .modal-wrapper.is-open .modal-content {
  opacity: 1;
  transition-delay: .3s;
}

@media (min-width: 768px) {
  [data-component="modal"] .modal-wrapper.is-open .modal-content {
    transform: translateY(0);
  }
}

[data-component="modal"] .modal-content {
  opacity: 0;
  transition: all .3s ease-out;
  top: 50%;
  transform: translateY(-50%);
}

@media (min-width: 768px) {
  [data-component="modal"] .modal-content {
    top: var(--emu-common-sizing-none);
    transform: translateY(-50px);
  }
}

.cq-Editable-dom--container [data-component="modal"] .modal-content {
  opacity: 1 !important;
}

[data-component="modal"] .modal__header {
  background-color: var(--emu-semantic-colors-brand-grey-400);
  padding-top: var(--emu-common-spacing-medium);
  padding-bottom: var(--emu-common-spacing-medium);
  padding-left: var(--emu-common-spacing-medium);
  padding-right: var(--emu-common-spacing-medium);
  border: 1px solid var(--emu-semantic-colors-brand-grey-400);
  border-top-left-radius: 4.8px;
  border-top-right-radius: 4.8px;
  height: 82.2px;
}

@media (min-width: 992px) {
  [data-component="modal"] .modal__header {
    display: none;
  }
}

[data-component="modal"] .modal__body {
  border: var(--emu-semantic-border-width-thin) solid #0003;
  padding: 48px;
}

@media (min-width: 992px) {
  [data-component="modal"] .modal__body {
    padding-top: 58.4px;
    padding-left: var(--emu-common-spacing-large);
    padding-right: var(--emu-common-spacing-large);
  }
}

[data-component="modal"] .modal__title {
  margin-top: var(--emu-semantic-sizing-none);
  margin-bottom: var(--emu-common-spacing-medium);
}

[data-component="modal"] .modal__title h2 {
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  letter-spacing: -.3px;
  line-height: 38px;
}

@media (min-width: 992px) {
  [data-component="modal"] .modal__title h2 {
    margin-bottom: 24px;
    font-size: 36px;
    line-height: 48px;
  }
}

[data-component="modal"] .modal__title h2 sup {
  font-size: 13px;
  font-weight: var(--emu-common-font-weight-black);
  top: -4px;
  left: 1px;
}

@media (min-width: 992px) {
  [data-component="modal"] .modal__title h2 sup {
    font-size: 15px;
    top: -4px;
    left: 3px;
  }
}

[data-component="modal"] .modal__description p {
  letter-spacing: 1.26px;
  line-height: 30px;
}

@media (min-width: 992px) {
  [data-component="modal"] .modal__description p {
    letter-spacing: .98px;
    font-size: 14px;
    line-height: var(--emu-semantic-sizing-one-line-height-wide);
  }
}

@media (min-width: 1025px) {
  [data-component="modal"] .modal__description p {
    letter-spacing: .07em;
    line-height: 15.4px;
  }
}

[data-component="modal"] .modal__description sup {
  font-size: 7px;
  font-weight: var(--emu-common-font-weight-black);
  top: -2px;
  left: -1px;
}

[data-component="modal"] .modal__description a, [data-component="modal"] .modal__description a:active {
  color: var(--emu-semantic-colors-brand-primary-600);
}

.js-modal-opened {
  padding-right: var(--scrollbar-width);
  overflow: hidden;
}

.natrelle-inspira {
  background-color: var(--emu-common-colors-brand-blue-600);
  position: relative;
  overflow: hidden;
}

@media (min-width: 768px) {
  .natrelle-inspira {
    background-color: var(--emu-common-colors-brand-blue-500);
  }
}

.natrelle-inspira__wrapper {
  padding-top: 34px;
}

@media (min-width: 768px) {
  .natrelle-inspira__wrapper {
    padding-bottom: 50px;
    padding-top: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1025px) {
  .natrelle-inspira__wrapper {
    padding: 3% 0 7%;
  }
}

.natrelle-inspira__wrapper .image {
  max-width: 75%;
  margin-left: auto;
  padding-top: 60px;
  padding-bottom: 60px;
  position: static;
  overflow: hidden;
}

@media (min-width: 768px) {
  .natrelle-inspira__wrapper .image {
    padding: var(--emu-common-spacing-none);
    margin: var(--emu-common-spacing-none);
    width: 100%;
    max-width: 400px;
    height: 100%;
    position: absolute;
    top: 0%;
    right: 0;
  }
}

@media (min-width: 1025px) {
  .natrelle-inspira__wrapper .image {
    top: 15%;
  }
}

@media (min-width: 1200px) {
  .natrelle-inspira__wrapper .image {
    right: -60px;
  }
}

@media (min-width: 1440px) {
  .natrelle-inspira__wrapper .image {
    left: 60%;
    right: 0;
  }
}

.natrelle-inspira__wrapper .image img {
  position: relative;
  right: -60px;
}

@media (min-width: 768px) {
  .natrelle-inspira__wrapper .image img {
    width: 65%;
    max-width: 100%;
    position: absolute;
    right: 0;
  }
}

@media (min-width: 1025px) {
  .natrelle-inspira__wrapper .image img {
    width: 100%;
  }
}

@media (min-width: 1440px) {
  .natrelle-inspira__wrapper .image img {
    width: auto;
  }
}

.natrelle-inspira__content {
  margin: auto;
}

@media (min-width: 768px) {
  .natrelle-inspira__content-title {
    font-size: var(--emu-common-font-sizes-narrow-xl);
    max-width: 82%;
    line-height: 1;
  }
}

@media (min-width: 1025px) {
  .natrelle-inspira__content-title {
    font-size: var(--emu-common-font-sizes-wide-xxl);
    line-height: var(--emu-common-line-heights-wide-xxl);
    max-width: 91%;
  }
}

@media (min-width: 768px) {
  .natrelle-inspira__content-title h2 {
    font-size: var(--emu-common-font-sizes-narrow-xl);
    letter-spacing: -.2px;
    line-height: 1;
  }
}

@media (min-width: 1025px) {
  .natrelle-inspira__content-title h2 {
    font-size: var(--emu-common-font-sizes-wide-xxl);
    line-height: var(--emu-common-line-heights-wide-xxl);
  }
}

@media (min-width: 1440px) {
  .natrelle-inspira__content-title h2 {
    letter-spacing: -.3px;
  }
}

@media (min-width: 768px) {
  .natrelle-inspira__content-title h2 br {
    display: none;
  }
}

.natrelle-inspira__content-title h2 .emphasis {
  display: block;
}

.natrelle-inspira__content-title h2 sup {
  font-size: 13px;
  font-style: normal;
  top: -2px;
  left: -1.9px;
}

@media (min-width: 768px) {
  .natrelle-inspira__content-title h2 sup {
    font-size: var(--emu-common-font-sizes-narrow-small);
  }
}

@media (min-width: 1025px) {
  .natrelle-inspira__content-title h2 sup {
    font-size: var(--emu-common-font-sizes-wide-small);
    top: -4px;
  }
}

.natrelle-inspira__content-title h2 i {
  letter-spacing: -.6px;
}

@media (min-width: 992px) {
  .natrelle-inspira__content-title h2 i {
    letter-spacing: -.4px;
  }
}

@media (min-width: 1440px) {
  .natrelle-inspira__content-title h2 i {
    letter-spacing: -.7px;
  }
}

.natrelle-inspira__content-desc {
  letter-spacing: .89px;
  padding-top: 15px;
  padding-bottom: 2%;
}

@media (min-width: 768px) {
  .natrelle-inspira__content-desc {
    max-width: 65%;
    padding-top: 1%;
    padding-bottom: 2%;
  }
}

@media (min-width: 1440px) {
  .natrelle-inspira__content-desc {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .natrelle-inspira__content-desc.cmp-text {
    margin-bottom: var(--emu-common-spacing-none);
    letter-spacing: .03em;
    font-size: 14px;
    line-height: 20px;
  }
}

@media (min-width: 1025px) {
  .natrelle-inspira__content-desc.cmp-text {
    letter-spacing: .07em;
    line-height: var(--emu-common-line-heights-narrow-body);
  }
}

@media (min-width: 1300px) {
  .natrelle-inspira__content-desc.cmp-text {
    font-size: var(--emu-common-font-sizes-narrow-body);
    letter-spacing: 1.29px;
  }
}

.natrelle-inspira__content-desc br {
  display: none;
}

@media (min-width: 992px) {
  .natrelle-inspira__content-desc br {
    display: block;
  }
}

.natrelle-inspira__content-desc sup {
  font-size: 8px;
}

@media (min-width: 768px) {
  .natrelle-inspira__content-desc sup {
    font-size: 7px;
    top: -1px;
    left: -1px;
  }
}

.natrelle-inspira__content .button .emu-button-v2.emu-button__secondary-outline.emu-button.natrelle-inspira__content-button {
  letter-spacing: 3.9px;
  text-align: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .natrelle-inspira__content .button .emu-button-v2.emu-button__secondary-outline.emu-button.natrelle-inspira__content-button {
    padding-top: 9px;
    padding-right: var(--emu-common-spacing-medium);
    font-size: var(--emu-common-font-sizes-narrow-small);
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-common-spacing-none);
    letter-spacing: 3.6px;
    width: auto;
    padding-bottom: 9px;
    padding-left: 15px;
  }
}

@media (min-width: 992px) {
  .natrelle-inspira__content .button .emu-button-v2.emu-button__secondary-outline.emu-button.natrelle-inspira__content-button {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1025px) {
  .natrelle-inspira__content .button .emu-button-v2.emu-button__secondary-outline.emu-button.natrelle-inspira__content-button {
    letter-spacing: 4.3px;
    padding: 15px 30px;
    font-size: 14px;
  }
}

.natrelle-inspira__content .button .emu-button-v2.emu-button__secondary-outline.emu-button.natrelle-inspira__content-button:hover, .natrelle-inspira__content .button .emu-button-v2.emu-button__secondary-outline.emu-button.natrelle-inspira__content-button:focus {
  color: var(--emu-common-colors-brand-blue-500) !important;
}

.natrelle-inspira__content .button .emu-button-v2.emu-button__secondary-outline.emu-button.natrelle-inspira__content-button:active {
  background-color: var(--emu-common-colors-transparent);
  color: var(--emu-common-colors-brand-pink-500);
}

.natrelle-inspira__content .button .emu-button-v2.emu-button__secondary-outline.emu-button.natrelle-inspira__content-button sup {
  letter-spacing: normal;
  font-size: 6px;
  top: -1px;
  right: 2px;
}

.patients-first {
  padding-top: 50px;
  padding-bottom: 50px;
  overflow: hidden;
}

@media (min-width: 1025px) {
  .patients-first {
    padding-top: 100px;
    padding-bottom: 20px;
  }
}

.patients-first__wrapper {
  position: relative;
}

.patients-first__title {
  text-align: center;
  margin-bottom: var(--emu-common-spacing-large);
  margin-top: var(--emu-semantic-spacing-none);
  max-width: 65%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .patients-first__title {
    text-align: left;
    font-size: var(--emu-semantic-font-sizes-narrow-xl);
    margin-bottom: var(--emu-semantic-spacing-vertical-narrow);
    max-width: 100%;
  }

  .patients-first__title h2 {
    font-size: var(--emu-semantic-font-sizes-narrow-xl);
    line-height: 35.2px;
  }
}

@media (min-width: 992px) {
  .patients-first__title h2 {
    letter-spacing: -.08px;
  }
}

@media (min-width: 1025px) {
  .patients-first__title h2 {
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
    line-height: var(--emu-semantic-line-heights-wide-xxl);
    letter-spacing: -.4px;
  }
}

.patients-first__title h2 i {
  letter-spacing: -.75px;
}

@media (min-width: 768px) {
  .patients-first__title h2 i {
    letter-spacing: -1.08px;
  }
}

@media (min-width: 992px) {
  .patients-first__title h2 i {
    letter-spacing: -1.2px;
  }
}

@media (min-width: 1200px) {
  .patients-first__title h2 i {
    letter-spacing: -2.1px;
  }
}

@media (min-width: 1440px) {
  .patients-first__title h2 i {
    letter-spacing: 0;
  }
}

.patients-first__image {
  vertical-align: middle;
  width: 100%;
  max-width: 180px;
  position: absolute;
  top: 7%;
  right: -5px;
}

@media (min-width: 1025px) {
  .patients-first__image {
    max-width: 250px;
  }
}

.patients-first__image-mobile {
  width: 75%;
  margin-top: 48px;
  margin-bottom: 45.6px;
}

.patients-first__content {
  text-align: center;
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2%;
}

@media (min-width: 768px) {
  .patients-first__content {
    margin-left: var(--emu-semantic-spacing-none);
    text-align: start;
    max-width: 70%;
  }
}

@media (min-width: 900px) {
  .patients-first__content {
    max-width: 95%;
  }
}

.patients-first__content .emphasis {
  display: inline-block;
}

.patients-first__content.text > p {
  letter-spacing: .18px;
  margin-bottom: var(--emu-semantic-spacing-none);
}

@media (min-width: 768px) {
  .patients-first__content.text > p {
    font-size: 14px;
    line-height: var(--emu-semantic-sizing-one-line-height-wide);
    margin-bottom: var(--emu-common-spacing-medium);
    letter-spacing: .14px;
  }
}

@media (min-width: 1025px) {
  .patients-first__content.text > p {
    font-size: var(--emu-semantic-font-sizes-wide-body);
    line-height: var(--emu-semantic-line-heights-narrow-body);
    letter-spacing: .18px;
  }
}

@media (min-width: 900px) {
  .patients-first__content.text > p br {
    display: none;
  }
}

@media (min-width: 1201px) {
  .patients-first__content.text > p br {
    display: block;
  }
}

.patients-first__content.text > p sup {
  font-size: 9px;
  top: -1px;
  left: -.9px;
}

@media (min-width: 768px) {
  .patients-first__content.text > p sup {
    font-size: 7px;
  }
}

@media (min-width: 1025px) {
  .patients-first__content.text > p sup {
    font-size: 9px;
  }
}

.patients-first__content.text > p > .trademark-sup {
  font-size: 28px;
  font-weight: var(--emu-semantic-font-weight-regular);
  top: 14px;
}

@media (min-width: 768px) {
  .patients-first__content.text > p > .trademark-sup {
    font-size: 21px;
    top: 10px;
    left: .1px;
  }
}

@media (min-width: 1025px) {
  .patients-first__content.text > p > .trademark-sup {
    font-size: 28px;
    top: 14px;
  }
}

.patients-first .button {
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .patients-first .button {
    margin-left: var(--emu-semantic-spacing-none);
    margin-right: var(--emu-semantic-spacing-none);
  }
}

.patients-first__btn.emu-button.emu-button-v2.emu-button__primary-outline {
  text-align: center;
  transition: all var(--emu-common-other-time-transition-short);
  width: 300px;
  margin-top: 45px;
  margin-bottom: 45px;
}

@media (min-width: 768px) {
  .patients-first__btn.emu-button.emu-button-v2.emu-button__primary-outline {
    margin-top: 20px;
    margin-bottom: var(--emu-semantic-spacing-none);
    margin-left: var(--emu-semantic-spacing-none);
    max-width: 168.16px;
    padding: 10px 15px;
  }
}

@media (min-width: 1025px) {
  .patients-first__btn.emu-button.emu-button-v2.emu-button__primary-outline {
    max-width: 220.5px;
    padding: 15px 30px;
  }
}

.patients-first__btn.emu-button.emu-button-v2.emu-button__primary-outline > span {
  letter-spacing: 4.2px;
  width: 100%;
}

@media (min-width: 768px) {
  .patients-first__btn.emu-button.emu-button-v2.emu-button__primary-outline > span {
    font-size: var(--emu-semantic-font-sizes-narrow-small);
    line-height: var(--emu-semantic-line-heights-narrow-xs);
    letter-spacing: 3.6px;
  }
}

@media (min-width: 1025px) {
  .patients-first__btn.emu-button.emu-button-v2.emu-button__primary-outline > span {
    letter-spacing: 4px;
    font-size: 14px;
    line-height: 16px;
  }
}

.aaaem-tabs__tablist {
  justify-content: space-between;
  height: 45px;
}

@media (min-width: 1025px) {
  .aaaem-tabs__tablist {
    height: 60px;
  }
}

.aaaem-tabs__tab {
  margin-bottom: var(--emu-common-spacing-none);
  letter-spacing: .3em;
  text-align: center;
  width: 30%;
  font-size: 12px;
  line-height: 14px;
}

@media (min-width: 1025px) {
  .aaaem-tabs__tab {
    font-size: 15px;
    line-height: 18px;
  }
}

@media (min-width: 1200px) {
  .aaaem-tabs__tab {
    width: 320px;
  }
}

.aaaem-tabs__tab a {
  border-radius: 25px 25px 0 0;
  justify-content: center;
  align-items: center;
  height: 45px;
  padding: 12px 10px 10px;
  display: flex;
}

@media (min-width: 1025px) {
  .aaaem-tabs__tab a {
    padding-top: var(--emu-common-spacing-small);
    padding-right: var(--emu-common-spacing-medium);
    padding-bottom: var(--emu-common-spacing-small);
    padding-left: var(--emu-common-spacing-medium);
    height: 60px;
  }
}

.aaaem-tabs__tabpanels {
  border-width: var(--emu-common-border-width-thick) 2px 2px 2px;
  border-style: solid;
  border-color: var(--emu-common-colors-brand-blue-500);
  background: #ffffffb3;
  position: relative;
}

.aaaem-tabs__tabpanel {
  opacity: 0;
  transition: opacity var(--emu-common-other-time-duration-instant) linear;
  padding: 40px;
  display: block;
}

@media (min-width: 1025px) {
  .aaaem-tabs__tabpanel {
    padding: 50px 75px;
  }
}

.aaaem-tabs__tabpanel[hidden="hidden"] {
  opacity: 0;
  padding: var(--emu-common-spacing-none);
  width: 0;
  transition: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.aaaem-tabs__tabpanel:not([hidden="hidden"]) {
  opacity: 1;
}

@media (max-width: 767px) {
  .u-tabs--inline-in-mobile .aaaem-tabs__tablist {
    display: none;
  }

  .u-tabs--inline-in-mobile .aaaem-tabs__tabpanels {
    border: none;
  }

  .u-tabs--inline-in-mobile .aaaem-tabs__tabpanel {
    padding: var(--emu-common-spacing-none);
    opacity: 1;
  }

  .u-tabs--inline-in-mobile .aaaem-tabs__tabpanel[hidden="hidden"] {
    width: 100%;
    position: static;
  }
}

.teaser-container {
  flex-direction: column-reverse;
  min-height: auto;
  display: flex;
}

@media (min-width: 768px) {
  .teaser-container {
    display: block;
  }
}

@media (min-width: 1025px) {
  .teaser-image-container .cmp-teaser__image--desktop img {
    position: static;
  }
}

.teaser-image-container .cmp-teaser__image--mobile {
  position: relative;
}

.teaser-image-container .cmp-teaser__image--mobile svg {
  width: 100%;
  height: 100%;
  display: block;
}

.teaser .emu-teaser-v3.inline-text-overlay .teaser-container > .content-container {
  position: static;
  top: 5%;
}

@media (min-width: 768px) {
  .teaser .emu-teaser-v3.inline-text-overlay .teaser-container > .content-container {
    height: auto;
    position: absolute;
    top: 0%;
    right: 5%;
  }
}

@media (min-width: 1440px) {
  .teaser .emu-teaser-v3 .teaser-container {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
}

.teaser .emu-teaser-v3 .content-container .emu-teaser__action-container {
  position: absolute;
  top: 64%;
  right: 43%;
}

@media (min-width: 768px) {
  .teaser .emu-teaser-v3 .content-container .emu-teaser__action-container {
    top: 38%;
    right: 37%;
  }
}

@media (min-width: 992px) {
  .teaser .emu-teaser-v3 .content-container .emu-teaser__action-container {
    top: 40%;
    right: 39%;
  }
}

@media (min-width: 1025px) {
  .teaser .emu-teaser-v3 .content-container .emu-teaser__action-container {
    top: 32%;
    right: 39%;
  }
}

.teaser .emu-teaser-v3 .content-container .emu-teaser__title {
  margin-bottom: var(--emu-common-spacing-small);
  font-weight: var(--emu-common-font-weight-regular);
  max-width: 95%;
}

@media (min-width: 768px) {
  .teaser .emu-teaser-v3 .content-container .emu-teaser__title {
    font-size: var(--emu-common-font-sizes-narrow-xl);
    line-height: 32px;
  }
}

@media (min-width: 1025px) {
  .teaser .emu-teaser-v3 .content-container .emu-teaser__title {
    font-size: var(--emu-common-font-sizes-wide-xxxl);
    line-height: var(--emu-common-line-heights-wide-xxxl);
  }
}

.teaser .emu-teaser-v3 .content-container .emu-teaser__title br {
  display: none !important;
}

@media (min-width: 768px) {
  .teaser .emu-teaser-v3 .content-container .emu-teaser__title br {
    display: block !important;
  }
}

.teaser .emu-teaser-v3 .content-container .emu-teaser__title sup {
  font-weight: var(--emu-common-font-weight-black);
}

@media (min-width: 1025px) {
  .teaser .emu-teaser-v3 .content-container .emu-teaser__title sup {
    top: -5px;
    left: -.1em;
  }
}

.teaser .emu-teaser-v3 .content-container .emu-teaser__title i {
  font-family: var(--emu-common-font-families-aleo);
  font-weight: var(--emu-common-font-weight-bold);
}

.teaser .emu-teaser-v3 .content-container .emu-teaser__description {
  margin-left: var(--emu-common-spacing-none);
  max-width: 90%;
}

@media (min-width: 768px) {
  .teaser .emu-teaser-v3 .content-container .emu-teaser__description {
    max-width: 46%;
    margin-left: auto;
  }
}

.teaser .emu-teaser-v3 .content-container .emu-teaser__description p {
  margin-bottom: var(--emu-common-spacing-none);
  display: block !important;
}

@media (min-width: 768px) {
  .teaser .emu-teaser-v3 .content-container .emu-teaser__description p {
    font-size: var(--emu-common-font-sizes-wide-small);
    line-height: 22px;
  }
}

@media (min-width: 1025px) {
  .teaser .emu-teaser-v3 .content-container .emu-teaser__description p {
    font-size: var(--emu-common-font-sizes-wide-large);
    line-height: 30px;
    font-weight: var(--emu-common-font-weight-regular);
  }
}

.teaser .emu-teaser-v3 .content-container .emu-teaser__description p sup {
  font-size: var(--emu-common-font-sizes-narrow-xs);
}

.teaser .emu-teaser-v3 .content-container .emu-teaser__subtext {
  padding-top: var(--emu-common-spacing-none);
  margin-bottom: 5px;
}

@media (min-width: 768px) {
  .teaser .emu-teaser-v3 .content-container .emu-teaser__subtext {
    margin-bottom: var(--emu-common-spacing-medium);
    max-width: 410px;
  }
}

.teaser .emu-teaser-v3 .content-container .emu-teaser__subtext p {
  letter-spacing: 1.26px;
  line-height: 30px;
}

@media (min-width: 768px) {
  .teaser .emu-teaser-v3 .content-container .emu-teaser__subtext p {
    letter-spacing: 1.54px;
    font-size: var(--emu-common-font-sizes-wide-large);
  }
}

@media (min-width: 1025px) {
  .teaser .emu-teaser-v3 .content-container .emu-teaser__subtext p {
    padding-top: 20px;
  }
}

.teaser .emu-teaser-v3 .content-container .emu-teaser__disclaimer {
  margin-bottom: var(--emu-common-spacing-medium);
  letter-spacing: .84px;
  margin-top: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
  width: 75%;
  max-width: 410px;
  position: relative;
}

@media (min-width: 768px) {
  .teaser .emu-teaser-v3 .content-container .emu-teaser__disclaimer {
    padding-top: 17px;
  }
}

@media (min-width: 1025px) {
  .teaser .emu-teaser-v3 .content-container .emu-teaser__disclaimer {
    margin-bottom: 9.5px;
    padding-top: 7px;
    position: absolute;
    bottom: 80px;
    left: auto;
  }
}

.teaser .emu-teaser-v3 .content-container .emu-teaser__disclaimer p {
  font-size: var(--emu-common-font-sizes-narrow-small);
  line-height: var(--emu-common-line-heights-narrow-xs);
}

@media (min-width: 768px) {
  .teaser .emu-teaser-v3 .content-container .emu-teaser__disclaimer p {
    line-height: 10px;
  }
}

@media (min-width: 1025px) {
  .teaser .emu-teaser-v3 .content-container .emu-teaser__disclaimer p {
    line-height: 14.5px;
  }
}

.teaser .emu-teaser-v3 .content-container .emu-teaser__disclaimer p br {
  display: block;
}

@media (min-width: 768px) {
  .teaser .emu-teaser-v3 .content-container .emu-teaser__disclaimer p br {
    display: none;
  }
}

.teaser .emu-teaser-v3 .title-lockup {
  margin-bottom: var(--emu-common-spacing-none);
}

.teaser__play-button {
  border-width: var(--emu-common-border-width-none);
  cursor: pointer;
  width: 60px;
  height: 60px;
}

@media (min-width: 1025px) {
  .teaser__play-button {
    width: 80px;
    height: 80px;
  }
}

.teaser__play-button.emu-button-v2.emu-button:not(a) {
  background-color: var(--emu-common-colors-transparent);
  border-color: var(--emu-common-colors-transparent);
}

.teaser__play-button.emu-button-v2.emu-button:not(a):hover, .teaser__play-button.emu-button-v2.emu-button:not(a):active, .teaser__play-button.emu-button-v2.emu-button:not(a):focus {
  background-color: var(--emu-common-colors-transparent);
  border-color: var(--emu-common-colors-transparent);
  outline-color: var(--emu-common-colors-transparent);
}

.teaser__play-button img {
  width: 60px;
  height: auto;
}

@media (min-width: 1025px) {
  .teaser__play-button img {
    width: 80px;
  }
}

@media (min-width: 768px) {
  .teaser__play-button--mobile.emu-button-v2.emu-button:not(a) {
    display: none;
  }
}

.teaser__play-button--desktop.emu-button-v2.emu-button:not(a) {
  display: none;
}

@media (min-width: 768px) {
  .teaser__play-button--desktop.emu-button-v2.emu-button:not(a) {
    display: block;
  }
}

.teaser-video-modal .modal-content {
  border-radius: var(--emu-common-border-radius-xs);
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
  padding-top: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-xs);
  width: auto;
  max-width: 900px;
  margin: 6px 9px;
  position: relative;
  overflow: hidden;
}

@media (min-width: 768px) {
  .teaser-video-modal .modal-content {
    max-width: 700px;
    margin: 28px auto;
  }
}

@media (min-width: 1025px) {
  .teaser-video-modal .modal-content {
    max-width: 900px;
  }
}

.teaser-video-modal .modal-content .close {
  padding: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-small);
  opacity: 1;
  margin-left: auto;
  line-height: 1;
  position: relative;
}

.teaser-video-modal .modal-content .close span {
  font-family: var(--emu-common-font-families-aleo);
  font-size: 24px;
}

@media (min-width: 768px) {
  .teaser-video-modal .modal-content .close span {
    font-size: 27px;
  }
}

[data-component="text"] p, [data-component="text"] li {
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
}

.title-text__title {
  margin-bottom: var(--emu-common-spacing-small);
  letter-spacing: -1px;
}

@media (min-width: 768px) {
  .title-text__title {
    font-size: 32px;
    line-height: 48px;
  }
}

@media (min-width: 1025px) {
  .title-text__title {
    font-size: var(--emu-common-font-sizes-wide-xxl);
    line-height: var(--emu-common-line-heights-wide-xxl);
  }
}

@media (min-width: 768px) {
  .title-text__title h1, .title-text__title h2, .title-text__title h3, .title-text__title h4 {
    font-size: inherit;
    line-height: inherit;
  }
}

.title-text__title .emphasis {
  display: block;
}

@media (min-width: 768px) {
  .title-text__title .emphasis {
    display: revert;
  }
}

.title-text__title.title-text__title--break-in-desktop .emphasis {
  display: inline;
}

@media (min-width: 768px) {
  .title-text__title.title-text__title--break-in-desktop .emphasis {
    display: block;
  }
}

.title-text__title.title-text__title--break-always .emphasis {
  display: block;
}

.title-text__title.title-text__title--no-break .emphasis {
  display: inline;
}

.title-text__txt {
  padding-top: var(--emu-common-spacing-medium);
  padding-bottom: var(--emu-common-spacing-medium);
  margin-bottom: var(--emu-common-spacing-medium);
  letter-spacing: .07em;
  color: var(--emu-common-colors-dark-grey-400);
  max-width: 95%;
}

@media (min-width: 768px) {
  .title-text__txt {
    font-size: 17px;
  }
}

@media (min-width: 1025px) {
  .title-text__txt {
    font-size: var(--emu-common-font-sizes-wide-small);
  }
}

.title-text__txt p {
  margin-bottom: var(--emu-common-spacing-none);
}

.title-text__txt p + p {
  margin-top: var(--emu-common-spacing-medium);
}

.title-text__cta.emu-button-v2.emu-button-v2.emu-button-v2 {
  text-align: center;
  letter-spacing: .3em;
  width: 90%;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  padding: 15px 25px;
  font-size: 14px;
  transition: all .2s;
  display: block;
}

@media (min-width: 768px) {
  .title-text__cta.emu-button-v2.emu-button-v2.emu-button-v2 {
    font-size: var(--emu-common-font-sizes-wide-xs);
    margin-top: 25px;
    margin-bottom: var(--emu-common-spacing-none);
    width: auto;
    padding: 10px 15px;
    line-height: 14px;
    display: inline-flex;
  }
}

@media (min-width: 1025px) {
  .title-text__cta.emu-button-v2.emu-button-v2.emu-button-v2 {
    padding: 15px 30px;
    font-size: 14px;
    line-height: 16px;
  }
}

@media (min-width: 1200px) {
  .title-text__cta.emu-button-v2.emu-button-v2.emu-button-v2 {
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: 35px;
  }
}

.title-text__disclaimer {
  color: var(--emu-common-colors-black);
  padding-top: var(--emu-common-spacing-large);
  letter-spacing: .07em;
  max-width: 95%;
  font-size: 11px;
}

@media (min-width: 1025px) {
  .title-text__disclaimer {
    font-size: var(--emu-common-font-sizes-wide-xs);
  }
}

@media (min-width: 1200px) {
  .title-text__disclaimer {
    padding-top: 48px;
  }
}

.title-text__person-label {
  color: var(--emu-common-colors-brand-blue-200);
  letter-spacing: .15em;
  font-size: 22px;
  line-height: 1.1;
  font-family: var(--emu-common-font-families-graphik);
}

@media (min-width: 768px) {
  .title-text__person-label {
    letter-spacing: normal;
    font-size: 16px;
  }
}

@media (min-width: 1025px) {
  .title-text__person-label {
    letter-spacing: .15em;
  }
}

.title-text__person-label p {
  margin-bottom: var(--emu-common-spacing-none);
}

.title-text__img img {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

@media (min-width: 768px) {
  .title-text__img img {
    margin: var(--emu-common-spacing-none);
    width: 40%;
  }
}

.title-text__img-caption {
  letter-spacing: .4em;
  margin-top: var(--emu-common-spacing-xxs);
  text-align: center;
  font-size: 13px;
  line-height: 1.3;
  font-family: var(--emu-common-font-families-graphik);
  padding-top: 2%;
}

@media (min-width: 768px) {
  .title-text__img-caption {
    text-align: left;
    font-size: 12px;
    line-height: 17px;
  }
}

.title-text__img-caption p {
  margin-bottom: var(--emu-common-spacing-none);
}

.video-modal[data-component="modal"] .modal-content {
  width: calc(100% - var(--emu-common-spacing-medium));
  margin: var(--emu-common-spacing-small);
  padding: var(--emu-common-spacing-none);
  background-color: var(--emu-common-colors-white);
  border: var(--emu-common-border-width-thin) solid #0003;
  background-clip: padding-box;
  border-radius: 4.8px;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

@media (min-width: 576px) {
  .video-modal[data-component="modal"] .modal-content {
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
    width: 100%;
    margin-top: 28px;
    margin-bottom: 28px;
  }
}

@media (min-width: 768px) {
  .video-modal[data-component="modal"] .modal-content {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1025px) {
  .video-modal[data-component="modal"] .modal-content {
    max-width: 900px;
  }
}

.video-modal[data-component="modal"] .close {
  color: var(--emu-common-colors-brand-blue-500);
  opacity: 1;
  padding: var(--emu-common-spacing-none);
  font-size: 24px;
  line-height: 1.1;
  font-weight: var(--emu-common-font-weight-bold);
  padding-left: var(--emu-common-spacing-small);
  align-self: flex-end;
  padding-right: 6px;
  display: inline-flex;
  position: static;
}

@media (min-width: 768px) {
  .video-modal[data-component="modal"] .close {
    max-height: 26px;
    font-size: 25px;
  }
}

.video-modal[data-component="modal"] .embed .aaaem-embed__oembed {
  padding: var(--emu-common-spacing-none);
}

.video-modal[data-component="modal"] .embed iframe {
  width: 600px;
  max-width: 100%;
  height: 210px;
  margin-bottom: 4px;
  display: block;
  position: static;
}

@media (min-width: 768px) {
  .video-modal[data-component="modal"] .embed iframe {
    width: 750px;
    height: 400px;
  }
}

@media (min-width: 1025px) {
  .video-modal[data-component="modal"] .embed iframe {
    width: 1000px;
    height: 500px;
  }
}

/*# sourceMappingURL=main.css.map */
