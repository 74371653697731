html {
  -webkit-text-size-adjust: 100%;
}

html,
body {
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
  font-size: var(--emu-semantic-font-sizes-narrow-body);
  font-family: var(--emu-semantic-font-families-body);
  line-height: var(--emu-common-line-heights-narrow-body);

  // Adobe adds a negative margin, need to overwrite. Do not remove.
  .xf-content-height {
    margin: inherit;
  }

  .experiencefragment {
    padding: var(--emu-common-spacing-none);
  }
}

body {
  -webkit-overflow-scrolling: touch;
}

* {
  outline: var(--emu-semantic-colors-surface-dark);
}

*,
::before,
::after {
  box-sizing: border-box;
}

.cmp-image__image,
image {
  max-width: 100%;
  width: auto;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

*,
::before,
::after {
  box-sizing: border-box;
}

sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: super;
  top: 0;
  display: inline-block;
  margin-right: 1px;
  font-family: var(--emu-common-font-families-roboto);
  letter-spacing: normal;
}

p,
li {
  margin-bottom: var(--emu-common-spacing-medium);
  margin-top: var(--emu-common-spacing-none);
}

b {
  font-weight: var(--emu-common-font-weight-bold);
}

i,
b,
.emphasis,
span,
a {
  font-family: inherit;
}
