.footer {
  &--outer {
    @include mq('large') {
      padding-left: var(--emu-common-spacing-large);
      padding-right: var(--emu-common-spacing-large);
    }
  }

  &__container {
    &.aaaem-container {
      padding-top: 30px;
      padding-left: var(--emu-common-spacing-medium);
      padding-bottom: var(--emu-common-spacing-large);
      padding-right: var(--emu-common-spacing-medium);

      @include mq('medium') {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: var(--emu-common-spacing-large);
      }

      @include mq('large') {
        padding-top: var(--emu-common-spacing-xl);
      }
    }
  }

  &__main {
    &.aaaem-container {
      @include mq('medium') {
        padding-left: 15px;
        padding-right: 15px;
      }

      @include mq('medium-plus') {
        max-width: 960px;
        margin-left: auto;
        margin-right: auto;
      }

      // breakpoint added to match with the live site
      @include mq('x-large') {
        max-width: var(--emu-common-sizing-xxxl);
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &__logos {
    &.aaaem-container {
      margin-bottom: var(--emu-common-spacing-large);

      @include mq('medium') {
        margin-bottom: 37px;
        display: flex;
        justify-content: center;
        padding-left: 25px;
        padding-right: 25px;
        align-items: center;
      }

      @include mq('large') {
        margin-bottom: 61px;
        margin-left: 10px;
        margin-right: 10px;
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
      }
    }

    > .image {
      img {
        margin-left: auto;
        margin-right: auto;
      }

      @include mq('medium') {
        flex: 1;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  &__links {
    &.aaaem-container {
      margin-bottom: 48px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: column;

      @include mq('medium') {
        margin-bottom: 36px;
      }

      @include mq('large') {
        margin-bottom: 45px;
      }
    }

    ul {
      margin: var(--emu-common-spacing-none);
      padding: var(--emu-common-spacing-none);
      list-style: none;

      @include mq('medium') {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
      }

      li {
        font-size: 15px;
        line-height: 24px;
        margin: var(--emu-common-spacing-none);
        text-transform: uppercase;

        @include mq('medium') {
          font-size: 10px;
          line-height: 15px;
          text-align: center;
        }

        @include mq('large') {
          font-size: 15px;
          line-height: 18px;
        }
      }

      a {
        text-decoration: underline;
        display: inline-block;
        margin-top: 3.2px; // to match live site
        margin-bottom: 3.2px; // to match live site
        font-family: var(--emu-semantic-font-families-body);

        @include mq('medium') {
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        br {
          line-height: 0;
        }
      }
    }

    .image {
      img {
        margin-top: 38.4px; // to match live site
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &__copyrights {
    .cmp-text {
      font-size: 13px;
      line-height: 22px;
      letter-spacing: 0.65px; // to match live site
      font-family: var(--emu-semantic-font-families-body);

      @include mq('medium') {
        max-width: 85%;
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-none);
        margin-left: auto;
        margin-right: auto;
        font-size: 13px;
        line-height: 15.25px;
        letter-spacing: 0.39px;
      }

      @include mq('large') {
        letter-spacing: 0;
      }
    }

    p {
      @include mq('medium') {
        margin-bottom: 20px;
      }

      @include mq('large') {
        margin-bottom: var(--emu-common-spacing-medium);
      }

      .emphasis {
        @include mq('medium') {
          display: block;
        }
      }
    }
  }
}

[lang='fr'] {
  .footer__copyrights p .emphasis br {
    @include mq('medium') {
      display: block;
    }
  }

  .footer__copyrights .cmp-text {
    @include mq('large') {
      max-width: 100%;
    }
  }
}
