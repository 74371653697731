.content-box {
  &__title {
    h2 {
      font-weight: var(--emu-common-font-weight-light);

      @include mq('medium') {
        font-size: var(--emu-common-font-sizes-narrow-xl);
        line-height: var(--emu-common-line-heights-narrow-xl);
      }

      @include mq('large') {
        font-size: var(--emu-common-font-sizes-wide-xxl);
        line-height: 58px;
      }

      b {
        letter-spacing: -1px;
      }
    }
  }

  &__description {
    padding-top: 5%;
    letter-spacing: 0.03em;
    max-width: 95%;
    margin-bottom: 5px;

    @include mq('medium') {
      max-width: 100%;
      padding-top: var(--emu-common-spacing-none);
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.03em;
      margin-bottom: var(--emu-common-spacing-medium);
    }

    @include mq('large') {
      font-size: var(--emu-common-font-sizes-narrow-body);
      line-height: var(--emu-common-line-heights-narrow-body);
      letter-spacing: 0.07em;
    }

    @include mq('x-large') {
      text-indent: -17px;
    }

    p {
      margin-bottom: var(--emu-common-spacing-none);
    }
  }

  &__button {
    margin-top: 40px;
    margin-bottom: 35px;
    width: 320px;
    text-align: center;

    @include mq('medium') {
      margin-top: 10px;
      margin-bottom: 20px;
      width: auto;
    }

    @include mq('large') {
      margin-top: 25px;
      margin-bottom: 25px;
    }

    &.emu-button-v2.emu-button.emu-button {
      justify-content: center;
      padding-top: 15px;
      padding-left: 25px;
      padding-bottom: 15px;
      padding-right: 25px;

      @include mq('medium') {
        width: auto;
        display: inline-block;
        padding-top: 10px;
        padding-left: 15px;
        padding-bottom: 10px;
        padding-right: 15px;
      }

      @include mq('large') {
        padding-top: 15px;
        padding-left: 30px;
        padding-bottom: 15px;
        padding-right: 30px;
      }
    }

    span {
      line-height: var(--emu-common-line-heights-wide-xs);
      letter-spacing: 0.3em;

      @include mq('medium') {
        font-size: var(--emu-common-font-sizes-narrow-small);
        line-height: 16px;
      }

      @include mq('large') {
        font-size: 15px;
        line-height: var(--emu-common-line-heights-narrow-small);
      }
    }
  }

  &--align-center {
    @include mq('medium') {
      text-align: center;
    }
  }
}
