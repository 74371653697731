header.experiencefragment {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: var(--emu-common-other-z-index-header);
}

.header {
  position: relative;
  width: 100%;

  &__wrapper {
    position: relative;
    background-color: var(--emu-common-colors-brand-blue-600);

    &.js-toggle-on {
      .header__nav-container {
        > .button {
          display: none;
        }
      }

      .header__nav-lang-wrapper {
        height: 100%;
        bottom: 0;

        > .button {
          display: flex;
          position: absolute;
          top: 21px;
          right: 30px;

          @include mq('medium') {
            top: 17px;
            right: 31px;
          }

          img {
            max-height: 19px;
          }

          .emu-button-v2.emu-button.header__menu-close-toggle {
            display: block;

            @include mq('medium-plus') {
              display: none;
            }
          }
        }
      }
    }
  }

  .header__nav-container {
    padding-top: var(--emu-common-spacing-small);
    padding-bottom: var(--emu-common-spacing-small);
    width: 100%;
    max-width: 1240px; //to match live
    margin: auto;

    @include mq('medium') {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .image {
      margin-right: var(--emu-common-spacing-medium);
      max-width: 55%;

      @include mq('medium') {
        max-width: none;
      }

      @include mq('medium-plus') {
        flex: 0 0 15%; // as per live site
      }

      // media query needed to match the live site
      @include mq('1201px') {
        flex: auto;
      }
    }

    > .button {
      display: flex;
      position: absolute;
      top: 21px;
      right: 21px;

      @include mq('small') {
        top: 25px;
      }

      @include mq('medium-plus') {
        display: none;
      }

      img {
        min-height: 38px;
      }

      .emu-button-v2.emu-button.header__menu-open-toggle {
        display: flex;
        border: none;
        background-color: var(--emu-common-colors-transparent);
        padding: var(--emu-common-spacing-none);
        margin-top: 5px;

        // breakpoint needed to match the design
        @include mq('576px') {
          margin-top: var(--emu-common-spacing-none);
        }
      }
    }
  }

  &__brand-logo {
    display: flex;

    a {
      display: block;
    }

    img {
      width: 100%;
      padding-top: 5px;
      padding-bottom: 5px;
      max-width: 220px;
      display: block;
    }
  }

  &__nav-lang-wrapper {
    width: 100%;
    height: 0;
    background-color: var(--emu-common-colors-brand-blue-500);
    z-index: var(--emu-common-other-z-index-header);
    transition: all 0.5s ease;
    position: fixed;
    bottom: 100%;
    left: 0;
    overflow: hidden;

    @include mq('medium-plus') {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      height: auto;
      position: relative;
      background-color: var(--emu-common-colors-brand-blue-600);
    }

    a {
      font-family: var(--emu-common-font-families-roboto);
    }

    .button .emu-button-v2.emu-button.header__menu-close-toggle {
      display: none;
      border: none;
      padding: var(--emu-common-spacing-none);
      background-color: var(--emu-common-colors-transparent);
      z-index: var(
        --emu-common-other-z-index-cookie-banner
      ); // using z-index greater than header's
    }

    .emu-navigation__content-wrapper {
      position: relative;
      left: 0;
      top: 0;
      width: 100%;
      max-height: 100vh;
      overflow: auto;

      @include aem-editor-view {
        max-height: none;
      }

      nav {
        position: relative;
      }
    }
  }

  .emu-navigation__menu--level-0 {
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;

    @include mq('medium-plus') {
      flex-direction: row;
    }

    li {
      margin: var(--emu-common-spacing-none);
      font-family: var(--emu-common-font-families-roboto-medium);
      font-size: 14px;
      line-height: 16px;
      font-weight: var(--emu-common-font-weight-medium);
      letter-spacing: 2px;
      width: 90%;

      @include mq('medium') {
        font-size: var(--emu-common-font-sizes-narrow-small);
      }

      @include mq('medium-plus') {
        width: auto;
      }

      @include mq('large') {
        font-size: 14px;
      }

      // media query needed to match the live site
      @include mq(1201px) {
        letter-spacing: 4px;
      }

      &:first-child {
        @include mq('medium-plus') {
          display: none;
        }
      }

      span,
      > a {
        &:focus {
          @include mq('large') {
            color: var(--emu-common-colors-brand-pink-500);
          }
        }
      }

      &.emu-navigation__item--active {
        > a,
        > span a {
          color: var(--emu-common-colors-brand-pink-500);
        }
      }

      a {
        border-bottom-width: var(--emu-common-border-width-thin);
        border-style: solid;
        border-color: var(--emu-common-colors-white);

        @include mq('medium') {
          padding-top: var(--emu-common-spacing-large);
          padding-bottom: var(--emu-common-spacing-large);
          padding-right: 10.4px;
          padding-left: 10.4px;
        }

        @include mq('medium-plus') {
          border: none;
          padding-top: var(--emu-common-spacing-small);
          padding-bottom: var(--emu-common-spacing-small);
        }

        // media query needed to match the live site
        @include mq('1201px') {
          //to match live site
          padding-top: var(--emu-common-spacing-small);
          padding-bottom: var(--emu-common-spacing-small);
          padding-right: 20.8px;
          padding-left: 20.8px;
        }

        &:hover,
        &:active {
          border-color: var(--emu-common-colors-white);
        }

        br {
          display: none;

          @include mq('medium') {
            display: block;
          }
        }

        sup {
          font-size: 50%;
          top: -1px;
          left: -1px;
          letter-spacing: normal;

          @include mq('large') {
            top: 0;
          }
        }
      }
    }
  }

  &__toggles {
    margin-top: 49.6px;
    margin-bottom: 33.6px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq('medium') {
      margin-top: 48px;
    }

    @include mq('medium-plus') {
      margin-top: var(--emu-common-spacing-none);
      margin-bottom: var(--emu-common-spacing-none);
      padding-left: 10.4px;
      padding-right: 10.4px;
    }

    // media query needed to match the live site
    @include mq(1201px) {
      padding-left: 20.8px;
      padding-right: var(--emu-common-spacing-none);
    }

    ul {
      margin: var(--emu-common-spacing-none);
      padding: var(--emu-common-spacing-none);
      display: flex;
      list-style: none;
    }

    li {
      margin: var(--emu-common-spacing-none);
      border: var(--emu-common-border-width-thin) solid
        var(--emu-common-colors-brand-pink-500);

      &:first-of-type,
      &:first-of-type .emphasis {
        border-radius: 30px 0 0 30px;
      }

      &:last-of-type,
      &:last-of-type .emphasis {
        border-radius: 0 30px 30px 0;
      }

      @include mq('medium-plus') {
        border-width: var(--emu-common-border-width-medium);
        border-color: var(--emu-common-colors-white);
      }

      .emphasis {
        background-color: var(--emu-common-colors-brand-pink-500);
        color: var(--emu-common-colors-brand-blue-500);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        @include mq('medium-plus') {
          background-color: var(--emu-common-colors-white);
        }
      }
    }

    a {
      font-size: 10.6px;
      line-height: 16px;
      font-weight: var(--emu-common-font-weight-medium);
      font-family: var(--emu-common-font-families-roboto-bold);
      letter-spacing: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 57px;
      height: 20px;
      border-radius: 30px;

      @include mq('medium-plus') {
        letter-spacing: 2px;
        width: 37px;
        height: 26px;
      }

      // media query needed to match the live site
      @include mq('1201px') {
        letter-spacing: 4px;
        width: 41px;
      }

      &,
      &:focus,
      &:active,
      &:hover {
        color: var(--emu-common-colors-brand-pink-500);
        text-decoration: none;

        @include mq('medium-plus') {
          color: var(--emu-common-colors-white);
        }
      }
    }
  }
}

// French header overrides
[lang='fr'] {
  .header .emu-navigation__menu--level-0 li a {
    // media query needed to match the live site
    @include mq('1201px') {
      padding-left: var(--emu-common-spacing-medium);
      padding-right: var(--emu-common-spacing-medium);
    }

    sup {
      top: 1px;
      left: 0;
    }
  }

  .header__toggles {
    // media query needed to match the live site
    @include mq('1201px') {
      padding-left: var(--emu-common-spacing-medium);
    }
  }
}
