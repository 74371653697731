.title-text {
  &__title {
    margin-bottom: var(--emu-common-spacing-small);
    letter-spacing: -1px;

    @include mq('medium') {
      font-size: 32px;
      line-height: 48px;
    }

    @include mq('large') {
      font-size: var(--emu-common-font-sizes-wide-xxl);
      line-height: var(--emu-common-line-heights-wide-xxl);
    }

    h1,
    h2,
    h3,
    h4 {
      @include mq('medium') {
        font-size: inherit;
        line-height: inherit;
      }
    }

    .emphasis {
      display: block;

      @include mq('medium') {
        display: revert;
      }
    }

    &.title-text__title--break-in-desktop {
      .emphasis {
        display: inline;

        @include mq('medium') {
          display: block;
        }
      }
    }

    &.title-text__title--break-always {
      .emphasis {
        display: block;
      }
    }

    &.title-text__title--no-break {
      .emphasis {
        display: inline;
      }
    }
  }

  &__txt {
    padding-top: var(--emu-common-spacing-medium);
    padding-bottom: var(--emu-common-spacing-medium);
    margin-bottom: var(--emu-common-spacing-medium);
    letter-spacing: 0.07em;
    max-width: 95%;
    color: var(--emu-common-colors-dark-grey-400);

    @include mq('medium') {
      font-size: 17px;
    }

    @include mq('large') {
      font-size: var(--emu-common-font-sizes-wide-small);
    }

    p {
      margin-bottom: var(--emu-common-spacing-none);

      & + p {
        margin-top: var(--emu-common-spacing-medium);
      }
    }
  }

  &__cta {
    // increasing specificity
    &.emu-button-v2.emu-button-v2.emu-button-v2 {
      display: block;
      width: 90%;
      margin-right: auto;
      margin-bottom: 15px;
      margin-left: auto;
      text-align: center;
      padding-top: 15px;
      padding-right: 25px;
      padding-bottom: 15px;
      padding-left: 25px;
      font-size: 14px;
      letter-spacing: 0.3em;
      transition: all 0.2s;

      @include mq('medium') {
        padding-top: 10px;
        padding-right: 15px;
        padding-bottom: 10px;
        padding-left: 15px;
        font-size: var(--emu-common-font-sizes-wide-xs);
        line-height: 14px;
        margin-top: 25px;
        margin-bottom: var(--emu-common-spacing-none);
        width: auto;
        display: inline-flex;
      }

      @include mq('large') {
        padding-top: 15px;
        padding-right: 30px;
        padding-bottom: 15px;
        padding-left: 30px;
        font-size: 14px;
        line-height: 16px;
      }

      @include mq('x-large') {
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: 35px;
      }
    }
  }

  &__disclaimer {
    max-width: 95%;
    color: var(--emu-common-colors-black);
    font-size: 11px;
    padding-top: var(--emu-common-spacing-large);
    letter-spacing: 0.07em;

    @include mq('large') {
      font-size: var(--emu-common-font-sizes-wide-xs);
    }

    @include mq('x-large') {
      padding-top: 48px;
    }
  }

  &__person-label {
    color: var(--emu-common-colors-brand-blue-200);
    letter-spacing: 0.15em;
    font-size: 22px;
    line-height: 1.1;
    font-family: var(--emu-common-font-families-graphik);

    @include mq('medium') {
      font-size: 16px;
      letter-spacing: normal;
    }

    @include mq('large') {
      letter-spacing: 0.15em;
    }

    p {
      margin-bottom: var(--emu-common-spacing-none);
    }
  }

  &__img {
    img {
      width: 80%; // vlaue from live site
      margin-left: auto;
      margin-right: auto;
      display: block;

      @include mq('medium') {
        width: 40%; // vlaue from live site
        margin: var(--emu-common-spacing-none);
      }
    }

    &-caption {
      padding-top: 2%; // vlaue from live site
      font-size: 13px;
      letter-spacing: 0.4em;
      line-height: 1.3;
      margin-top: var(--emu-common-spacing-xxs);
      text-align: center;
      font-family: var(--emu-common-font-families-graphik);

      @include mq('medium') {
        font-size: 12px;
        text-align: left;
        line-height: 17px;
      }

      p {
        margin-bottom: var(--emu-common-spacing-none);
      }
    }
  }
}
