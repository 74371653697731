#breast-augmentation {
  // content below the main hero with blue background
  .img-with-text {
    &__top-wave {
      margin-top: -25px; // value from live site
      position: relative;
      z-index: var(--emu-common-other-z-index-layer);

      @include mq('medium') {
        margin-top: -100px; // value from live site
      }

      @include mq('1280px') {
        margin-bottom: -6px; // value from live site
      }

      @include mq('mega') {
        margin-top: -150px; // value from live site
      }
    }

    &__content {
      &-container {
        position: relative;
        padding-top: 45px;
        margin-top: -5px; // value from live site
        min-height: 720px;
        background-color: var(--emu-common-colors-brand-blue-600);

        @include mq('medium') {
          margin-top: -8px;
          padding-bottom: 7%;
          padding-top: 3%;
          background-color: var(--emu-common-colors-brand-blue-500);
          min-height: initial;
        }

        @include mq('large') {
          margin-top: var(--emu-common-spacing-none);
        }
      }

      &-container-inner {
        @include mq('medium') {
          display: flex;
        }

        > .container {
          &:first-child {
            @include mq('medium') {
              flex: 66.667%;
            }
          }

          &:last-child {
            @include mq('medium') {
              flex: 33.333%;
            }
          }
        }
      }
    }

    &__text-container {
      padding-left: 15px;
      padding-right: 15px;

      @include mq('large') {
        padding-right: var(--emu-common-spacing-none);
      }
    }

    &__title {
      max-width: 89%; // value from live site
      margin-bottom: var(--emu-common-spacing-small);
      letter-spacing: -0.4px;

      @include mq('medium') {
        max-width: 100%;
        font-size: 32px;
        line-height: var(--emu-common-font-sizes-wide-xxl);
        letter-spacing: -0.2px;
      }

      @include mq('large') {
        font-size: var(--emu-common-font-sizes-wide-xxl);
        line-height: 57px;
      }

      @include mq('x-large') {
        letter-spacing: -0.4px;
      }

      h1,
      h2,
      h3 {
        letter-spacing: inherit;

        @include mq('medium') {
          font-size: inherit;
          line-height: inherit;
        }
      }

      .emphasis {
        display: block;
        max-width: 89%; // value from live site

        @include mq('medium') {
          display: inline;
        }
      }

      sup {
        font-size: 45%;
        font-style: normal;
        top: -2px;
        display: inline-block;
        margin-right: 1px;
        font-family: var(--emu-common-font-families-roboto);

        @include mq('x-large') {
          top: -3px;
          left: -2px;
        }
      }
    }

    &__text {
      padding-top: 6%; // value from live site
      padding-bottom: 6%; // value from live site
      max-width: 97%; // value from live site
      margin-bottom: var(--emu-common-spacing-medium);
      letter-spacing: 0.03em;

      @include mq('medium') {
        padding-top: 2%;
        padding-bottom: 3%;
        max-width: 100%;
      }

      @include mq('large') {
        letter-spacing: normal;
      }

      p {
        margin-bottom: var(--emu-common-spacing-none);
      }

      sup {
        font-size: 45%;
        top: -1px;
        left: -1px;
        display: inline-block;
        margin-right: 1px;
      }

      br {
        display: none;

        @include mq('medium') {
          display: block;
        }
      }
    }

    &__cta {
      padding-top: 12px;
      padding-right: 11px;
      padding-bottom: 12px;
      padding-left: 12px;
      background-color: var(--emu-common-colors-brand-blue-500);
      letter-spacing: 0.3em;
      font-size: 13px;
      line-height: 17px;
      border-width: var(--emu-common-border-width-medium);
      transition: all 0.2s linear;

      @include mq('medium') {
        font-size: 15px;
        line-height: 17px;
        padding-top: 10px;
        padding-right: 14px;
        padding-bottom: 10px;
        padding-left: 15px;
      }

      @include mq('large') {
        padding-top: 15px;
        padding-right: 29px;
        padding-bottom: 15px;
        padding-left: 30px;
      }

      &:hover,
      &:focus {
        background-color: var(--emu-common-colors-brand-pink-500);
        color: var(--emu-common-colors-brand-blue-500);
      }

      sup {
        font-size: 45%;
        top: -1px;
      }
    }

    &__img-container {
      max-width: 90%;
      width: 100%;
      padding-top: 30px;
      padding-bottom: 10px;
      overflow: hidden;
      margin-left: auto;

      @include mq('medium') {
        position: absolute;
        right: 15px;
        top: 0;
        max-width: 420px;
        width: 100%;
        padding-top: 12px;
        height: 100%;
        padding-bottom: var(--emu-common-spacing-none);
      }

      // breakpoint needed to match the live site
      @include mq('1024px') {
        max-width: 360px;
      }

      @include mq('x-large') {
        max-width: 420px;
      }

      @include mq('xx-large') {
        position: static;
      }

      img {
        position: relative;
        right: -60px;
        width: 100%;

        @include mq('medium') {
          width: 60%;
          right: 0;
          position: absolute;
        }

        @include mq('large') {
          width: 100%;
        }

        @include mq('xx-large') {
          position: static;
        }
      }
    }
  }

  // breast augmentation description
  .aug-desc {
    > .container {
      padding-top: 55px;
      padding-bottom: 40px;
      padding-left: 15px;
      padding-right: 15px;

      @include mq('medium') {
        padding-top: 48px;
        padding-bottom: var(--emu-common-spacing-none);
      }
    }

    &__inner {
      @include mq('medium-plus') {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .title-text {
      &.aug-desc__intro {
        .title-text__txt {
          @include mq('medium') {
            padding-bottom: var(--emu-common-spacing-none);
          }
        }
      }

      &__cta {
        color: var(--emu-common-colors-dark-grey-400);
        border-color: var(--emu-common-colors-dark-grey-500);

        &:hover,
        &:focus {
          color: var(--emu-common-colors-white);
          background-color: var(--emu-common-colors-dark-grey-400);
          border-color: var(--emu-common-colors-dark-grey-400);
        }
      }
    }
  }

  // invest section "Invest in yourself with confidence"
  .aug-invest {
    margin-top: -3px;

    @include mq('medium') {
      margin-top: 15%; // value from live site
    }

    @include mq('large') {
      margin-top: 10%; // value from live site
    }

    // breakpoint needed to match the live site
    @include mq('1280px') {
      margin-top: var(--emu-common-spacing-none);
    }

    &__desktop-top-wave {
      @include mq('large') {
        margin-top: -44px; // value from live site
      }

      // breakpoint needed to match the live site
      @include mq('1280px') {
        margin-bottom: -6px; // value from live site
      }
    }

    &__container {
      padding-bottom: 15%; // value from live site
      padding-left: 15px;
      padding-right: 15px;

      @include mq('medium') {
        padding-bottom: 12%; // value from live site
      }

      @include mq('medium-plus') {
        padding-left: 4%; // value from live site
        padding-right: var(--emu-common-spacing-none);
      }

      @include mq('large') {
        padding-left: var(--emu-common-spacing-none);
        padding-bottom: 5%; // value from live site
      }

      &-inner {
        padding-top: 40px;
        padding-bottom: 55px;

        @include mq('medium') {
          padding-top: 6%; // value from live site
          padding-bottom: var(--emu-common-spacing-none);
        }

        @include mq('medium-plus') {
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }
      }
    }

    .title-text {
      &__title {
        letter-spacing: -0.4px;
      }

      &__txt {
        max-width: 93%; // value from live site
        padding-top: 5%; // value from live site

        @include mq('medium') {
          padding-top: 1%; // value from live site
          max-width: 100%;
        }
      }

      &__cta {
        width: 95%; // value from live site
        margin-top: 31px;
        margin-bottom: var(--emu-common-spacing-none);

        &:focus {
          color: var(--emu-common-colors-brand-blue-500);
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }

        &:active {
          color: var(--emu-common-colors-brand-blue-500);
        }

        @include mq('medium') {
          width: auto;
          margin-top: var(--emu-common-spacing-none);
        }
      }
    }

    &__text {
      &--top {
        @include mq('medium') {
          margin-bottom: -2px; // needed to align more closer to the live site
        }

        @include mq('medium-plus') {
          margin-bottom: -1px; // needed to align more closer to the live site
        }

        .title-text {
          &__title {
            max-width: 85%; // value from live site

            @include mq('medium') {
              max-width: 100%;
            }
          }

          &__txt {
            padding-bottom: var(--emu-common-spacing-none);
          }
        }
      }

      &--bottom {
        padding-top: 5%; // value from live site

        .title-text {
          &__txt {
            padding-bottom: 2.5%; // value from live site
          }
        }
      }
    }
  }

  // presurgery section that says "Pre-surgery planning"
  .aug-presurgery {
    background-color: var(--emu-common-colors-white);
    background-position: 0 0;
    position: relative;
    z-index: var(--emu-common-other-z-index-layer);

    @include mq('767px', 'max-width') {
      background-image: none !important;
    }

    @include mq('medium') {
      margin-top: -65px; // value from live site
      margin-bottom: -1px;
    }

    @include mq('1024px', 'max-width') {
      background-image: none !important;
    }

    @include mq('large') {
      margin-top: var(--emu-common-spacing-none);
    }

    &__mobile-top-wave {
      margin-top: -30px; // value from live site
      margin-bottom: -7px; // value from live site
    }

    &__mobile-bottom-wave {
      margin-top: -6px; // value from live site
      margin-bottom: -7px; // value from live site
      position: relative;
      z-index: var(--emu-common-other-z-index-base);
    }

    &__desktop-top-wave,
    &__desktop-bottom-wave {
      // breakpoint needed to match the live site
      @include mq('1280px') {
        margin-top: -6px; // value from live site
        margin-bottom: -6px; // value from live site
      }
    }

    &__container {
      padding-top: 45px;
      padding-bottom: 45px;
      padding-left: 15px;
      padding-right: 15px;

      @include mq('medium-plus') {
        padding-left: var(--emu-semantic-spacing-none);
        padding-right: var(--emu-semantic-spacing-none);
      }

      @include mq('medium') {
        padding-top: var(--emu-common-spacing-none);
        padding-bottom: 4%; // value from live site
      }

      @include mq('large') {
        padding-bottom: var(--emu-common-spacing-none);
      }
    }

    .title-text {
      &__title {
        letter-spacing: -0.5px;
      }

      &__txt {
        padding-top: 3%; // value from live site
        padding-bottom: var(--emu-common-spacing-none);
        max-width: 100%;

        @include mq('medium') {
          padding-top: 1%; // value from live site
        }
      }
    }

    &__desc {
      &--bottom {
        padding-top: 5%; // value from live site

        @include mq('medium') {
          padding-top: 3%; // value from live site
        }

        .title-text {
          &__txt {
            padding-bottom: 5%; // value from live site

            @include mq('medium') {
              padding-bottom: 3%; // value from live site
            }
          }
        }
      }
    }

    &__img {
      &-container {
        padding-top: 5%; // value from live site
        padding-bottom: 5%; // value from live site

        @include mq('medium') {
          padding-top: var(--emu-common-spacing-none);
          padding-right: 15px;
          padding-bottom: var(--emu-common-spacing-none);
          padding-left: 15px;
        }

        img {
          position: relative;
          width: 100%;
          left: -8px; // value from live site

          @include mq('medium') {
            left: -30px; // value from live site
          }

          @include mq('large') {
            left: -65px; // value from live site
          }

          @include mq('xx-large') {
            position: unset;
          }
        }
      }
    }
  }

  // implants section that says "Breast Implant placement options"
  .aug-implant-options {
    &__container {
      padding-top: 65px;
      padding-bottom: 65px;

      // breakpoint needed to match the live site
      @include mq('576px') {
        padding-left: 15px;
        padding-right: 15px;
      }

      @include mq('medium') {
        padding-top: 5%; // value from live site
        padding-bottom: var(--emu-common-spacing-none);
      }

      @include mq('medium-plus') {
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
      }

      &-inner {
        padding-left: 15px;
        padding-right: 15px;

        @include mq('medium') {
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }
      }
    }

    .title-text {
      &__title {
        letter-spacing: -0.5px;
      }
    }

    &__text {
      &--top {
        .title-text {
          &__txt {
            padding-top: 6%; // value from live site
            padding-bottom: 10%;
            max-width: 100%;

            @include mq('medium') {
              padding-top: 1%; // value from live site
              padding-bottom: 7%; // value from live site
            }
          }
        }
      }

      &--bottom {
        .title-text {
          &__title {
            padding-top: 25%; // value from live site

            @include mq('medium') {
              padding-top: 5%; // value from live site
            }
          }

          &__txt {
            padding-top: 6%; // value from live site
            padding-bottom: var(--emu-common-spacing-none);
            max-width: 99%;

            @include mq('medium') {
              padding-top: 1%; // value from live site
              max-width: 100%;
              margin-bottom: var(--emu-common-spacing-none);
              padding-bottom: var(--emu-common-spacing-medium);
            }
          }
        }
      }
    }

    .carousel {
      margin-bottom: var(--emu-common-spacing-none);
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
    }

    &__carousel {
      border-width: var(--emu-common-border-width-none);

      &-container {
        padding-left: 45px;
        padding-right: 45px;
      }

      &-item {
        min-height: 390px; // value from live site
        padding-top: 15px;

        @include mq('medium') {
          min-height: var(--emu-common-sizing-none);
          padding-top: var(--emu-common-spacing-none);
        }
      }

      img {
        width: 230px; // value from live site
        margin-left: auto;
        margin-right: auto;
        display: block;

        @include mq('medium') {
          width: auto;
          max-width: 100%;
        }

        @include mq('x-large') {
          margin: var(--emu-common-spacing-none);
        }
      }

      .cmp-text {
        padding-top: 8%; // value from live site
        font-size: 22px;
        line-height: 1.1;

        @include mq('medium') {
          padding-right: 40px;
        }

        p {
          @include mq('medium') {
            margin-bottom: var(--emu-common-spacing-none);
          }
        }
      }

      .tns-nav {
        position: absolute;
        bottom: -25px; // value from live site
        top: auto;
        width: 100%;
        gap: var(--emu-common-spacing-none);
        padding: var(--emu-common-spacing-none);

        > button {
          margin-right: var(
            --emu-component-containers-carousel-indicators-dots-gap-narrow
          );
          margin-bottom: 5px;
        }
      }

      .emu-carousel {
        &__content {
          @include mq('medium') {
            display: flex;
          }
        }

        &__item {
          @include mq('medium') {
            padding-left: 15px;
            padding-right: 15px;
            width: 33.33%;
          }
        }
      }
    }

    &__bottom-wave {
      margin-top: -8px;
      background-color: var(--emu-common-colors-white);
      position: relative;
      z-index: var(--emu-common-other-z-index-behind);
    }
  }

  // journey section that says "Start your breast augmentation journey today"
  .aug-journey {
    &__wave-top-desktop {
      // breakpoint needed to match the live site
      @include mq('1280px') {
        margin-top: -6px; // value from live site
        margin-bottom: -3px; // value from live site
      }
    }

    &__wave-mobile-bottom {
      margin-bottom: -6px;
    }

    &__container {
      padding-top: 45px;
      padding-bottom: 45px;

      @include mq('medium') {
        padding-top: var(--emu-common-spacing-none);
        padding-bottom: var(--emu-common-spacing-none);
      }

      &-inner {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .title-text {
      &__title {
        padding-top: var(--emu-common-spacing-none);

        // breakpoint needed to match the live site
        @include mq('576px') {
          padding-top: 5%; // value from live site
        }

        @include mq('medium-plus') {
          padding-top: 19%; // value from live site
        }

        @include mq('large') {
          font-size: var(--emu-common-font-sizes-wide-xl);
        }

        .emphasis {
          @include mq('large') {
            font-size: var(--emu-common-font-sizes-wide-xxl);
            line-height: var(--emu-common-line-heights-wide-xxl);
            padding-bottom: var(--emu-common-spacing-xs);
          }
        }
      }

      &__txt {
        letter-spacing: 0.03em; // value from live site
        padding-top: 3%; // value from live site
        max-width: 100%;

        @include mq('medium') {
          width: 80%;
          letter-spacing: 0.07em; // value from live site
          font-size: 28px;
        }

        @include mq('large') {
          line-height: 1.1; // value from live site
        }
      }
    }

    .fancy-img-with-text {
      @include mq('medium') {
        min-height: 530px; // value from live site
      }

      @include mq('x-large') {
        min-height: 720px; // value from live site
      }

      &__img {
        &-disclaimer {
          font-size: 12px;
          letter-spacing: 0.03em; // value from live site
          padding-top: 15px;

          @include mq('large') {
            line-height: 1.1;
            letter-spacing: normal;
          }
        }

        img {
          margin-top: 35px;
          margin-bottom: 5px;
          padding: var(--emu-common-spacing-none);
          top: 0;

          @include mq('medium') {
            margin: var(--emu-common-spacing-none);
          }
        }
      }
    }
  }

  // 3d visualizer section
  .aug-3d {
    &__top-wave {
      padding-top: 18%; // value from live site

      @include mq('medium') {
        padding-top: 4%; // value from live site
      }
    }

    &__container {
      margin-top: -30px;
      padding-top: 25px;
      padding-bottom: 25px;

      @include mq('medium') {
        margin-top: -62px; // value from live site
        padding-bottom: 8%; // value from live site
        padding-top: var(--emu-common-spacing-none);
      }

      // breakpoint needed to match the live site
      @include mq('1300px') {
        margin-top: -5%; // value from live site
      }

      &-inner {
        padding-left: 15px;
        padding-right: 15px;

        @include mq('medium') {
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }
      }
    }

    .fancy-img-with-text {
      &__img {
        img {
          padding: var(--emu-common-spacing-none);
          border-radius: 5%; // value from live site
          overflow: hidden;
          width: 100%;
          position: static;
        }
      }

      > .container {
        @include mq('medium') {
          min-width: 50%;
          max-width: none;
          padding-left: 15px;
          padding-right: 15px;
        }
      }

      .button {
        @include mq('medium') {
          line-height: 14px;
        }

        @include mq('large') {
          line-height: 16px;
        }
      }
    }

    .title-text {
      &__title {
        text-align: center;
        padding-top: 8%; // value from live site
        padding-bottom: 5%; // value from live site
        letter-spacing: -0.4px;

        @include mq('medium') {
          text-align: left;
          letter-spacing: -0.3px;
        }

        i {
          font-style: normal;
        }

        sup {
          font-size: 45%;
          text-shadow: 1px 1px 0px currentColor;
          left: -2px;
          top: -3px;
        }

        .emphasis {
          @include mq('x-large') {
            display: block;
          }

          br {
            &:last-of-type {
              display: none;

              @include mq('medium') {
                display: block;
              }
            }
          }
        }
      }

      &__cta {
        width: 85%; // value from live site
        margin-top: 55px;
        margin-right: auto;
        margin-bottom: 35px;
        margin-left: auto;

        @include mq('medium') {
          margin: var(--emu-common-spacing-none);
          width: fit-content;
          display: inline;
          line-height: inherit;
        }

        &.mobile-view {
          @include mq('medium') {
            display: none;
          }
        }

        &.tablet-desktop-view {
          @include mq('767px', 'max-width') {
            display: none;
          }
        }
      }
    }
  }

  .hero__img img {
    @include mq('large') {
      object-fit: unset;
    }
  }
}

// author mode styles
.aug-3d .title-text__cta {
  @include aem-editor-view {
    display: block !important;
  }
}

[lang='fr_ca'] {
  #breast-augmentation {
    .aug-desc .title-text.aug-desc__intro {
      .title-text__txt {
        max-width: 94%; // as per live

        @include mq('medium') {
          max-width: 95%; // as per live
        }
      }
    }

    .fancy-img-with-text {
      &__img-disclaimer {
        line-height: var(--emu-common-line-heights-narrow-small);
      }

      .title-text__txt {
        @include mq('medium') {
          max-width: 100%;
        }
      }

      .title-text__disclaimer {
        @include mq('medium') {
          padding-top: 24px;
          line-height: 20px;
          max-width: 100%;
        }

        @include mq('large') {
          line-height: var(--emu-common-line-heights-wide-body);
          padding-top: 48px;
        }
      }
    }

    .hero {
      &__content-inner {
        max-width: 95%; //as per live

        @include mq('large') {
          width: 55%;
        }
      }

      &__title {
        letter-spacing: -0.5px;

        h1 {
          @include mq('medium') {
            font-size: 45px;
            line-height: 40px;
            letter-spacing: -0.7px;
          }

          @include mq('large') {
            font-size: var(--emu-common-font-sizes-wide-xxxl);
            line-height: var(--emu-common-line-heights-wide-xxxl);
          }

          i {
            @include mq('large') {
              letter-spacing: 0.2px;
            }
          }
        }
      }

      &__para.cmp-text {
        @include mq('medium') {
          line-height: 20px;
          font-size: 21px;
          max-width: 97%; //as per live
        }

        @include mq('large') {
          font-size: var(--emu-common-font-sizes-wide-large);
          line-height: 30px;
        }
      }

      &__person-label.hero__para.cmp-text {
        @include mq('medium') {
          padding-top: 10px;
          line-height: 20px;
        }

        @include mq('large') {
          padding-top: 20px;
          line-height: 30px;
        }
      }

      &__disclaimer.hero__para.cmp-text {
        font-size: 11px;

        @include mq('medium') {
          line-height: 20px;
        }

        @include mq('large') {
          line-height: 14.5px;
        }
      }
    }

    .img-with-text {
      &__content-container {
        min-height: 680px; //as per live

        @include mq('medium') {
          min-height: initial;
        }
      }

      &__title {
        max-width: 100%;
        margin-bottom: var(--emu-semantic-spacing-none);

        @include mq('medium') {
          margin-bottom: var(--emu-common-spacing-small);
        }

        h2 {
          i {
            letter-spacing: -0.6px;

            sup {
              display: inline;
              font-size: 40%;
            }
          }
        }
      }

      &__text {
        max-width: 100%;

        sup {
          font-size: 55%;
          top: 2px;
        }

        &-container {
          @include mq('large') {
            padding-right: 15px;
          }
        }
      }

      &__img-container {
        @include mq('x-large') {
          max-width: 360px; //as per live
        }

        @include mq('1201px') {
          max-width: 420px; //as per live
          position: absolute;
          right: 15px;
        }

        @include mq('xxx-large') {
          position: unset;
        }
      }

      &__cta {
        text-align: center;

        sup {
          display: inline;
          font-size: 55%;
        }
      }
    }

    .before-after {
      &__intro .title-text {
        &__title {
          @include mq('large') {
            max-width: 100%;
          }
        }

        &__txt {
          @include mq('medium') {
            max-width: 75%; // as per live
          }
        }
      }

      &__disclaimer {
        @include mq('medium') {
          margin-bottom: var(--emu-common-sizing-small);
        }

        @include mq('large') {
          margin-bottom: var(--emu-common-sizing-xs);
        }
      }
    }

    .aug-journey {
      &__wave-bottom-desktop {
        @include mq('xxx-large') {
          margin-top: -2px;
          margin-bottom: -6px;
        }
      }

      .title-text {
        &__title h2 i {
          @include mq('large') {
            font-size: var(--emu-common-font-sizes-wide-xxl);
          }
        }
      }

      .fancy-img-with-text {
        @include mq('medium') {
          max-height: 100%;
        }

        &__img-disclaimer {
          @include mq('medium') {
            line-height: var(--emu-common-line-heights-narrow-body);
            max-width: 100%;
          }

          @include mq('large') {
            line-height: 13.2px;
          }
        }
      }
    }

    .aug-3d {
      .fancy-img-with-text {
        @include mq('large') {
          max-height: fit-content;
        }
      }

      .title-text__title {
        letter-spacing: 0;

        @include mq('large') {
          letter-spacing: -0.5px;
        }

        h2 {
          u {
            text-decoration: none;

            @include mq('medium') {
              letter-spacing: -0.5px;
            }
          }

          sup {
            font-family: var(--emu-common-font-families-graphik);
            font-size: 55%;
            text-shadow: none;
            top: 2px;
            left: -0.5px;
          }
        }
      }
    }
  }
}
