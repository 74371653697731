.carousel-text {
  padding: 3% 2% 0.5%;

  @include mq('small') {
    padding: 35px;
  }

  @include mq('medium') {
    padding-left: 39px;
    padding-right: 39px;
  }

  @include mq('medium-plus') {
    padding: 3% 2.5% 0.5%;
  }

  &__slide-content {
    background-color: var(--emu-common-colors-white);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;

    @include mq('medium') {
      flex-direction: row;
    }

    &-outer {
      width: 100%;
      display: flex;
      flex-direction: row;

      @include mq('small') {
        flex-direction: column;
      }

      @include mq('medium') {
        flex-direction: row;
      }
    }

    &-inner {
      position: relative;

      &-image {
        padding-top: 15px;
        padding-bottom: 15px;

        @include mq('medium') {
          padding-top: var(--emu-common-spacing-none);
          padding-bottom: var(--emu-common-spacing-none);
        }

        img {
          @include mq('small') {
            width: 51.3%;
          }

          @include mq('medium') {
            width: 100%;
          }
        }
      }

      &-description {
        position: absolute;

        &.cmp-text {
          font-size: 15px;
          letter-spacing: 1.05px;
          font-weight: var(--emu-common-font-weight-bold);

          @include mq('medium') {
            line-height: var(--emu-semantic-line-heights-narrow-body);
          }

          @include mq('large') {
            font-size: 16px;
            line-height: 17.6px;
            letter-spacing: 1.12px;
          }

          > p {
            margin-bottom: var(--emu-semantic-spacing-none);
          }
        }
      }
    }

    &--tagline {
      letter-spacing: 0.7px;
      padding-top: 5px;
      padding-bottom: 5px;

      @include mq('medium') {
        padding-top: 17px;
        padding-bottom: var(--emu-common-spacing-none);
      }

      @include mq('x-large') {
        max-width: var(--emu-common-sizing-xxxl); //as per live
      }

      &.cmp-text {
        font-size: 16px;
        line-height: 22px;
      }

      > p {
        margin: var(--emu-common-spacing-none);

        sup {
          font-size: 50%;
          left: -1.6px;
        }
      }
    }
  }

  &__disclaimer {
    font-size: var(--emu-semantic-font-sizes-narrow-small);
    letter-spacing: 0.45px;
    padding-left: 4%;
    padding-top: var(--emu-common-spacing-none);
    margin-bottom: 5px;

    @include mq('medium') {
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: var(--emu-common-spacing-medium);
    }

    @include mq('medium-plus') {
      padding-left: 2%;
      line-height: 13.2px;
      max-width: 960px; //as per live
      padding-left: 20px;
      padding-right: 20px;
      margin-left: auto;
      margin-right: auto;
    }

    @include mq('x-large') {
      max-width: var(--emu-common-sizing-xxxl); //as per live
    }

    > p {
      margin-bottom: 5px;

      @include mq('medium') {
        margin-bottom: var(--emu-common-spacing-medium);
      }

      @include mq('large') {
        line-height: 13.2px;
      }

      > sup {
        font-size: 5px;
      }
    }
  }
}
