#site-map {
  .site-map {
    &__title {
      padding-left: 15px; // as per live site
      padding-right: 15px;
      width: 100%;
      margin-right: auto;
      margin-left: auto;

      h1 {
        padding-top: 5%; // as per live site
        padding-bottom: 15%;
        font-size: 60px;
        line-height: 72px;
        letter-spacing: -1.3px;

        @include mq('medium') {
          padding-top: 75px;
          padding-bottom: 30px;
        }
      }
    }

    &__wrapper {
      > .container:not(:last-child) {
        max-width: 100%;
        margin: auto;
        padding-bottom: 6.5%;

        @include mq('medium') {
          max-width: 85%; // as per live site
        }

        @include mq('large') {
          max-width: 810px; // as per live site
        }

        @include mq('x-large') {
          margin-bottom: var(--emu-common-spacing-xs);
        }
      }
    }

    &__list-wrapper {
      display: flex;
      flex-wrap: wrap;

      > .text {
        width: 100%;

        @include mq('small') {
          flex: 0 0 50%; // as per live site
          max-width: 50%;
        }

        @include mq('medium') {
          display: flex;
          justify-content: center;
        }
      }

      .site-map__list {
        @include mq('medium') {
          margin-bottom: 13px;
        }

        // using ">" because of too many nested lists and to target only immediate children.
        > ul {
          margin: var(--emu-common-spacing-none);
          padding: var(--emu-common-spacing-none);
          font-family: var(--emu-common-font-families-roboto);

          > li {
            list-style-type: none;
            margin: var(--emu-common-spacing-none);

            > b {
              a {
                display: inline-block;
                color: var(--emu-common-colors-dark-grey-400);
                font-size: var(--emu-common-font-sizes-narrow-body);
                line-height: 24px;
                letter-spacing: 0.54px;
                margin-top: 10px;
                margin-bottom: 12px;

                @include mq('medium') {
                  padding-top: 5%;
                  margin-bottom: var(--emu-common-spacing-medium);
                }

                @include mq('large') {
                  padding-top: var(--emu-common-spacing-none);
                }
              }
            }

            > ul {
              margin-bottom: 35.5px; // needed to match the design more closely
              padding-left: 8%;
              margin-top: -6px; // as per live site

              @include mq('medium') {
                padding-left: 24px;
                margin-top: var(--emu-common-spacing-none);
              }

              > li {
                list-style-type: disc; // as per live site
                font-size: 16px;
                line-height: 17.6px;
                margin-bottom: 7.5px; // needed to match the design
                font-family: var(--emu-common-font-families-roboto);

                @include mq('x-large') {
                  margin-bottom: 7.25px; // needed to match the design
                }

                b {
                  display: inline-block;
                }

                a {
                  font-size: 15px;
                  line-height: 18.5px; // to match live site
                  letter-spacing: 0.45px;
                  color: var(--emu-common-colors-brand-blue-500);

                  @include mq('medium') {
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }

        a {
          text-decoration: none;

          &:hover,
          &:active,
          &:focus {
            text-decoration: underline;
          }

          // to  match live site
          sup {
            font-family: var(--emu-common-font-families-superscript-regular);
            top: -1px;
            left: 1px;
            font-size: 50%;
          }
        }
      }
    }

    &__squoosh {
      margin-bottom: -6px; // as per live site
      margin-top: var(--emu-common-spacing-xxs);

      @include mq('x-large') {
        margin-top: var(--emu-common-spacing-xs);
      }

      img {
        width: 100%;
      }
    }
  }

  // to change active home link color from pink to white
  .header {
    .emu-navigation__menu--level-0 {
      li {
        &.emu-navigation__item--active {
          > a,
          > span a {
            color: var(--emu-common-colors-white);
          }
        }
      }
    }
  }
}
