#home {
  // main teaser
  .home-teaser {
    @include mq('medium') {
      background: linear-gradient(
        180deg,
        var(--emu-common-colors-white) 0%,
        var(--emu-common-colors-white) 50%,
        var(--emu-common-colors-brand-blue-200) 100%
      );
    }

    @include mq('x-large') {
      background: none;
    }

    .content-container {
      padding-bottom: var(--emu-common-spacing-none);

      @include mq('medium') {
        padding-top: 70px;
        left: unset;
      }

      @include mq('large') {
        padding-right: var(--emu-common-spacing-none);
        padding-top: 70px;
      }
    }

    .content {
      text-align: left;

      @include mq('medium') {
        text-align: right;
      }
    }

    .title-lockup {
      color: var(--emu-common-colors-brand-blue-500);
    }

    .emu-teaser {
      max-width: var(--emu-semantic-sizing-breakpoints-xxx-large);
      margin-right: auto;
      margin-left: auto;

      &__title {
        letter-spacing: -0.5px;

        @include mq('medium') {
          margin-left: auto;
          letter-spacing: -0.3px;
        }

        @include mq('large') {
          margin-left: auto;
          letter-spacing: -0.5px;
        }

        b {
          letter-spacing: -1.3px;

          @include mq('medium') {
            letter-spacing: -0.49px;
          }

          @include mq('large') {
            letter-spacing: -0.8px;
          }

          @include mq('x-large') {
            letter-spacing: -0.9px;
          }
        }

        sup {
          font-size: 45%;
          top: -5px;
          left: -3px;

          @include mq('xxx-large') {
            top: -5px;
          }
        }
      }

      &__description {
        padding-top: 15px;

        @include mq('medium') {
          padding-top: 13px;
        }

        @include mq('medium-plus') {
          max-width: 40%;
        }

        @include mq('1024px') {
          max-width: 42%;
        }

        @include mq('xxx-large') {
          max-width: 43%;
        }

        p {
          letter-spacing: 0.9px;

          @include mq('medium') {
            letter-spacing: 0.48px;
          }

          @include mq('large') {
            letter-spacing: -0.01px;
          }

          sup {
            top: -2px;
            left: -1px;

            @include mq('large') {
              top: -1px;
            }
          }

          b {
            font-weight: inherit;

            @include mq('medium') {
              font-size: var(--emu-common-font-sizes-wide-large);
              line-height: 30px;
              font-weight: var(--emu-common-font-weight-regular);
              white-space: nowrap !important;
              letter-spacing: 0.48px;
            }

            @include mq('large') {
              font-weight: inherit;
              font-size: inherit;
              line-height: inherit;
              letter-spacing: normal;
            }

            @include mq('xxx-large') {
              letter-spacing: -0.01px;
            }
          }
        }
      }
    }

    .teaser-image-container {
      margin-top: -4px;
      padding-top: var(--emu-common-spacing-none);

      @include mq('medium') {
        padding-top: 80px;
        margin-top: var(--emu-common-spacing-none);
      }

      @include mq('x-large') {
        padding-top: var(--emu-common-spacing-none);
      }
    }

    .cmp-teaser__image--desktop {
      @include mq('x-large') {
        display: none;
      }
    }
  }

  // banner for screens above 1200px
  .home-banner {
    @include mq('x-large') {
      min-height: 825px;
      width: 100%;
      z-index: var(--emu-common-other-z-index-behind);
      position: relative;
    }

    @include mq('1300px') {
      background-position-y: 0;
    }

    @include mq('jumbo') {
      min-height: 1200px;
    }
  }

  .biocell {
    position: relative;
    height: 137px; //as per live
    top: -20px; // as per live site

    @include mq('medium') {
      margin-top: -15%;
      height: 155px; //as per live
      top: 0;
    }

    @include mq('large') {
      height: 200px; //as per live
    }

    @include mq('2400px') {
      height: 450px; //as per live
      margin-top: -10%; //as per live
    }

    &__image {
      padding-bottom: 20px;
      margin-bottom: -2px;
      background: linear-gradient(
        180deg,
        var(--emu-semantic-colors-none) 0%,
        var(--emu-semantic-colors-none) 35%,
        var(--emu-semantic-colors-brand-primary-600) 35.1%
      );

      > img {
        width: 100%;
        height: auto;
      }

      &-container {
        background-color: var(--emu-common-colors-transparent);
      }
    }

    &-strip {
      background-color: var(--emu-semantic-colors-brand-grey-400);
      position: absolute;
      overflow: hidden;
      width: 100%;

      @include mq('medium') {
        top: 38%;
      }

      @include mq('large') {
        top: 65%;
      }

      &__container {
        padding-bottom: 3px;
        padding-top: 6px;

        @include mq('medium') {
          max-width: var(--emu-semantic-sizing-breakpoints-xxx-large);
          margin-left: auto;
          margin-right: auto;
          padding-left: 15px;
          padding-right: 15px;
          padding-top: var(--emu-common-spacing-xs);
        }
      }

      &__content {
        margin-left: -2px;
        margin-right: -2px;
      }

      &__btn.emu-button-v2.emu-button__primary-outline.emu-button {
        color: var(--emu-semantic-colors-primary-0);
        width: 100%;
        border: none;
        justify-content: space-between;
        align-items: center;
        padding-left: 14px;
        padding-right: 14px;
        padding-top: 6px;
        padding-bottom: 6px;

        @include mq('medium') {
          padding-top: 6px;
          padding-bottom: 6px;
        }

        > img {
          vertical-align: middle;
        }

        > span {
          margin-left: 40px;
          letter-spacing: 3.3px;
          line-height: var(--emu-semantic-sizing-one-line-height-narrow);
          font-size: 13px;
          text-align: left;
          font-weight: var(--emu-common-font-weight-bold);

          @include mq('medium') {
            font-size: var(--emu-common-font-sizes-narrow-body);
            letter-spacing: 5.5px;
          }

          > span sup {
            font-size: 3px;
            top: -2px;
            left: -2px;

            @include mq('medium') {
              font-size: 9px;
              top: -1px;
              left: -5px;
            }
          }
        }

        &:hover {
          background-color: var(--emu-semantic-colors-none);
        }

        &:active {
          background-color: var(--emu-semantic-colors-none);
          outline: none;
        }

        &:focus {
          background-color: var(--emu-semantic-colors-none);
        }
      }
    }
  }

  .biocell-modal {
    .is-open .modal-content {
      transform: translateY(0);
    }

    .modal {
      &-content {
        width: 95%; //as per live
        margin: var(--emu-common-spacing-small);
        border-radius: 4.8px;
        max-width: 95%; //as per live
        padding: var(--emu-semantic-sizing-none);
        top: var(--emu-common-sizing-none);
        transform: translateY(-50px);

        @include mq('small') {
          margin-top: 28px;
          margin-bottom: 28px;
          margin-left: auto;
          margin-right: auto;
        }

        @include mq('medium-plus') {
          max-width: 950px; //as per live
          width: 950px; //as per live
        }

        > button {
          color: var(--emu-semantic-colors-primary-0);
          opacity: 1;
          font-size: 44px;
          text-shadow: 0 1px 0 var(--emu-semantic-colors-primary-0);
          line-height: 1.1px;
          font-weight: var(--emu-common-font-weight-bold);
          padding-left: var(--emu-semantic-sizing-none);
          padding-right: var(--emu-semantic-sizing-none);
          left: 16px;
          right: 100%;
          top: 24px;
          -webkit-tap-highlight-color: var(--emu-common-colors-transparent);
          width: fit-content;

          @include mq('medium-plus') {
            color: var(--emu-common-colors-black);
            margin-top: -16px;
          }

          &:hover {
            opacity: 0.75;
          }

          &:not(:disabled):not(.disabled):focus {
            opacity: 0.75;
          }

          > span {
            padding-left: 6px;
            padding-right: var(--emu-common-spacing-small);
            padding-top: var(--emu-common-spacing-medium);
            padding-bottom: var(--emu-common-spacing-medium);
            font-size: 48px;

            @include mq('medium-plus') {
              font-size: 26px;
            }
          }
        }
      }
    }
  }

  .home-inspira {
    overflow: visible;

    @include mq('large') {
      overflow: hidden;
    }
    .natrelle-inspira {
      &__wrapper {
        margin-top: -50px;

        @include mq('medium') {
          margin-top: var(--emu-common-spacing-none);
        }

        @include mq('medium-plus') {
          margin-top: -0.2px;
        }
      }
    }
  }

  .patients-first {
    &__btn {
      @include mq('medium') {
        max-width: fit-content;
      }
    }
  }

  .choose-surgeon {
    &.image-text {
      position: relative;
      background-size: 100% !important; //to override aem container bg styles
      background-color: var(--emu-common-colors-brand-blue-600);
      bottom: -1px;

      @include mq('medium') {
        background-color: unset;
        bottom: unset;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: linear-gradient(
          180deg,
          var(--emu-common-colors-brand-blue-50) 0%,
          var(--emu-common-colors-brand-blue-50) 10%,
          var(--emu-common-colors-brand-blue-50) 20%,
          var(--emu-common-colors-brand-blue-500) 30%,
          var(--emu-common-colors-brand-blue-500) 100%
        );

        @include mq('767px', 'max-width') {
          display: none; //to get background color from utility in mobile
        }
      }

      .cmp-image__image {
        transform: translateY(-1px);
      }

      &__flex-container {
        > .container:last-child {
          @include mq('x-large') {
            max-width: 47%;
          }
        }
      }

      &__title {
        @include mq('medium-plus') {
          padding-top: 35px;
        }

        > h2 {
          font-weight: var(--emu-common-font-weight-medium);
        }
      }

      .u-title-secondary--pink i {
        font-weight: var(--emu-common-font-weight-medium);
      }
    }
  }

  .augmentation-sec {
    overflow: hidden;

    &__wrapper {
      > .container {
        padding-top: 45px;
        margin-top: -2px;
        padding-bottom: 50px;

        @include mq('medium') {
          padding-top: 52px;
          margin-top: var(--emu-common-spacing-none);
        }
      }
    }

    &__image {
      margin-right: -4px;

      img {
        width: 100%;
        margin-bottom: -4px;
        transform: translateY(2px);
      }
    }

    .real-women {
      .image-text {
        &__flex-container {
          > .container:first-child {
            @include mq('medium') {
              max-width: 55%; // as per live
            }
          }

          > .container:last-child {
            @include mq('medium') {
              max-width: 45%; // as per live
            }
          }
        }

        &__image-container {
          max-width: 100%;
          position: relative;

          &-image {
            margin: var(--emu-common-spacing-none);
          }
        }

        &__content {
          padding-top: 35px;

          @include mq('medium') {
            margin-right: var(--emu-semantic-spacing-none);
            align-items: flex-start;
            justify-content: unset;
            padding-top: var(--emu-semantic-spacing-none);
          }

          .button + .text {
            @include mq('medium') {
              padding-top: 25%;
            }
          }
        }

        &__title {
          &.cmp-text {
            margin-top: var(--emu-semantic-spacing-none);

            @include mq('medium') {
              text-align: left;
              margin-top: 50px;
              padding-top: var(--emu-common-spacing-none);
            }

            @include mq('large') {
              margin-top: 47px;
            }

            @include mq('x-large') {
              margin-top: 50px;
              width: 640px;
            }
          }
        }

        &__btn {
          @include mq('large') {
            margin-top: 33px;
          }

          @include mq('xxx-large') {
            margin-top: 30px;
          }

          &.emu-button-v2.emu-button__primary-outline.emu-button {
            width: 320px;
            text-align: center;

            @include mq('medium') {
              width: 100%;
            }
          }
        }

        &__disclaimer {
          &.cmp-text {
            margin-bottom: var(--emu-common-spacing-none);
            max-width: 100%;
            text-align: center;
            font-size: var(--emu-common-font-sizes-narrow-xs);
            letter-spacing: normal;
            position: absolute;
            bottom: 16px;
            width: 100%;

            @include mq('medium') {
              position: static;
              margin-top: var(--emu-common-spacing-none);
              font-size: var(--emu-common-font-sizes-narrow-small);
            }
          }
        }
      }
    }

    .breast-visualizer {
      margin-top: 20px;

      @include mq('medium') {
        padding-top: 35px;
        margin-top: var(--emu-common-spacing-none);
      }

      @include mq('xxx-large') {
        padding-top: var(--emu-common-spacing-none);
      }

      .image-text {
        &__container {
          overflow: visible;
        }

        &__flex-container {
          .container {
            @include mq('medium') {
              max-width: 50%; // as per live
            }
          }
        }

        &__content {
          padding-top: var(--emu-common-spacing-none);

          @include mq('medium') {
            align-items: flex-start;
            justify-content: unset;
          }

          @include mq('large') {
            margin-right: var(--emu-common-spacing-none);
          }

          &-btn {
            margin-top: 45px;
            margin-bottom: var(--emu-common-spacing-none);
            text-align: center;

            @include mq('medium') {
              margin-top: 10px;
              margin-bottom: 20px;
            }

            @include mq('large') {
              margin-top: var(--emu-common-spacing-none);
              margin-bottom: 25px;
            }

            &.emu-button-v2.emu-button__primary-outline.emu-button {
              padding-top: 10px;
              padding-bottom: 10px;
              padding-left: 45px;
              padding-right: 45px;
              width: fit-content;

              @include mq('medium') {
                padding-left: 15px;
                padding-right: 15px;
              }

              @include mq('large') {
                padding-top: 15px;
                padding-right: 30px;
                padding-bottom: 15px;
                padding-left: 30px;
                margin-top: 25px;
                margin-bottom: 25px;
              }
            }

            span {
              width: 100%;
              letter-spacing: 4.2px;
              line-height: 16px;

              @include mq('medium') {
                letter-spacing: 3.6px;
                font-size: var(--emu-semantic-font-sizes-wide-xs);
              }

              @include mq('large') {
                font-size: 15px;
                letter-spacing: 4.5px;
                line-height: var(--emu-semantic-line-heights-wide-xs);
              }
            }
          }
        }

        &__title {
          text-align: center;
          margin-top: 19px;
          margin-bottom: var(--emu-common-spacing-large);

          @include mq('medium') {
            text-align: left;
            margin-top: 27px;
            margin-bottom: 25px;
            padding-top: 5%;
          }

          @include mq('medium-plus') {
            padding-top: 4%;
          }

          @include mq('large') {
            margin-top: var(--emu-common-spacing-none);
            margin-bottom: var(--emu-common-spacing-small);
          }

          h2 {
            line-height: 39.52px;
            font-size: 30.4px;
            font-weight: var(--emu-common-font-weight-light);
            letter-spacing: 0.9px;

            @include mq('medium') {
              font-size: 24px;
              line-height: 26.4px;
            }

            @include mq('large') {
              font-size: var(--emu-common-font-sizes-wide-xxl);
              line-height: 58px;
            }

            @include mq('x-large') {
              letter-spacing: 1.4px;
            }

            b {
              font-style: normal;
              letter-spacing: 0.03px;
            }

            sup {
              font-size: 11px;
              left: -2px;
              top: 0px;

              @include mq('medium') {
                font-size: 9px;
              }

              @include mq('large') {
                font-size: 17px;
              }
            }
          }
        }

        &__logo {
          max-width: 75%;
          margin-left: auto;
          margin-right: auto;

          @include mq('medium') {
            max-width: 100%;
          }

          > .cmp-image__image {
            margin-bottom: var(--emu-common-spacing-medium);
            text-indent: 0;
            width: 100%;

            @include mq('small') {
              width: 290px; //as per live
              margin-bottom: var(--emu-semantic-spacing-none);
              margin-left: var(--emu-common-spacing-none);
            }

            @include mq('medium-plus') {
              margin-left: -17px;
              max-width: 98%;
              padding-top: var(--emu-common-spacing-xxs);
            }
          }
        }
      }
    }
  }

  .breast-reconstruction {
    &--content-wrapper {
      @include mq('medium') {
        padding-top: 4%;
      }
    }

    &-container {
      background-image: none;
      padding-top: 50px;
      padding-bottom: 50px;
      background-size: 100% !important; //to make override inline style of cover
      background-repeat: no-repeat;
      position: relative;
      background-color: var((--emu-common-colors-brand-violet-400));

      @include mq('medium') {
        background-color: unset;
      }

      &::before {
        @include mq('medium') {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: var(--emu-common-other-z-index-behind);
          background: linear-gradient(
            180deg,
            var(--emu-common-colors-brand-blue-50) 0%,
            var(--emu-common-colors-brand-blue-50) 3%,
            var(--emu-common-colors-brand-violet-400) 7%,
            var(--emu-common-colors-brand-violet-400) 9%,
            var(--emu-common-colors-brand-violet-400) 10%,
            var(--emu-common-colors-brand-violet-400) 20%,
            var(--emu-common-colors-brand-violet-400) 100%
          );
        }

        @include mq('large') {
          background: linear-gradient(
            180deg,
            var(--emu-common-colors-brand-blue-50) 0%,
            var(--emu-common-colors-brand-blue-50) 5%,
            var(--emu-common-colors-brand-violet-400) 10%,
            var(--emu-common-colors-brand-violet-400) 20%,
            var(--emu-common-colors-brand-violet-400) 100%
          );
          background-size: 100%;
          background-repeat: no-repeat;
        }
      }

      &--mobile {
        margin-bottom: -2px;
        overflow: hidden;

        .image {
          margin-right: -4px;

          img {
            width: 100%;
          }
        }
      }

      .content-box {
        &__title {
          padding-bottom: 4%;

          @include mq('medium') {
            padding-bottom: var(--emu-semantic-spacing-none);
          }

          h2 {
            font-weight: var(--emu-common-font-weight-medium);

            @include mq('medium') {
              letter-spacing: -1px;
            }

            b {
              letter-spacing: -0.2px;
              padding-bottom: 2%;
            }
          }
        }

        &__description {
          padding-top: var(--emu-semantic-spacing-none);
          max-width: 100%;

          @include mq('x-large') {
            text-indent: var(--emu-common-spacing-none);
          }
        }

        .button {
          max-width: 70%;
          margin-left: auto;
          margin-right: auto;

          @include mq('medium') {
            margin-left: var(--emu-semantic-spacing-none);
            margin-right: var(--emu-semantic-spacing-none);
          }
        }

        &__button {
          margin-top: 41px;
          margin-bottom: 55px;

          justify-content: center;
          width: 100%;

          @include mq('767px', 'max-width') {
            //to override only mobile values from component
            padding-left: 35px;
            padding-right: 35px;
          }

          @include mq('medium') {
            margin-top: 25px;
            margin-bottom: 15px;
            margin-left: var(--emu-semantic-spacing-none);
            max-width: fit-content;
          }

          span span {
            @include mq('medium') {
              letter-spacing: 4.3px;
              line-height: 18px;
              font-size: 15px;
            }
          }
        }
      }

      .image-text {
        &__image-container {
          max-width: 100%;
          position: relative;
        }

        &__content {
          padding-top: var(--emu-common-spacing-none);
          margin-top: 53px;

          @include mq('medium') {
            justify-content: center;
            margin: var(--emu-common-spacing-none);
            align-items: flex-start;
          }
        }

        &__flex-container .container:first-child {
          max-width: 50%;
        }

        &__title {
          padding-bottom: 2%;
          margin-top: -4px;

          @include mq('medium') {
            padding: var(--emu-common-spacing-none);
            text-align: left;
            margin-top: var(--emu-common-spacing-none);
          }

          h2 {
            font-weight: var(--emu-common-font-weight-regular);

            @include mq('medium') {
              font-size: var(--emu-common-font-sizes-narrow-xl);
              line-height: var(--emu-common-line-heights-narrow-xl);
            }

            @include mq('large') {
              font-size: var(--emu-common-font-sizes-wide-xxl);
              line-height: 58px;
            }

            i {
              padding-bottom: 3.9px;
            }
          }
        }

        &__quote {
          max-width: 88%;
          padding: var(--emu-common-spacing-none);
          padding-top: 3px;

          @include mq('medium') {
            max-width: 100%;
          }

          &.cmp-text {
            @include mq('medium') {
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.42px;
            }

            @include mq('large') {
              font-size: var(--emu-common-font-sizes-narrow-xl);
              line-height: 36px;
              letter-spacing: 2.24px;
            }

            p {
              br {
                @include mq('medium') {
                  display: block;
                }
              }
            }
          }
        }

        &__btn {
          margin-top: 61px;
          margin-bottom: 25px;
          width: auto;
          display: block;
          margin-left: auto;
          margin-right: auto;
          text-align: center;

          @include mq('767px', 'max-width') {
            padding-left: 35px;
            padding-right: 35px;
          }

          @include mq('medium') {
            margin-top: 25px;
            margin-bottom: 15px;
          }

          span span {
            @include mq('medium') {
              letter-spacing: 4.3px;
              line-height: 18px;
              font-size: 15px;
            }
          }
        }

        &__patient-name {
          @include mq('large') {
            font-size: 24px;
            line-height: var(--emu-common-line-heights-narrow-body);
          }
        }

        &__disclaimer {
          margin-bottom: var(--emu-common-spacing-none);
          max-width: 100%;
          text-align: center;
          position: absolute;
          bottom: -15px;
          width: 100%;

          @include mq('medium') {
            position: static;
            padding-left: 30px;
            padding-top: 30px;
            padding-bottom: 30px;
            margin-top: var(--emu-common-spacing-none);
            text-align: left;
          }

          &.cmp-text {
            font-size: var(--emu-common-font-sizes-narrow-small);
            line-height: var(--emu-common-line-heights-narrow-xs);
          }
        }
      }
    }
  }

  .curve-clip {
    margin-bottom: -1px;
    margin-top: -1px;
  }
}

[lang='fr_ca'] {
  #home {
    .home-inspira {
      .natrelle-inspira__content-title h2 sup {
        display: inline;
      }
    }
    
    .choose-surgeon {
      margin-top: -5px;
      .image-text {
        &__flex-container {
          > .container:last-child {
            @include mq('medium-plus') {
              max-width: 59%;
            }

            @include mq('x-large') {
              max-width: 58%;
            }
          }
        }

        &__title {
          @include mq('medium-plus') {
            padding-top: 35px;
          }
        }

        &__disclaimer {
          @include mq('medium') {
            margin-top: 15px;
          }

          @include mq('large') {
            margin-top: 25px;
          }

          @include mq('x-large') {
            max-width: 85%;
            left: 15%;
            margin-top: 10px;
          }
        }
      }
    }

    .augmentation-sec {
      &__wrapper {
        > .container {
          @include mq('medium') {
            padding-top: 50px;
          }
        }
      }

      .content-box {
        &__title {
          padding-top: 20px;

          @include mq('medium') {
            padding-top: var(--emu-common-spacing-none);
          }

          h2 {
            @include mq('small') {
              letter-spacing: 1.1px;
            }

            i {
              letter-spacing: -0.5px;
            }
          }
        }

        &__description {
          max-width: 99%;

          @include mq('x-large') {
            text-indent: 0;
          }
        }
      }

      .real-women {
        .image-text {
          &__content {
            padding-top: 28px;

            @include mq('medium') {
              padding-top: var(--emu-common-spacing-none);
            }

            .button + .text {
              padding-top: var(--emu-common-spacing-none);

              @include mq('medium') {
                padding-top: 12%;
              }
            }
          }

          &__title.cmp-text {
            padding-top: var(--emu-common-spacing-none);

            h2 {
              letter-spacing: 1.6px;

              @include mq('medium') {
                letter-spacing: 1.5px;
              }

              i {
                display: block;

                @include mq('medium') {
                  display: inline;
                }
              }
            }
          }

          &__quote {
            &.cmp-text {
              @include mq('large') {
                letter-spacing: 2.4px;
              }

              @include mq('x-large') {
                max-width: 85%;
                letter-spacing: 2.6px;
              }
            }
          }

          &__disclaimer.cmp-text {
            bottom: 11px;

            @include mq('small') {
              bottom: 21px;
            }

            @include mq('medium') {
              text-align: left;
            }
          }
        }
      }

      .breast-visualizer {
        @include mq('xx-large') {
          padding-top: var(--emu-common-spacing-none);
        }

        .image-text {
          &__title {
            @include mq('x-large') {
              width: 95%;
              padding-top: 4%;
            }

            h2 {
              @include mq('medium-plus') {
                letter-spacing: 1px;
              }

              @include mq('large') {
                letter-spacing: 2.2px;
              }

              @include mq('x-large') {
                letter-spacing: 0.2px;
                line-height: 56px;// to match live
              }

              > b {
                font-weight: var(--emu-common-font-weight-medium);
                letter-spacing: 0.2px;

                @include mq('medium') {
                  letter-spacing: normal;
                }

                @include mq('medium-plus') {
                  letter-spacing: -1.4px;
                }

                @include mq('large') {
                  letter-spacing: 1px;
                }

                @include mq('x-large') {
                  letter-spacing: -2px;
                }
              }
            }
          }
        }
      }
    }

    .patients-first {
      &__title {
        max-width: 100%;
      }

      &__content {
        max-width: 95%;

        sup {
          display: inline;
        }
      }
    }

    .breast-reconstruction {
      &-container {
        .content-box {
          &__title {
            h2 {
              @include mq('medium') {
                letter-spacing: normal;
              }

              i {
                padding-bottom: 2%;
              }
            }
          }

          &__description {
            margin-bottom: var(--emu-common-spacing-none);

            @include mq('medium') {
              margin-bottom: var(--emu-common-spacing-medium);
              letter-spacing: 0.43px;
              padding-top: var(--emu-common-spacing-none);
            }

            @include mq('large') {
              letter-spacing: 1.26px;
            }
          }

          .button {
            max-width: 85%;
          }

          &__button {
            margin-bottom: 50px;

            @include mq('medium') {
              max-width: fit-content;
              margin-bottom: 15px;
            }

            span {
              @include mq('medium') {
                letter-spacing: 4.55px;
              }
            }
          }
        }

        .image-text {
          &__content {
            margin-top: 75px; //to match the design

            @include mq('medium') {
              margin-top: var(--emu-common-spacing-none);
            }
          }

          &__flex-container .container:last-child {
            @include mq('medium') {
              max-width: 50%;
            }
          }

          &__image-container-image {
            margin-top: var(--emu-common-spacing-none);
          }

          &__title {
            @include mq('x-large') {
              margin-top: -11px;
            }

            h2 {
              letter-spacing: normal;

              @include mq('medium') {
                letter-spacing: -1.5px;
              }

              @include mq('medium-plus') {
                letter-spacing: normal;
              }

              @include mq('large') {
                letter-spacing: 0.5px;
              }

              @include mq('x-large') {
                letter-spacing: -1.5px;
              }

              i {
                letter-spacing: 1.3px;
                padding-bottom: 2%;

                @include mq('large') {
                  letter-spacing: 1.7px;
                }
              }
            }
          }

          &__quote {
            @include mq('large') {
              letter-spacing: 2.2px;
            }

            @include mq('x-large') {
              letter-spacing: 2.5px;
              max-width: 90%;
            }
          }

          &__patient-name {
            letter-spacing: 5.7px;

            @include mq('medium') {
              letter-spacing: 0.6px;
            }

            @include mq('large') {
              letter-spacing: 2px;
            }

            @include mq('x-large') {
              font-size: 32px;
              line-height: 36px;
              max-width: 90%;
              letter-spacing: 3px;
            }
          }

          &__disclaimer {
            bottom: -35px;
          }

          &__btn {
            max-width: 80%;
            margin-top: 46px;

            @include mq('medium') {
              max-width: 100%;
              margin-top: 25px;
            }

            span {
              line-height: var(--emu-common-line-heights-narrow-small);

              @include mq('medium-plus') {
                letter-spacing: 4.7px;
              }
            }
          }

          &__flex-container .container:last-child {
            @include mq('medium-plus') {
              max-width: 50%;
            }
          }

          &__quote {
            @include mq('x-large') {
              max-width: 90%;
            }

            &.cmp-text {
              @include mq('large') {
                letter-spacing: 2.2px;
              }

              @include mq('x-large') {
                letter-spacing: 2.3px;
              }
            }
          }

          &__patient-name {
            @include mq('large') {
              font-size: 32px;
              line-height: 36px;
              letter-spacing: 2.24px;
            }
          }
        }
      }
    }

    .home-teaser {
      .emu-teaser__title {
        @include mq('large') {
          max-width: 92%;
        }

        sup {
          font-size: 40%;
          font-weight: var(--emu-common-font-weight-medium);
          font-family: var(--emu-common-font-families-aleo);
        }
      }
      .emu-teaser__description {
        @include mq('medium') {
          max-width: 42%; // to match live
        }

        @include mq('medium-plus') {
          max-width: 43%; // to match live
        }
      }
    }

    .biocell-strip {
      &__btn.emu-button-v2.emu-button__primary-outline.emu-button {
        > span {
          @include mq('medium') {
            margin-left: var(--emu-semantic-spacing-none);
          }

          > span sup {
            font-size: 7px;
            font-family: var(--emu-common-font-families-graphik);
            font-weight: var(--emu-common-font-weight-regular);
            letter-spacing: normal;

            @include mq('medium') {
              top: 0;
            }
          }
        }
      }
    }

    .biocell-modal {
      .modal__title h2 {
        sup {
          font-family: var(--emu-common-font-families-aleo);
          font-weight: var(--emu-common-font-weight-medium);
          left: -1px;
        }
      }
    }
  }
}
