.patients-first {
  padding-top: 50px;
  padding-bottom: 50px;
  overflow: hidden;

  //to match live
  @include mq('large') {
    padding-top: 100px;
    padding-bottom: 20px;
  }

  &__wrapper {
    position: relative;
  }

  &__title {
    text-align: center;
    margin-bottom: var(--emu-common-spacing-large);
    margin-top: var(--emu-semantic-spacing-none);
    margin-left: auto;
    margin-right: auto;
    max-width: 65%;

    @include mq('medium') {
      max-width: 100%;
      text-align: left;
      font-size: var(--emu-semantic-font-sizes-narrow-xl);
      margin-bottom: var(--emu-semantic-spacing-vertical-narrow);
    }

    h2 {
      @include mq('medium') {
        font-size: var(--emu-semantic-font-sizes-narrow-xl);
        line-height: 35.2px;
      }

      @include mq('medium-plus') {
        letter-spacing: -0.08px;
      }

      @include mq('large') {
        font-size: var(--emu-semantic-font-sizes-wide-xxl);
        line-height: var(--emu-semantic-line-heights-wide-xxl);
        letter-spacing: -0.4px;
      }

      i {
        letter-spacing: -0.75px;

        @include mq('medium') {
          letter-spacing: -1.08px;
        }

        @include mq('medium-plus') {
          letter-spacing: -1.2px;
        }

        @include mq('x-large') {
          letter-spacing: -2.1px;
        }

        @include mq('xxx-large') {
          letter-spacing: 0;
        }
      }
    }
  }

  &__image {
    position: absolute;
    right: -5px;
    top: 7%;
    width: 100%;
    max-width: 180px; //as per live
    vertical-align: middle;

    @include mq('large') {
      max-width: 250px; //as per live
    }

    &-mobile {
      margin-top: 48px;
      margin-bottom: 45.6px;
      width: 75%; //as per live
    }
  }

  &__content {
    text-align: center;
    max-width: 85%; //as per live
    margin-left: auto;
    margin-right: auto;
    padding-top: 2%;

    @include mq('medium') {
      max-width: 70%; //as per live
      margin-left: var(--emu-semantic-spacing-none);
      text-align: start;
    }

    // breakpoint added to match with the live site
    @include mq('900px') {
      max-width: 95%; //as per live
    }

    .emphasis {
      display: inline-block;
    }

    //to increase specificity
    &.text > p {
      letter-spacing: 0.18px;
      margin-bottom: var(--emu-semantic-spacing-none);

      @include mq('medium') {
        font-size: 14px;
        line-height: var(--emu-semantic-sizing-one-line-height-wide);
        margin-bottom: var(--emu-common-spacing-medium);
        letter-spacing: 0.14px;
      }

      @include mq('large') {
        font-size: var(--emu-semantic-font-sizes-wide-body);
        line-height: var(--emu-semantic-line-heights-narrow-body);
        letter-spacing: 0.18px;
      }

      br {
        // breakpoint added to match with the live site
        @include mq('900px') {
          display: none;
        }

        // breakpoint added to match with the live site
        @include mq('1201px') {
          display: block;
        }
      }

      sup {
        top: -1px;
        left: -0.9px;
        font-size: 9px;

        @include mq('medium') {
          font-size: 7px;
        }

        @include mq('large') {
          font-size: 9px;
        }
      }

      > .trademark-sup {
        top: 14px;
        font-size: 28px;
        font-weight: var(--emu-semantic-font-weight-regular);

        @include mq('medium') {
          font-size: 21px;
          top: 10px;
          left: 0.1px;
        }

        @include mq('large') {
          font-size: 28px;
          top: 14px;
        }
      }
    }
  }

  .button {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;

    @include mq('medium') {
      margin-left: var(--emu-semantic-spacing-none);
      margin-right: var(--emu-semantic-spacing-none);
    }
  }

  //To increase specificity
  &__btn.emu-button.emu-button-v2.emu-button__primary-outline {
    margin-top: 45px;
    margin-bottom: 45px;
    width: 300px; //as per live
    text-align: center;
    transition: all var(--emu-common-other-time-transition-short);

    @include mq('medium') {
      margin-top: 20px;
      margin-bottom: var(--emu-semantic-spacing-none);
      margin-left: var(--emu-semantic-spacing-none);
      max-width: 168.16px; //as per live
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    @include mq('large') {
      max-width: 220.5px; //as per live
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 30px;
      padding-right: 30px;
    }

    > span {
      width: 100%;
      letter-spacing: 4.2px;

      @include mq('medium') {
        font-size: var(--emu-semantic-font-sizes-narrow-small);
        line-height: var(--emu-semantic-line-heights-narrow-xs);
        letter-spacing: 3.6px;
      }

      @include mq('large') {
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 4px;
      }
    }
  }
}
