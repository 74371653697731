.teaser {
  &-container {
    display: flex;
    flex-direction: column-reverse;
    min-height: auto;

    @include mq('medium') {
      display: block;
    }
  }

  &-image-container {
    .cmp-teaser__image--desktop {
      img {
        @include mq('large') {
          position: static;
        }
      }
    }

    .cmp-teaser__image--mobile {
      position: relative;

      svg {
        height: 100%;
        width: 100%;
        display: block;
      }
    }
  }

  .emu-teaser-v3 {
    &.inline-text-overlay .teaser-container > .content-container {
      top: 5%;
      position: static;

      @include mq('medium') {
        top: 0%;
        height: auto;
        position: absolute;
        right: 5%;
      }
    }

    .teaser-container {
      @include mq('xxx-large') {
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
      }
    }

    .content-container {
      .emu-teaser {
        // play button
        &__action-container {
          position: absolute;
          top: 64%;
          right: 43%;

          @include mq('medium') {
            top: 38%;
            right: 37%;
          }

          @include mq('medium-plus') {
            top: 40%;
            right: 39%;
          }

          @include mq('large') {
            top: 32%;
            right: 39%;
          }
        }

        &__title {
          max-width: 95%;
          margin-bottom: var(--emu-common-spacing-small);
          font-weight: var(--emu-common-font-weight-regular);

          @include mq('medium') {
            font-size: var(--emu-common-font-sizes-narrow-xl);
            line-height: 32px;
          }

          @include mq('large') {
            font-size: var(--emu-common-font-sizes-wide-xxxl);
            line-height: var(--emu-common-line-heights-wide-xxxl);
          }

          br {
            display: none !important; // display inline are getting added for description

            @include mq('medium') {
              display: block !important;
            }
          }

          sup {
            font-weight: var(--emu-common-font-weight-black);

            @include mq('large') {
              top: -5px;
              left: -0.1em;
            }
          }

          i {
            font-family: var(--emu-common-font-families-aleo);
            font-weight: var(--emu-common-font-weight-bold);
          }
        }

        &__description {
          max-width: 90%;
          margin-left: var(--emu-common-spacing-none);

          @include mq('medium') {
            margin-left: auto;
            max-width: 46%;
          }

          p {
            display: block !important; // getting display inline in inline styles
            margin-bottom: var(--emu-common-spacing-none);

            @include mq('medium') {
              font-size: var(--emu-common-font-sizes-wide-small);
              line-height: 22px;
            }

            @include mq('large') {
              font-size: var(--emu-common-font-sizes-wide-large);
              line-height: 30px;
              font-weight: var(--emu-common-font-weight-regular);
            }

            sup {
              font-size: var(--emu-common-font-sizes-narrow-xs);
            }
          }
        }

        &__subtext {
          margin-bottom: 5px;
          padding-top: var(--emu-common-spacing-none);

          @include mq('medium') {
            margin-bottom: var(--emu-common-spacing-medium);
            max-width: 410px;
          }

          p {
            letter-spacing: 1.26px;
            line-height: 30px;

            @include mq('medium') {
              letter-spacing: 1.54px;
              font-size: var(--emu-common-font-sizes-wide-large);
            }

            @include mq('large') {
              padding-top: 20px;
            }
          }
        }

        &__disclaimer {
          margin-bottom: var(--emu-common-spacing-medium);
          position: relative;
          width: 75%;
          letter-spacing: 0.84px;
          margin-top: var(--emu-common-spacing-none);
          padding: var(--emu-common-spacing-none);
          max-width: 410px;

          @include mq('medium') {
            padding-top: 17px;
          }

          @include mq('large') {
            padding-top: 7px;
            margin-bottom: 9.5px;
            position: absolute;
            bottom: 80px;
            left: auto;
          }

          p {
            font-size: var(--emu-common-font-sizes-narrow-small);
            line-height: var(--emu-common-line-heights-narrow-xs);

            @include mq('medium') {
              line-height: 10px;
            }

            @include mq('large') {
              line-height: 14.5px;
            }

            br {
              display: block;

              @include mq('medium') {
                display: none;
              }
            }
          }
        }
      }
    }

    .title-lockup {
      margin-bottom: var(--emu-common-spacing-none);
    }
  }

  &__play-button {
    width: 60px;
    height: 60px;
    border-width: var(--emu-common-border-width-none);
    cursor: pointer;

    @include mq('large') {
      width: 80px;
      height: 80px;
    }

    &.emu-button-v2.emu-button:not(a) {
      background-color: var(--emu-common-colors-transparent);
      border-color: var(--emu-common-colors-transparent);

      &:hover,
      &:active,
      &:focus {
        background-color: var(--emu-common-colors-transparent);
        border-color: var(--emu-common-colors-transparent);
        outline-color: var(--emu-common-colors-transparent);
      }
    }

    img {
      width: 60px;
      height: auto;

      @include mq('large') {
        width: 80px;
      }
    }

    &--mobile {
      &.emu-button-v2.emu-button:not(a) {
        @include mq('medium') {
          display: none;
        }
      }
    }

    &--desktop {
      &.emu-button-v2.emu-button:not(a) {
        display: none;

        @include mq('medium') {
          display: block;
        }
      }
    }
  }
}

.teaser-video-modal {
  .modal {
    &-content {
      width: auto;
      position: relative;
      border-radius: var(--emu-common-border-radius-xs);
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
      padding-top: var(--emu-common-spacing-none);
      padding-bottom: var(--emu-common-spacing-xs);
      overflow: hidden;
      margin: 6px 9px; // to match live site
      max-width: 900px;

      @include mq('medium') {
        margin-top: 28px;
        margin-bottom: 28px;
        margin-left: auto;
        margin-right: auto;
        max-width: 700px;
      }

      @include mq('large') {
        max-width: 900px;
      }

      .close {
        position: relative;
        padding: var(--emu-common-spacing-none);
        margin-left: auto;
        padding-right: var(--emu-common-spacing-small);
        opacity: 1;
        line-height: 1;

        span {
          font-family: var(--emu-common-font-families-aleo);
          font-size: 24px; // to match live site

          @include mq('medium') {
            font-size: 27px; // to match live site
          }
        }
      }
    }
  }
}
