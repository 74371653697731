#fas {
  .nca-fas {
    background-repeat: no-repeat;
    background-size: cover;

    @include mq('xxx-large') {
      background-position: bottom;
    }

    @include mq('mega') {
      margin-top: 45px;
      background-size: 100% 100% !important; // needed to override the AEM's inline styles
    }

    &__above-vector-img {
      margin-top: -1px;

      @include mq('mega') {
        top: -45px;
        height: 45px;
        position: relative;
        overflow: hidden;
      }

      img {
        width: 100%;
      }
    }

    &__container {
      max-width: 1440px; //as per live
      padding-left: var(--emu-semantic-spacing-none);
      padding-right: var(--emu-semantic-spacing-none);
    }

    &__wrapper {
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column-reverse;
      margin-top: -1px;
      background-color: var(--emu-common-colors-brand-pink-500);

      @include mq('medium') {
        background-color: unset;
        display: block;
      }
    }

    &__bg-img {
      background-image: none;
      position: relative;

      @include mq('medium') {
        background-image: unset;
      }

      @include mq('x-large') {
        margin-top: -120px;
      }

      @include mq('xx-large') {
        text-align: center;
      }

      img {
        width: 100%;
      }
    }

    &__inner-container {
      &-content {
        position: relative;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-top: 50px;
        padding-bottom: 10px;
        top: 0;

        @include mq('medium') {
          padding-top: var(--emu-semantic-spacing-none);
          padding-bottom: var(--emu-semantic-spacing-none);
          position: absolute;
          top: 150px;
          justify-content: unset;
        }

        @include mq('x-large') {
          top: 200px;
        }
      }

      .content-box {
        &__title {
          h2 {
            padding-bottom: 15px;
            margin-bottom: var(--emu-common-spacing-small);
            font-weight: var(--emu-common-font-weight-medium);
            letter-spacing: -0.5px;

            @include mq('medium') {
              padding-bottom: var(--emu-semantic-spacing-none);
              margin-bottom: var(--emu-semantic-spacing-none);
            }

            @include mq('large') {
              line-height: var(--emu-common-line-heights-wide-xxl);
              letter-spacing: -0.7px;
            }

            b {
              .emphasis {
                letter-spacing: 0;
              }
            }
          }
        }

        &__description {
          max-width: 100%;
          padding-top: var(--emu-semantic-spacing-none);
          margin-bottom: 30px;
          letter-spacing: 0.9px;

          @include mq('medium') {
            margin-top: 30px;
            margin-bottom: 30px;
            letter-spacing: 0.42px;
          }

          @include mq('large') {
            font-size: 16px;
            line-height: 17.6px;
            letter-spacing: unset;
          }

          @include mq('x-large') {
            text-indent: unset;
            font-size: var(--emu-common-font-sizes-wide-body);
            line-height: 30px;
          }

          &.emphasis {
            br {
                display: none;

                @include mq('medium-plus') {
                    display: block;
                }
            }
          }
        }

        &__button {
          margin-top: var(--emu-semantic-spacing-none);
          margin-bottom: var(--emu-semantic-spacing-none);
          width: max-content;

          @include mq('medium') {
            line-height: 0;
          }

          span {
            line-height: 16px;

            @include mq('medium') {
              line-height: var(--emu-common-line-heights-narrow-xs);
            }

            @include mq('large') {
              letter-spacing: 3.7px;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
}
