.hero {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;

  @include mq('medium') {
    display: revert;
  }

  @include mq('mega') {
    overflow: hidden;
  }

  &__img {
    img {
      width: 100%;
      height: auto;

      @include mq('large') {
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }

      // breakpoint needed to match live site
      @include mq('1280px') {
        height: auto;
        object-fit: initial;
      }

      @include aem-editor-view {
        position: static !important;
        max-height: 600px;
        object-fit: contain;
      }
    }

    &-container {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      z-index: calc(var(--emu-common-other-z-index-behind) + 1);

      @include mq('medium') {
        bottom: auto;
        top: 0;
      }

      @include mq('large') {
        height: 100%;
      }

      @include aem-editor-view {
        position: static !important;
      }
    }
  }

  &__cta {
    &-container {
      position: absolute;
      top: 72%;
      right: 43%;
      z-index: calc(var(--emu-common-other-z-index-layer) + 2);
      cursor: pointer;

      @include mq('medium') {
        top: 37%;
      }

      @include mq('large') {
        top: 34%;
      }

      @include aem-editor-view {
        position: static !important;
      }
    }

    // The actual button. Increasing specificity
    &.emu-button-v2.emu-button.emu-button-v2 {
      padding: var(--emu-common-spacing-none);
      display: block;

      &,
      &:hover,
      &:focus,
      &:active {
        background-color: var(--emu-common-colors-transparent);
        border: var(--emu-common-border-width-none);
        outline: none;
      }

      span {
        display: none;
      }

      svg {
        display: block;
        width: 60px;
        height: 60px; // needed to match the design
        margin: var(--emu-common-spacing-none);

        @include mq('large') {
          width: 80px;
          height: 80px; // needed to match the design
        }
      }
    }
  }

  &__content {
    &-container {
      position: relative;
      z-index: var(--emu-common-other-z-index-layer);
      min-height: 660px;

      @include mq('medium') {
        min-height: 515px;
        padding-left: 15px;
        padding-right: 15px;
      }

      @include mq('large') {
        min-height: 720px;
      }
    }

    &-inner {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 5%;
      left: 0;
      padding-left: 20px;

      @include mq('medium') {
        width: 55%;
        padding-top: 55px;
        position: static;
        padding-left: var(--emu-common-spacing-none);
      }

      @include mq('large') {
        padding-top: 80px;
        width: 45%;
      }

      @include aem-editor-view {
        position: static !important;
      }
    }
  }

  &__title {
    margin-bottom: var(--emu-common-spacing-small);
    letter-spacing: -0.8px;

    @include mq('medium') {
      font-size: 50px;
      line-height: 46px;
    }

    @include mq('large') {
      font-size: var(--emu-common-font-sizes-wide-xxxl);
      line-height: var(--emu-common-line-heights-wide-xxxl);
    }

    h1,
    h2,
    h3 {
      @include mq('medium') {
        font-size: inherit;
        line-height: inherit;
      }
    }
  }

  &__para {
    // increasing specificity
    &.cmp-text {
      padding-top: 7px;
      margin-bottom: 16px;
      letter-spacing: 0.07em;
      max-width: 95%;
      font-size: var(--emu-common-font-sizes-narrow-body);
      line-height: var(--emu-common-line-heights-narrow-body);
      max-width: 95%;

      @include mq('medium') {
        font-size: var(--emu-common-font-sizes-narrow-large);
        line-height: 30px;
        max-width: 410px;
      }

      p {
        margin-bottom: var(--emu-common-spacing-none);
      }
    }
  }

  &__person-label {
    &.hero__para.cmp-text {
      line-height: 30px;
      margin-bottom: 5px;
      padding-top: var(--emu-common-spacing-none);

      @include mq('medium') {
        margin-bottom: var(--emu-common-spacing-medium);
      }

      @include mq('large') {
        padding-top: 20px;
      }
    }
  }

  &__disclaimer {
    &.hero__para.cmp-text {
      font-size: var(--emu-common-font-sizes-narrow-small);
      line-height: var(--emu-common-line-heights-narrow-xs);
      width: 75%;

      @include mq('medium') {
        color: var(--emu-common-colors-black);
        position: absolute;
        bottom: 80px;
        line-height: 10px;
        padding-top: 25px;
      }

      @include mq('large') {
        line-height: var(--emu-common-line-heights-wide-small);
        margin-bottom: 1%;
      }

      // media query as per live site
      @include mq('1280px') {
        margin-bottom: var(--emu-common-spacing-medium);
        padding-top: 7px;
      }

      @include aem-editor-view {
        position: static !important;
      }
    }

    br {
      @include mq('medium') {
        display: none;
      }
    }
  }

  &--right-algin-content {
    min-height: 700px; // as per live site
    overflow: hidden;

    @include mq('medium') {
      min-height: 385px; // as per live site
      overflow: visible;
    }

    // breakpoint needed as per live site
    @include mq('900px') {
      min-height: 450px; // as per live site
    }

    @include mq('x-large') {
      min-height: 600px; // as per live site
    }

    @include mq('xxx-large') {
      min-height: 720px; // as per live site
    }

    @include mq('mega') {
      min-height: 820px; // as per live site
    }

    // making sure that the color is always purple
    .cmp-text {
      color: inherit !important; // needed to override any styles
    }

    .hero {
      &__img-container {
        top: 0;
        bottom: unset;
      }

      &__img {
        img {
          position: static;
        }
      }

      &__cta-container {
        top: 20%;

        @include mq('medium') {
          top: 50%;
        }
      }

      &__cta.emu-button.emu-button-v2 {
        svg {
          @include mq('medium') {
            width: 80px; // as per live site
            height: 80px; // as per live site
          }
        }
      }

      &__content {
        &-container {
          width: 100%;
          height: 100%;
          min-height: var(--emu-common-sizing-none);
          position: absolute;
          left: 0;
          top: 35%;
          right: 35px;
          background: linear-gradient(
            180deg,
            transparent 2%,
            var(--emu-common-colors-white) 20%,
            var(--emu-common-colors-white) 100%
          );
          padding-top: 60px;
          padding-left: 35px;
          padding-right: 35px;

          @include mq('medium') {
            position: relative;
            padding-top: var(--emu-common-spacing-none);
            padding-right: 15px;
            padding-bottom: var(--emu-common-spacing-none);
            padding-left: 15px;
            background: var(--emu-common-colors-transparent);
            min-height: 385px; // as per live site
          }

          // breakpoint needed as per live site
          @include mq('900px') {
            min-height: 450px; // as per live site
          }

          @include mq('x-large') {
            min-height: 600px; // as per live site
          }

          @include mq('xxx-large') {
            min-height: 720px; // as per live site
          }
        }

        &-inner {
          position: static;
          padding: var(--emu-common-spacing-none);

          @include mq('medium') {
            width: 50%;
            margin-left: auto;
            margin-right: var(--emu-common-spacing-none);
            padding-top: 60px;
            text-align: right;
          }

          // breakpoint needed as per live site
          @include mq('900px') {
            width: 44%; // as per live site
          }

          @include mq('x-large') {
            width: 64%; // as per live site
          }
        }
      }

      &__title {
        max-width: 75%;

        @include mq('medium') {
          font-size: 34px;
          line-height: 35px;
          max-width: none;
          margin-left: auto;
        }

        @include mq('large') {
          font-size: 52px;
          line-height: 62px;
        }

        .emphasis {
          @include mq('large') {
            font-size: 60px;
            line-height: 70.4px;
          }
        }
      }

      &__para {
        letter-spacing: 0.1em;

        @include mq('medium') {
          font-size: 18px;
          line-height: 28px;
          max-width: none;
          margin-left: auto;
        }

        @include mq('large') {
          font-size: 22px;
          line-height: 30px;
          padding-top: 7px;
          max-width: 425px;
        }
      }

      &__person-label {
        &.hero__para.cmp-text {
          padding-top: 20px;
          line-height: 22px;
          margin-bottom: var(--emu-common-spacing-small);

          @include mq('medium') {
            padding-top: var(--emu-common-spacing-none);
          }

          @include mq('large') {
            font-size: 18px;
            line-height: 22px;
          }

          @include mq('x-large') {
            padding-top: 20px;
          }
        }
      }

      &__disclaimer {
        &.hero__para.cmp-text {
          max-width: 95%;
          position: absolute;
          left: 0;
          right: 0;
          padding-top: 7px;
          padding-bottom: 25px;
          padding-left: 35px;
          font-size: 10px;
          line-height: 12px;
          width: 100%;

          @include mq('medium') {
            margin-bottom: var(--emu-common-spacing-none);
            font-size: 12px;
            line-height: 14.5px;
            bottom: -15px;
            max-width: 425px;
            margin-left: auto;
            padding-bottom: var(--emu-common-spacing-none);
            padding-left: 12px;
          }

          @include mq('large') {
            margin-bottom: var(--emu-common-spacing-medium);
          }
        }
      }
    }
  }
}
