#breast-reconstruction {
  .img-with-text {
    overflow: hidden;
    position: relative;

    &__top-wave {
      background-color: var(--emu-common-colors-white);

      @include mq('medium') {
        background-color: var(--emu-common-colors-transparent);
      }
    }

    &__img-container {
      position: relative;
      right: -9px;

      @include mq('medium') {
        position: static;
      }

      img {
        min-width: 440px; // value from live site
        width: 100%;

        @include mq('medium') {
          min-width: 320px; // value from live site
          position: absolute;
          right: -27px; // value from live site
          bottom: 0;
        }

        @include mq('large') {
          min-width: var(--emu-common-sizing-none);
          right: 11px;
        }

        @include mq('x-large') {
          width: 100%;
          bottom: -45px;
        }

        // breakpoint needed as per live site
        @include mq('1268px') {
          min-width: 440px; // value from live site
        }
      }
    }

    &__content {
      &-container {
        margin-top: -2px;
        padding-top: 35px;
        padding-bottom: 25px;
        background-color: var(--emu-common-colors-brand-violet-300);
        position: relative;

        @include mq('medium') {
          margin-left: -15px;
          margin-right: -15px;
          margin-top: var(--emu-common-spacing-none);
          padding-bottom: 35px;
        }

        @include mq('x-large') {
          padding-bottom: 60px;
        }

        &-inner {
          padding-left: 15px;
          padding-right: 15px;
          display: flex;
          flex-direction: column-reverse;

          @include mq('medium') {
            flex-direction: row;
          }

          @include mq('medium-plus') {
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
          }

          > .container {
            @include mq('medium') {
              padding-left: 15px;
              padding-right: 15px;
              position: relative;
            }

            &:first-child {
              @include mq('medium') {
                flex: 0 0 33.333%;
              }
            }

            &:last-child {
              max-width: 680px; // as per live site

              @include mq('medium') {
                flex: 0 0 66.667%;
              }
            }
          }
        }
      }
    }

    &__title {
      font-size: var(--emu-common-font-sizes-narrow-xxl);
      line-height: var(--emu-common-line-heights-narrow-xxl);
      margin-bottom: var(--emu-common-spacing-medium);
      letter-spacing: -0.4px;

      @include mq('medium') {
        font-size: 32px;
        line-height: 1.1;
      }

      @include mq('large') {
        font-size: var(--emu-common-font-sizes-wide-xxl);
        line-height: var(--emu-common-line-heights-wide-xxl);
      }

      h1,
      h2,
      h3 {
        font-size: inherit;
        line-height: inherit;
        letter-spacing: inherit;
      }
    }

    &__text {
      margin-bottom: var(--emu-common-spacing-medium);
      max-width: 95%;
      letter-spacing: 0.05em;

      @include mq('medium') {
        max-width: 100%;
        letter-spacing: 0.07em;
      }

      p {
        margin-bottom: var(--emu-common-spacing-none);
      }

      sup {
        font-size: 45%;
        margin-right: 3px;
      }
    }

    &__cta {
      &.emu-button-v2.emu-button-v2.emu-button-v2 {
        width: fit-content;
        margin-top: 5px;
        margin-bottom: 25px;
        font-size: var(--emu-common-font-sizes-narrow-small);
        padding-top: 15px;
        padding-right: 30px;
        padding-bottom: 15px;
        padding-left: 30px;
        letter-spacing: 0.3em;

        @include mq('medium') {
          padding-top: 10px;
          padding-right: 15px;
          padding-bottom: 10px;
          padding-left: 15px;
          margin-top: 15px;
          margin-bottom: 35px;
          line-height: 14px;
        }

        @include mq('large') {
          padding-top: 15px;
          padding-right: 30px;
          padding-bottom: 15px;
          padding-left: 30px;
          font-size: 14px;
          line-height: 16px;
        }

        sup {
          font-size: 50%;

          @include mq('large') {
            margin-right: 4.5px;
          }
        }
      }
    }
  }

  .recon-desc {
    padding-top: 35px;

    @include mq('medium') {
      padding-top: 50px;
    }

    .title-text {
      &__txt {
        padding-top: var(--emu-common-spacing-none);
        padding-bottom: var(--emu-common-spacing-none);
        letter-spacing: 0.05em;

        @include mq('medium') {
          font-size: var(--emu-common-font-sizes-wide-body);
          line-height: var(--emu-common-line-heights-wide-body);
          letter-spacing: 0.05em;
          max-width: 100%;
        }

        a {
          font-family: var(--emu-common-font-families-graphik);
          font-size: 16px;
          line-height: 1.1;
        }

        sup {
          font-size: 45%;
        }
      }

      &__title {
        @include mq('medium') {
          font-size: 32px;
          line-height: 1.1;
          letter-spacing: -0.6px;
        }

        @include mq('large') {
          font-size: var(--emu-common-font-sizes-wide-xxl);
          line-height: var(--emu-common-line-heights-wide-xxl);
        }
      }
    }

    .fancy-img-with-text {
      flex-direction: column;
      padding-top: 15px;

      @include mq('medium') {
        flex-direction: row;
        max-height: none;
        padding-top: var(--emu-common-spacing-none);
      }

      > .container {
        @include mq('medium') {
          max-width: 50%;
        }

        &:first-child {
          @include mq('medium') {
            margin-top: 55px;
          }
        }
      }

      .title-text {
        &__title {
          font-size: var(--emu-common-font-sizes-narrow-xl);
          line-height: 1.15;
          max-width: 95%;
          margin-top: 5px;
          padding-top: var(--emu-common-spacing-none);

          @include mq('medium') {
            font-size: 42px;
            line-height: 1.1;
            margin-top: var(--emu-common-spacing-none);
          }

          @include mq('large') {
            line-height: var(--emu-common-line-heights-wide-xxl);
          }

          h1,
          h2,
          h3 {
            font-size: inherit;
            line-height: inherit;
            letter-spacing: inherit;
          }
        }

        &__cta {
          margin-top: 25px;
          margin-bottom: 25px;
          width: fit-content;
          margin-left: auto;
          margin-right: auto;
          padding-left: 30px;
          padding-right: 30px;
          font-size: 12px;

          @include mq('medium') {
            margin-top: 25px;
            margin-bottom: 25px;
            padding-left: 15px;
            padding-right: 15px;
          }

          @include mq('large') {
            font-size: 14px;
            padding-left: 30px;
            padding-right: 30px;
          }

          &:hover,
          &:focus,
          &:active {
            color: var(--emu-common-colors-white);
          }
        }
      }

      &__img-container {
        img {
          padding-top: 5px;
          padding-bottom: 45px;

          @include mq('medium') {
            padding: var(--emu-common-spacing-none);
            top: auto;
          }

          @include mq('x-large') {
            position: relative;
            bottom: -20px;
          }
        }
      }
    }
  }

  .recon-coverage {
    &__top-wave {
      margin-top: -20px;

      @include mq('medium') {
        margin-top: var(--emu-common-spacing-none);
      }

      @include mq('x-large') {
        margin-top: -90px;
      }
    }

    &__container {
      margin-top: -2px;
      padding-top: 35px;
      padding-bottom: 35px;

      @include mq('medium') {
        margin-top: var(--emu-common-spacing-none);
        padding-bottom: var(--emu-common-spacing-none);
      }

      @include mq('x-large') {
        padding-top: 65px;
      }
    }

    .title-text {
      padding-right: 15px;

      @include mq('medium') {
        padding-left: 15px;
      }

      @include mq('x-large') {
        padding-left: var(--emu-common-spacing-none);
      }

      &__title {
        letter-spacing: 0;

        @include mq('medium') {
          line-height: 1.1;
          letter-spacing: -0.2px;
        }

        @include mq('large') {
          line-height: 56px;
          letter-spacing: -0.7px;
        }
      }

      &__txt {
        padding: var(--emu-common-spacing-none);
        letter-spacing: 0.05em;

        @include mq('medium') {
          font-size: var(--emu-common-font-sizes-wide-body);
          max-width: 100%;
        }
      }
    }

    &__initial {
      margin-top: 35px;
      margin-bottom: 15px;

      @include mq('medium') {
        margin-top: 55px;
        margin-bottom: 55px;
      }

      .title-text {
        &__title {
          max-width: 80%;
        }
      }
    }

    &__timing {
      margin-top: 35px;
      margin-bottom: 25px;

      @include mq('medium') {
        margin-top: 75px;
      }

      .title-text {
        &__title {
          text-align: left;

          @include mq('medium') {
            text-align: center;
          }
        }

        &__txt {
          max-width: 100%;

          @include mq('medium') {
            max-width: 69%;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
          }
        }
      }
    }

    &__time-diff {
      &-container {
        margin-top: 35px;

        @include mq('medium') {
          margin-top: 55px;
        }

        @include mq('x-large') {
          max-width: 1040px; // as per live site
          margin-left: auto;
          margin-right: auto;
        }

        &-inner {
          @include mq('medium') {
            display: flex;
            margin-left: -15px;
            margin-right: -15px;
          }

          > .container {
            @include mq('medium') {
              padding-left: 15px;
              padding-right: 15px;
              flex: 0 0 50%;
              max-width: 50%;
            }
          }
        }
      }

      &-item {
        margin-top: 10px;
        display: flex;
        flex-direction: row-reverse;

        @include mq('medium') {
          flex-direction: row;
          margin-top: var(--emu-common-spacing-none);
        }

        > .image {
          flex: 0 0 20%;
          max-width: 20%;

          @include mq('medium') {
            flex: 0 0 25%;
            max-width: 25%;
            margin-right: var(--emu-common-spacing-small);
          }

          @include mq('large') {
            margin-right: var(--emu-common-spacing-none);
          }
        }

        > .text {
          flex: 0 0 80%;
          max-width: 80%;

          @include mq('medium') {
            flex: 0 0 75%;
            max-width: 75%;
          }
        }

        h3 {
          font-family: var(--emu-common-font-families-roboto);
          font-size: var(--emu-common-font-sizes-narrow-large);
          line-height: var(--emu-common-line-heights-narrow-xl);
          letter-spacing: 0.13em;
          font-weight: var(
            --emu-common-font-weight-bold
          ); // live has 500, but 700 matches better
        }

        p {
          letter-spacing: 0.05em;
          width: 360px; // as per live site
          padding-top: 12px;
          padding-right: 30px;

          @include mq('medium') {
            width: 100%;
            letter-spacing: 0.07em;
            padding: var(--emu-semantic-border-radius-none);
          }
        }
      }
    }

    &__bottom-wave {
      background: linear-gradient(
        180deg,
        var(--emu-common-colors-brand-pink-300) 0%,
        var(--emu-common-colors-brand-pink-300) 5%,
        var(--emu-common-colors-white) 30%,
        var(--emu-common-colors-white) 100%
      );

      @include mq('medium') {
        background: var(--emu-common-colors-brand-pink-300);
      }
    }
  }

  .recon-options {
    margin-top: 35px;
    margin-bottom: 35px;

    @include mq('medium') {
      margin-top: 55px;
      margin-bottom: 55px;
    }

    &__container-inner {
      @include mq('medium-plus') {
        max-width: 100%;
      }

      @include mq('large') {
        max-width: 1040px; // as per live site
      }
    }

    &__top-sec {
      @include mq('medium') {
        display: flex;
        margin-left: -15px;
        margin-right: -15px;
      }

      > .container {
        @include mq('medium') {
          padding-left: 15px;
          padding-right: 15px;
        }

        &:first-child {
          @include mq('medium') {
            flex: 0 0 41.667%;
          }
        }

        &:last-child {
          @include mq('medium') {
            flex: 0 0 58.333%;
          }
        }
      }
    }

    .title-text {
      &__title {
        max-width: 65%;
        font-size: var(--emu-common-font-sizes-narrow-xl);
        line-height: 1.15;
        margin-bottom: var(--emu-common-spacing-medium);
        letter-spacing: -0.5px;

        @include mq('large') {
          font-size: 41px;
          line-height: 48px;
        }

        b {
          font-size: var(--emu-common-font-sizes-narrow-xxl);
          line-height: var(--emu-common-line-heights-narrow-xxl);

          @include mq('large') {
            font-size: var(--emu-common-font-sizes-wide-xxl);
            line-height: var(--emu-common-line-heights-wide-xxl);
          }
        }

        i {
          font-size: var(--emu-common-font-sizes-narrow-xl);
          line-height: 36.8px;

          @include mq('medium') {
            font-size: 41px;
            line-height: 48px;
          }
        }

        @include mq('medium') {
          font-size: 41px;
          line-height: 48px;
          max-width: 100%;
        }

        h1,
        h2,
        h3,
        h4 {
          font-size: inherit;
          line-height: inherit;
          letter-spacing: inherit;
        }
      }

      &__txt {
        letter-spacing: 0.05em;
        padding: var(--emu-common-spacing-none);

        @include mq('medium') {
          letter-spacing: 0.07em;
          max-width: 85%;
          font-size: var(--emu-common-font-sizes-wide-body);
          line-height: var(--emu-common-line-heights-wide-body);
        }
      }
    }

    &__imgs-container {
      display: flex;
      margin-left: -15px;
      margin-right: -15px;

      > .image {
        width: 50%;
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    &__bottom-sec {
      margin-top: 25px;
      margin-bottom: 51px;

      @include mq('medium') {
        margin-top: 55px;
        display: flex;
        margin-left: -15px;
        margin-right: -15px;
      }

      > .container {
        @include mq('medium') {
          padding-left: 15px;
          padding-right: 15px;
          flex: 0 0 33.333%;
          max-width: 33.333%;
        }
      }
    }

    &__card-content {
      h3 {
        font-family: var(--emu-common-font-families-roboto);
        letter-spacing: 0.13em;
        font-size: var(--emu-common-font-sizes-narrow-medium);
        line-height: var(--emu-common-line-heights-narrow-medium);
        font-weight: var(
          --emu-common-font-weight-bold
        ); // live has 500, but 700 looks closer
        margin-bottom: 20px;

        @include mq('medium') {
          margin-bottom: var(--emu-common-spacing-small);
        }

        .emphasis {
          font-size: 16px;
          line-height: 1.1;
          color: var(--emu-common-colors-brand-blue-300);
        }
      }

      p {
        max-width: 95%;
        letter-spacing: 0.05em;

        @include mq('medium') {
          letter-spacing: 0.07em;
        }
      }
    }
  }

  .recon-with-implants {
    // background-color: var(--emu-common-colors-brand-pink-300);

    &__container {
      padding-top: 55px;

      @include mq('medium') {
        padding-top: 85px;
      }

      &-inner {
        @include mq('medium-plus') {
          max-width: none;
          padding: var(--emu-common-spacing-none);
        }
      }
    }

    &__intro {
      margin-bottom: 25px;

      @include mq('medium') {
        margin-bottom: 55px;
      }

      .title-text {
        &__title {
          max-width: 75%;
          letter-spacing: -0.2px;

          @include mq('medium') {
            text-align: center;
            max-width: none;
            line-height: 1.1;
            letter-spacing: -0.4px;
          }

          @include mq('large') {
            line-height: 56px;
            letter-spacing: -0.8px;
          }
        }

        &__txt {
          letter-spacing: 0.05em;
          padding-top: 20px;
          padding-bottom: var(--emu-common-spacing-none);

          @include mq('medium') {
            letter-spacing: 0.07em;
            text-align: center;
            font-size: var(--emu-common-font-sizes-wide-body);
            line-height: 20px;
            max-width: none;
          }

          @include mq('large') {
            line-height: 1.1;
          }
        }
      }
    }

    &__content {
      padding-bottom: 15px;

      @include mq('medium') {
        padding-bottom: 35px;
      }

      &-container {
        padding-bottom: 10px;

        @include mq('medium') {
          display: flex;
          padding-bottom: var(--emu-common-spacing-none);
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }

        @include mq('medium-plus') {
          max-width: 1040px; // as per live site
        }

        > .text {
          @include mq('medium') {
            padding-left: 15px;
            padding-right: 15px;
            flex: 0 0 50%;
            max-width: 50%;
          }
        }
      }

      h3 {
        font-family: var(--emu-common-font-families-roboto);
        font-weight: var(
          --emu-common-font-weight-bold
        ); // live has 500 but 700 matches more
        font-size: var(--emu-common-font-sizes-narrow-medium);
        line-height: 26px;
        letter-spacing: 0.13em;
        margin-bottom: var(--emu-common-spacing-small);
      }

      p {
        font-size: var(--emu-common-font-sizes-narrow-body);
        line-height: var(--emu-common-line-heights-narrow-body);
        letter-spacing: 0.07em;
      }
    }

    &__carousel {
      min-height: 350px; // value from live site

      &-container {
        padding-top: 15px;
        padding-bottom: 35px;
      }

      img {
        min-height: 245px;
        max-width: 90%;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        background: linear-gradient(
          180deg,
          #f4fbfd 0%,
          #d8f2f6 100%
        ); // colors does not seem to have variables
      }

      &-text {
        font-size: var(--emu-common-font-sizes-narrow-body);
        line-height: 25px;
        letter-spacing: 0.03em;
        font-family: var(--emu-common-font-families-roboto);
        font-weight: var(--emu-common-font-weight-bold);
        padding-top: 15px;
        max-width: 50%;
        margin-left: auto;
        margin-right: auto;

        h1,
        h2,
        h3,
        h4,
        h5 {
          font: inherit;
        }
      }

      .tns-nav {
        bottom: -35px;
        transform: none;
        left: 0;
        width: 100%;
        padding-top: 6px;
        padding-bottom: var(--emu-common-spacing-xs);
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
        gap: var(--emu-common-spacing-none);

        > button {
          margin-left: 5px;
          margin-right: 10px;
          border-color: var(--emu-common-colors-brand-violet-500);

          &.tns-nav-active {
            background-color: var(--emu-common-colors-brand-violet-500);
          }
        }
      }
    }

    &__img-txt-container {
      @include mq('medium') {
        background: linear-gradient(
          180deg,
          #f4fbfd 0%,
          #d8f2f6 100%
        ); // no css variables for these colors
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
      }

      @include mq('medium-plus') {
        max-width: none;
      }

      @include mq('large') {
        position: relative;
      }
    }

    &__img-container {
      &-inner {
        @include mq('medium') {
          display: flex;
        }

        @include mq('medium-plus') {
          max-width: 960px;
          margin-left: auto;
          margin-right: auto;
        }

        @include mq('x-large') {
          max-width: 1140px;
        }

        > .image {
          @include mq('medium') {
            padding-left: 15px;
            padding-right: 15px;
            flex: 0 0 33.33%;
            max-width: 33.33%;
          }
        }
      }

      img {
        @include mq('medium') {
          max-height: 200px;
        }

        @include mq('large') {
          max-height: 330px;
        }
      }
    }

    &__txt-container {
      @include mq('medium') {
        background-color: var(--emu-common-colors-brand-pink-300);
        margin-top: -45px;
        padding-top: 20px;
        padding-bottom: 20px;
      }

      @include mq('x-large') {
        position: absolute;
        bottom: -75px;
        width: 100%;
        text-align: center;
      }

      &-inner {
        @include mq('medium') {
          display: flex;
        }

        @include mq('medium-plus') {
          max-width: 960px;
          margin-left: auto;
          margin-right: auto;
        }

        @include mq('x-large') {
          max-width: 1140px;
        }

        > .text {
          @include mq('medium') {
            padding-left: 15px;
            padding-right: 15px;
            flex: 0 0 33.33%;
            max-width: 33.33%;
          }
        }
      }
    }

    &__img-caption {
      @include mq('medium') {
        font-weight: var(--emu-common-font-weight-bold);
        font-size: var(--emu-common-font-sizes-narrow-body);
        line-height: 25px;
        letter-spacing: 0.13em;
        margin-left: 40px;

        p {
          margin-bottom: var(--emu-common-spacing-none);
        }
      }
    }
  }

  .recon-tissue-expanders {
    padding-top: 35px;
    padding-bottom: 15px;
    // background-color: var(--emu-common-colors-brand-pink-300);

    @include mq('medium') {
      padding-top: 85px;
      padding-bottom: 50px;
    }

    .title-text {
      &__title {
        font-size: var(--emu-common-font-sizes-narrow-xl);
        line-height: 1.15;
        max-width: 80%;
        letter-spacing: -0.4px;

        @include mq('medium') {
          font-size: var(--emu-common-font-sizes-wide-xxl);
          line-height: 1.1;
          letter-spacing: -0.8px;
          max-width: 100%;
        }

        @include mq('large') {
          line-height: var(--emu-common-line-heights-wide-xxl);
        }

        sup {
          font-size: 45%;
        }

        h1,
        h2,
        h3,
        h4 {
          font-size: inherit;
          line-height: inherit;
          letter-spacing: inherit;
        }
      }

      &__txt {
        padding: var(--emu-common-spacing-none);

        @include mq('medium') {
          font-size: var(--emu-common-font-sizes-wide-body);
        }
      }
    }
  }

  .recon-place-options {
    &__container {
      // breakpoint needed to match the live site
      @include mq('576px') {
        padding-left: 15px;
        padding-right: 15px;
      }

      @include mq('medium-plus') {
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
      }

      &-inner {
        padding-left: 15px;
        padding-right: 15px;

        @include mq('medium') {
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }

        @include mq('x-large') {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }

    .title-text {
      &__title {
        letter-spacing: -0.5px;
      }
    }

    &__text {
      &--top {
        margin-left: -15px;
        margin-right: -15px;

        @include mq('medium') {
          margin-left: var(--emu-common-spacing-none);
          margin-right: var(--emu-common-spacing-none);
        }

        .title-text {
          &__title {
            max-width: 85%;
            margin-left: 15px;
            margin-right: 15px;

            @include mq('medium') {
              line-height: 1.1;
              margin-left: var(--emu-common-spacing-none);
              margin-right: var(--emu-common-spacing-none);
            }

            @include mq('large') {
              line-height: 56px;
            }
          }

          &__txt {
            padding-top: 15px; // value from live site
            padding-bottom: 15px;
            max-width: 85%;
            margin-left: 15px;
            margin-right: 15px;

            @include mq('medium') {
              font-size: var(--emu-common-font-sizes-wide-body);
              max-width: 95%;
              margin-left: var(--emu-common-spacing-none);
              margin-right: var(--emu-common-spacing-none);
            }
          }
        }
      }

      &--bottom {
        margin-top: 55px;
        margin-bottom: 35px;

        @include mq('medium') {
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-none);
          padding-left: 15px;
          padding-right: 15px;
        }

        @include mq('x-large') {
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }

        .title-text {
          &__title {
            max-width: 95%;

            @include mq('medium') {
              line-height: 1.1;
            }

            @include mq('large') {
              line-height: 56px;
            }
          }

          &__txt {
            padding-top: 12px;
            padding-bottom: var(--emu-common-spacing-none);

            @include mq('medium') {
              padding-top: var(--emu-common-spacing-none);
              font-size: var(--emu-common-font-sizes-wide-body);
            }
          }
        }
      }
    }

    .carousel {
      margin-bottom: var(--emu-common-spacing-none);
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
    }

    &__carousel {
      border-width: var(--emu-common-border-width-none);

      &-container {
        margin-top: 36px;
        margin-bottom: 45px;

        @include mq('medium') {
          margin-top: 61px;
          margin-bottom: 60px;
        }

        @include mq('x-large') {
          margin-left: -15px;
          margin-right: -15px;
        }
      }

      &-item {
        min-height: 350px; // value from live site

        @include mq('medium') {
          min-height: var(--emu-common-sizing-none);
        }
      }

      img {
        width: auto; // value from live site
        margin-right: auto;
        margin-bottom: 25px;
        margin-left: auto;
        display: block;
        max-width: 100%;
      }

      .cmp-text {
        font-size: var(--emu-common-font-sizes-narrow-body);
        line-height: 22px;
        margin-bottom: var(--emu-common-spacing-none);

        p {
          @include mq('medium') {
            margin-bottom: var(--emu-common-spacing-none);
          }
        }
      }

      .tns-nav {
        position: absolute;
        bottom: -25px; // value from live site
        top: auto;
        width: 100%;
        gap: var(--emu-common-spacing-none);
        padding-top: var(--emu-common-spacing-none);
        padding-bottom: 5px;

        > button {
          margin-right: 10px;
          margin-left: 5px;
          border-color: var(--emu-common-colors-brand-violet-500);

          &.tns-nav-active {
            border-color: var(--emu-common-colors-brand-violet-500);
            background-color: var(--emu-common-colors-brand-violet-500);
          }
        }
      }

      .emu-carousel {
        &__content {
          @include mq('medium') {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          @include mq('x-large') {
            padding-left: 15px;
          }
        }

        &__item {
          @include mq('medium') {
            padding-left: 15px;
            padding-right: 15px;
            width: 33.33%;
          }
        }
      }
    }

    &__bottom-wave {
      background: linear-gradient(
        180deg,
        var(--emu-common-colors-brand-pink-300) 0%,
        var(--emu-common-colors-brand-pink-300) 5%,
        var(--emu-common-colors-white) 30%,
        var(--emu-common-colors-white) 100%
      );

      @include mq('medium') {
        background: none;
      }
    }
  }

  .recon-journey {
    &__container {
      padding-top: 35px;
      padding-bottom: 35px;

      @include mq('medium') {
        padding-top: var(--emu-common-spacing-none);
        padding-bottom: var(--emu-common-spacing-none);
      }

      &-inner {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .title-text {
      &__title {
        padding-top: var(--emu-common-spacing-none);

        @include mq('medium') {
          margin-top: 45px;
          line-height: 1.1;
        }

        @include mq('large') {
          font-size: var(--emu-common-font-sizes-wide-xxl);
          line-height: var(--emu-common-line-heights-wide-xxl);
        }
      }

      &__txt {
        padding-top: var(--emu-common-spacing-none);

        @include mq('medium') {
          width: 80%;
          font-size: 28px;
          line-height: 1.1;
          margin-top: 33px;
        }
      }

      &__person-label {
        font-size: var(--emu-common-font-sizes-narrow-body);
        line-height: var(--emu-common-line-heights-narrow-body);
        letter-spacing: 0.07em;
        margin-top: 25px;
        font-family: var(--emu-common-font-families-graphik);

        @include mq('medium') {
          font-size: 28px;
          line-height: 1.1;
          max-width: 80%;
          margin-top: 41px;
        }
      }
    }

    .fancy-img-with-text {
      @include mq('medium') {
        max-height: none;
      }

      > .container {
        @include mq('medium') {
          padding-left: var(--emu-common-spacing-none);
        }
      }

      &__img {
        max-width: 525px;

        @include mq('medium') {
          max-width: none;
        }

        &-disclaimer {
          font-size: 12px;
          letter-spacing: 0.05em; // value from live site

          @include mq('medium') {
            line-height: 20px;
            letter-spacing: 0.03em;

            @include mq('large') {
              line-height: 1.1;
              letter-spacing: normal;
            }
          }
        }

        img {
          padding: var(--emu-common-spacing-none);
          top: 0;
          width: auto;
          max-width: 100%;

          @include mq('medium') {
            margin: var(--emu-common-spacing-none);
          }
        }
      }
    }
  }

  .before-after {
    &__intro {
      @include mq('medium') {
        padding-top: 45px;
        padding-bottom: 35px;
      }

      .title-text {
        &__title {
          max-width: 100%;

          @include mq('medium') {
            line-height: 1.1;
          }

          @include mq('large') {
            line-height: 56px;
          }
        }

        &__txt {
          max-width: 95%;
          padding: var(--emu-common-spacing-none);
          margin-top: 10px;
          letter-spacing: 0.07em;

          @include mq('medium') {
            max-width: 75%;
            margin-top: 25px;
          }
        }
      }
    }

    .carousel {
      @include mq('medium') {
        margin-bottom: var(--emu-common-spacing-none);
      }

      @include mq('medium-plus') {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    &__carousel {
      margin-bottom: 5px;
      // breakpoint needed to match the live site
      @include mq('600px') {
        margin-bottom: var(--emu-common-spacing-none);
      }

      @include mq('medium-plus') {
        margin-left: -15px;
        margin-right: -15px;
      }

      &-container {
        .tns-controls {
          // breakpoint needed to match the live site
          @include mq('600px') {
            display: block;
          }
        }
      }

      .emu-carousel__action {
        top: 30%;

        @include mq('medium') {
          top: 38%;
        }

        @include mq('medium-plus') {
          top: 35%;
        }

        @include mq('large') {
          top: 43%;
        }

        @include mq('x-large') {
          top: 40%;
        }

        &-next {
          right: -30px;

          @include mq('medium') {
            right: -24px;
          }

          @include mq('large') {
            right: -40px;
          }

          @include mq('x-large') {
            right: -55px;
          }
        }

        &-prev {
          left: -25px;

          @include mq('medium') {
            top: 39%;
          }

          @include mq('medium-plus') {
            top: 37%;
          }

          @include mq('large') {
            left: -40px;
            top: 42%;
          }

          @include mq('x-large') {
            left: -55px;
            top: 40%;
          }
        }

        span:before {
          background-image: url('./assets/images/carousel-left-arrow-wide.png');
          background-size: cover;
          height: 91px; // deduced as per live site

          @include mq('medium') {
            height: 60px;
            background-size: 100% 100%;
          }

          @include mq('large') {
            height: 91px;
          }
        }
      }

      .tns-nav {
        bottom: -100px;
        transform: none;
        left: 0;
        width: 100%;
        padding-top: 6px;
        padding-bottom: var(--emu-common-spacing-xs);
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
        gap: var(--emu-common-spacing-none);

        // breakpoint needed to match the live site
        @include mq('600px') {
          display: none;
        }

        > button {
          margin-left: 5px;
          margin-right: 10px;
          border-color: var(--emu-common-colors-brand-violet-500);

          &.tns-nav-active {
            background-color: var(--emu-common-colors-brand-violet-500);
          }
        }
      }
    }

    &__slide {
      &-content {
        @include mq('medium') {
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }

        > .container {
          width: 50%;
          margin: var(--emu-common-spacing-none);
          padding-top: 5px;
          padding-right: 15px;
          padding-bottom: 5px;
          padding-left: 15px;

          @include mq('medium') {
            width: 25%;
            padding-top: var(--emu-common-spacing-none);
            padding-bottom: var(--emu-common-spacing-none);
          }
        }

        &-disclaimer {
          padding-top: 22px;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 0.05em;

          @include mq('medium') {
            padding-left: 15px;
            padding-top: 17px;
          }
        }
      }
    }

    &__disclaimer {
      padding-left: 15px;
      line-height: 22px;
      letter-spacing: 0.05em;

      @include mq('medium') {
        margin-bottom: var(--emu-common-spacing-medium);
      }
    }
  }

  .recon-ext-links {
    &__top-wave {
      padding-top: 85px;
    }

    &__content {
      &-container {
        padding-top: 35px;
        padding-bottom: 25px;

        @include mq('medium') {
          padding-top: var(--emu-common-spacing-none);
          padding-bottom: 50px;
        }
      }
    }

    .title-text {
      margin-bottom: 30px;

      @include mq('medium') {
        margin-bottom: 50px;
      }

      &__title {
        font-size: var(--emu-common-font-sizes-narrow-xl);
        line-height: var(--emu-common-line-heights-narrow-xxl);
        max-width: 95%;
        letter-spacing: -0.4px;

        @include mq('medium') {
          font-size: var(--emu-common-font-sizes-wide-xl);
          line-height: 1.1;
          max-width: 100%;
        }

        @include mq('large') {
          line-height: var(--emu-common-line-heights-wide-xxl);
          letter-spacing: -0.2px;
        }

        h1,
        h2,
        h3,
        h4 {
          font-size: inherit;
          line-height: inherit;
          letter-spacing: inherit;
        }
      }

      &__txt {
        padding: var(--emu-common-spacing-none);
        letter-spacing: 0.05em;
        margin-bottom: var(--emu-common-spacing-small);

        @include mq('medium') {
          font-size: var(--emu-common-font-sizes-wide-body);
          line-height: var(--emu-common-line-heights-wide-body);
          max-width: 88%;
          margin-bottom: 20px;
          letter-spacing: 0.07em;
        }

        p + p,
        p + ul {
          margin-top: var(--emu-common-spacing-small);

          @include mq('medium') {
            margin-top: 20px;
          }
        }
      }

      &__cta {
        width: fit-content;
        margin-top: 25px;
        margin-bottom: 45px;
        letter-spacing: 0.05em;
        font-size: 16px;
        line-height: 22px;

        @include mq('medium') {
          padding-top: 10px;
          padding-right: 15px;
          padding-bottom: 10px;
          padding-left: 15px;
          margin-bottom: 20px;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.07em;
        }

        @include mq('medium') {
          padding-top: 15px;
          padding-right: 30px;
          padding-bottom: 15px;
          padding-left: 30px;
        }

        &:hover,
        &:focus,
        &:active {
          color: var(--emu-common-colors-white);
        }

        @include mq('medium') {
          margin-top: 10px;
        }
      }
    }
  }

  .faq {
    &__title {
      padding-top: 40px;
      padding-bottom: 20px;
      letter-spacing: -0.4px;

      @include mq('medium') {
        line-height: 1.1;
      }

      @include mq('large') {
        line-height: 56px;
      }

      h1,
      h2,
      h3,
      h4 {
        max-width: 85%;
        margin-left: auto;
        margin-right: auto;
      }

      .emphasis {
        display: inline;
      }
    }

    &__main {
      padding-top: var(--emu-common-spacing-none);
      margin-top: var(--emu-common-spacing-none);
    }
  }
}
