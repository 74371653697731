.accordion.panelcontainer {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  @include mq('medium-plus') {
    max-width: 720px; //to match live site
    margin-left: auto;
    margin-right: auto;
  }

  @include mq('large') {
    max-width: 1170px; //to match live site
    padding-left: 20px;
    padding-right: 20px;
  }
}

.aaaem-accordion {
  &__item {
    &:first-of-type {
      margin-top: 15px;

      @include mq('medium') {
        margin-top: 20px;
      }
    }
  }

  &__header {
    border-radius: 20px;
    border: var(--emu-common-border-width-thin) solid
      var(--emu-common-colors-white);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    transition: all ease-in-out
      var(--emu-component-lists-accordion-item-body-transition);

    &.-ba-is-active {
      border-radius: 20px 20px 0 0;

      .aaaem-accordion__button {
        &.plus-minus-icon {
          .aaaem-accordion__icon {
            background-image: url('../../assets/images/accordion-close-icon-blue.svg');
          }
        }
      }
    }
  }

  &__title {
    width: 100%;
    display: block;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.2em;
    font-weight: var(--emu-common-font-weight-medium);

    @include mq('medium') {
      line-height: 20px;
      letter-spacing: 0.3em;
    }

    sup {
      font-size: 45%;
      top: -2px;
      left: -1px;
      margin: var(--emu-common-spacing-none);

      &.sup-md {
        font-size: 100%;
        top: 2px;
      }
    }
  }

  &__button {
    @include mq('medium') {
      align-items: center;
      padding-top: 5px;
    }

    &.plus-minus-icon {
      .aaaem-accordion__icon {
        background-image: url('../../assets/images/accordion-open-icon-blue.svg');
        background-size: cover;

        &::before,
        &::after {
          display: none;
        }
      }
    }
  }

  // needed for increasing specificity
  .aaaem-accordion__panel {
    margin-top: -20px;
    margin-bottom: 35px;
    transition: all ease-in-out
      var(--emu-component-lists-accordion-item-body-transition);
    position: relative;
    z-index: var(--emu-common-other-z-index-layer);

    @include mq('medium') {
      margin-bottom: 40px;
    }

    &.-ba-is-active {
      border-radius: 0 0 20px 20px;
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.12);
      margin-top: var(--emu-common-spacing-none);
      margin-bottom: 15px;

      @include mq('medium') {
        margin-bottom: 20px;
        margin-top: -4px;
      }
    }

    sup {
      font-size: 45%;
      left: -1px;
      margin: var(--emu-common-spacing-none);
    }

    .js-badger-accordion-panel-inner {
      margin-top: -4px;
      padding-bottom: 5px;
      transition: all 0.2s linear 0.2s;

      @include mq('medium') {
        padding-bottom: 17px;
      }

      .cmp-text {
        font-size: 16px;
        width: 97%;
        letter-spacing: 0.07em;
        color: var(--emu-common-colors-black);

        @include mq('medium') {
          width: 98%;
          line-height: 24px;
        }
      }
    }
  }
}
