.carousel {
  [data-component='carousel-v2'].emu-carousel .tns-controls.js-show {
    @include mq('medium', 'max-width') {
      display: none;
    }
  }

  &.panelcontainer {
    display: block;
    margin-left: auto;
    margin-right: auto;

    @include mq('medium-plus') {
      max-width: 960px; //as per live
      padding-left: 5px;
      padding-right: 5px;
      margin-left: auto;
      margin-right: auto;
    }

    @include mq('x-large') {
      max-width: var(--emu-common-sizing-xxxl); //as per live
    }

    .emu-carousel {
      padding-bottom: var(--emu-common-spacing-none);
    }
  }

  @include mq('medium') {
    margin-bottom: 30px;
  }

  .tns-outer {
    > .tns-nav {
      bottom: -92px;
      z-index: var(--emu-common-other-z-index-layer);

      @include mq('medium') {
        display: none;
      }

      > button {
        width: 15px;
        height: 15px;
        margin-left: var(--emu-semantic-spacing-none);
        margin-right: var(--emu-semantic-spacing-none);
      }
    }
  }

  .emu-carousel {
    &__action {
      @include mq('medium') {
        top: 38%;
      }

      @include mq('medium-plus') {
        top: 37%;
      }

      @include mq('large') {
        top: 53%;
      }

      span {
        &::before {
          display: block;
          content: '';
          background-image: url('../../assets/images/carousel-left-arrow.png');
          background-size: cover;
          height: 60px; //as per live
          min-width: 40px; //as per live

          @include mq('large') {
            height: 91px; //as per live
          }
        }

        svg {
          display: none;
        }
      }

      &-prev {
        @include mq('medium') {
          left: -40px;
        }

        @include mq('large') {
          left: -70px;
        }
      }

      &-next {
        @include mq('medium') {
          right: -40px;
        }

        @include mq('large') {
          right: -70px;
        }

        span {
          &::before {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
