.before-after {
  &__mobile-top-wave {
    background: linear-gradient(
      0deg,
      var(--emu-common-colors-brand-pink-300) 0%,
      var(--emu-common-colors-brand-pink-300) 5%,
      var(--emu-common-colors-white) 6%,
      var(--emu-common-colors-white) 100%
    );
  }

  &__intro {
    padding-top: 35px;
    padding-bottom: 35px;

    @include mq('medium') {
      padding-top: 4%;
      padding-bottom: var(--emu-common-spacing-none);
    }

    &-inner {
      padding-left: 15px;
      padding-right: 15px;
    }

    .title-text {
      &__title {
        margin-bottom: var(--emu-common-spacing-none);
        margin-left: auto;
        margin-right: auto;
        max-width: 85%;
        letter-spacing: -0.5px;

        @include mq('medium') {
          margin-bottom: var(--emu-common-spacing-small);
        }

        @include mq('large') {
          letter-spacing: -0.8px;
        }
      }

      &__txt {
        padding-top: 2%;
        padding-bottom: 5%;
        letter-spacing: 0.03em;
        margin-left: auto;
        margin-right: auto;
        max-width: 85%;
        margin-bottom: var(--emu-common-spacing-none);

        @include mq('medium') {
          font-size: 18px;
        }

        @include mq('large') {
          letter-spacing: normal;
        }

        sup {
          font-size: 45%;
          left: -1px;
          top: -1px;
        }
      }
    }
  }

  &__carousel-container {
    padding-top: 3%;
    padding-right: 2%;
    padding-bottom: 0.5%;
    padding-left: 2%;

    // breakpoint needed to match the live site
    @include mq('576px') {
      padding: 35px;
    }

    @include mq('medium-plus') {
      padding-top: 3%;
      padding-right: 2%;
      padding-bottom: 0.5%;
      padding-left: 2%;
    }

    @include mq('large') {
      overflow-x: hidden;
    }

    .carousel.panelcontainer {
      @include mq('large') {
        margin-bottom: 22px;
      }
    }
  }

  &__carousel {
    // increasing specificity
    &[data-component='carousel-v2'].emu-carousel.emu-carousel {
      border: none;

      .tns-controls.js-show {
        @include mq('medium') {
          display: block;
        }
      }

      .emu-carousel__action {
        top: 37%;
        padding: var(--emu-common-spacing-none);

        @include mq('large') {
          top: 54%;
        }

        &-next {
          right: -25px;

          @include mq('large') {
            right: -56px;
          }
        }

        &-prev {
          left: -20px;

          @include mq('medium-plus') {
            left: -25px;
          }

          @include mq('large') {
            left: -56px;
          }
        }
      }

      .emu-carousel__action span::before {
        background-size: 40px 60px;
        background-repeat: no-repeat;

        @include mq('large') {
          background-size: cover;
        }
      }

      .tns-nav {
        bottom: -96px;
        padding-right: 13px;
      }
    }
  }

  &__slide {
    &-content {
      display: flex;
      flex-wrap: wrap;

      @include mq('medium') {
        padding-left: 5px;
        padding-right: 5px;
      }

      @include mq('medium-plus') {
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
      }

      > .container {
        width: 44%;
        padding: 5px;
        margin: 10px;

        @include mq('medium') {
          padding-left: 15px;
          padding-right: 15px;
          padding-top: var(--emu-common-spacing-none);
          padding-bottom: var(--emu-common-spacing-none);
          width: 25%;
          margin: var(--emu-common-spacing-none);
          position: relative;
        }
      }

      &-img {
        .cmp-image__title {
          position: absolute;
          bottom: 0;
          left: 15px;
          width: 89.5%;
          color: var(--emu-common-colors-white);
          display: flex;
          justify-content: center;
          align-items: flex-end;
          text-align: center;
          height: 50px;
          padding-bottom: 5px;
          font-family: var(--emu-common-font-families-graphik);
          font-weight: var(--emu-common-font-weight-bold);
          letter-spacing: 0.07em;

          @include mq('medium') {
            padding-bottom: 10px;
          }
        }
      }

      &-disclaimer {
        font-size: 15px;
        line-height: 22px;
        padding-top: 5px;
        padding-bottom: 5px;
        letter-spacing: 0.07em;

        @include mq('medium') {
          padding-left: 20px;
          padding-top: 17px;
          line-height: 28px;
          padding-bottom: var(--emu-common-spacing-none);
        }

        @include mq('medium-plus') {
          padding-left: 15px;
        }

        @include mq('large') {
          font-size: 16px;
          line-height: 1.1;
        }

        p {
          margin-bottom: var(--emu-common-spacing-none);
        }

        sup {
          font-size: 45%;
        }
      }
    }
  }

  &__disclaimer {
    font-size: 12px;
    letter-spacing: 0.03em;
    padding-left: 4%;
    padding-top: 0%;
    margin-bottom: 5px;

    @include mq('medium') {
      padding-left: 20px;
    }

    @include mq('medium-plus') {
      max-width: 960px;
      margin-left: auto;
      margin-right: auto;
    }

    @include mq('x-large') {
      max-width: var(--emu-common-sizing-xxxl);
    }

    @include mq('large') {
      letter-spacing: normal;
    }

    sup {
      font-size: 55%;
    }

    p {
      margin-bottom: var(--emu-common-spacing-none);
    }
  }
}
