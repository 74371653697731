#breast-surgery-resources {
  // first section with carousel in mobile and tabs in desktop
  .resources {
    background: linear-gradient(
      360deg,
      var(--emu-common-colors-brand-blue-100) 0%,
      rgba(153, 221, 232, 0) 100%
    );

    &__intro {
      margin-top: 24px;

      @include mq('medium') {
        margin-top: 48px;
      }

      @include mq('x-large') {
        margin-top: var(--emu-common-spacing-xl);
      }

      &-inner {
        padding-left: 15px;
        padding-right: 15px;
      }

      &-title {
        margin-bottom: var(--emu-common-spacing-medium);

        @include mq('medium') {
          font-size: 32px;
          line-height: 1.15;
          letter-spacing: -0.4px;
        }

        @include mq('large') {
          font-size: 56px;
          margin-bottom: 24px;
        }

        h1,
        h2,
        h3 {
          @include mq('medium') {
            font-size: inherit;
            line-height: inherit;
          }
        }
      }

      &-text {
        letter-spacing: 1.26px;
        margin-bottom: var(--emu-common-spacing-medium);
        max-width: 85%;
        margin-left: auto;
        margin-right: auto;

        @include mq('medium') {
          max-width: 100%;
        }

        @include mq('large') {
          font-size: var(--emu-common-font-sizes-wide-small);
          letter-spacing: 1.12px;
        }

        p {
          margin-bottom: var(--emu-common-spacing-none);
        }
      }
    }

    &__content {
      padding-bottom: 35px;

      @include mq('medium') {
        padding-top: 85px;
        padding-bottom: 85px;
        margin-left: auto;
        margin-right: auto;
        max-width: 1200px; // value from live site
      }

      &-inner {
        padding-left: 15px;
        padding-right: 15px;

        @include mq('medium-plus') {
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }
      }
    }

    .carousel {
      @include mq('medium') {
        margin: var(--emu-common-spacing-none);
      }
    }

    &__carousel {
      border: none;

      .tns-nav {
        bottom: auto;
        height: 30px; // as per live site
        top: 40%;
        padding: var(--emu-common-spacing-none);
        gap: var(--emu-common-spacing-none);
        margin-top: var(--emu-common-spacing-small);

        // media query needed to make it look good
        @include mq('576px') {
          top: auto;
          bottom: -30px;
        }

        > button {
          margin-left: 10px;
          margin-right: 10px;
          width: 10px;
          height: 10px;
          padding: 5px;
          background-color: var(--emu-common-colors-black);
          border: none;
          opacity: 0.25;

          &.tns-nav-active {
            opacity: 0.75;
          }
        }
      }

      .emu-carousel__item-container {
        padding-left: 15px;
        padding-right: 15px;
      }

      &-item {
        // breakpoint needed to match the live site
        @include mq('576px') {
          display: flex;
          margin-left: -15px;
          margin-right: -15px;
          align-items: center;
        }

        > .container {
          // breakpoint needed to match the live site
          @include mq('576px') {
            padding-left: 15px;
            padding-right: 15px;
          }

          &:first-child {
            // breakpoint needed to match the live site
            @include mq('576px') {
              flex: 16.667%;
            }
          }

          &:last-child {
            // breakpoint needed to match the live site
            @include mq('576px') {
              flex: 83.333%;
            }
          }
        }

        &-img-container {
          margin-left: auto;
          margin-right: auto;
          min-height: 350px; // value from live site
          max-width: 200px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            margin-top: var(--emu-common-spacing-medium);
            margin-right: auto;
            margin-bottom: var(--emu-common-spacing-medium);
            margin-left: auto;
          }
        }

        &-title {
          margin-top: 48px;
          margin-bottom: var(--emu-common-spacing-medium);
          letter-spacing: -0.6px;
        }

        &-text {
          margin-bottom: 20px;
          letter-spacing: 1.26px;
        }

        &-cta {
          margin-bottom: 15px;
          padding-top: 15px;
          padding-right: 25px;
          padding-bottom: 15px;
          padding-left: 25px;
          width: 100%;
          line-height: 22px;
          margin-left: auto;
          margin-right: auto;
          text-align: center;
          justify-content: center;
          letter-spacing: 4.2px;
        }
      }
    }

    &__tabs {
      @include mq('medium-plus') {
        max-width: 960px; // value from live site
        padding-left: 15px;
        padding-right: 15px;
        margin-left: auto;
        margin-right: auto;
      }

      @include mq('x-large') {
        max-width: var(--emu-common-sizing-xxxl);
      }

      &-item {
        &-inner {
          margin-left: -15px;
          margin-right: -15px;
          display: flex;
          align-items: center;

          > .container {
            padding-left: 15px;
            padding-right: 15px;

            &:first-child {
              flex: 16.667%;

              @include mq('large') {
                padding-left: var(--emu-common-spacing-none);
              }
            }

            &:last-child {
              flex: 83.333%;
            }
          }
        }

        &-title {
          font-size: var(--emu-common-font-sizes-narrow-xl);
          line-height: 1.15;
          margin-bottom: var(--emu-common-spacing-medium);
          letter-spacing: -0.6px;

          @include mq('large') {
            font-size: var(--emu-common-font-sizes-wide-xxl);
          }

          h1,
          h2,
          h3 {
            font-size: inherit;
            line-height: inherit;
            letter-spacing: inherit;
          }
        }

        &-text {
          font-size: 16px;
          margin-bottom: 20px;
          line-height: 1.15;
          letter-spacing: 0.07em;

          @include mq('large') {
            font-size: var(--emu-common-line-heights-wide-xs);
            line-height: var(--emu-common-line-heights-wide-body);
          }

          p {
            margin-bottom: var(--emu-common-spacing-none);
          }
        }

        &-cta {
          font-size: var(--emu-common-font-sizes-narrow-small);
          line-height: var(--emu-common-line-heights-narrow-xs);
          padding-top: 10px;
          padding-right: 15px;
          padding-bottom: 10px;
          padding-left: 15px;
          letter-spacing: 0.3em;

          @include mq('large') {
            font-size: 14px;
            line-height: 16px;
            padding-top: 15px;
            padding-right: 30px;
            padding-bottom: 15px;
            padding-left: 30px;
          }
        }
      }
    }
  }

  // second section with natrelle certified badge
  .patients-first {
    padding-top: 35px;
    padding-bottom: 35px;

    @include mq('medium') {
      padding-top: var(--emu-common-spacing-xl);
      padding-bottom: var(--emu-common-spacing-xl);
    }

    &__title {
      font-size: var(--emu-common-font-sizes-narrow-xl);
      line-height: 1.1;
      margin-bottom: var(--emu-common-spacing-medium);

      @include mq('medium') {
        line-height: 40px;
        margin-bottom: 24px;
      }

      @include mq('large') {
        font-size: var(--emu-common-font-sizes-wide-xxl);
        line-height: var(--emu-common-line-heights-wide-xxl);
        letter-spacing: -0.8px;
      }

      i {
        @include mq('767px', 'max-width') {
          font-size: var(--emu-common-font-sizes-narrow-xxl);
          line-height: var(--emu-common-line-heights-narrow-xxl);
        }
      }

      h1,
      h2,
      h3 {
        font-size: inherit;
        line-height: inherit;
      }
    }

    &__image {
      @include mq('medium') {
        top: 0;
        right: 0;
      }

      &-mobile {
        margin-top: 26px;
        margin-bottom: 25px;
      }
    }

    &__content {
      padding-top: var(--emu-common-spacing-none);
      max-width: 90%;
      letter-spacing: 0.07em;

      @include mq('medium') {
        max-width: 88%;
        margin-bottom: var(--emu-common-spacing-large);
        font-size: 16px;
      }

      sup {
        @include mq('large') {
          margin-right: -1px;
        }
      }

      p {
        font-size: inherit;
        line-height: inherit;
        letter-spacing: inherit;
      }
    }

    .button {
      max-width: 95%;
      width: 100%;
    }

    &__btn {
      width: 100%;
      margin-bottom: 35px;
      padding-left: 25px;
      padding-right: 25px;

      @include mq('medium') {
        margin: var(--emu-common-spacing-none);
        max-width: none;
        width: fit-content;
        padding-left: 15px;
        padding-right: 15px;
      }

      @include mq('large') {
        padding-left: 30px;
        padding-right: 30px;
      }

      span {
        @include mq('large') {
          letter-spacing: 0.3em;
        }
      }
    }
  }

  // FAQ section
  .faq {
    &__mobile-top-wave {
      margin: var(--emu-common-spacing-none);
    }

    &__title {
      padding-top: 35px;
      padding-bottom: var(--emu-common-spacing-small);

      @include mq('medium') {
        position: absolute;
        top: 30%;
        width: 100%;
        height: 100%;
        padding: var(--emu-common-spacing-none);
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--emu-component-ingredients-title-color-light);
        background-color: var(--emu-common-colors-transparent);
      }

      @include mq('large') {
        top: 35%;
      }

      h1,
      h2,
      h3,
      h4 {
        @include mq('medium') {
          margin-bottom: var(--emu-common-spacing-small);
        }
      }

      &-container {
        @include mq('medium') {
          position: relative;
          max-height: 250px; // value from live site
        }
      }

      .emphasis {
        display: inline;
      }
    }

    &__main {
      padding-top: 5px;
      padding-bottom: 5px;
      margin-top: var(--emu-common-spacing-none);

      @include mq('medium') {
        padding-top: 50px;
        padding-bottom: var(--emu-common-spacing-none);
      }

      .aaaem-accordion {
        width: 100%;
      }

      .has-special-list {
        ul {
          margin-bottom: var(--emu-common-spacing-large);
        }

        li {
          line-height: 24px;
        }

        a {
          font-family: var(--emu-common-font-families-roboto);
          text-underline-offset: 1px;
        }
      }
    }
  }

  // Find a surgeon section
  .fas-wrapper {
    #fas {
      .nca-fas {
        // breakpoint needed to match the live site
        @include mq('mega') {
          margin-top: var(--emu-common-spacing-none);
        }

        &__above-vector {
          display: none;
        }

        &__inner-container {
          &-content {
            padding-bottom: var(--emu-common-spacing-none);

            @include mq('medium') {
              top: 100px;
            }

            .content-box {
              &__description {
                line-height: 23px;

                @include mq('medium') {
                  font-size: var(--emu-common-font-sizes-wide-body);
                  line-height: 30px;
                  letter-spacing: 0.03em;
                }

                @include mq('large') {
                  letter-spacing: normal;
                }

                &.emphasis {
                  br {
                    @include mq('medium') {
                      display: block;
                    }
                  }
                }
              }

              &__button {
                span {
                  @include mq('large') {
                    font-size: 14px;
                    letter-spacing: 0.3em;
                  }
                }
              }
            }
          }
        }

        &__bg-img {
          @include mq('x-large') {
            margin-top: var(--emu-common-spacing-none);
          }

          img {
            @include mq('medium') {
              display: none;
            }
          }

          .resources-page-image {
            @include mq('medium') {
              display: block !important; // needed to override some default styles
            }

            img {
              @include mq('medium') {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  .glossary {
    background-color: var(--emu-common-colors-brand-blue-400);

    .accordion {
      @include mq('large') {
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
      }

      &.panelcontainer {
        @include mq('medium-plus') {
          max-width: 720px; // value from live site
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }

        @include mq('large') {
          max-width: 1170px; // value deduced from live site
        }
      }

      .aaaem-accordion {
        padding-top: 17px;
        padding-bottom: 17px;

        @include mq('medium') {
          padding-top: 50px;
          padding-bottom: var(--emu-common-spacing-none);
        }

        &__header {
          background-color: var(--emu-common-colors-transparent);
          box-shadow: unset;
          padding-top: var(--emu-common-spacing-large);
          padding-bottom: var(--emu-common-spacing-large);
          padding-left: 12px;
          padding-right: 12px;
          border: none;

          @include mq('medium') {
            padding-top: var(--emu-common-spacing-medium);
            padding-right: var(--emu-common-spacing-none);
            padding-bottom: var(--emu-common-spacing-medium);
            padding-left: var(--emu-common-spacing-none);
          }

          &.-ba-is-active {
            @include mq('medium') {
              padding-bottom: var(--emu-common-spacing-none);
            }
          }
        }

        &__button {
          justify-content: center;
          border: var(--emu-common-border-width-thin) solid
            var(--emu-common-colors-transparent);

          @include mq('medium') {
            padding-top: 6px;
            padding-bottom: 6px;
          }
        }

        &__icon {
          margin-left: 20px;
        }

        &__title {
          width: max-content;
          color: var(--emu-common-colors-white);
          flex-grow: 0;
          font-size: var(--emu-common-font-sizes-narrow-xl);
          line-height: 1.1;
          font-family: var(--emu-common-font-families-aleo);
          letter-spacing: var(--emu-semantic-spacing-none);

          @include mq('medium') {
            font-size: var(--emu-common-font-sizes-wide-xxl);
            line-height: var(--emu-common-line-heights-wide-xxl);
          }
        }

        &__panel {
          background-color: var(--emu-common-colors-brand-blue-400);
          box-shadow: unset;
          margin-left: auto;
          margin-right: auto;
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-none);

          @include mq('x-large') {
            max-width: 1200px; // to match with figma
          }

          .js-badger-accordion-panel-inner {
            padding-top: var(--emu-common-spacing-large);
            padding-bottom: var(--emu-common-spacing-small);
            padding-left: var(--emu-common-spacing-medium);
            padding-right: var(--emu-common-spacing-medium);
            margin-top: var(--emu-common-spacing-none);

            @include mq('medium') {
              margin-left: -15px;
              margin-right: -15px;
            }

            @include mq('medium-plus') {
              padding-left: var(--emu-common-spacing-none);
              padding-right: var(--emu-common-spacing-none);
            }

            .cmp-text {
              width: 99%;

              @include mq('medium') {
                width: 100%;
              }

              p {
                @include mq('medium') {
                  width: 98%;
                }
              }
            }
          }
        }

        &__item {
          margin: var(--emu-common-spacing-none);
        }
      }
    }

    &__content-wrapper {
      @include mq('medium-plus') {
        padding-bottom: 24px;
      }

      > div:first-child {
        display: grid;
        grid-template-columns: 1fr;

        @include mq('medium') {
          grid-template-columns: repeat(3, 1fr);
        }

        > .text {
          float: none;

          @include mq('medium') {
            padding-left: 15px;
            padding-right: 15px;
          }
        }

        &.aem-Grid {
          &::before,
          &::after {
            display: none;
          }
        }

        .cmp-text {
          h4 {
            text-transform: uppercase;
            color: var(--emu-common-colors-brand-pink-500);
            font-size: var(--emu-common-font-sizes-wide-small);
            font-family: var(--emu-semantic-font-families-body);
            font-weight: 600;
            margin-bottom: 5px;
            line-height: var(--emu-common-line-heights-narrow-body);
            letter-spacing: 1.1px;

            @include mq('medium-plus') {
              letter-spacing: 0.07em;
            }

            sup {
              top: -1px;
              left: -2px;
              font-size: 8px;
            }
          }

          p {
            letter-spacing: 0.03em;
            color: var(--emu-common-colors-white);
            margin-bottom: 26px; // to match with figma
            line-height: var(--emu-common-line-heights-narrow-body);
          }
        }
      }
    }
  }

  .ext-links {
    &__wave .cmp-image__image {
      width: 100%;
      background-color: var(--emu-common-colors-brand-blue-400);
    }

    &__content {
      &-wrapper {
        padding-top: 30px;
        padding-bottom: 28px;
        padding-left: 15px;
        padding-right: 15px;
        max-width: 1200px; // value from live site
        margin-left: auto;
        margin-right: auto;

        @include mq('medium') {
          padding-top: 50px;
          padding-bottom: 50px;
        }
      }

      &-box {
        margin-top: 50px;
        margin-bottom: 50px;
        padding-bottom: 42px;

        @include mq('medium') {
          padding-bottom: 40px;
        }

        @include mq('large') {
          margin-top: 50px;
        }
      }

      &-title {
        h2 {
          font-size: var(--emu-common-font-sizes-narrow-xl);
          line-height: 1.3;
          font-weight: var(--emu-common-font-weight-medium);

          @include mq('medium') {
            font-size: var(--emu-common-font-sizes-wide-xl);
            line-height: 1.1;
            margin-bottom: var(--emu-common-spacing-medium);
          }

          @include mq('large') {
            line-height: var(--emu-common-line-heights-wide-xxl);
          }
        }
      }

      &-btn {
        margin-bottom: var(--emu-common-spacing-none);
        margin-top: 35px;

        @include mq('medium') {
          font-size: var(--emu-common-font-sizes-narrow-small);
          margin: var(--emu-common-spacing-none);
        }

        @include mq('xxx-large') {
          font-size: 15px;
        }

        &.emu-button-v2.emu-button.emu-button {
          width: auto;
          padding-top: 15px;
          padding-bottom: 15px;

          @include mq('medium') {
            padding-top: 9px;
            padding-bottom: 9px;
          }

          @include mq('large') {
            padding-top: 15px;
            padding-right: 30px;
            padding-bottom: 15px;
            padding-left: 30px;
          }

          .cmp-button__text {
            line-height: 16px;
            display: block;

            @include mq('medium') {
              letter-spacing: 0.3em;
            }

            @include mq('large') {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

[lang='fr_ca'] {
  #breast-surgery-resources {
    .glossary
      .accordion
      .aaaem-accordion__panel
      .js-badger-accordion-panel-inner
      .cmp-text
      p {
      width: 93%; //as per live
    }

    .resources__intro {
      &-text {
        max-width: 100%;
      }
    }

    .ext-links {
      .content-box__title h2 b {
        letter-spacing: -1.5px;
      }
    }

    .patients-first__title {
      max-width: 100%;
    }

    .resources__tabs-item-cta {
      text-align: center;
    }

    .aaaem-accordion__header {
      padding-top: 12px;
      padding-bottom: 12px;
    }

    .resources__carousel {
      .tns-nav {
        top: 35%;

        // media query needed to make it look good
        @include mq('576px') {
          top: auto;
          bottom: -30px;
        }
      }
    }
  }
}
