.aaaem-tabs {
  &__tablist {
    justify-content: space-between;
    height: 45px; // value from live site

    @include mq('large') {
      height: 60px; // value from live site
    }
  }

  &__tab {
    margin-bottom: var(--emu-common-spacing-none);
    width: 30%;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.3em;
    text-align: center;

    @include mq('large') {
      font-size: 15px;
      line-height: 18px;
    }

    @include mq('x-large') {
      width: 320px; // value from live site
    }

    a {
      height: 45px; // value from live site
      border-radius: 25px 25px 0px 0px;
      padding-top: 12px;
      padding-right: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include mq('large') {
        height: 60px; // value from live site
        padding-top: var(--emu-common-spacing-small);
        padding-right: var(--emu-common-spacing-medium);
        padding-bottom: var(--emu-common-spacing-small);
        padding-left: var(--emu-common-spacing-medium);
      }
    }
  }

  &__tabpanels {
    background: rgba(255, 255, 255, 0.7); // value from live site
    border-width: var(--emu-common-border-width-thick) 2px 2px 2px;
    border-style: solid;
    border-color: var(--emu-common-colors-brand-blue-500);
    position: relative;
  }

  &__tabpanel {
    padding: 40px;
    opacity: 0;
    transition: opacity var(--emu-common-other-time-duration-instant) linear;
    display: block;

    @include mq('large') {
      padding-top: 50px;
      padding-right: 75px;
      padding-bottom: 50px;
      padding-left: 75px;
    }

    &[hidden='hidden'] {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      overflow: hidden;
      transition: none;
      padding: var(--emu-common-spacing-none);
    }

    &:not([hidden='hidden']) {
      opacity: 1;
    }
  }
}

.u-tabs--inline-in-mobile {
  @include mq('767px', 'max-width') {
    .aaaem-tabs {
      &__tablist {
        display: none;
      }

      &__tabpanels {
        border: none;
      }

      &__tabpanel {
        padding: var(--emu-common-spacing-none);
        opacity: 1;

        &[hidden='hidden'] {
          position: static;
          width: 100%;
        }
      }
    }
  }
}
