.natrelle-inspira {
  position: relative;
  background-color: var(--emu-common-colors-brand-blue-600);
  overflow: hidden;

  @include mq('medium') {
    background-color: var(--emu-common-colors-brand-blue-500);
  }

  &__wrapper {
    padding-top: 34px;

    @include mq('medium') {
      padding-bottom: 50px;
      padding-top: var(--emu-common-spacing-none);
    }

    @include mq('large') {
      padding: 3% 0 7%;
    }

    .image {
      position: static;
      padding-top: 60px;
      padding-bottom: 60px;
      max-width: 75%;
      margin-left: auto;
      overflow: hidden;

      @include mq('medium') {
        max-width: 400px;
        position: absolute;
        top: 0%;
        right: 0;
        width: 100%;
        height: 100%;
        padding: var(--emu-common-spacing-none);
        margin: var(--emu-common-spacing-none);
      }

      @include mq('large') {
        top: 15%;
      }

      @include mq('x-large') {
        right: -60px;
      }

      @include mq('xxx-large') {
        left: 60%;
        right: 0;
      }

      img {
        right: -60px;
        position: relative;

        @include mq('medium') {
          width: 65%;
          max-width: 100%;
          position: absolute;
          right: 0;
        }

        @include mq('large') {
          width: 100%;
        }

        @include mq('xxx-large') {
          width: auto;
        }
      }
    }
  }

  &__content {
    margin: auto;

    &-title {
      @include mq('medium') {
        max-width: 82%;
        font-size: var(--emu-common-font-sizes-narrow-xl);
        line-height: 1;
      }

      @include mq('large') {
        font-size: var(--emu-common-font-sizes-wide-xxl);
        max-width: 91%;
        line-height: var(--emu-common-line-heights-wide-xxl);
      }

      h2 {
        @include mq('medium') {
          font-size: var(--emu-common-font-sizes-narrow-xl);
          line-height: 1;
          letter-spacing: -0.2px;
        }

        @include mq('large') {
          font-size: var(--emu-common-font-sizes-wide-xxl);
          line-height: var(--emu-common-line-heights-wide-xxl);
        }

        @include mq('xxx-large') {
          letter-spacing: -0.3px;
        }

        br {
          @include mq('medium') {
            display: none;
          }
        }

        .emphasis {
          display: block;
        }

        sup {
          font-size: 13px;
          font-style: normal;
          top: -2px;
          left: -1.9px;

          @include mq('medium') {
            font-size: var(--emu-common-font-sizes-narrow-small);
          }

          @include mq('large') {
            font-size: var(--emu-common-font-sizes-wide-small);
            top: -4px;
          }
        }

        i {
          letter-spacing: -0.6px;

          @include mq('medium-plus') {
            letter-spacing: -0.4px;
          }

          @include mq('xxx-large') {
            letter-spacing: -0.7px;
          }
        }
      }
    }

    &-desc {
      padding-top: 15px;
      padding-bottom: 2%;
      letter-spacing: 0.89px;

      @include mq('medium') {
        padding-bottom: 2%;
        padding-top: 1%;
        max-width: 65%;
      }

      @include mq('xxx-large') {
        max-width: 100%;
      }

      &.cmp-text {
        @include mq('medium') {
          margin-bottom: var(--emu-common-spacing-none);
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.03em;
        }

        @include mq('large') {
          letter-spacing: 0.07em;
          line-height: var(--emu-common-line-heights-narrow-body);
        }

        @include mq('xx-large') {
          font-size: var(--emu-common-font-sizes-narrow-body);
          letter-spacing: 1.29px;
        }
      }

      br {
        display: none;

        @include mq('medium-plus') {
          display: block;
        }
      }

      sup {
        font-size: 8px;

        @include mq('medium') {
          font-size: 7px;
          left: -1px;
          top: -1px;
        }
      }
    }

    .button
      .emu-button-v2.emu-button__secondary-outline.emu-button.natrelle-inspira__content-button {
      margin-top: 20px;
      margin-bottom: 20px;
      letter-spacing: 3.9px;
      justify-content: center;
      width: 100%;
      text-align: center;

      @include mq('medium') {
        width: auto;
        padding-top: 9px;
        padding-right: var(--emu-common-spacing-medium);
        padding-bottom: 9px;
        padding-left: 15px;
        font-size: var(--emu-common-font-sizes-narrow-small);
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-none);
        letter-spacing: 3.6px;
      }

      @include mq('medium-plus') {
        padding-left: 15px;
        padding-right: 15px;
      }

      @include mq('large') {
        padding-top: 15px;
        padding-right: 30px;
        padding-bottom: 15px;
        padding-left: 30px;
        font-size: 14px;
        letter-spacing: 4.3px;
      }

      &:hover,
      &:focus {
        // unable to use bg utility class because bg-color is changing for different viewport
        color: var(--emu-common-colors-brand-blue-500) !important;
      }

      &:active {
        background-color: var(--emu-common-colors-transparent);
        color: var(--emu-common-colors-brand-pink-500);
      }

      sup {
        font-size: 6px;
        right: 2px;
        top: -1px;
        letter-spacing: normal;
      }
    }
  }
}

[lang='fr_ca'] {

}